import Dashboard from "./views/Dashboard";
import Dashboard_flow from "./views/Dashboard_flow";

import Changepassword from "./views/Changepassword";
import Realtime from "./views/Realtime";
import Realtime_flow from "./views/Realtime_flow";


import Customers from "./views/Customer";
import Boards from "./views/Board";
import History from "./views/History";
import Savings from "./views/Savings";
import Comparison from "./views/Comparison";
import Landingpage from './views/landingpage';
import PowerAnalyser from './views/Poweranalyser';
import Production from './views/Production';
import Logs from './views/Logs';
import Logs_flow from './views/Logs_flow';
import Logs_admin from './views/Logs_admin';

import Thermostat from "./views/Thermostat";
import hvac from "./views/hvac";
import Demand from "./views/Demand";
import Alert from "./views/Alert";
import TapSetting from "./views/TapSetting";

import Datamangement from "./views/Datamangement";

import sense_logo from "./assets/images/neurosense_logo.png"
import logo from "./assets/images/test.png"
import Operations from "./views/Operations";
import carbon from "./views/carbon";
import Sensors from "./views/Sensors";
import Reports from './views/Reports';
import DigitalAsset from './views/DigitalAsset'
import ADRComponent from './views/ADR';
import Heater from "./views/Heater";
import TreeChart from "./views/Tree";
import BoardStatus from "./views/BoardStatus";
import ColdStorage from "./views/ColdStorage";
import ScheduleCreation from "./views/ScheduleCreation";
import test from "./views/test";
import HeaterSchedule from "./views/HeaterSchedule";
import OrgChart from "./views/OrgChart";
import LightSchedule from "./views/LightSchedule";
import GroupingData from "./views/GroupingData";

const neurobotz="neurobotz.com"
const neurosense="theneurosense.com"
const local="localhost"
const localIP="127.0.0.1"
const ip="139.59.13.114"
const v3_ip="128.199.27.22"
var host = window.location.hostname

export const site_selection = () =>{
  // 1==smartpo
  // 2==neurosense
  // 3==neurobotz US
  var t=1
  if(host==neurosense){
    t=2
  }else if(host==neurobotz  ){
    t=3
  }
  return t
}


export const v3_server_check=()=>{
  var p=false
  if(host== v3_ip){ 
     p=true
   }
   return p
}

export const routeselect =()=>{
  var x=routes
  if(site_selection() == 3  ){ 
   x=routesUS
  }else if(site_selection() == 2 ){
    var z=localStorage.getItem("meter")
    x=routes_neuro_meter

   if(z=="2"){
    x=routes_neuro

   }
  }
  // console.log(x,"jasdgajsh",host,routes)
  return x
}
export const initialRoutes =()=>{
  var x="/neurobotz/dashboard"
  if(site_selection() == 2){
    var z=localStorage.getItem("meter")
   if(z=="2"){
    x="/neurobotz/dashboard_flow"

   }
  }
  return x
}

export const ApplicationName =()=>{
  var x="MY SMARTPO"
  if(site_selection() == 3   ){ 
   x="NEUROBOTZ"
  }else if(site_selection() == 2 ){
    x="Neurosense"
  }
  return x 
}
export const initialRoute =()=>{
  var x="/neurobotz/process"
  if(site_selection() == 3  ){
   x="/neurobotz/dashboard"
  }
  return x
}
export const logoimage =()=>{
  var x=logo
  if(site_selection() == 2  ){
   x=sense_logo
  }
  return x
}
export const chartPhase =()=>{
  var x=true
  if(site_selection() == 3  ){
   x=false
  }
  return x
}
export const comparison_baseline =()=>{
  var x=true
  if(site_selection() == 2 ){
   x=false
  }
  return x
}
export const footerName =()=>{
  var x={name:"Empores LLC",link:"http://emporesllc.com/"}
  if(site_selection() == 3   ){ 
    x={name:"Neurobotz",link:"http://neurobotz.com/"}
  }else if(site_selection() == 2 ){
    x={name:"Neurosense",link:"http://theneurosense.com/"}

  }
  return x
}
export const routes = [
    {
      path: "/customers",
      name: "Customers",
      icon: "fas fa-user-plus",
      component: Customers,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/boards",
      name: "Boards",
      icon: "fas fa-tachometer-alt",
      component: Boards,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "fas fa-chart-pie",
      component: Landingpage,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/digitalassets",
      name: "Digital Asset",
      icon: "fas fa-microchip",
      component: DigitalAsset,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/process",
      name: "Process",
      icon: "fas fa-desktop",
      component: Dashboard,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/operations",
      name: "Operations",
      icon: "fas fa-cogs",
      component: Operations,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/group_data",
      name: "Grouping",
      icon: "fa fa-object-group",
      component: GroupingData,
      layout: "/neurobotz",
      // visible: false,
      // adminvisible: true
      visible: true,
      adminvisible: true
    },
    {
      path: "/carbon",
      name: "Carbon emission",
      icon: "fab fa-cloudversify",
      component: carbon,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/production",
      name: "Production",
      icon: "fas fa-industry",
      component: Production,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/realtime",
      name: "Realtime",
      icon: "fas fa-chart-line",
      component: Realtime,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/sld",
      name: "SLD",
      icon: "fas fa-project-diagram",
      component: OrgChart,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/history",
      name: "History",
      icon: "fas fa-history",
      component: History,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/savings",
      name: "Savings",
      icon: "fas fa-money-bill-alt",
      component: Savings,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/comparison",
      name: "Comparison",
      icon: "fas fa-balance-scale",
      component: Comparison,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/tapping_settings",
      name: "Tap Settings",
      icon: "fas fa-cog",
      component: TapSetting,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/demand",
      name: "Demand",
      icon: "fas fa-chart-area",
      component: Demand,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/autodemandresponse",
      name: "ADR",
      icon: "fas fa-clock",
      component: ADRComponent,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/poweranalyser",
      name: "Power Analyser",
      icon: "fas fa-plug",
      component: PowerAnalyser,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    // {
    //   path: "/thermostat",
    //   name: "Thermostat",
    //   icon: "fas fa-thermometer-three-quarters",
    //   component: Thermostat,
    //   layout: "/neurobotz",
    //   visible: false,
    //   adminvisible: true
    // },
    // {
    //   path: "/hvac",
    //   name: "HVAC",
    //   icon: "fas fa-snowflake",
    //   component: hvac,
    //   layout: "/neurobotz",
    //   visible: true,
    //   adminvisible: true
    // },
    // {
    //   path: "/heater",
    //   name: "HEATER",
    //   icon: "fas fa-fire",
    //   component: Heater,
    //   layout: "/neurobotz",
    //   visible: true,
    //   adminvisible: true
    // },
    {
      path: "/hvac_schedule",
      name: "Iglupod",
      icon: "fa fa-snowflake",
      component: ScheduleCreation,
      layout: "/neurobotz",
      // visible: false,
      // adminvisible: true
      visible: true,
      adminvisible: true
    },
    {
      path: "/sldconfig",
      // name: "Data Mgmt",
      // icon: "fa fa-database",
      component: OrgChart,
      layout: "/neurobotz",
      // visible: false,
      // adminvisible: true
    },
    {
      path: "/HeaterSchedule",
      name: "Neuroswitch",
      icon: "fa fa-fire",
      component: HeaterSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/LightSchedule",
      name: "Digital Breaker",
      icon: "fa fa-th",
      component: LightSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/cold_storage",
      name: "Cold Storage",
      icon: "fas fa-thermometer-three-quarters",
      component: ColdStorage,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    // {
    //   path: "/alert",
    //   name: "Alert",
    //   icon: "fas fa-bell",
    //   component: Alert,
    //   layout: "/neurobotz",
    //   visible: true,
    //   adminvisible: true
    // },
    {
      path: "/logs",
      name: "Logs",
      icon: "fas fa-info-circle",
      component: Logs,
      layout: "/neurobotz",
      visible: true,
      adminvisible: false
    },
    {
      path: "/logs_admin",
      name: "Logs",
      icon: "fas fa-info-circle",
      component: Logs_admin,
      layout: "/neurobotz",
      visible: false,
      adminvisible: true
    },
    {
      path: "/sensors",
      name: "Sensors",
      icon: "nc-icon nc-tablet-2",
      component: Sensors,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/reports",
      name: "Reports",
      icon: "fas fa-file",
      component: Reports,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/board_status",
      name: "Board Status",
      icon: "fas fa-wifi",
      component: BoardStatus,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/changepassword",
      name: "Change Password",
      icon: "nc-icon nc-lock-circle-open",
      component: Changepassword,
      layout: "/neurobotz",
      visible: false,
      adminvisible: false
    },
    {
      path: "/datamanagement",
      // name: "Data Mgmt",
      // icon: "fa fa-database",
      component: Datamangement,
      layout: "/neurobotz",
      // visible: false,
      // adminvisible: true
    }
]
export const routesUS = [
  {
    path: "/customers",
    name: "Customers",
    icon: "fas fa-user-plus",
    component: Customers,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/boards",
    name: "Boards",
    icon: "fas fa-tachometer-alt",
    component: Boards,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-pie",
    component: Dashboard,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },

  {
    path: "/realtime",
    name: "Realtime",
    icon: "fas fa-chart-line",
    component: Realtime,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/sld",
    name: "SLD",
    icon: "fas fa-project-diagram",
    component: OrgChart,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/history",
    name: "History",
    icon: "fas fa-history",
    component: History,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/savings",
    name: "Savings",
    icon: "fas fa-money-bill-alt",
    component: Savings,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/operations",
    name: "Operations",
    icon: "fas fa-cogs",
    component: Operations,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/group_data",
    name: "Grouping",
    icon: "fa fa-object-group",
    component: GroupingData,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
    visible: true,
    adminvisible: true
  },
  {
    path: "/carbon",
    name: "Carbon emission",
    icon: "fab fa-cloudversify",
    component: carbon,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/comparison",
    name: "Comparison",
    icon: "fas fa-balance-scale",
    component: Comparison,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/tapping_settings",
    name: "Tap Settings",
    icon: "fas fa-cog",
    component: TapSetting,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/demand",
    name: "Demand",
    icon: "fas fa-chart-area",
    component: Demand,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/poweranalyser",
    name: "Power Analyser",
    icon: "fas fa-plug",
    component: PowerAnalyser,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  // {
  //   path: "/thermostat",
  //   name: "Thermostat",
  //   icon: "fas fa-thermometer-three-quarters",
  //   component: Thermostat,
  //   layout: "/neurobotz",
  //   visible: false,
  //   adminvisible: true
  // },
  // {
  //   path: "/hvac",
  //   name: "HVAC",
  //   icon: "fas fa-snowflake",
  //   component: hvac,
  //   layout: "/neurobotz",
  //   visible: true,
  //   adminvisible: true
  // },
  // {
  //   path: "/heater",
  //   name: "HEATER",
  //   icon: "fas fa-fire",
  //   component: Heater,
  //   layout: "/neurobotz",
  //   visible: true,
  //   adminvisible: true
  // },
  {
    path: "/hvac_schedule",
    name: "Iglupod",
    icon: "fas fa-snowflake",
    component: ScheduleCreation,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
    visible: true,
    adminvisible: true
  },
  {
      path: "/sldconfig",
      // name: "Data Mgmt",
      // icon: "fa fa-database",
      component: OrgChart,
      layout: "/neurobotz",
      // visible: false,
      // adminvisible: true
    },
    {
      path: "/HeaterSchedule",
      name: "Neuroswitch",
      icon: "fa fa-fire",
      component: HeaterSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/LightSchedule",
      name: "Digital Breaker",
      icon: "fa fa-th",
      component: LightSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/cold_storage",
      name: "Cold Storage",
      icon: "fas fa-thermometer-three-quarters",
      component: ColdStorage,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
  // {
  //   path: "/alert",
  //   name: "Alert",
  //   icon: "fas fa-bell",
  //   component: Alert,
  //   layout: "/neurobotz",
  //   visible: true,
  //   adminvisible: true
  // },
  {
    path: "/logs",
    name: "Logs",
    icon: "fas fa-info-circle",
    component: Logs,
    layout: "/neurobotz",
    visible: true,
    adminvisible: false
  },
  {
    path: "/logs_admin",
    name: "Logs",
    icon: "fas fa-info-circle",
    component: Logs_admin,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/changepassword",
    name: "Change Password",
    icon: "nc-icon nc-lock-circle-open",
    component: Changepassword,
    layout: "/neurobotz",
    visible: false,
    adminvisible: false
  },
  {
    path: "/datamanagement",
    // name: "Data Mgmt",
    // icon: "fa fa-database",
    component: Datamangement,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  }
]
export const routes_neuro_meter = [
  {
    path: "/customers",
    name: "Customers",
    icon: "fas fa-user-plus",
    component: Customers,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/boards",
    name: "Boards",
    icon: "fas fa-tachometer-alt",
    component: Boards,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-pie",
    component: Landingpage,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/digitalassets",
    name: "Digital Asset",
    icon: "fas fa-microchip",
    component: DigitalAsset,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/process",
    name: "Process",
    icon: "fas fa-desktop",
    component: Dashboard,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/operations",
    name: "Operations",
    icon: "fas fa-cogs",
    component: Operations,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/group_data",
    name: "Grouping",
    icon: "fa fa-object-group",
    component: GroupingData,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
    visible: true,
    adminvisible: true
  },
  {
    path: "/carbon",
    name: "Carbon emission",
    icon: "fab fa-cloudversify",
    component: carbon,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/production",
    name: "Production",
    icon: "fas fa-industry",
    component: Production,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/realtime",
    name: "Realtime",
    icon: "fas fa-chart-line",
    component: Realtime,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/sld",
    name: "SLD",
    icon: "fas fa-project-diagram",
    component: OrgChart,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/history",
    name: "History",
    icon: "fas fa-history",
    component: History,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/tariff",
    name: "Tariff",
    icon: "fas fa-balance-scale",
    component: Comparison,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },

  {
    path: "/demand",
    name: "Demand",
    icon: "fas fa-chart-area",
    component: Demand,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/autodemandresponse",
    name: "ADR",
    icon: "fas fa-clock",
    component: ADRComponent,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/poweranalyser",
    name: "Power Analyser",
    icon: "fas fa-plug",
    component: PowerAnalyser,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  // {
  //   path: "/alert",
  //   name: "Alert",
  //   icon: "fas fa-bell",
  //   component: Alert,
  //   layout: "/neurobotz",
  //   visible: true,
  //   adminvisible: true
  // },
  {
    path: "/logs",
    name: "Logs",
    icon: "fas fa-info-circle",
    component: Logs,
    layout: "/neurobotz",
    visible: true,
    adminvisible: false
  },
  {
    path: "/logs_admin",
    name: "Logs",
    icon: "fas fa-info-circle",
    component: Logs_admin,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/sensors",
    name: "Sensors",
    icon: "nc-icon nc-tablet-2",
    component: Sensors,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/hvac_schedule",
    name: "Iglupod",
    icon: "fa fa-snowflake",
    component: ScheduleCreation,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
    },
    {
      path: "/HeaterSchedule",
      name: "Neuroswitch",
      icon: "fa fa-fire",
      component: HeaterSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
      path: "/LightSchedule",
      name: "Digital Breaker",
      icon: "fa fa-th",
      component: LightSchedule,
      layout: "/neurobotz",
      visible: true,
      adminvisible: true
    },
    {
    path: "/cold_storage",
    name: "Cold Storage",
    icon: "fas fa-thermometer-three-quarters",
    component: ColdStorage,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-file",
    component: Reports,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/board_status",
    name: "Board Status",
    icon: "fas fa-wifi",
    component: BoardStatus,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/changepassword",
    name: "Change Password",
    icon: "nc-icon nc-lock-circle-open",
    component: Changepassword,
    layout: "/neurobotz",
    visible: false,
    adminvisible: false
  },
  {
    path: "/datamanagement",
    // name: "Data Mgmt",
    // icon: "fa fa-database",
    component: Datamangement,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: false
  }
]
export const routes_neuro = [
  {
    path: "/customers",
    name: "Customers",
    icon: "fas fa-user-plus",
    component: Customers,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/boards",
    name: "Boards",
    icon: "fas fa-tachometer-alt",
    component: Boards,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/dashboard_flow",
    name: "Dashboard",
    icon: "fas fa-chart-pie",
    component: Dashboard_flow,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/group_data",
    name: "Grouping",
    icon: "fa fa-object-group",
    component: GroupingData,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
    visible: true,
    adminvisible: true
  },
  {
    path: "/digitalassets",
    name: "Digital Asset",
    icon: "fas fa-microchip",
    component: DigitalAsset,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/realtime_flow",
    name: "Realtime",
    icon: "fas fa-chart-line",
    component: Realtime_flow,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/sld",
    name: "SLD",
    icon: "fas fa-project-diagram",
    component: OrgChart,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/operations",
    name: "Operations",
    icon: "fas fa-cogs",
    component: Operations,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/carbon",
    name: "Carbon emission",
    icon: "fab fa-cloudversify",
    component: carbon,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/logs_flow",
    name: "Logs",
    icon: "fas fa-info-circle",
    component: Logs_flow,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/sensors",
    name: "Sensors",
    icon: "nc-icon nc-tablet-2",
    component: Sensors,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/hvac_schedule",
    name: "Iglupod",
    icon: "fa fa-snowflake",
    component: ScheduleCreation,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/sldconfig",
    // name: "Data Mgmt",
    // icon: "fa fa-database",
    component: OrgChart,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
  },
  {
    path: "/HeaterSchedule",
    name: "Neuroswitch",
    icon: "fa fa-fire",
    component: HeaterSchedule,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/LightSchedule",
    name: "Digital Breaker",
    icon: "fa fa-th",
    component: LightSchedule,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/cold_storage",
    name: "Cold Storage",
    icon: "fas fa-thermometer-three-quarters",
    component: ColdStorage,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-file",
    component: Reports,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/board_status",
    name: "Board Status",
    icon: "fas fa-wifi",
    component: BoardStatus,
    layout: "/neurobotz",
    visible: true,
    adminvisible: true
  },
  {
    path: "/autodemandresponse",
    name: "ADR",
    icon: "fas fa-clock",
    component: ADRComponent,
    layout: "/neurobotz",
    visible: false,
    adminvisible: true
  },
  {
    path: "/changepassword",
    name: "Change Password",
    icon: "nc-icon nc-lock-circle-open",
    component: Changepassword,
    layout: "/neurobotz",
    visible: false,
    adminvisible: false
  },
  {
    path: "/datamanagement",
    // name: "Data Mgmt",
    // icon: "fa fa-database",
    component: Datamangement,
    layout: "/neurobotz",
    // visible: false,
    // adminvisible: true
  }
]

