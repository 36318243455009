import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,CardText
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import classnames from 'classnames';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import swal from 'sweetalert';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Savings extends React.Component {
    constructor(props) {
        super();
        let daysOfYear = [];
        let getyear = new Date();
		let s = getyear.getFullYear();
		let t = getyear.getFullYear() - 10;
		for (let d = t; d <= s; d++) {
			daysOfYear.push(d);
        }
        this.daysOfYear = daysOfYear
		this.currentyear = getyear.getFullYear().toString();
        this.state = {
            loader: false,
            charttype: 'column',
            checkboxvalue:  'kWh',
            period: 'MONTHLY',
            prefix: '',
            suffix: '',
            fillAlphas: 1,
            graphtype: 'column',
            belowtable: [],
            chartdata: [],
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1,
            belowtableuom: 'kWh',
            activetab: '1',
            buttontext: 'Add',
            intervention_list: [],
            showcontent: false,            
            yearrange: [
                { 'id': 1, "itemName": this.daysOfYear[10] },
                { 'id': 2, "itemName": this.daysOfYear[9] },
                { 'id': 3, 'itemName': this.daysOfYear[8] },
                { 'id': 4, 'itemName': this.daysOfYear[7] },
                { 'id': 5, 'itemName': this.daysOfYear[6] },
                { 'id': 6, "itemName": this.daysOfYear[5] },
                { 'id': 7, 'itemName': this.daysOfYear[4] },
                { 'id': 8, "itemName": this.daysOfYear[3] },
                { 'id': 9, 'itemName': this.daysOfYear[2] },
                { 'id': 10, 'itemName': this.daysOfYear[1] },
                { 'id': 11, 'itemName': this.daysOfYear[0] }
            ],
            selectedyear: this.daysOfYear[10],     
            monthrange: [
                { 'id': 1, "itemName": 'Jan ' + this.currentyear },
                { 'id': 2, 'itemName': 'Feb ' + this.currentyear },
                { 'id': 3, 'itemName': "Mar " + this.currentyear },
                { 'id': 4, "itemName": 'Apr ' + this.currentyear },
                { 'id': 5, 'itemName': 'May ' + this.currentyear },
                { 'id': 6, 'itemName': 'Jun ' + this.currentyear },
                { 'id': 7, 'itemName': 'Jul ' + this.currentyear },
                { 'id': 8, "itemName": "Aug " + this.currentyear },
                { 'id': 9, "itemName": "Sep " + this.currentyear },
                { 'id': 10, "itemName": "Oct " + this.currentyear },
                { 'id': 11, "itemName": "Nov " + this.currentyear },
                { 'id': 12, 'itemName': 'Dec ' + this.currentyear }
            ],
            selectedmonth: ['Jan ' + this.currentyear],
            quarterrange: [
                { 'id': 1, 'itemName': 'Q1 ' + this.currentyear, "value": 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear },
                { 'id': 2, "itemName": "Q2 " + this.currentyear, "value": "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear },
                { 'id': 3, 'itemName': "Q3 " + this.currentyear, "value": "Jul " + this.currentyear + "," + 'Aug ' + this.currentyear + "," + 'Sep ' + this.currentyear },
                { 'id': 4, "itemName": 'Q4 ' + this.currentyear, 'value': "Oct " + this.currentyear + ',' + "Nov " + this.currentyear + ',' + 'Dec ' + this.currentyear },
            ],
            selectedquarter: 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear,
        }
        

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = ['Jan ' + this.currentyear];
        this.graphtype = 'column'
        this.fillAlphas= 1
        this.savingsconfig = {}
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.convertdate(new Date())
        this.props.history.push('/neurobotz/savings')
        if (localStorage.getItem('selectedboard')) {
            // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            this.setState({
                showcontent: true
            })
        }
        if (localStorage.getItem('currency') == 'INR') {
            this.setState({suffix : localStorage.getItem('currency')})
        } else {
            this.setState({prefix : '$'})
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.interventionForm.patchValue({
            date:this.today
        })
    }

    interventionForm = FormBuilder.group({
        id: [""],
        date: [this.today],
        intervention_type: ["", [Validators.required]],
        technology: ["", [Validators.required]],
        customer_id: [localStorage.getItem('selectedcustomer')],
        created_by: [localStorage.getItem('user_id')],
        updated_by: [localStorage.getItem('user_id')],
        board_id: [localStorage.getItem('selectedboard')],
    });

    interventioncancel() {
        this.interventionForm.patchValue({
            id: '',
            date: this.today,
            intervention_type: '',
            technology: ''
        })
        this.setState({
            buttontext: 'Add'
        })
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            intervention_list: []
        })
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true
        })
        if (this.state.activetab == '1') {
            if (this.state.period == 'MONTHLY') {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'QUARTERLY') {
                this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
            } else {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            }
        } else if (this.state.activetab == '2') {
            this.getinterventionlist(board_id)
            this.interventioncancel();
            this.setState({
                buttontext: 'Add'
            })
        } else {
            if (this.state.period == 'MONTHLY') {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'QUARTERLY') {
                this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
            } else {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            }
        }
        
    }

    periodhandleChange(e) {
        e.preventDefault();
        this.setState({
            period: e.target.value
        })
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.setState({
            checkboxvalue: e.target.value
        })
    }

    charthandleChange(e) {
        e.preventDefault();
        if (e.target.value == 'line') {
            this.setState({
                charttype: 'line',
                fillAlphas: 0
            })
            this.graphtype = 'line'
            this.fillAlphas= 0
        } else {
            this.setState({
                charttype: 'column',
                fillAlphas: 1
            })
            this.graphtype = 'column'
            this.fillAlphas= 1
        }
        this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
    }

    rangehandleChange(range) {
        if (this.state.period == 'MONTHLY') {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i] == range) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj != range)
            } else {
                this.pri_list.push(range)
            }
            this.setState({
                selectedmonth: this.pri_list
            })
        } else if (this.state.period == 'QUARTERLY') {
            this.setState({
                selectedquarter: range
            })
        } else if (this.state.period == 'YEARLY') {
            this.setState({
                selectedyear: range
            })
        } else {
            this.setState({
                selectedmonth: this.pri_list
            })
        }
    }

    handleSubmit() {
        this.setState({
            belowtableuom: this.state.checkboxvalue,
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1
        })
        if (this.state.period == 'MONTHLY') {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'QUARTERLY') {
            this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'YEARLY') {
            this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            this.setState({
                currentbelowtableyear: this.state.selectedyear,
                previousbelowtableyear: this.state.selectedyear - 1
            })
        } else {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        }
    }

    interventionhandleSubmit(event,formvalues) {
        event.preventDefault();
        formvalues.board_id = localStorage.getItem('selectedboard')
        formvalues.customer_id = localStorage.getItem('selectedcustomer')
        if (formvalues.id == '' || formvalues.id == undefined || formvalues.id == null) {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Add Intervention")
            } else {
                this.setState({ loader: true })
                apiService.AddInterventions(formvalues).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Intervention Added Successfully")          
                    this.getinterventionlist(formvalues.board_id)
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            }
        } else {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Add Intervention")
            } else {
                this.setState({ loader: true })
                apiService.UpdateInterventions(formvalues).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Intervention Updated Successfully")       
                    this.getinterventionlist(formvalues.board_id)
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } 
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
    }

    fetchdata(period,range,checkboxvalue,board_id) {
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Board needed")  
        } else {
            this.setState({ loader: true })
            apiService.OldSavingschartdata(period,range,checkboxvalue,board_id).then(response => {
                     var array=response.data.meter_data[0][Object.keys(response.data.meter_data[0])[0]]
                    // console.log(Object.keys(response.data.meter_data[0])[0])
                    var array1=array.map((obj)=>{
                         if(obj.previous==0){
                           obj.difference=0
                         }
                        return obj
                    })
                this.setState({
                            belowtable: array,
                            chartdata:array1
                        })
               
                this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
                this.setState({
                    loader: false,
                }) 
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } 
    }

    toggle(option) {
        this.setState({
            activetab: option
        })
        if (option == '2') {
            this.getinterventionlist(localStorage.getItem('selectedboard'))
        } 
        // else {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        // }
    }

    getinterventionlist(board_id) {
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Choose Some Board")   
        } else {
            if ($.fn.dataTable.isDataTable('#interventiontable')) {
                $('#interventiontable').DataTable().clear().destroy();
            }  
            this.setState({ loader: true })
            apiService.Interventionlist(board_id).then(response => {
                this.setState({intervention_list: this.state.intervention_list.concat(response.data)})
                $(document).ready(function () {
                    $('#interventiontable').DataTable({
                      "bLengthChange": false,
                      "pageLength": 5,
                      "searching": true,
                      "bInfo": true,
                      "bSort": true,
                      "bPaginate": true,
                      "dom": 'Bfrtip',
                      "buttons": []
                    });
                });
                this.setState({ loader: false })
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    interventionedit(values) {
        this.interventionForm.patchValue({
            id: values.id,
            date: values.date,
            intervention_type: values.intervention_type,
            technology: values.technology
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    interventiondelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Intervention!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.DeleteInterventions(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Intervention Deleted Successfully")      
                    this.getinterventionlist(localStorage.getItem('selectedboard'))
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    createchart(chartdata,belowtableuom,theme) {
        this.savingsconfig = AmCharts.makeChart("savingchart",{
            "type": "serial",
            'hideCredits': true,
             "theme": theme,
             "legend": {
                "horizontalGap": 10,
                "maxColumns": 2,
                "position": "top",
                // "useGraphSettings": true,
                "markerSize": 10,
                "data": [{
                    'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
                    "color": "#f44336"
                  }, {
                    'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',
                    "color": "#9c27b0"
                  }]
            },
            
            "categoryField": "month",
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start",
                "position": "left"
            },
            "trendLines": [],
            "graphs":[
                {
                    "balloonText": "Previous:[[value]] " + belowtableuom,
                    "fillColors": "#f44336",
                    'lineColor': '#f44336',
                    "fillAlphas": this.fillAlphas,
                    "id": "AmGraph-2",
                    "lineAlpha": 1,
                    'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
                    "type": this.graphtype,
                    "valueField": "previous",
                    "labelPosition": "top",
                        "labelText": "[[previous_intervention]]"
                },
                {
                    "balloonText": "Current:[[value]] " + belowtableuom,
                    "fillColors": "#9c27b0",
                    'lineColor': '#9c27b0',
                    "fillAlphas": this.fillAlphas,
                    "id": "AmGraph-1",
                    "lineAlpha": 1,
                    'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',
                    "type": this.graphtype,
                    "valueField": "current",
                    "labelPosition": "top",
                        "labelText": "[[current_intervention]]",
                    
                },
                {
                    "balloonText": "Difference:[[difference]] " + belowtableuom,
                    "fillColorsField":"flag",
                    "fillAlphas": this.fillAlphas,
                    "id": "AmGraph-3",
                    "lineAlpha": 1,
                    "type": this.graphtype,
                    "valueField": "difference",
                    "labelPosition": "top",
                    "labelText": "[[percentage]] %"
                }
            ],
            "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "position": "bottom",
                    "axisAlpha": 0,
                    'title': belowtableuom
                }
            ],
            "allLabels": [],
            "balloon": {},
            "titles": belowtableuom,
            "dataProvider": chartdata,
            "responsive": {
                "enabled": true
            },
            "export": {
                "enabled": true
             }
        })
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ? 
                <>
              <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Savings
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Interventions
                            </NavLink>
                        </NavItem>
                    </Nav> 
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <TabContent activeTab={this.state.activetab}>
                    <TabPane tabId="1">
                    <form>
                        <Row>
                            <Col className="pr-1" md="3">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                        <InputLabel id="demo-simple-select-outlined-label1">Period</InputLabel>  
                                            <Select
                                                labelId="demo-simple-select-outlined-label1"
                                                id="demo-simple-select-outlined1"
                                                label="Period"
                                                fullWidth variant="outlined"
                                                onChange={(e) => this.periodhandleChange(e)}
                                                value={this.state.period}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'MONTHLY'}>Monthly</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'QUARTERLY'}>Quarterly</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'YEARLY'}>Yearly</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="3">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <InputLabel id="demo-mutiple-name-label">Range</InputLabel>
                                            {this.state.period == 'MONTHLY' ?
                                                <div>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Select
                                                        labelId="demo-mutiple-name-label"
                                                        id="demo-mutiple-name"
                                                        multiple variant="outlined" fullWidth
                                                        value={this.state.selectedmonth}
                                                        label="Range"
                                                        >
                                                        {this.state.monthrange.map((option) => (
                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                            {option.itemName}
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                    
                                            : 
                                            this.state.period == 'QUARTERLY' ? 
                                                <Select
                                                    labelId="demo-simple-select-outlined-label4"
                                                    id="demo-simple-select-outlined4"
                                                    label="Range"
                                                    fullWidth variant="outlined"
                                                    value={this.state.selectedquarter}
                                                    >
                                                    {this.state.quarterrange.map(option => (
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.value} onClick={(e) => this.rangehandleChange(option.value)}>
                                                        {option.itemName}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            : this.state.period == 'YEARLY' ?
                                                <Select
                                                    labelId="demo-simple-select-outlined-label4"
                                                    id="demo-simple-select-outlined4"
                                                    label="Range"
                                                    fullWidth variant="outlined"
                                                    value={this.state.selectedyear}
                                                    >
                                                    {this.state.yearrange.map(option => (
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                        {option.itemName}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            :
                                            ''}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item> 
                                            <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                            <Select
                                                labelId="demo-simple-select-outlined-label2"
                                                id="demo-simple-select-outlined2"
                                                label="Units"
                                                fullWidth variant="outlined"
                                                onChange={(e) => this.uomhandleChange(e)}
                                                value={this.state.checkboxvalue}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kWh'}>kWh</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kW'}>kW</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kVA'}>kVA</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'Cost'}>Cost</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label3"
                                                id="demo-simple-select-outlined3"
                                                fullWidth variant="outlined"
                                                label="Charts"
                                                onChange={(e) => this.charthandleChange(e)}
                                                value={this.state.charttype}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div style={{paddingTop: '0.8em'}}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleSubmit()}
                                            >
                                            Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                        </Row>
                    </form>
                    <hr />
                    <div className="pull-left">
                        <span className="fas fa-arrow-down" style={{fontSize: "15px !important",color: "#FFA500"}}></span> Decrease &nbsp;
                        <span className="fas fa-arrow-up" style={{fontSize: "15px !important",color: "#008000"}}></span> Increase
                    </div>  
                    <div id="savingchart" style={{ width: "100%", height: "500px" }} options={this.savingsconfig} />
                    <hr style={{marginTop: "2rem"}} />
                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <table id="historybelowtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Previous Year ({this.state.previousbelowtableyear}) {this.state.belowtableuom}</th>
                                    <th>Current Year ({this.state.currentbelowtableyear}) {this.state.belowtableuom}</th>
                                    <th>Savings (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.belowtable.map((value, index) => (
                                <tr key={index+1}>
                                    <td>{value.month}</td>
                                    <td>{value.previous}</td>
                                    <td>{value.current}</td>  
                                    <td>{value.percentage}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <FieldGroup
                        control={this.interventionForm}
                        strict= {false}
                        render={({ get, invalid, value }) => (
                        <form>
                            <Row>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="intervention_type"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Intervention Type" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                    {touched
                                                        && hasError("required")
                                                        && `* Intervention Type is required`}
                                                </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="technology"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Technology" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                    {touched
                                                        && hasError("required")
                                                        && `* Technology is required`}
                                                </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="date"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <TextField type="date" required fullWidth variant="outlined" label="Date" error={touched && hasError("required")}  {...handler("text")} />
                                                </Grid>
                                                <span className="error">
                                                    {touched
                                                        && hasError("required")
                                                        && `* Date is required`}
                                                </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={invalid}
                                        onClick={(event) => this.interventionhandleSubmit(event,this.interventionForm.getRawValue())}
                                        >
                                        {this.state.buttontext}
                                    </Button>&nbsp;
                                    <Button
                                        onClick={() => this.interventioncancel()}
                                        variant="contained"
                                        color="danger"
                                        >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        )} 
                    /> 
                        <hr />
                        <Row>
                            <Col>
                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="interventiontable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Intervention Type</th>
                                            <th>Technology</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.intervention_list.map((value, index) => (
                                        <tr key={index+1}>
                                            <td>
                                                <a href="javascript:void(0)" onClick={(event) => this.interventiondelete(event,value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.interventionedit(value)}><i className="fa fa-edit icons"></i></a>
                                            </td>
                                            <td>{value.intervention_type}</td>
                                            <td>{value.technology}</td>
                                            <td>{value.date}</td>       
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                            </Col>
                        </Row>
                        </TabPane>
                    </TabContent>
                </CardBody>
              </Card>
            </Col>
              </Row>
              </>
              : 
              <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Savings);