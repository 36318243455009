import React, { Component } from 'react';
import OrgChart from "@balkangraph/orgchart.js"; 

export default class OrganizationChart extends Component {
    
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
    }

    componentDidMount() {
        OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.myTemplate.size = [250, 300];
        OrgChart.templates.myTemplate.img_0 =
        '<clipPath id="ulaImg">'
        + '<circle cx="150" cy="40" r="100"></circle>'
        + '</clipPath>'
        + '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="65" y="10" width="150" height="100">'
        + '</image>';
        OrgChart.templates.myTemplate.field_0 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 17px;font-weight: bold" fill="#000000" x="130" y="125" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_1 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="165" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_2 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="185" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_3 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="205" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_4 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="225" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_5 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="245" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_6 = '<text data-width="230" data-text-overflow="multiline"  style="font-size: 15px;" fill="#000000" x="130" y="265" text-anchor="middle">{val}</text>';


        this.chart = new OrgChart (this.divRef.current , {
            template: "myTemplate",
            nodes: this.props.nodes,
            enableSearch: false,
            enableDragDrop: false,
            nodeMouseClick: OrgChart.action.none,
            mouseScrool: OrgChart.action.none,
            collapse: {
                level: 2
            },
            menu: {
                pdf: { text: "Export PDF" },
                png: { text: "Export PNG" }
            },
            nodeBinding: {
                field_0: 'name',
                img_0: 'img',
                field_1: 'field1',
                field_2: 'field2',
                field_3: 'field3',
                field_4: 'field4',
                field_5: 'field5',
                field_6: 'field6',
            },
        });
    }

    render() {
        return (
            <div id="tree" className={this.props.customclass} ref={this.divRef}></div>
        );
    }
}