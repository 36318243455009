import React, { Component } from 'react';
import './WaveProgressBar.css'; // Import your CSS file

class WaveProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: (((Math.min(Math.max(props.progress, 16), 30)) - 16) / (30 - 16)) * 100,
    };
  }

  componentDidMount() {
    // var sprogress=this.state.progress;
    // this.interval = setInterval(() => {
    //   // Update the progress every second (adjust the interval as needed)
    //   this.setState((prevState) => ({
    //     progress: (sprogress) % 101,
    //   }));
    // }, 100);
  }
componentDidUpdate(prevProps){
  if (this.props.progress !== prevProps.progress) {
    this.state = {
      progress: (((Math.min(Math.max(this.props.progress, 16), 30)) - 16) / (30 - 16)) * 100,
    };
  var sprogress=this.state.progress;
  this.setState((prevState) => ({
    progress: (sprogress) % 101,
  }));
  }
  // var sprogress=this.state.progress;
  //   this.interval = setInterval(() => {
  //     // Update the progress every second (adjust the interval as needed)
  //     this.setState((prevState) => ({
  //       progress: (sprogress) % 101,
  //     }));
  //   }, 100);
}
  componentWillUnmount() {
    clearInterval(this.interval); // Cleanup interval on component unmount
  }

  render() {
    const { progress } = this.state;

    return (
      <div className="wave-progress-container">
        <div className="wave-progress-bar">
          {/* <div className="progress-label" style={{ textAlign: 'justify', marginTop: '140px', position: 'fixed', zIndex: 100 }}>
            {`${progress}%`}
          </div> */}
          <div className="wave" style={{ height: `${progress}%` }}></div>
        </div>
      </div>
    );
  }
}

export default WaveProgressBar;
