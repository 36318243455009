import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import Switch from '@material-ui/core/Switch';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.useTheme(am4themes_animated);

class Landingpage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            yesterday_data:[],
            last_week_data:[],
            last_week_total:0,
            yesterday_total:0

        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        // this.props.history.push('/neurobotz/dashboard_flow')
        if (localStorage.getItem('selectedboard')) {
          // this.initresponse(parseInt(localStorage.getItem('selectedboard')))
          this.setState({
            showcontent: true
          })
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.theme != this.props.theme) {
         this.createchart34('topchartdiv3',this.state.kwyesterday,this.props.theme); 
        this.createchart34('topchartdiv4',this.state.kw7days,this.props.theme);
          }
    }

    customerhandler(customerId) {
      this.selectedcustomer = customerId
      this.setState({
        showcontent: false
      })
    }
  
    boardhandler(board_id) {
      this.selectedboard = board_id
      this.setState({
        yesterday_data: [],
        last_week_data: []
      })
      this.initresponse(board_id)
      this.setState({
        showcontent: true
      })
    }

    initresponse(board_id) {
      this.setState({ loader: true })
      apiService.dashboard_flow(board_id).then(response => {
          this.setState({ loader: false })
          if (response.data.length != 0) {
            this.setState({
              yesterday_data: this.state.yesterday_data.concat(response.data[0].previous_flowtotalizerrate_data),
              last_week_data: this.state.last_week_data.concat(response.data[0].last7days_flowtotalizerrate_data),
               yesterday_total : response.data[0].previous_flowtotalizerrate_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last_week_total : response.data[0].last7days_flowtotalizerrate_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
            })
           this.createchart34('topchartdiv3',response.data[0].previous_flowtotalizerrate_data,this.props.theme); 
            this.createchart34('topchartdiv4',response.data[0].last7days_flowtotalizerrate_data,this.props.theme);
            } 
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }

 

    createchart34(divdata,data,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      let chart = am4core.create(divdata, am4charts.PieChart3D);
      chart.logo.disabled = true;
      chart.hiddenState.properties.opacity = 0;
      chart.data =data
      chart.innerRadius = 50;
      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      series.labels.template.radius = am4core.percent(-45);
      series.labels.template.fill = am4core.color("white");
      series.labels.template.relativeRotation = 90;
      series.hiddenState.properties.endAngle = -90;
      series.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0;
        }
        return radius;
      });
      series.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      series.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0
        }
        return 1;
      });
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;
            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart3 = chart;
    }

   

    componentWillUnmount() {
     
      if (this.chart3) {
        this.chart3.dispose();
      }
   
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
              {this.state.showcontent == true ? 
               <Row>
              
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle"> 
                        Flow Data - Yesterday</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv3" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                      <hr />
                      <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total Flow : {this.state.yesterday_total} m³</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle">
                      Flow Data - Last 7 Days</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <div id="topchartdiv4" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                    <hr />
                    <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total Flow : {this.state.last_week_total} m³</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
              :
              <Row>
                <Col md="12">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                      <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    themecolor: state.themecolor 
  }
}

export default connect(mapStateToProps)(Landingpage);