import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import {SampleBase } from './Samplebase'
import TopNavDropdown from './TopDropdown';
import AmCharts from '@amcharts/amcharts3-react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {chartPhase} from "../routes"
import {v3_server_check} from "../routes"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { array } from "prop-types";
am4core.useTheme(am4themes_animated);

class Operations extends SampleBase {
    constructor(props) {
        super();
        // var newtimeZone = localStorage.getItem('time_zone')
        // if(newtimeZone){
        //     let options = {
        //         timeZone: newtimeZone,
        //         year: 'numeric',
        //         month: 'numeric',
        //         day: 'numeric',
        //         hour: 'numeric',
        //         minute: 'numeric',
        //         second: 'numeric',
        //       },
        //     formatter = new Intl.DateTimeFormat([], options);
        //     var timezone = (formatter.format(new Date())).split(" ");
        //     var timezoneformat = timezone[0].replace(/,\s*$/, "")
        //     var finaloutput = new Date(timezoneformat)
        //     this.today = finaloutput
        // }
        // else{
        //     this.today = new Date(new Date().toDateString());
        // }  
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd =this.today;
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        this.state = {
            loader: false,
            charttype: 'line',
            checkboxvalue:  'y_phase_active_power',
            minutevalue: 60,
            prefix: '',
            suffix: '',
            high: '',
            low: '',
            average: '',
            total: '',

            pageloadcheck : 1,

            newhigh: '',
            newlow: '',
            newaverage: '',
            newtotal: '',

            final_chart_data: [],           
            defaultrange : [this.today,this.today],
            processed_data: [],
            chart_data: [],
            seriesdata: [],
            fillAlphas: 1,
            graphtype: 'line',
            tooltip: {},
            showcontent: false, 
            backgroundcolor: 'white',
            textcolor: 'black',

            pieDataHours:[],
            colorspie1:[],
            pieDataTotalKwh:[],
            colorspie2:[
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
            ],
            pieDataTotalKwhHour:[],
            colorspie3:[
                am4core.color("#bf4040"),
                am4core.color("#e69900"),
            ],
            cardsData:[],
            cardsShutdownData:[]
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.newtest = this.newtest.bind(this);
        this.resetOperations = this.resetOperations.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.graphtype = 'line'
        this.fillAlphas= 1
        this.seriesdata = []
        this.seriesData = []
        this.chartconfig = {}
        this.chartconfig1 = {}
        this.finalchartdata = []
        this.var1 = [[0,0,0]];
        this.var2 = [];
        this.var3 = [];
        this.calcData = []
        this.show=false
    }

    componentDidMount() { 
  
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/operations')
        if (localStorage.getItem('selectedboard')) {
            this.setState({
                showcontent: true
            })
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    componentDidUpdate(prevProps) {
        if (this.props.themecolor == 'greycolor' || this.props.themecolor ==  'whitecolor') {
            this.setState({
                backgroundcolor : 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor : 'white',
                textcolor: 'black'
            })
        }
        if (prevProps.theme != this.props.theme) {
            this.createchart1('piechartdiv1',this.state.pieDataHours,this.props.themecolor,this.state.colorspie1)
            this.createchart1('piechartdiv2',this.state.pieDataTotalKwh,this.props.themecolor,this.state.colorspie2)
            this.createchart1('piechartdiv3',this.state.pieDataTotalKwhHour,this.props.themecolor,this.state.colorspie3)
        }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            
        })
        this.resetOperations()
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.convertdate(this.state.defaultrange,board_id)
        this.setState({
            showcontent: true
        })
        this.resetOperations()
    }

    resetOperations(){
        this.finalchartdata = []
        this.realtimeForm.patchValue({
            baseline:''
        })
        this.setState({
            pieDataHours:[],
            pieDataTotalKwh:[],
            pieDataTotalKwhHour:[],
            cardsData:[],
            cardsShutdownData:[]
        },()=>{
            this.refinechartdata(this.finalchartdata,'kWh')
            this.createchart1('piechartdiv1',this.state.pieDataHours,this.props.themecolor,this.state.colorspie1)
            this.createchart1('piechartdiv2',this.state.pieDataTotalKwh,this.props.themecolor,this.state.colorspie2)
            this.createchart1('piechartdiv3',this.state.pieDataTotalKwhHour,this.props.themecolor,this.state.colorspie3)
        })

    }

    realtimeForm = FormBuilder.group({
        daterange: [""],
        uom: ["y_phase_active_power"],
        chart_type: ["line"],
        minutes: [60],
        benchmark: ["", [Validators.required]]
    });

    handleOperationSubmit(event, values) {
        event.preventDefault()
        
        this.handleRunningSubmit(event,values)
        this.handleOperationRealtimeSubmit(event,values)
    }

    handleOperationRealtimeSubmit(event, values) {
        event.preventDefault()
        const getDateRange = this.convertdate(this.state.defaultrange)
          this.setState({ loader: true })
          apiService.operationSubmit(values,getDateRange[0],getDateRange[1]).then(response => {
            this.setState({loader:false})
            var fin = response.data.chart_data[0].kwh
            this.refinechartdata(fin,'kWh')
            this.finalchartdata = response.data.chart_data[0].kwh
          }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
          })
    }

    handleRunningSubmit(event, values) {
        event.preventDefault()
        const getDateRange = this.convertdate(this.state.defaultrange)
          this.setState({ 
                loader: true,
                pieDataHours:[],
                pieDataTotalKwh:[],
                pieDataTotalKwhHour:[]
            })
          apiService.operationRunningSubmit(values,getDateRange[0],getDateRange[1]).then(response => {

            if(values.uom == 'y_phase_active_power' || values.uom == 'total_kw'){
                const hoursData = response.data.power[0].hours[0]
                const totalKwhData = response.data.power[0].total_kwh[0]
                const totalKwhHoursData = response.data.power[0].total_kwh_per[0]
                const cardArrays1 = [
                    {id:"1", process:'Shutdown - Hours', name:'hours', value:hoursData.zero_power, icolor:'text-danger'}
                ]
                const cardArrays2 = [
                    {id:"2", process:'Process idle condition', name:'hours', value:hoursData.idle_power_lesser, icolor:'text-warning'},
                    {id:"5", process:'Process running condition', name:'hours', value:hoursData.load_power_greater, icolor:'text-success'},
                    {id:"8", process:'Total Hours', name:'hours', value:hoursData.total_hours, icolor:'text-success'},
                    {id:"4", process:'Process idle condition - Average', name:'kWh', value:totalKwhHoursData.idle_kwh_per_hour, icolor:'text-warning'},
                    {id:"3", process:'Process idle condition - Total', name:'kWh', value:totalKwhData.idle_lesser, icolor:'text-warning'},
                    {id:"6", process:'Process running condition - Total', name:'kWh', value:totalKwhData.load_greater, icolor:'text-success'},
                    {id:"9", process:'Total kWh', name:'kWh', value:hoursData.total_kwh, icolor:'text-success'},
                    {id:"7", process:'Process running condition - Average', name:'kWh', value:totalKwhHoursData.load_kwh_per_hour, icolor:'text-success'},
                ]
                this.setState({ 
                    loader: false,
                    cardsShutdownData:cardArrays1,
                    cardsData:cardArrays2,
                    pieDataHours:[
                        {'name': 'Idle','value': hoursData.idle_power_lesser },
                        {'name': 'Load','value': hoursData.load_power_greater },
                        {'name': 'Shutdown','value': hoursData.zero_power }
                    ],
                    pieDataTotalKwh:[
                        {'name': 'Idle','value': totalKwhData.idle_lesser },
                        {'name': 'Load','value': totalKwhData.load_greater },
                    ],
                    pieDataTotalKwhHour:[
                        {'name': 'Idle','value': totalKwhHoursData.idle_kwh_per_hour},
                        {'name': 'Load','value': totalKwhHoursData.load_kwh_per_hour},
                    ]
                },()=>{
                
                    this.createchart1('piechartdiv1',this.state.pieDataHours,this.props.themecolor,this.state.colorspie1)
                    this.createchart1('piechartdiv2',this.state.pieDataTotalKwh,this.props.themecolor,this.state.colorspie2)
                    this.createchart1('piechartdiv3',this.state.pieDataTotalKwhHour,this.props.themecolor,this.state.colorspie3)
                })
            }
            else{
                const hoursData = response.data.amp[0].hours[0]
                const totalKwhData = response.data.amp[0].total_kwh[0]
                const totalKwhHoursData = response.data.amp[0].total_kwh_per[0]
                const cardArrays1 = [
                    {id:"1", process:'Shutdown - Hours', name:'hours', value:hoursData.zero_amps, icolor:'text-danger'}
                ]
                const cardArrays2 = [
                    {id:"2", process:'Process idle condition', name:'hours', value:hoursData.idle_lesser, icolor:'text-warning'},
                    {id:"5", process:'Process running condition', name:'hours', value:hoursData.load_amps_greater, icolor:'text-success'},
                    {id:"8", process:'Total Hours', name:'hours', value:hoursData.total_hours, icolor:'text-success'},
                    {id:"4", process:'Process idle condition - Average', name:'KWh/hour', value:totalKwhHoursData.idle_kwh_per_hour, icolor:'text-warning'},
                    {id:"3", process:'Process idle condition - Total', name:'kWh', value:totalKwhData.idle_lesser, icolor:'text-warning'},
                    {id:"6", process:'Process running condition - Total', name:'kWh', value:totalKwhData.load_greater, icolor:'text-success'},
                    {id:"9", process:'Total kWh', name:'kWh', value:hoursData.total_kwh, icolor:'text-success'},
                    {id:"7", process:'Process running condition - Average', name:'KWh/hour', value:totalKwhHoursData.load_kwh_per_hour, icolor:'text-success'},
                ]
                this.setState({ 
                    loader: false,
                    cardsShutdownData:cardArrays1,
                    cardsData:cardArrays2,
                    pieDataHours:[
                        {'name': 'Idle','value': hoursData.idle_lesser },
                        {'name': 'Load','value': hoursData.load_amps_greater },
                        {'name': 'Shutdown','value': hoursData.zero_amps }
                    ],
                    pieDataTotalKwh:[
                        {'name': 'Idle','value': totalKwhData.idle_lesser },
                        {'name': 'Load','value': totalKwhData.load_greater },
                    ],
                    pieDataTotalKwhHour:[
                        {'name': 'Idle','value': totalKwhHoursData.idle_kwh_per_hour},
                        {'name': 'Load','value': totalKwhHoursData.load_kwh_per_hour},
                    ]
                },()=>{
                
                    this.createchart1('piechartdiv1',this.state.pieDataHours,this.props.themecolor,this.state.colorspie1)
                    this.createchart1('piechartdiv2',this.state.pieDataTotalKwh,this.props.themecolor,this.state.colorspie2)
                    this.createchart1('piechartdiv3',this.state.pieDataTotalKwhHour,this.props.themecolor,this.state.colorspie3)
                })
            }

          }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
          })
    }
    

    createchart1(name,data,theme,colors) { 
        if (theme == 'whitecolor' || theme == 'greycolor') {
          am4core.unuseTheme(am4themes_material);
          am4core.unuseTheme(am4themes_dataviz);
          am4core.unuseTheme(am4themes_kelly);
          am4core.useTheme(am4themes_dark);
        } else {
          am4core.unuseTheme(am4themes_dark);
          am4core.unuseTheme(am4themes_dataviz);
          am4core.unuseTheme(am4themes_kelly);
          am4core.useTheme(am4themes_material);
        } 
        let chart = am4core.create(name, am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0; 
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;
        chart.data = data;
        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "value";
        series.dataFields.category = "name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;
  
        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;
        series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        series.labels.template.adapter.add("radius", function(radius, target) {
          if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
            return 0;
          }
          return radius;
        });
        series.labels.template.adapter.add("fill", function(color, target) {
          if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
            return am4core.color("#ffffff");
          }
          return color;
        });
        series.labels.template.adapter.add("opacity", function(color, target) {
          if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
            return 0
          }
          return 1;
        });
        if(colors.length){
            series.colors.list = colors
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
          relevant: function(target) {
            if (target.pixelWidth <= 400) {
              chart.legend.maxHeight = 150;
  
              chart.legend.disabled = true;
              return true;
            } 
            if (target.pixelWidth > 400) {
              chart.legend.maxHeight = 300;
              chart.legend.disabled = false;
               return false;
            }
            return false;
          },
          state: function(target, stateId) {
            if (target instanceof am4charts.PieSeries) {
              var state = target.states.create(stateId);
              return state;
            }
            return null;
          }
        });
        this.chart1 = chart;
      }

    charthandleChange(e) {
        e.preventDefault();
        this.realtimeForm.patchValue({
            chart_type: e.target.value
        })
        this.setState({
            charttype: e.target.value,
        })
        if (e.target.value == 'area' || e.target.value == 'column') {
            this.fillAlphas= 1
            if (e.target.value == 'area') {
                this.graphtype = 'area'
            } else {
                this.graphtype = 'column'
            }
        } else {
            this.graphtype = 'spline'
            this.fillAlphas= 0
        }
        this.refinechartdata(this.finalchartdata,'kWh')
    }

    datehandleChange(e) {
        this.setState({
            defaultrange: e.target.value
        })
        // this.convertdate(e.target.value,localStorage.getItem('selectedboard'))
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.realtimeForm.patchValue({
            uom: e.target.value
        })
        this.setState({
            checkboxvalue: e.target.value
        })
        // this.show=false
    }

    convertdate(e,board_id) {
        const sy = e[0].getFullYear();
        const sm = e[0].getMonth() + 1;
        const sd = e[0].getDate();
        const ey = e[1].getFullYear();
        const em = e[1].getMonth() + 1;
        const ed = e[1].getDate();
        this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        const finaldate = [this.startdate,this.enddate]
        return finaldate
        // this.fetchdata(this.startdate,this.enddate,this.state.checkboxvalue,board_id,this.state.minutevalue);
    }

    refinechartdata(data,uom) {
        if (uom == 'kWh') {
            var chartdata1 = [];
            var chartdata2 = [];

            for (var i = 0; i <= data.length - 1; i++) {
                var time = new Date(data[i].time)
                var y = time.getUTCFullYear();
                var m = time.getUTCMonth();
                var d = time.getUTCDate();
                var h = time.getUTCHours();
                var min = time.getUTCMinutes();
                var s = time.getUTCSeconds();
                var tZ = new Date().getTimezoneOffset() / 30
                if (tZ === -17.5) {
                    h -= -17;
                    min -= -30;
                } else {
                    h -= tZ;
                }
                var timestamp = new Date(y, m, d, h, min, s)
                var dat = new Date(timestamp);
                // console.log(data[0].time,y, m, d, h, min, s,dat)

                var final = dat.getTime();
                chartdata1.push([final, data[i].value, data[i].board_value])  
                chartdata2.push([final, data[i].value])  
            }
            // var var1 = chartdata2.sort((a,b)=> a[0]-b[0])
            var var1 = chartdata2

            this.seriesData = [{
                name: 'kWh',
                data: var1,
                type: this.graphtype,
                color: '#f44336',
                fillOpacity: 1,
                threshold: null,
                // visible: true
            },
            // {
			// 	name: 'Current consumed (kWh)',
			// 	data: var2,
			// 	type: this.graphtype,
			// 	color: '#002DB3',
			// 	fillOpacity: 1,
			// 	// threshold: null,
			// 	// showInNavigator: true,
            //     // visible: false,

			//   }
        ]
            this.setState({
                final_chart_data : this.seriesData,
                suffix : 'kWh', prefix: ''
            })  
        } 
        
        // this.bottomTableData();   
        setTimeout(() => {
            var x=document.getElementsByClassName("highcharts-button")
            // console.log(x)
            if(x!=undefined && x.length!=0){
                x[x.length-1].onclick()

            }
        }, 100);
      
    }

    newtest(event) {
        var timearray1=[]
		var timearray2=[]
        var timearray3=[]
        var array=[]
        var max1=null
		var min1=null
		var sum=0
        var x= 0
        var max = event.max;
        var min = event.min;
        var uom = event.target.series[0]['yAxis']['axisTitle']['textStr']
        if (uom == 'kWh' || uom == 'Cost') {
            for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
            }
            timearray2 = []
            timearray3 = []
        } else if (uom == 'kW' || uom == 'kVA') {
            if (event.target.series.length == 2) {
                for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                    timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
                }
                for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
                    timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
                }
                timearray3 = []
            } else if (event.target.series.length == 3) {
                for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                    timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
                }
                for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
                    timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
                }
                for (let i = 0 ; i<= event.target.series[2]['xData'].length - 1 ; i++) {
                    timearray3.push([event.target.series[2]['xData'][i],event.target.series[2]['yData'][i]])
                }
            } else {
                for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                    timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
                }
                for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
                    timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
                }
                timearray3 = []
            }
        } else {
            for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
            }
            timearray2 = []
            timearray3 = []
        }
        if (uom == 'kWh') {
            this.var1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
               }
                if(x==1){
                  array.push(row)
                } 
            });
        } else if (uom == 'kW') {
            timearray1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
              timearray2.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
              timearray3.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
  
        } else if (uom == 'kVA') {
            timearray1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
              timearray2.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
              timearray3.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
                  
               }
                if(x==1){
                  array.push(row)
                }
                
              });
  
        } else if (uom == 'Cost') {
            timearray1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0   
               }
                if(x==1){
                  array.push(row)
                }
              });
        } else {
            timearray1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
               }
                if(x==1){
                  array.push(row)
                } 
            });
        }
        if (array.length == 0 ) {
            max1=0
            min1=0
            sum=0
            var average1= 0
            var obj={
                max:max1,
                min:min1,
                sum:sum,
                average:average1
            }
          } else {
            max1=Math.max.apply(Math, array.map(function(o) { return o[1]; }))
            min1=Math.min.apply(Math, array.map(function(o) { return o[1]; }))
            sum=0
            array.forEach((key)=>{
                sum=sum+key[1]
            })

            var finalsum=0
            var average1
              if(uom=="kWh"){
                // console.log("array", array)
                // console.log("last", array[array.length-1][2])
                // console.log("first", array[0][2])
                finalsum=array[array.length-1][2] - array[0][2]
                 var l=array[array.length-1][0] - array[0][0]
                var diff = l / 3600000;
                if(finalsum>0){
                    average1=finalsum/diff

                }else{
                    average1=0
                }

            //  console.log(finalsum,array,"asjf")
              }else{
                finalsum=sum
                average1=sum/(array.length)
    
              }

            var obj={
                max:max1,
                min:min1,
                sum:finalsum,
                average:average1
            }
          }
        var obj1=JSON.stringify(obj)
        sessionStorage.setItem('bottomTableData',obj1)

        if(this.state.pageloadcheck == 1){
            this.setState({
                high: this.state.newhigh,
                low: this.state.newlow,
                total: this.state.newtotal,
                average: this.state.newaverage,
        })

            this.setState({
                pageloadcheck:2
            })
        }
        else if(this.state.pageloadcheck == 2){
            this.setState({
                    high: this.state.newhigh,
                    low: this.state.newlow,
                    total: this.state.newtotal,
                    average: this.state.newaverage,
            })
            this.setState({
                pageloadcheck:3
            })
        }
        // else if(this.state.pageloadcheck == 3){
        //     this.setState({
        //         high: max1.toFixed(2),
        //         low: min1.toFixed(2),
        //         total: finalsum.toFixed(2),
        //         average: average1.toFixed(2)
        //     })
        // }
    }
  


    render() {
        var realtimeconfig = {
            chart: {
              renderTo: 'realtimechartdiv',
              backgroundColor: this.state.backgroundcolor,
              zoomType: 'x',
              style: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                color: this.state.textcolor,
                fontWeight: 'bold',
              },

            },
            exporting: { enabled: true },
            credits: {
              enabled: false
            },
            rangeSelector: {
              selected: 5,
              inputEnabled: false,
              buttons: [{
                  type: 'all',
                  text: 'Reset',
              }],
              buttonTheme: {
                  width: 80,
                  style:{
                      fontSize:'13px',
                      fontWeight:'400',
                      
                  }
              },
              // labelStyle: {
              //     visibility: 'hidden'
              // },
            },
      
            legend: {
              enabled: true,
              itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
              
              title: {
                enabled: true,
                text: 'Date / Time',
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  color: this.state.textcolor,
                  fontWeight: 'bold',
                }
              },
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              events: {
                afterSetExtremes: function (event) {
                  let min = event.min;
                  let max = event.max;
                  var testObj={
                    max:max,
                    min:min
                  }
                  var obj1=JSON.stringify(testObj)
                  sessionStorage.setItem('bottomTableMaxMin',obj1)
                },
                setExtremes: this.newtest
              }
            },
            yAxis: {
              opposite: false,
              min: 0,
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              title: {
                text: 'kWh',
                style: {
                    fontFamily: 'Montserrat',
                  fontSize: '14px',
                   fontWeight: 'bold',
                   color: this.state.textcolor,
                },
              },
            },
            // scrollbar: {
            //     barBackgroundColor: 'gray',
            //     barBorderRadius: 7,
            //     barBorderWidth: 0,
            //     buttonBackgroundColor: 'gray',
            //     buttonBorderWidth: 0,
            //     buttonArrowColor: 'yellow',
            //     buttonBorderRadius: 7,
            //     rifleColor: 'yellow',
            //     trackBackgroundColor: 'white',
            //     trackBorderWidth: 1,
            //     trackBorderColor: 'silver',
            //     trackBorderRadius: 7,
            // },
            tooltip: {
                headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
                valuePrefix: this.state.prefix + ' ', 
                valueSuffix: ' ' + this.state.suffix,
                valueDecimals:2,
                split: false,
                shared: true,
                style: {fontSize: '14px'} 
              },
            series:  this.state.final_chart_data
        };
      var x=2
      var y=v3_server_check()
      if(y==true){
          x=2
      }
        return (
          <>
            <div className="content">
                {
                    this.state.loader && <Loader />
                }
                <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
                {this.state.showcontent == true ?
                <>    
                <Row>
                    <Col md="12">
                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <CardHeader>
                                <CardTitle tag="h5"></CardTitle>
                            </CardHeader>
                            <CardBody>
                            <FieldGroup
                                control={this.realtimeForm}
                                strict= {false}
                                render={({ get, invalid, value }) => (
                                <form>
                                    <Row>
                                        <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="datarange"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                                        <div className='control-pane'>
                                                            <div className='control-section'>
                                                                <div className='datepicker-control-section'>
                                                                    <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e)} value={this.state.defaultrange}>
                                                                        <PresetsDirective>
                                                                            <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                            <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                            <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                        </PresetsDirective>
                                                                    </DateRangePickerComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md={x}>
                                        <FieldControl
                                            name="uom"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item> 
                                                            <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label2"
                                                                id="demo-simple-select-outlined2"
                                                                label="Units"
                                                                fullWidth variant="outlined"
                                                                onChange={(e) => this.uomhandleChange(e)}
                                                                value={this.state.checkboxvalue}
                                                                >
                                                                {/* <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kWh'}>kWh</MenuItem> */}
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'y_phase_active_power'}>kW - Y Phase</MenuItem>
                                                                {/* <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kVA'}>kVA</MenuItem> */}
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'y_phase_current'}>Amp - Y Phase</MenuItem>
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'total_kw'}>Total kW (Active power)</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md={x}>
                                            <FieldControl
                                                name="benchmark"
                                                strict= {false}
                                                render={({ handler, touched, hasError }) => (
                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <Grid className={"Formfieldcss"} container spacing={1}>                
                                                            <Grid item>
                                                            <InputLabel id="demo-simple-select-outlined-label3">Benchmark</InputLabel>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} {...handler("number")}  />
                                                            </Grid>
                                                            <span className="error">
                                                                {touched
                                                                    && hasError("required")
                                                                    && `Benchmark is required`}
                                                            </span>
                                                        </Grid>
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md={x}>
                                        <FieldControl
                                            name="chart_type"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label3"
                                                                id="demo-simple-select-outlined3"
                                                                fullWidth variant="outlined"
                                                                label="Charts"
                                                                onChange={(e) => this.charthandleChange(e)}
                                                                value={this.state.charttype}
                                                                >
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'area'}>Area</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md="3">
                                            <div className="login-button changepassword text-center">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={invalid}
                                                    onClick={(event) => this.handleOperationSubmit(event, this.realtimeForm.getRawValue())}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                                )} 
                            />
                            <hr />
                                <div style={{width:"100%"}}>
                                <HighchartsReact 
                                    style={{width:"100%"}}
                                        highcharts={Highcharts}
                                        constructorType={'stockChart'}
                                        options={realtimeconfig}
                                        containerProps = {{ className: 'chartContainer' }}
                                        id="realtimechartdiv"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                        
                <Row>
                    <Col lg="2" md='12'>
                        <Row>
                        {this.state.cardsShutdownData.map((value, index) => (
                            <Col lg="12">
                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                    <div className="numbers text-center">
                                        <CardTitle tag="p">
                                            {value.value} {value.name}
                                        </CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats text-center">
                                <i className={"fas fa-bolt " + value.icolor} /> <b><span className="text-dark">{value.process}</span></b>   
                                </div>
                                </CardFooter>
                            </Card>
                            </Col>
                        ))}
                        </Row>
                    </Col>
                    <Col lg="10" md='12'>
                        <Row>
                        {this.state.cardsData.map((value, index) => (
                        <Col lg="3" md="6" sm="6">
                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                    <div className="numbers text-center">
                                        <CardTitle tag="p">
                                            {value.value} {value.name}
                                            &nbsp;&nbsp;
                                            {value.id == "8" &&
                                                <i title={"Due to network issue there could be some loss of data."} className="fas fa-info-circle text-primary" />
                                            }
                                        </CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats text-center">
                                <i className={"fas fa-bolt " + value.icolor} /> <b><span className="text-dark">{value.process}</span></b>   
                                </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        ))}        
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                        <CardTitle tag="h5" className="dashboardcardtitle">Running Hours</CardTitle>
                        </CardHeader>
                        <hr/>
                        <CardBody>
                        <div id="piechartdiv1" style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                        {/* <hr /> */}
                        {/* <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}>Pie chart</CardTitle> */}
                        </CardBody>
                    </Card>
                    </Col>
                    <Col md="6">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                        <CardTitle tag="h5" className="dashboardcardtitle">Total kWh </CardTitle>
                        </CardHeader>
                        <hr/>
                        <CardBody>
                        <div id="piechartdiv2" style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                        {/* <hr /> */}
                        {/* <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}>Pie chart</CardTitle> */}
                        </CardBody>
                    </Card>
                    </Col>
                    <Col md="6">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                        <CardTitle tag="h5" className="dashboardcardtitle">Average</CardTitle>
                        </CardHeader>
                        <hr/>
                        <CardBody>
                        <div id="piechartdiv3" style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                        {/* <hr /> */}
                        {/* <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}>Pie chart</CardTitle> */}
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </>

                :
                
                <Row>
                    <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader> 
                        </CardHeader>
                        <CardBody>
                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            }                      
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Operations);