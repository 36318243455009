import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Typography } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => {
  return {
    root: {
      borderRadius: 50,
      width: 40,
      height: "100%",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      transform: ({ value }) => {
        return `translateY(${100 - value}%) !important`;
      },
      backgroundColor: "#1a90ff",
    },
  };
})(LinearProgress);

// Inspired by the former Facebook spinners.

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: 300,
    gap: 10,
    display: "flex",
    
  },
  stats: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

export default function CustomizedProgressBars({temperature}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.stats}>
        <BorderLinearProgress variant="determinate" value={temperature} />
        <Typography variant="body2" color="textSecondary" align="center">
        {`${temperature}°C`}
      </Typography>
      </div>
    </Card>
  );
}
