/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import {footerName} from "../../routes"

class Footer extends React.Component {
  render() {
    var x=footerName()
    return (
      <footer
      className={"footer" + (this.props.default ? " footer-default " : " ") + (this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "bluelighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <div className="credits ml-auto">
              <div className="copyright">
                <a href={x.link} target="_blank" className={"atextdecoration " + (this.props.default ? " footer-default " : " ") + (this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "lighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")}>{x.name}</a>&nbsp;
                &copy; {1900 + new Date().getYear()}
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
