import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import {SampleBase } from './Samplebase'
import TopNavDropdown from './TopDropdown';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Drop_y from "../assets/images/drop_yellow.svg"
import Drop_r from "../assets/images/drop_red.svg"
import Drop_g from "../assets/images/drop_green.svg"
import Drop_b from "../assets/images/drop_blue.svg"



class Realtime extends SampleBase {
    constructor(props) {
        super(); 
                // var newtimeZone = localStorage.getItem('time_zone')
        // if(newtimeZone){
        //     let options = {
        //         timeZone: newtimeZone,
        //         year: 'numeric',
        //         month: 'numeric',
        //         day: 'numeric',
        //         hour: 'numeric',
        //         minute: 'numeric',
        //         second: 'numeric',
        //       },
        //     formatter = new Intl.DateTimeFormat([], options);
        //     var timezone = (formatter.format(new Date())).split(" ");
        //     var timezoneformat = timezone[0].replace(/,\s*$/, "")
        //     var finaloutput = new Date(timezoneformat)
        //     this.today = finaloutput
        // }
        // else{
        //     this.today = new Date(new Date().toDateString());
        // }  
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd =this.today;
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        this.state = {
            loader: false,
            dial_bottom_data:{today:0,last7:0,last30:0},
            charttype: 'line',
            realtime_bottom_data:{ high:0,avg:0,low:0},
            monthly_total:0,
            defaultrange : [this.today,this.today],
            defaultrange1 : [this.monthStart,this.today],



            checkboxvalue:  'kWh',
            minutevalue: 60,
            prefix: '',
            suffix: '',
            high: '',
            low: '',
            average: '',
            total: '',
            final_chart_data: [],           
            processed_data: [],
            chart_data: [],
            seriesdata: [],
            fillAlphas: 1,
            graphtype: 'line',
            tooltip: {},
            showcontent: false, 
            backgroundcolor: 'white',
            textcolor: 'black'
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.newtest = this.newtest.bind(this);
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.graphtype = 'line'
        this.fillAlphas= 1
        this.seriesdata = []
        this.seriesData = []
        this.chartconfig = {}
        this.chartconfig1 = {}
        this.finalchartdata = []
        this.var1 = [[0,0,0]];
        this.var2 = [];
        this.var3 = [];
        this.calcData = []
        this.show=false
        this.setInterval1=null
        this.setInterval2=null
    }

    componentDidMount() { 
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/realtime_flow')
        if (localStorage.getItem('selectedboard')) {
            this.setState({
                showcontent: true
            })
        }
        this.set_intervel()
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }
    DialChart_data(board_id) {
      this.setState({ loader: true })
      apiService.dial_data_flow(board_id).then(response => {
          this.setState({ loader: false })
          if (response.data.length != 0) {
            var z=response.data[0].flow_rate_chart_data
            var p=response.data[0].flow_rate_processed_data
              var x={flow_rate:z.flow_rate,flow_total:z.flow_totalizer_rate}
              var y={today:p.sofartoday,last7:p.last7days,last30:p.last30days}
           
            this.setState({dial_chart_data:x,dial_bottom_data:y},()=>{
              this.dialchart("dialchart",z.flow_rate)
        this.dialchart("dialchart1",z.flow_totalizer_rate)
            })
              } 
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }
    set_intervel(){
      this.setInterval1= setInterval(() => {
      let board_id = localStorage.getItem('selectedboard');

      apiService.dial_data_flow(board_id).then(response => {
        if (response.data.length != 0) {
          var z=response.data[0].flow_rate_chart_data
          var p=response.data[0].flow_rate_processed_data
            var x={flow_rate:z.flow_rate,flow_total:z.flow_totalizer_rate}
            var y={today:p.sofartoday,last7:p.last7days,last30:p.last30days}
            sessionStorage.setItem("flow_dial",JSON.stringify(x))
          this.setState({dial_chart_data:x,dial_bottom_data:y},()=>{
          })
            } 
    }).catch(error => {
        
    })
     }, 60000);
    }
    BarChart_data(start,end) {
      this.setState({ loader: true })
      apiService.monthly_data_flow(start,end).then(response => {
          this.setState({ loader: false })
          var z=0
          if (response.data.length != 0) {
            z=response.data[0].chart_data.map(item => parseInt(item.total)).reduce((prev, curr) => prev + curr, 0)
            var data=response.data[0].chart_data.map((obj)=>{
              var lastStr = obj['date'].split("-").splice(-1)[0];
              var lastButStr= obj['date'].split("-").splice(-2)[0];
              obj['new_category'] = lastButStr + '-' + lastStr
              return obj
            })
            this.setState({monthly_total:z})
            // monthly_total
          this.barchart(data)
              } 
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }
    StockChart_data(start,end) {
      this.setState({ loader: true })
      apiService.realtime_data_flow(start,end).then(response => {
          this.setState({ loader: false })
          if (response.data.length != 0) {
            var data=response.data[0].chart_data
            this.refinechartdata(data)
                // this.setState({realtime_bottom_data:response.data[0].processed_data})
              } 
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }
    onChangeDatepicker_mon(e){
      const sy = e[0].getFullYear();
      const sm = e[0].getMonth() + 1;
      const sd = e[0].getDate();
      const ey = e[1].getFullYear();
      const em = e[1].getMonth() + 1;
      const ed = e[1].getDate();
      var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
       var y = ey.toString() + '-' + em.toString() + '-' + ed.toString();
       this.BarChart_data(x,y)

    }
    onChangeDatepicker_real(e){
      const sy = e[0].getFullYear();
      const sm = e[0].getMonth() + 1;
      const sd = e[0].getDate();
      const ey = e[1].getFullYear();
      const em = e[1].getMonth() + 1;
      const ed = e[1].getDate();
      var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
       var y = ey.toString() + '-' + em.toString() + '-' + ed.toString();
       this.StockChart_data(x,y)

    }
     dialchart(data,value){
        if (this.props.theme  == 'darktheme' || this.props.theme  == 'greytheme') {
            am4core.useTheme(am4themes_dark);
          } else {
            am4core.unuseTheme(am4themes_dark);
          } 
        am4core.useTheme(am4themes_material);
        am4core.useTheme(am4themes_animated);
        // Themes end
        
        // create chart
        var chart = am4core.create(data, am4charts.GaugeChart);
        chart.innerRadius = am4core.percent(82);
        chart.logo.disabled = true;
      
        /**
         * Normal axis
         */
        
        var axis = chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = false;
        // axis.renderer.labels.template.disabled = true;
        // axis.axisFill.zIndex = -1;
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.ticks.template.disabled = false
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.length = 10;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(30);
        axis.renderer.labels.template.adapter.add("text", function(text) {
          return text;
        })
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
          relevant: function(target) {
            if (target.pixelWidth <= 400) {
              axis.renderer.minGridDistance = 99;
                      // axis.renderer.labels.template.disabled = true;


              return true;
            } 
            if (target.pixelWidth > 400) {
              axis.renderer.minGridDistance = 10;
                      // axis.renderer.labels.template.disabled = false;

               return false;
            }
            return false;
          },
          state: function(target, stateId) {
            if (target instanceof am4charts.GaugeChart) {
              var state = target.states.create(stateId);
              return state;
            }
            return null;
          }
        });
        
        /**
         * Axis for ranges
         */
        
        var colorSet = new am4core.ColorSet();
        
        var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
        axis2.min = 0;
        axis2.max = 100;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = true;
        
        var range0 = axis2.axisRanges.create();
        range0.value = 0;
        range0.endValue = 50;
        range0.axisFill.fillOpacity = 1;
        range0.axisFill.fill = colorSet.getIndex(0);
        
        var range1 = axis2.axisRanges.create();
        range1.value = 50;
        range1.endValue = 100;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = colorSet.getIndex(2);
        
        /**
         * Label
         */
        
        var label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = 45;
        label.x = am4core.percent(50);
        label.y = am4core.percent(100);
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        label.text = "50%";
        
        
        /**
         * Hand
         */
        
        var hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(20);
        hand.startWidth = 10;
        hand.pin.disabled = true;
        hand.value =value;
        
        hand.events.on("propertychanged", function(ev) {
          range0.endValue = ev.target.value;
          range1.value = ev.target.value;
          label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
          axis2.invalidate();
        });
        
       this.setInterval1= setInterval(function() {

        
          // var value = Math.round(Math.random() * 100);
          var y=JSON.parse(sessionStorage.getItem("flow_dial"))
          var t=0
          if(y!=undefined){
            if(data=="dialchart"){
              t= y.flow_rate

            }else if(data=="dialchart1"){
              t= y.flow_totalizer_rate

            }
          }
          var animation = new am4core.Animation(hand, {
            property: "value",
            to:t
          }, 500, am4core.ease.cubicOut).start();
        }, 10000);
     }
     barchart(data) { 
        if (this.props.theme == 'darktheme' || this.props.theme == 'greytheme') {
          am4core.useTheme(am4themes_dark);
        } else {
          am4core.unuseTheme(am4themes_dark);
        } 
        var chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
chart.exporting.menu.verticalAlign = "top";
        // will use this to store colors of the same items
        var colors = {};
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "new_category";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataItems.template.text = "{new_category}";
        categoryAxis.adapter.add("tooltipText", function(tooltipText, target){
          return categoryAxis.tooltipDataItem.dataContext.new_category;
        })
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "m³";
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;
        // single column series for all data
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.tooltipText = "{new_category}: {valueY} m³";
        columnSeries.dataFields.categoryX = "new_category";
        columnSeries.dataFields.valueY = "total";
        
        columnSeries.columns.template.adapter.add("fill", function(fill, target) {
         var name = target.dataItem.dataContext.date;
         if (!colors[name]) {
           colors[name] = chart.colors.next();
         }
         target.stroke = colors[name];
         return colors[name];
        })
        var rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;
        chart.data = data;
        this.chart = chart;
      }
    componentDidUpdate(prevProps) {
        if (this.props.themecolor == 'greycolor' || this.props.themecolor ==  'whitecolor') {
            this.setState({
                backgroundcolor : 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor : 'white',
                textcolor: 'black'
            })
        }
        if (prevProps.theme != this.props.theme) {
            this.refinechartdata(this.finalchartdata,this.state.checkboxvalue)
        }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    componentWillUnmount(){
      if(this.setInterval1!=null){
        clearInterval(this.setInterval1);
      }
      if(this.setInterval2!=null){
        clearInterval(this.setInterval2);
      }
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.DialChart_data(board_id)
        this.onChangeDatepicker_mon([this.monthStart,this.today])
        this.onChangeDatepicker_real([this.today,this.today])
        this.setState({
            showcontent: true,
            defaultrange:[this.today,this.today],
            defaultrange1:[this.monthStart,this.today]

        })
    }

    realtimeForm = FormBuilder.group({
        daterange: [""],
        uom: ["kWh", ],
        chart_type: ["line"],
        minutes: [60]
    });
    refinechartdata(data) {
            var chartdata1 = [];
            for (var i = 0; i <= data.length - 1; i++) {
                var time = new Date(data[i].created_at)
                var y = time.getUTCFullYear();
                var m = time.getUTCMonth();
                var d = time.getUTCDate();
                var h = time.getUTCHours();
                var min = time.getUTCMinutes();
                var s = time.getUTCSeconds();
                var timestamp = new Date(y, m, d, h + 11, min, s)
                var dat = new Date(timestamp);
                var final = dat.getTime();
                chartdata1.push([final, data[i].flow_rate])  
            } 
            var var1 = chartdata1

            this.var1 = var1
            this.seriesData = [{
                name: 'Flow rate',
                data: var1,
                type: this.graphtype,
                color: '#f44336',
                fillOpacity: 1,
                threshold: null,
                // visible: true
            }
        ]

            this.setState({
                final_chart_data : this.seriesData,
            })  
            this.real_bottom_convertion(this.var1)

             
        // setTimeout(() => {
        //     var x=document.getElementsByClassName("highcharts-button")
        //     if(x!=undefined && x.length!=0){
        //       x[x.length-1].onclick()
      
        //    }
    // x[x.length-1].onclick()
// }, 100);
      
    }


  
    charthandleChange(e){
      // console.log(e.target.value)
      this.graphtype=e.target.value
      this.setState({charttype:e.target.value})
//       this.seriesData = [{
//         name: 'Flow rate',
//         data: var1,
//         type: this.graphtype,
//         color: '#f44336',
//         fillOpacity: 1,
//         threshold: null,
//         // visible: true
//     }
// ]
  this.seriesData[0].type=e.target.value
    this.setState({
        final_chart_data : this.seriesData
    })  
    }
    newtest(event) {
      // console.log("comes")
        var timearray1=[]
		
        var array=[]
       
        var x= 0
        var max = event.max;
        var min = event.min;
        var uom = event.target.series[0]['yAxis']['axisTitle']['textStr']
            for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
                timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
            }
          


            this.var1.forEach(row => {
                if(row[0]>min){
                   x=1
                }
                if(row[0]>max){
                  x=0
               }
                if(x==1){
                  array.push(row)
                } 
            });


            this.real_bottom_convertion(array)

    }
    real_bottom_convertion(array){
    
    
      var obj={
          high:0,
          low:0,
          avg:0
      }
      if (array.length != 0 ) {
      
      var max=Math.max.apply(Math, array.map(function(o) { return o[1]; }))
      var min=Math.min.apply(Math, array.map(function(o) { return o[1]; }))
        var average
        var t1=array[array.length-1]
        var t2=array[0]

           var finalsum=t1[1] - t2[1]
             var l=t1[0] - t2[0]
            var diff = l;
            if(finalsum>0){
                average=finalsum/diff

            }else{
                average=0
            }
            obj={
            high:max,
            low:min,
            avg:average.toFixed(1)
        }
      }
      // console.log(obj)
      var t=this.state.realtime_bottom_data
      if(t.high != obj.high || t.low != obj.low || t.avg != obj.avg ){
        this.setState({realtime_bottom_data:obj})

      }

    }

    render() {
        var realtimeconfig = {
            chart: {
              renderTo: 'realtimechartdiv',
              backgroundColor: this.state.backgroundcolor,
              zoomType: 'x',
              style: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                color: this.state.textcolor,
                fontWeight: 'bold',
              },

            },
            exporting: { enabled: true },
            credits: {
              enabled: false
            },
            rangeSelector: {
              selected: 5,
              inputEnabled: false,
              buttonTheme: {
                visibility: 'hidden'
              },
              labelStyle: {
                visibility: 'hidden'
              }
            },
      
            legend: {
              enabled: true,
              itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
              
              title: {
                enabled: true,
                text: 'Date / Time',
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  color: this.state.textcolor,
                  fontWeight: 'bold',
                }
              },
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              events: {
                afterSetExtremes: function (event) {
                  let min = event.min;
                  let max = event.max;
                  var testObj={
                    max:max,
                    min:min
                  }
                  var obj1=JSON.stringify(testObj)
                  // sessionStorage.setItem('flow_bottomTableMaxMin',obj1)
                },
                setExtremes: this.newtest
              }
            },
            yAxis: {
              opposite: false,
              min: 0,
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              title: {
                text: "m³/h",
                style: {
                    fontFamily: 'Montserrat',
                  fontSize: '14px',
                   fontWeight: 'bold',
                   color: this.state.textcolor,
                },
              },
            },
            // scrollbar: {
            //     barBackgroundColor: 'gray',
            //     barBorderRadius: 7,
            //     barBorderWidth: 0,
            //     buttonBackgroundColor: 'gray',
            //     buttonBorderWidth: 0,
            //     buttonArrowColor: 'yellow',
            //     buttonBorderRadius: 7,
            //     rifleColor: 'yellow',
            //     trackBackgroundColor: 'white',
            //     trackBorderWidth: 1,
            //     trackBorderColor: 'silver',
            //     trackBorderRadius: 7,
            // },
            tooltip: {
                valueSuffix: ' m³/h',
                valueDecimals:2,
                split: false,
                shared: true
              },
            series:  this.state.final_chart_data
        };
   var x=this.state
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ?
            <>
             <Row>
            <Col lg="6" md="12" sm="12">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
              <CardHeader>
                  <CardTitle tag="h5">Water - Current Flow rate ( m³/h )</CardTitle>
                   </CardHeader>
                <CardBody>
                 <div id="dialchart" style={{width:"100%",height:350}}></div>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-bolt" /> Currently consuming
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="6" md="12" sm="12">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
              <CardHeader>
                  <CardTitle tag="h5">Water - Current Flow total ( m³ )</CardTitle>
                   </CardHeader>
                <CardBody>
                 <div id="dialchart1" style={{width:"100%",height:350}}></div>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-bolt" /> Total consumption
                  </div>
                </CardFooter>
              </Card>
            </Col>
            
             </Row>
             <Row>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <img src={Drop_y} />
                                             </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Water Consumption</p>
                        <CardTitle tag="p" className="newsizetext">{x.dial_bottom_data.today}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> So Far Today ( m³ )
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                      <img src={Drop_g} />

                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Water Consumption</p>
                        <CardTitle tag="p" className="newsizetext">{x.dial_bottom_data.last7}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> Last 7 days ( m³ )
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                      <img src={Drop_r} />

                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Water Consumption</p>
                        <CardTitle tag="p" className="newsizetext"> {x.dial_bottom_data.last30}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                 
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> Last 30 days ( m³ )
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Monthly Consumption </CardTitle>
                  </CardHeader>
                <CardBody>
                <Row>
            <Col lg="4" md="12" sm="12">
                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                            <div className='control-pane'>
                                                <div className='control-section'>
                                                    <div className='datepicker-control-section'>
                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.onChangeDatepicker_mon(e.target.value)} value={this.state.defaultrange1}>
                                                            <PresetsDirective>
                                                                <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                            </PresetsDirective>
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                    </Col>
                </Row>        
                <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="sorafontclass"></div>
                <Row>
                  <Col className="pr-1 text-center" md="12">
                    <span className="cardtitle-timetitle dialtotal">
                    <img src={Drop_b} width={25} height={25} /> {x.monthly_total} m³</span>
                    </Col>
                    
                </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="fa fa-calendar" /> Last 30 days
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
            <Row>
               
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5"></CardTitle>
                </CardHeader>
                <CardBody>
                <FieldGroup
                    control={this.realtimeForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="4">
                            <FieldControl
                                name="datarange"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                            <div className='control-pane'>
                                                <div className='control-section'>
                                                    <div className='datepicker-control-section'>
                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.onChangeDatepicker_real(e.target.value)} value={this.state.defaultrange}>
                                                            <PresetsDirective>
                                                                <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                            </PresetsDirective>
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md="3">
                            <FieldControl
                                name="chart_type"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label3"
                                                    id="demo-simple-select-outlined3"
                                                    fullWidth variant="outlined"
                                                    label="Charts"
                                                    onChange={(e) => this.charthandleChange(e)}
                                                    value={this.state.charttype}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'area'}>Area</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                         
                        </Row>
                    </form>
                    )} 
                />
                <hr />
              <div style={{width:"100%"}}>
              <HighchartsReact 
                 style={{width:"100%"}}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={realtimeconfig}
                    containerProps = {{ className: 'chartContainer' }}
                    id="realtimechartdiv"
                />
              </div>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
                <>
                <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                            <Col md="12" xs="12">
                            <div className="numbers text-center">
                              
                                    <CardTitle tag="p">
                                         {x.realtime_bottom_data.high} m³/h
                                    </CardTitle> 
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                        <img src={Drop_r} width={15} height={15} /> High   
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                                    <CardTitle tag="p">
                                        {x.realtime_bottom_data.low} m³/h
                                    </CardTitle> 
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                        <img src={Drop_y} width={15} height={15} /> Low
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                              
                                    <CardTitle tag="p">
                                         {x.realtime_bottom_data.avg} m³/h
                                    </CardTitle> 
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                        <img src={Drop_g} width={15} height={15} /> Average
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    </>
                </Row>
           
            </> :
                <Row>
                    <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader> 
                        </CardHeader>
                        <CardBody>
                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            }
              
        
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Realtime);