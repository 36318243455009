import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators
} from "react-reactive-form";
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import * as apiService from '../services/apiService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import { connect } from 'react-redux';
const $ = require('jquery');
const moment = require('moment');
$.DataTable = require('datatables.net');

class Logs extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            showcontent: false,
            logstable: [],
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/logs')
        if (localStorage.getItem('selectedboard')) {
            this.convertdate(new Date())
            this.setState({
                showcontent: true
            })
            this.logForm.patchValue({
                board_id: localStorage.getItem('selectedboard')
            })
            // console.log()
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var date = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.logForm.patchValue({
            start_date:date,
            end_date:date
        })
    }

    componentDidUpdate(prevProps) {
      if (prevProps.theme !== this.props.theme) {
      }
    }

    logForm = FormBuilder.group({
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      board_id: ['', [Validators.required]],
      startTime:['00:00', [Validators.required]],
      endTime:['23:59', [Validators.required]]
    });

    customerhandler(customerId) {
      this.selectedcustomer = customerId
      this.setState({
        showcontent: false
      })
    }
  
    boardhandler(board_id) {
      this.selectedboard = board_id
      this.setState({
        logstable: [],
      })
      this.gettableforlogs(board_id)
      this.logForm.patchValue({
        board_id: board_id,
      })
      this.setState({
        showcontent: true
      })
    }
    date_conversion(date){
      var x=date.split(" ")
      var z="--"
      if(Array.isArray(x) && x.length==2){
        var y=moment(new Date(x[0])).format('MMM Do,YYYY')
        var timeString = x[1];
        var H = +timeString.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? "AM" : "PM";
        timeString = h + timeString.substr(2, 3) + ampm;
        z=y + " at " +timeString
  
      }
      return z
  
    }
    gettableforlogs(board_id) {
      this.setState({ loader: true })
      apiService.LogsTable(board_id).then(response => {
        var x=[]
        if(Array.isArray(response.data)){
          x=response.data.map((obj)=>{
            var z=obj['created_at']
            obj['created_at']=this.date_conversion(z)
            return obj
  
          })
  
        }
  
        this.setState({ logstable:x,loader: false })
  
          
      
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }

    handleSubmit(event,values) {
        event.preventDefault();
        var start = values.start_date.split('-')
		var end = values.end_date.split('-')
		var date1 = new Date(start[1] + '/' + start[2] + '/' + start[0]);
		var date2 = new Date(end[1] + '/' + end[2] + '/' + end[0]);
        var Difference_In_Time = date2.getTime() - date1.getTime();
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		if (Difference_In_Days + 1 > 93) {
            apiService.Alerts_info("Please select date range between 3 months")
		} else if (Difference_In_Days < 0) {
            apiService.Alerts_info("Choose correct dates")
		} else {
            this.setState({ loader: true })
            apiService.DownloadLogsTable(values).then(response => {
                this.setState({ loader: false})
                swal({
                    title: "Logs Download",
                    text: "Your File is ready to Download",
                    icon: "success",
                    buttons: true,
                    dangerMode: false,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                        window.open(response.data.url);
                    } else {
                      // swal("Your file is safe!");
                    }
                });
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

        
        
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
              {this.state.showcontent === true ? 
               <Row>
                <Col md="12">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                    <FieldGroup
                        control={this.logForm}
                        strict= {false}
                        render={({ get, invalid, value }) => (
                        <form>
                            <Row>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="start_date"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <TextField type="date" required fullWidth variant="outlined" label="Start Date" {...handler("text")} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1 text-center" md="3">
                                <FieldControl
                                    name="end_date"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <TextField type="date" required fullWidth variant="outlined" label="End Date" {...handler("text")} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                 <Col className="pr-1" md="2">
                                    <FieldControl
                                        name="startTime"
                                        strict={false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className={"Formfieldcss"} container spacing={1}>
                                                    <Grid item>
                                                        <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="Start Time " {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Start Time is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                </Col>
                                <Col className="pr-1" md="2">
                                    <FieldControl
                                        name="endTime"
                                        strict={false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className={"Formfieldcss"} container spacing={1}>
                                                    <Grid item>
                                                        <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="End Time " {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* End Time is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                    <div className="login-button changepassword text-center">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={invalid}
                                            onClick={(event) => this.handleSubmit(event,this.logForm.getRawValue())}
                                            >
                                            Download
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        )} 
                    /> 
                    <hr />
                    <div className={"fixTableHead table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{height:"700px"}}>
                        <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    {/* <tr>
                                        <th rowSpan="2" style={{width : '250px'}}>Created at</th> 
                                        <th rowSpan="2" style={{width : '250px'}}>Meter Reading</th> 
                                        <th rowSpan="2" style={{width : '200px'}}>kWh</th> 
                                        <th colSpan="3" style={{width : '450px'}}>kW</th> 
                                        <th colSpan="3" style={{width : '450px'}}>kVA</th>
                                        <th colSpan="3" style={{width : '450px'}}>Amps</th>
                                        <th colSpan="3" style={{width : '450px'}}>Voltage</th>  
                                    </tr>
                                    <tr>
                                        <th style={{width : '150px'}}>R Phase</th>
                                        <th style={{width : '150px'}}>Y Phase</th>
                                        <th style={{width : '150px'}}>B Phase</th>
                                        <th style={{width : '150px'}}>R Phase</th>
                                        <th style={{width : '150px'}}>Y Phase</th>
                                        <th style={{width : '150px'}}>B Phase</th>
                                        <th style={{width : '150px'}}>R Phase</th>
                                        <th style={{width : '150px'}}>Y Phase</th>
                                        <th style={{width : '150px'}}>B Phase</th>
                                        <th style={{width : '150px'}}>R Phase</th>
                                        <th style={{width : '150px'}}>Y Phase</th>
                                        <th style={{width : '150px'}}>B Phase</th>
                                    </tr> */}
                                    <tr>
                                      <th style={{ width: '250px' }}>Created at</th>
                                      {
                                        (localStorage.getItem('is_admin') == 'true' || localStorage.getItem('is_admin') == true) &&
                                        <th style={{ width: '250px' }}>Meter Reading</th>
                                      }
                                      {/*<th style={{ width: '200px' }}>kWh</th>*/}
                                      <th style={{ width: '150px' }}>kW (R Phase)</th>
                                      <th style={{ width: '150px' }}>kW (Y Phase)</th>
                                      <th style={{ width: '150px' }}>kW (B Phase)</th>
                                      <th style={{ width: '150px' }}>kVA (R Phase)</th>
                                      <th style={{ width: '150px' }}>kVA (Y Phase)</th>
                                      <th style={{ width: '150px' }}>kVA (B Phase)</th>
                                      <th style={{ width: '150px' }}>Amps (R Phase)</th>
                                      <th style={{ width: '150px' }}>Amps (Y Phase)</th>
                                      <th style={{ width: '150px' }}>Amps (B Phase)</th>
                                      <th style={{ width: '180px' }}>Voltage (R Phase)</th>
                                      <th style={{ width: '180px' }}>Voltage (Y Phase)</th>
                                      <th style={{ width: '180px' }}>Voltage (B Phase)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.logstable.map((value, index) => (
                                    <tr key={index+1}>
                                        <td>{value.created_at}</td>
                                        {
                                        (localStorage.getItem('is_admin') == 'true' || localStorage.getItem('is_admin') == true) &&
                                          <td>{value.board_total_active_energy}</td>
                                        }
                                        {/*<td>{value.total_active_energy}</td>*/}
                                        <td>{value.r_phase_active_power}</td>
                                        <td>{value.y_phase_active_power}</td>
                                        <td>{value.b_phase_active_power}</td>
                                        <td>{value.r_phase_apparent_power}</td>
                                        <td>{value.y_phase_apparent_power}</td>
                                        <td>{value.b_phase_apparent_power}</td>
                                        <td>{value.r_phase_current}</td>
                                        <td>{value.y_phase_current}</td>
                                        <td>{value.b_phase_current}</td>
                                        <td>{value.r_phase_neurtal_voltage}</td>
                                        <td>{value.y_phase_neurtal_voltage}</td>
                                        <td>{value.b_phase_neurtal_voltage}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :
              <Row>
                <Col md="12">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                      <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    themecolor: state.themecolor 
  }
}

export default connect(mapStateToProps)(Logs);