/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import {ApplicationName,initialRoutes} from "../../routes"
import logo from "../../favicon.png";
import "../../assets/css/scroller.css"


var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
    this.is_admin = false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (localStorage.getItem('is_admin') == 'true') {
      this.is_admin = true;
    } else {
      this.is_admin = false;
    }
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.sidebar.current, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    // }
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.routes!=this.props.routes){
      let y = this.props.routes.find((e)=>window.location.pathname == e.layout + e.path)
      if(y == undefined){
        var x=initialRoutes()
        this.props.history.push(x)
      }
    }

  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }
  render() {
    var x=window.screen.width

    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
        style={{zIndex:1001,overflowX:"hidden !important"}}
        >
        <div className="logo">
          <a
            href="javascript:void(0);"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="javascript:void(0);"
            className="simple-text logo-normal"
          >
            {ApplicationName()}
          </a>
        </div>
        {
          this.is_admin ? 
          <div className={"sidebar-wrapper " + (this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "bluelighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")} ref={this.sidebar}
          style={{height:x<500 && 1000,overflow:"hidden !important",overflowY:"auto !important",width:"auto",whiteSpace: "nowrap" }}>
            <Nav>
              {this.props.routes.map((prop, key) => {
                return (
                  <li
                    className={
                      this.activeRoute(prop.path) +
                      (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                  {
                    (prop.adminvisible) && <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  }   
                  </li>
                );
              })}
            </Nav>
          </div> :
          <div className={"sidebar-wrapper " + (this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "bluelighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")} ref={this.sidebar}
        style={{height:x<500 && 1000,overflowX:"hidden",overflowY:"auto !important",width:"auto",whiteSpace: "nowrap" }}>

         <Nav>
            {this.props.routes.map((prop, key) => {
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                {
                  (prop.visible) && <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                }   
                </li>
              );
            })}
          </Nav>
        </div>
        }
      </div>
    );
  }
}

export default Sidebar;
