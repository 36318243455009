const initState = {
    theme: 'lighttheme',
    themecolor: 'blackcolor',
    charttheme: 'none',
    timestamp:new Date().getTime()
}

const rootReducer = (state = initState, action) => {
    if (action.type === 'CHANGE_THEME') {
        return {
            ...state,
            theme : action.theme,
            themecolor: action.themecolor,
            charttheme: action.charttheme
        }
    }else if (action.type === 'TimeStamp') {
        return {
            ...state,
            timestamp:new Date().getTime()

        }
    } else {
        return {
            ...state,
            theme : 'lighttheme',
            themecolor: 'blackcolor',
            charttheme: 'none'
        }
    }
    return state;
}

export default rootReducer