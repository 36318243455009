import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import "../assets/css/auth.css"
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing2.png';
import swingicon1 from '../assets/images/displayswing.png';
import remote from '../assets/images/remote.png';
import {
    FormBuilder,
    Validators,
} from "react-reactive-form";
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class test extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            modal: false,     
            today:[new Date(new Date().toDateString()),new Date(new Date().toDateString())],
            dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())],

            thermostatList: [],
            showcontent: false,
            ChoosedThermoStat: null,
            thermostatName: "",
            autoStatusSetting: "",
            dataChange: false,
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            },
            editmode: false,
            buttontext: 'Add',
            dropDownList: [],

            scheduleHistoryList: [],
            ScheduleList: [],
            BoardList: [],
            startdate:"",
            enddate:'',

            remoteDataTemp:0,
            remoteDataPower:'AUTO',
            remoteDataPowerid:'',
            remoteDataFan:'LOW',
            remoteDataFanid:3,
            remoteDataSwing:'OFF',
            remoteDataSwingid:6,
            remoteThermo_id:'',
            remoteThermo_name:'',
        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.togglemodal = this.togglemodal.bind(this)
        this.getRemote = this.getRemote.bind(this)
        this.fanControl = this.fanControl.bind(this)
        this.swingControl = this.swingControl.bind(this)
        this.increaseTemp = this.increaseTemp.bind(this)
        this.decreaseTemp = this.decreaseTemp.bind(this)
        this.closeRemote = this.closeRemote.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''


    }
    
	
    hvaccancel() {
        
        this.scheduleForm.reset()
        this.setState({
            buttontext: 'Add',
            dateselected:this.state.today
        })

    }
    
    selectThermoStat(data) {
        var x = {
            temp: data.set_temp,
            power: data.operation,
            fan: "AUTO",
            swing: "AUTO",

        }


        if (data.operation == "ON") {
            x = {
                temp: data.schedule_temp,
                power: data.operation,
                fan: data.fan_speed,
                swing: data.swing_status,

            }
        } else if (data.operation == "OFF") {
            x = {
                temp: data.set_temp,
                power: data.operation,
                fan: "--",
                swing: "--",

            }
        }

        if(data.fan_speed == ""){
            this.setState({
                remoteDataFan:'LOW'
            })
        }
        else{
            this.setState({
                remoteDataFan: data.fan_speed
            })
        }

        if(data.swing_status == ""){
            this.setState({
                remoteDataSwing:'OFF'
            })
        }
        else{
            this.setState({
                remoteDataSwing: data.swing_status
            })
        }

        if(data.operation == 'ON'){
            this.setState({
                remoteDataPower:data.operation,
                remoteDataPowerid: 1,
                remoteDataTemp:data.schedule_temp,
            })
        }
        else{
            this.setState({
                remoteDataPower:data.operation,
                remoteDataPowerid: 2,
                remoteDataTemp:data.set_temp,
            })
        }
        this.setState({
            ChoosedThermoStat: data,
            thermostatName: data.thermo_desc,
            autoStatusSetting: data.status,
            displayData: x,
            remoteThermo_id:data.id,
            remoteThermo_name:data.thermo_name
        }
        )

        sessionStorage.setItem("selectedThermoStatID", data.id);

    }
    
    getThermostat() {

        this.setState({ loader: true })
        apiService.getThermostat().then(response => {
            // console.log(response.data)
            this.setState({ thermostatList: response.data })
            this.setState({ loader: false })
            var obj = JSON.parse(sessionStorage.getItem('selectedThermoStatID'))            
            if(sessionStorage.getItem('selectedThermoStatID').length == 0){
                this.setState({
                    thermostatList: response.data 
                })                
            }
            else{
                var item = this.state.thermostatList.find(item => item.id === obj);
                this.selectThermoStat(item)
            }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/test')
            if (localStorage.getItem('selectedboard')) {

                this.setState({
                    showcontent: true,
                    thermostatList: []
                })

                sessionStorage.setItem("selectedThermoStatID", '');
                this.getThermostat()
            }
        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    boardhandler(board_id) {
        // console.log(board_id, "board")
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            ChoosedThermoStat: null,
            thermostatName: "",
            autoStatusSetting: "",
            dataChange: false,
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            },
            buttontext: 'Add',

            scheduleHistoryList: [],
            ScheduleList: [],
        })
        localStorage.setItem('selectedboard', board_id)
        this.getThermostat()
        this.hvaccancel()
        // if (this.state.period == 'MONTHLY') {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'QUARTERLY') {
        //     this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'YEARLY') {
        //     this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
        // } else {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // }
        this.setState({
            showcontent: true
        })
    }

    //-----------------------------REMOTE CONTROLS START-----------------------------------------------------------------

    togglemodal() {
        this.setState({
          modal: !this.state.modal
        });
        // this.getThermostat()
    }

    closeRemote(){
        this.getThermostat()
    }

    remoteApicall(name,value){
        if(name == ''){
            this.setState({ loader: true })
            apiService.hvacRemoteApi(this.state.remoteThermo_id,this.state.remoteThermo_name,this.state.remoteDataPowerid,this.state.remoteDataFanid,this.state.remoteDataSwingid,this.state.remoteDataTemp).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({ 
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,
        
                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

        else{
            this.setState({ loader: true })
            apiService.hvacRemoteApiSingle(this.state.remoteThermo_id,this.state.remoteThermo_name,name,value).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({ 
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,
        
                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
        
    }

    getRemote(){
        if(this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ){
            this.setState({
                remoteDataPower: 'ON',
                remoteDataPowerid:1
            },()=>{
                this.remoteApicall("","")
            })
        }
        else{
            this.setState({
                remoteDataPower: 'OFF',
                remoteDataPowerid:2
            },() =>{
                this.remoteApicall('operation', this.state.remoteDataPowerid)
            })
        }
    }

    fanControl(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataFan == 'LOW'){
                this.setState({
                    remoteDataFan: 'HIGH',
                    remoteDataFanid:4
                },()=>{
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
            else{
                this.setState({
                    remoteDataFan: 'LOW',
                    remoteDataFanid:3
                },() =>{
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
        }
    }

    swingControl(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataSwing == 'OFF'){
                this.setState({
                    remoteDataSwing: 'ON',
                    remoteDataSwingid:5
                },() =>{
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
            else{
                this.setState({
                    remoteDataSwing: 'OFF',
                    remoteDataSwingid:6
                },() =>{
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
        }
    }

    increaseTemp(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataTemp == 32){
                this.setState({
                    remoteDataTemp: 32
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })    
            }
            else{
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp + 1
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

    decreaseTemp(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataTemp == 16){
                this.setState({
                    remoteDataTemp: 16
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })    
            }
            else{
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp -1 
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

//-----------------------------REMOTE CONTROLS END-----------------------------------------------------------------

    

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            // this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
    }


 render() {
     function firstcolor(data){
        var x='#1092C1' 
        if(data=='ON'){
            x='green' 
        }else if(data=='OFF'){
            x='red' 
        }
        return x
     }
//    var x='green' 


//    [ngStyle]="displayData.power=='ON' ? { color:'green' } : displayData.power=='OFF' ? {color: 'red'} : displayData.power=='AUTO' ? {color: '#1092C1'} : {color: ''}"
  return (
      <>
          <div className="content">
              {
                  this.state.loader && <Loader />
              }
              <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
              {this.state.showcontent == true ?
                  <>
                      <Row>
                          {/* Remote Modal */}
                        <MDBContainer>
                        <MDBModal isOpen={this.state.modal} side position="top-right" data-mdb-backdrop="static">
                            <MDBModalHeader style={{display:"unset"}}>
                                <span className="ml-3">Remote - {this.state.thermostatName}</span>
                                <button type="button" onClick={() => { this.togglemodal(); this.closeRemote();}} className="close text-right mr-4 p-0" aria-label="Close">
                                    <span style={{fontSize:'50px', color:'red'}} aria-hidden="true">×</span>
                                </button>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <Row className="m-2" style={{height:"460px",background:"#f0f0f5"}}>
                                    <Col sm="12 p-0" className="border">
                                        <Row className="m-2 border">
                                            <Col sm="12" className="p-4 d-flex justify-content-center align-items-center"  style={{height:"180px",background:"#1f1f2e"}}>
                                                {this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ?
                                                <Row>
                                                    <Col sm="12">
                                                        <i class="fa fa-power-off fa-3x" style={{color:"#ff3333"}} aria-hidden="true"></i> &nbsp;&nbsp; <span className="text-white" style={{fontSize:"30px"}}><b>{this.state.remoteDataPower}</b></span>
                                                    </Col>
                                                </Row>
                                                
                                                :
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="12">
                                                            <i class="fa fa-power-off remote_display" id="remotepower" aria-hidden="true" style={{color:"#0FFF50"}}></i> &nbsp;&nbsp;<span className="text-white remote_display">{this.state.remoteDataPower}</span>
                                                            </Col>
                                                            <Col sm="12" className="pt-3">
                                                                <i class="fas fa-fan remote_display" style={{color:"#c2c2d6"}} aria-hidden="true"></i> &nbsp; <span className="text-white remote_display">{this.state.remoteDataFan}</span>
                                                            </Col>
                                                            <Col sm="12" className="pt-3">
                                                                <img src={swingicon1} height={20} width={20}/> &nbsp; <span className="text-white remote_display">{this.state.remoteDataSwing}</span>
                                                            </Col>
                                                            
                                                        </Row>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center align-items-center">
                                                        <span className="remote_display_temp"><b>{this.state.remoteDataTemp}&#176; C</b></span>
                                                    </Col>
                                                </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="m-2 border">
                                            <Col sm="12" className="p-3"  style={{height:"250px",background:"white"}}>
                                                <Row>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remotepower" onClick={this.getRemote}><i class="fa fa-power-off remote_temp_icon" id="remotepower" aria-hidden="true" style={{color:"#ff4d4d"}}></i></a>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remotefan" onClick={this.fanControl}><i class="fas fa-fan remote_temp_icon" id="remotefan" style={{color:"#47d147"}}></i></a>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remoteswing" onClick={this.swingControl}><img src={swingicon} height={45} width={45}/></a>
                                                    </Col>
                                                </Row>
                                                <Row className="bg-light" style={{margin:"50px 10px 0 10px", height:"100px", borderRadius:"30px",alignItems:"center",justifyContent:'center'}}>
                                                    <Col className="d-flex justify-content-center">
                                                        <i class="fa fa-minus-circle remote_temp_icon" aria-hidden="true" onClick={this.decreaseTemp} style={{cursor:"pointer"}}></i>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                    <span style={{fontSize:"20px"}}>Temp</span>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <i class="fa fa-plus-circle remote_temp_icon" style={{cursor:"pointer"}} onClick={this.increaseTemp} aria-hidden="true"></i>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </MDBModalBody>
                            <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => { this.togglemodal(); this.closeRemote();}}>Close</MDBBtn>
                            {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                            </MDBModalFooter>
                        </MDBModal>
                        </MDBContainer>

                          <Col md="12">

                                  <Row>
                                      <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5" className="dashboardcardtitle" style={{marginTop:0}}>Thermostat Control Centre</CardTitle>
                                              </CardHeader>
                                          </Card>
                                      </Col>
                                      <Col md="6">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5">
                                                      <Nav tabs>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: '1' === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                              >
                                                                  List
                            </NavLink>
                                                          </NavItem>
                                                      </Nav>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody>
                                                  <TabContent activeTab='1'>
                                                      <TabPane tabId="1">
                                                          <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                              <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                  <tbody>
                                                                      {this.state.thermostatList.map((value, index) => (
                                                                          <tr key={index + 1} onClick={() => this.selectThermoStat(value)}>
                                                                              <th style={{ width: '150px' }}>
                                                                                 {this.state.ChoosedThermoStat==value &&  <i class="fa fa-check icons selectedthermo" aria-hidden="true"></i>}
                                                                                  {value.thermo_desc}</th>
                                                                              <td style={{ width: '150px' }}>{value.operation == 'AUTO' && <span>{value.set_temp + '°'}</span>}
                                                                                  {value.operation == 'OFF' && <span>--</span>}
                                                                                  {value.operation == 'ON' && <span>{value.schedule_temp + '°'}</span>}</td>

                                                                          </tr>

                                                                      ))}
                                                                  </tbody>
                                                                  {/* <tbody> */}

                                                              </table>
                                                          </div>

                                                      </TabPane>
                                                  </TabContent>
                                              </CardBody>
                                          </Card>
                                      </Col>
                                      <Col md="6">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5" className="dashboardcardtitle">
                                                      <Row>
                                                          <Col className="pr-1" md="6">
                                                              {this.state.thermostatName}
                                                          </Col>
                                                          <Col className="pr-1 d-flex justify-content-center" lg="3">
                                                            
                                                          </Col>
                                                          <Col className="pr-1 d-flex justify-content-center" lg="3">
                                                            <button style={{cursor:'pointer',display:'grid',padding:'5px', border:'none', backgroundColor:"Transparent"}} onClick={this.togglemodal} data-backdrop="static" data-keyboard="false" disabled={this.state.ChoosedThermoStat == null}><img src={remote} height={50} width={50} style={{display:'block', margin: '0 auto'}}/>
                                                                <span className={"card-user pl-3 pr-3 " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{fontSize:'18px'}}>Remote</span>
                                                            </button>
                                                          </Col>
                                                      </Row>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody style={{ textAlign: "center" }}>
                                                  <div className={"dialog"}>
                                                      <img src={dial_image} style={{ position: "center", objectFit: "cover" }} className={"img-rounded"} alt="Cinque Terre" width="100%" />
                                                      <div>
                                                          <label>Set to</label><br />
                                                          {this.state.displayData.power != 'OFF' && <span>{this.state.displayData.temp}°</span>}

                                                      </div>
                                                  </div>
                                                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)+ ' '+ "thermocard"} >
                                                      <CardHeader>
                                                          <table >
                                                              <tr>
                                                                  <td>
                                                                      {/* <i style="font-size: 28px !important;padding-top: 2px;	color: green;"
																			[ngStyle]="displayData.power=='ON' ? { color:'green' } : displayData.power=='OFF' ? {color: 'red'} : displayData.power=='AUTO' ? {color: '#1092C1'} : {color: ''}"
                                                                            className='fas fa-power-off'></i><br> */}
                                                                     <i className="fa fa-power-off"  style={{color:firstcolor(this.state.displayData.power)}}/><br/>
                                                                      <span
                                                                          className="batch">POWER</span><br />
                                                                      <h5
                                                                      >
                                                                          {this.state.displayData.power}
                                                                      </h5>
                                                                  </td>

                                                                  <td>
                                                                      {/* <img src="../../assets/images/IRimage/fan.new.png"
																			width="30px" height="30px"><br> */}
                                                                              <i className="fas fa-fan" style={{color:firstcolor(this.state.displayData.fan)}} /><br/>
                                                                      <span
                                                                          className="batch">FAN</span>
                                                                      <h5 >{this.state.displayData.fan}</h5>
                                                                  </td>
                                                                  <td>
                                                                      {/* <img src="../../assets/images/IRimage/newAC.png"
																			width="30px" height="30px"><br> */}
                                                                             <i class="fas fa-wind" style={{color:firstcolor(this.state.displayData.swing)}}/><br/>
                                                                      <span
                                                                          className="batch">SWING</span>
                                                                      <h5 >{this.state.displayData.swing}
                                                                      </h5>
                                                                  </td>
                                                              </tr>

                                                          </table>
                                                      </CardHeader>
                                                  </Card>

                                              </CardBody>
                                          </Card>
                                      </Col>
                                  </Row>
                                  
                          </Col>
                      </Row>
                  </>
                  :
                  <Row>
                      <Col md="12">
                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                              <CardHeader>
                              </CardHeader>
                              <CardBody>
                                  <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(test);
