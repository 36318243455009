import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,CardText
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import classnames from 'classnames';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import {SampleBase } from './Samplebase'
import swal from 'sweetalert';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
var AmCharts = require("@amcharts/amcharts3-react");
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Production extends SampleBase {
    constructor(props) {
        super();
                // var newtimeZone = localStorage.getItem('time_zone')
        // if(newtimeZone){
        //     let options = {
        //         timeZone: newtimeZone,
        //         year: 'numeric',
        //         month: 'numeric',
        //         day: 'numeric',
        //         hour: 'numeric',
        //         minute: 'numeric',
        //         second: 'numeric',
        //       },
        //     formatter = new Intl.DateTimeFormat([], options);
        //     var timezone = (formatter.format(new Date())).split(" ");
        //     var timezoneformat = timezone[0].replace(/,\s*$/, "")
        //     var finaloutput = new Date(timezoneformat)
        //     this.today = finaloutput
        // }
        // else{
        //     this.today = new Date(new Date().toDateString());
        // }  
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
            - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        let daysOfYear = [];
        let getyear = new Date();
		let s = getyear.getFullYear();
		let t = getyear.getFullYear() - 10;
		for (let d = t; d <= s; d++) {
			daysOfYear.push(d);
        }
        this.daysOfYear = daysOfYear
		this.currentyear = getyear.getFullYear().toString();
        this.state = {
            loader: false,
            activetab: '1',
            modal: false, 
            final_production_chart_data: [],
            final_ukg_chart_data: [],  
            final_monthly_chart_data: [],                             
            productiondefaultrange : [this.today,this.today],
            ukgdefaultrange : [this.today,this.today],
            production_process_list: [],
            def_production_process: [localStorage.getItem('selectedboard')],
            ukg_process_list: [],
            def_ukg_process: [localStorage.getItem('selectedboard')],
            checkboxvalue: 'Kgs',
            process_total: false,
            showcontent: false,
            prd_graph_series : [],
            final_daily_prd_table: [],
            final_monthly_prd_table: [],
            period: 'MONTHLY',
            yearrange: [
                { 'id': 1, "itemName": this.daysOfYear[10] },
                { 'id': 2, "itemName": this.daysOfYear[9] },
                { 'id': 3, 'itemName': this.daysOfYear[8] },
                { 'id': 4, 'itemName': this.daysOfYear[7] },
                { 'id': 5, 'itemName': this.daysOfYear[6] },
                { 'id': 6, "itemName": this.daysOfYear[5] },
                { 'id': 7, 'itemName': this.daysOfYear[4] },
                { 'id': 8, "itemName": this.daysOfYear[3] },
                { 'id': 9, 'itemName': this.daysOfYear[2] },
                { 'id': 10, 'itemName': this.daysOfYear[1] },
                { 'id': 11, 'itemName': this.daysOfYear[0] }
            ],
            selectedyear: this.daysOfYear[10],     
            monthrange: [
                { 'id': 1, "itemName": 'Jan ' + this.currentyear },
                { 'id': 2, 'itemName': 'Feb ' + this.currentyear },
                { 'id': 3, 'itemName': "Mar " + this.currentyear },
                { 'id': 4, "itemName": 'Apr ' + this.currentyear },
                { 'id': 5, 'itemName': 'May ' + this.currentyear },
                { 'id': 6, 'itemName': 'Jun ' + this.currentyear },
                { 'id': 7, 'itemName': 'Jul ' + this.currentyear },
                { 'id': 8, "itemName": "Aug " + this.currentyear },
                { 'id': 9, "itemName": "Sep " + this.currentyear },
                { 'id': 10, "itemName": "Oct " + this.currentyear },
                { 'id': 11, "itemName": "Nov " + this.currentyear },
                { 'id': 12, 'itemName': 'Dec ' + this.currentyear }
            ],
            selectedmonth: ['Jan ' + this.currentyear],
            quarterrange: [
                { 'id': 1, 'itemName': 'Q1 ' + this.currentyear, "value": 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear },
                { 'id': 2, "itemName": "Q2 " + this.currentyear, "value": "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear },
                { 'id': 3, 'itemName': "Q3 " + this.currentyear, "value": "Jul " + this.currentyear + "," + 'Aug ' + this.currentyear + "," + 'Sep ' + this.currentyear },
                { 'id': 4, "itemName": 'Q4 ' + this.currentyear, 'value': "Oct " + this.currentyear + ',' + "Nov " + this.currentyear + ',' + 'Dec ' + this.currentyear },
            ],
            selectedquarter: ['Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear],
            dailyprdbtntext: 'Add',
            monthlyprdbtntext: 'Add',
            tableproductvariationlist : [],
            selectedprocess: '',
            selectedprdvar: ''
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = [];
        this.ukg_list = [];
        this.tableprocesslist = [];
        this.mon_prd_list = ['Jan ' + this.currentyear];
        this.prd_char_config = {}
        this.ukg_char_config = {}
        this.monthly_prd_chart_config = {}
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/production')
        this.convertdate1(new Date())
        if (localStorage.getItem('selectedboard')) {
            this.setState({
                showcontent: true
            })
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.convertdate(this.state.productiondefaultrange,localStorage.getItem('selectedcustomer'),'production')
        this.convertdate(this.state.ukgdefaultrange,localStorage.getItem('selectedcustomer'),'ukg')
        this.getboardsfordropdown(localStorage.getItem('selectedcustomer'))
        this.getdailybelowtable(localStorage.getItem('selectedcustomer'))
        this.getdailyproductvariationdata(localStorage.getItem('selectedcustomer'))
        this.dailyproductionsubmit()
        this.dailyukgsubmit()
        this.monthlyprdSubmit()
        this.getmonthlybelowtable(localStorage.getItem('selectedcustomer'))
        this.setState({
            showcontent: true
        })
    }

    getboardsfordropdown(selectedcustomer) {
        this.setState({ loader: true })
        apiService.Boarddropdowndata(selectedcustomer).then(response => {
            this.setState({ loader: false, production_process_list: response.data , ukg_process_list: response.data})
            this.pri_list = response.data
            this.ukg_list = response.data
            this.tableprocesslist = response.data
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getdailyproductvariationdata(selectedcustomer) {
        this.setState({ loader: true })
        apiService.dailyproductvariationdata(selectedcustomer).then(response => {
            this.setState({ loader: false, tableproductvariationlist: response.data})
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getdailybelowtable(selectedcustomer) {
        if ($.fn.dataTable.isDataTable('#dailyprdtable')) {
            $('#dailyprdtable').DataTable().clear().destroy();
        } 
        this.setState({ loader: true })
        apiService.Belowtabledailydata(selectedcustomer).then(response => {
            this.setState({ loader: false ,final_daily_prd_table: [] ,final_daily_prd_table : this.state.final_daily_prd_table.concat(response.data)})
            $(document).ready(function () {
                $('#dailyprdtable').DataTable({
                  "bLengthChange": false,
                  "pageLength": 10,
                  "searching": true,
                  "bInfo": true,
                  "bSort": true,
                  "bPaginate": true,
                  "dom": 'Bfrtip',
                  "buttons": []
                });
            }); 
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getmonthlybelowtable(selectedcustomer) {
        if ($.fn.dataTable.isDataTable('#monthlyprdtable')) {
            $('#monthlyprdtable').DataTable().clear().destroy();
        } 
        this.setState({ loader: true })
        apiService.Belowtablemonthlydata(selectedcustomer).then(response => {
            this.setState({ loader: false ,final_monthly_prd_table: [] ,final_monthly_prd_table : this.state.final_monthly_prd_table.concat(response.data)})
            $(document).ready(function () {
                $('#monthlyprdtable').DataTable({
                  "bLengthChange": false,
                  "pageLength": 10,
                  "searching": true,
                  "bInfo": true,
                  "bSort": true,
                  "bPaginate": true,
                  "dom": 'Bfrtip',
                  "buttons": []
                });
            }); 
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    convertdate(e,customer_Id,type) {
        const sy = e[0].getFullYear();
        const sm = e[0].getMonth() + 1;
        const sd = e[0].getDate();
        const ey = e[1].getFullYear();
        const em = e[1].getMonth() + 1;
        const ed = e[1].getDate();
        if (type == ' production') {
            this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
            this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        } else if (type == 'ukg') {
            this.ukgstartdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
            this.ukgenddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        } else {
            this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
            this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        }
        this.DailyProductionform.patchValue({
            process: [parseInt(localStorage.getItem('selectedboard'))],
            customer_id: localStorage.getItem('selectedcustomer'),
        })
        this.DailyUKGform.patchValue({
            process: [parseInt(localStorage.getItem('selectedboard'))],
            customer_id: localStorage.getItem('selectedcustomer'),
        })
    }

    togglemodal() {
        this.setState({
          modal: !this.state.modal
        });
    }

    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.monthlytoday = sy.toString() + '-' + sm.toString();
        this.DailyPRDform.patchValue({
            date:this.dailytoday,
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            updated_by: localStorage.getItem('user_id'),
        })
        this.MonthlyPRDform.patchValue({
            date:this.monthlytoday,
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            updated_by: localStorage.getItem('user_id'),
        })
    }

    DailyProductionform = FormBuilder.group({
        daterange: [""],
        process: [[parseInt(localStorage.getItem('selectedboard'))], [Validators.required]],
        process_total:[false],
        customer_id: [localStorage.getItem('selectedcustomer'), [Validators.required]],
    });

    DailyUKGform = FormBuilder.group({
        daterange: [""],
        process: [[parseInt(localStorage.getItem('selectedboard'))], [Validators.required]],
        unit_type:["Kgs", [Validators.required]],
        customer_id: [localStorage.getItem('selectedcustomer'), [Validators.required]],
    });

    DailyPRDform = FormBuilder.group({
        created_by: [localStorage.getItem('user_id')],
        customer_id: [localStorage.getItem('selectedcustomer')],
        date: ['', [Validators.required]],
        from_time: ['00:00', [Validators.required]],
        grid_kwh_manual: [0, [Validators.required]],
        grid_kwh_system: [0, [Validators.required]],
        id: [''],
        process:  ['', [Validators.required]],
        production_kgs: [0, [Validators.required]],
        production_variation: ['', [Validators.required]],
        to_time: ['23:59', [Validators.required]],
        updated_by: [localStorage.getItem('user_id')],
        wastage: [0, [Validators.required]]
    })

    MonthlyPRDform = FormBuilder.group({
        created_by: [localStorage.getItem('user_id')],
        customer_id: [localStorage.getItem('selectedcustomer')],
        date: ['', [Validators.required]],
        grid_kwh_manual: [0, [Validators.required]],
        grid_kwh_system: [0, [Validators.required]],
        id: [''],
        production_kgs: [0, [Validators.required]],
        solar_kwh: [0, [Validators.required]],
        updated_by: [localStorage.getItem('user_id')],
        wastage: [0, [Validators.required]],
    })

    datehandleChange1(e) {
        this.setState({
            defaultrange: e.target.value
        })
        this.convertdate(e.target.value,localStorage.getItem('selectedcustomer'),'production')
    }

    datehandleChange2(e) {
        this.setState({
            defaultrange: e.target.value
        })
        this.convertdate(e.target.value,localStorage.getItem('selectedcustomer'),'ukg')
    }

    rangehandleChange3(range) {
        if (this.state.period == 'MONTHLY') {
            var test = false;
            for (let i = 0 ; i<= this.mon_prd_list.length -1 ;i++) {
                if (this.mon_prd_list[i] == range) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.mon_prd_list = this.mon_prd_list.filter((obj) => obj != range)
            } else {
                this.mon_prd_list.push(range)
            }
            this.setState({
                selectedmonth: this.mon_prd_list
            })
        } else if (this.state.period == 'QUARTERLY') {
          this.setState({
              selectedquarter: range
          })
        } else if (this.state.period == 'YEARLY') {
            this.setState({
                selectedyear: range
            })
        } else {
            this.setState({
                selectedmonth: this.mon_prd_list
            })
        }
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.DailyUKGform.patchValue({
            unit_type: e.target.value
        })
        this.setState({
            checkboxvalue: e.target.value
        })
    }

    processhandleChange(id) {
        this.DailyPRDform.patchValue({
            process: id
        })
        this.setState({
            selectedprocess: id
        })
    }

    prdvariationhandleChange(id) {
        this.DailyPRDform.patchValue({
            production_variation: id
        })
        this.setState({
            selectedprdvar: id
        })
    }

    monthlyperiodhandleChange(e) {
        e.preventDefault();
        this.setState({
            period: e.target.value
        })
    }

    changeprocesstotal(e) {
        if (e == false) {
            this.DailyProductionform.patchValue({
                process_total: true
            })
            this.setState({
                process_total: true
            })
        } else {
            this.DailyProductionform.patchValue({
                process_total: false
            })
            this.setState({
                process_total: false
            })
        }
    }

    productionprocesshandleChange(option) {
        if (option == '') {
            this.setState({
                def_production_process: [],
            })
            this.DailyProductionform.patchValue({
                process: []
            })
            this.pri_list = []
        } else {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj.id != option['id'])
            } else {
                this.pri_list.push(option)
            }
        
            this.setState({
                def_production_process: [option.id],
            })
            this.DailyProductionform.patchValue({
                process: [option.id]
            })
        }
    }

    ukgprocesshandleChange(option) {
        if (option == '') {
            this.setState({
                ukg_production_process: [],
            })
            this.DailyUKGform.patchValue({
                process: []
            })
            this.ukg_list = []
        } else {
            var test = false;
            for (let i = 0 ; i<= this.ukg_list.length -1 ;i++) {
                if (this.ukg_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.ukg_list = this.ukg_list.filter((obj) => obj.id != option['id'])
            } else {
                this.ukg_list.push(option)
            }
        
            this.setState({
                ukg_production_process: [option.id],
            })
            this.DailyUKGform.patchValue({
                process: [option.id]
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            var uom = ''
            if (this.state.checkboxvalue == 'kWh') {
                uom = 'kWh'
            } else {
                uom = 'Unit/Kgs'
            }
            this.dailyproductionsubmit();
            this.ukgchart(this.state.final_ukg_chart_data,uom,this.props.charttheme)
            this.monthlyprdchart(this.state.final_monthly_chart_data,'Unit/Kgs',this.props.charttheme)
        }
    }

    toggle(option) {
        this.setState({
            activetab: option
        })
    }

    dailyproductionsubmit() {
        var finalresponse = {
            customer_id: this.DailyProductionform.getRawValue()['customer_id'],
            from_date: this.startdate,
            process: this.DailyProductionform.getRawValue()['process'],
            process_total: this.state.process_total,
            to_date: this.enddate
        }
        this.setState({ loader: true })
        apiService.getdailyProductionchartdata(finalresponse).then(response => {
            this.setState({ loader: false, final_production_chart_data : response.data})
            var graph_series = []
            if (finalresponse.process_total == true ) {
                if (response.data.length != 0) {
                    const keylist = Object.keys(response.data[0]);
                    if (keylist.includes('date')) {
                        keylist.splice(keylist.indexOf('date'), 1 );
                    }
                    if (keylist.includes('Process Total')) {
                        keylist.splice(keylist.indexOf('Process Total'), 1 );
                    }
                    keylist.forEach(function(item) {
                        const graphproperty = {
                          'balloonText': "<b>[[title]]</b>: <b>[[value]] Kgs</b></span>",
                          'fillAlphas': 1,
                          'labelText': "",
                          'title': item,
                          'lineAlpha': 0.5,
                          'type': 'column',
                          'valueField' : item
                        };
                        graph_series.push(graphproperty);
                    });
                    var newstack = {
                        "balloonText": "<b>[[title]]</b>: <b>[[value]] Kgs</b></span>",
                        "fillAlphas": 1,
                        "labelText": "",
                        "lineAlpha": 0.3,
                        "title": "Process Total",
                        "type": "column",
                        "newStack": true,
                        "valueField": "Process Total"
                    }
                    graph_series.push(newstack)
                    this.setState({
                        prd_graph_series : graph_series
                    })
                }
            } else {
                if (response.data.length != 0) {
                    const keylist = Object.keys(response.data[0]);
                    if (keylist.includes('date')) {
                        keylist.splice(keylist.indexOf('date'), 1 );
                    }
                    keylist.forEach(function(item) {
                        const graphproperty = {
                          'balloonText': "<b>[[title]]</b>: <b>[[value]] Kgs</b></span>",
                          'fillAlphas': 1,
                          'labelText': "",
                          'title': item,
                          'lineAlpha': 0.5,
                          'type': 'column',
                          'valueField' : item
                        };
                        graph_series.push(graphproperty);
                    });
                    this.setState({
                        prd_graph_series : graph_series
                    })
                }
            }
            this.productionchart(this.state.final_production_chart_data,'Kgs',this.props.charttheme,graph_series)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handledailyprdtableSubmit(event,formvalues) {
        event.preventDefault();
        if (formvalues.id == '' || formvalues.id == undefined || formvalues.id == null) {
            this.setState({ loader: true })
            apiService.postBelowtabledailydata(formvalues).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Daily Production Data Successfully")          
                this.getdailybelowtable(formvalues.customer_id);
                this.dailyprdtablecancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.putBelowtabledailydata(formvalues).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Daily Production Data Updated Successfully")       
                this.getdailybelowtable(formvalues.customer_id)
                this.dailyprdtablecancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    handlemonthlyprdtableSubmit(event,formvalues) {
        event.preventDefault();
        if (formvalues.id == '' || formvalues.id == undefined || formvalues.id == null) {
            this.setState({ loader: true })
            formvalues.date = formvalues.date + '-01'
            apiService.postBelowtablemonthlydata(formvalues).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Monthly Production Data Successfully")          
                this.getmonthlybelowtable(formvalues.customer_id);
                this.monthlyprdtablecancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            formvalues.date = formvalues.date + '-01'
            apiService.putBelowtablemonthlydata(formvalues).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Monthly Production Data Updated Successfully")       
                this.getmonthlybelowtable(formvalues.customer_id)
                this.monthlyprdtablecancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    dailyprdtabledelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Daily Production Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.deletetabledailydata(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Daily Production Data Deleted Successfully")      
                    this.getdailybelowtable(value.customer_id)
                    this.dailyprdtablecancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    monthlyprdtabledelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Monthly Production Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.deletetablemonthlydata(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Monthly Production Data Deleted Successfully")      
                    this.getmonthlybelowtable(value.customer_id)
                    this.monthlyprdtablecancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    dailyprdtableedit(value) {
        this.DailyPRDform.patchValue({
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            date: value.date,
            from_time: value.from_time,
            grid_kwh_manual: value.grid_kwh_manual,
            grid_kwh_system: value.grid_kwh_system,
            id: value.id,
            process:  parseInt(value.process),
            production_kgs: value.production_kgs,
            production_variation: parseInt(value.production_variation),
            to_time: value.to_time,
            updated_by: localStorage.getItem('user_id'),
            wastage: value.wastage,
        })
        this.setState({
            dailyprdbtntext: 'Update'
        })
        this.setState({
            selectedprocess: parseInt(value.process),
            selectedprdvar: parseInt(value.production_variation)
        })
    }

    monthlyprdtableedit(value) {
        this.setState({
            monthlyprdbtntext: 'Update'
        })
        var edit_month = value.date.split("-")[0] + '-' + value.date.split("-")[1]
        this.MonthlyPRDform.patchValue({
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            date: edit_month,
            grid_kwh_manual: value.grid_kwh_manual,
            grid_kwh_system: value.grid_kwh_system,
            id: value.id,
            production_kgs: value.production_kgs,
            solar_kwh: value.solar_kwh,
            updated_by: localStorage.getItem('user_id'),
            wastage: value.wastage,
        })
    }

    dailyprdtablecancel() {
        this.setState({
            dailyprdbtntext: 'Add'
        })
        this.DailyPRDform.patchValue({
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            date: this.dailytoday,
            from_time: '00:00',
            grid_kwh_manual: 0,
            grid_kwh_system: 0,
            id: '',
            process:  '',
            production_kgs: 0,
            production_variation: '',
            to_time: '23:59',
            updated_by: localStorage.getItem('user_id'),
            wastage: 0,
        })
        this.setState({
            selectedprocess: '',
            selectedprdvar: ''
        })
    }

    monthlyprdtablecancel() {
        this.setState({
            monthlyprdbtntext: 'Add'
        })
        this.MonthlyPRDform.patchValue({
            created_by: localStorage.getItem('user_id'),
            customer_id: localStorage.getItem('selectedcustomer'),
            date: this.monthlytoday,
            grid_kwh_manual: 0,
            grid_kwh_system: 0,
            id: '',
            production_kgs: 0,
            solar_kwh: 0,
            updated_by: localStorage.getItem('user_id'),
            wastage: 0,
        })
    }

    dailyukgsubmit() {
        var finalresponse = {
            customer_id: this.DailyUKGform.getRawValue()['customer_id'],
            from_date: this.ukgstartdate,
            process: this.DailyUKGform.getRawValue()['process'],
            unit_type: this.state.checkboxvalue,
            to_date: this.ukgenddate
        }
        this.setState({ loader: true })
        apiService.getdailyUKGchartdata(finalresponse).then(response => {
            this.setState({ loader: false, final_ukg_chart_data : response.data})
            var graph_series = []
            var uom = ''
            if (finalresponse.unit_type == 'kWh') {
                uom = 'kWh'
            } else {
                uom = 'Unit/Kgs'
            }
            this.ukgchart(this.state.final_ukg_chart_data,uom,this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    monthlyprdSubmit() {
        if (this.state.period == 'MONTHLY' ) {
            this.getmonthlyprdchart(this.state.period,this.state.selectedmonth,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'QUARTERLY') {
            this.getmonthlyprdchart(this.state.period,this.state.selectedquarter,localStorage.getItem('selectedboard'))
        }else if (this.state.period == 'YEARLY') {
            this.getmonthlyprdchart(this.state.period,this.state.selectedyear,localStorage.getItem('selectedboard'))
        } else {
            this.getmonthlyprdchart(this.state.period,this.state.selectedmonth,localStorage.getItem('selectedboard'))
        }
    }

    getmonthlyprdchart(period,range,board_id) {
        this.setState({ loader: true })
        apiService.getmonthlyProductionchartdata(period,range,board_id).then(response => {
            this.setState({ loader: false, final_monthly_chart_data: response.data })
            this.monthlyprdchart(this.state.final_monthly_chart_data,'Unit/Kgs',this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    monthlyprdchart(chartdata,belowtableuom,theme) {
        this.monthly_prd_chart_config = AmCharts.makeChart("monthlyprdchartdiv",{
            "type": "serial",
            "theme": theme,
            'marginRight': 60,
            "categoryField": "date",
            'hideCredits': true,
            "legend": {
              "horizontalGap": 10,
              "useGraphSettings": true,
              "markerSize": 10
            },
            "rotate": false,
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start",
                "position": "left"
            },
            "trendLines": [],
            "graphs": [
                {
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'System',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'system'
                },{
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'Manual',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'manual'
                },{
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'Discrepancy',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'discrepancy'
                }
            ],
            'chartScrollbar': {
                'dragIcon': 'dragIconRoundBig',
            },
	        "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "position": "bottom",
                    "axisAlpha": 0,
                    "title": belowtableuom 
                }
            ],
            "allLabels": [],
            "balloon": {},
            "titles": [],
	        "dataProvider": chartdata,
            "responsive": {
                "enabled": true
            },
            "export": {
              "enabled": true
            }
        })
    }

    productionchart (chartdata,belowtableuom,theme,graphseries) {
        this.prd_char_config = AmCharts.makeChart("dailyprdchart",{
            "type": "serial",
            "colors": [
                "#2a6fdb",
                "#f5487f"
            ],
            "theme": theme,
            'marginRight': 60,
            'hideCredits': true,
            'mouseWheelZoomEnabled': true,
            "legend": {
              "horizontalGap": 10,
              "useGraphSettings": true,
              "markerSize": 10
            },
            "dataProvider": chartdata,
            "valueAxes": [ {
              "stackType": "regular",
              "axisAlpha": 0,
              "title": 'Total Production (' + belowtableuom + ')'
            } ],
            "graphs": graphseries,
            'chartScrollbar': {
                'dragIcon': 'dragIconRoundBig',
            },
            'listeners': [{
                'event': 'rendered',
                'method': function(e) {
                  e.chart.zoomToIndexes(0, 15);
                }
            }],      
            "categoryField": "date",
            "categoryAxis": {
              "gridPosition": "start",
              "axisAlpha": 0,
              "position": "left"
            },
            "responsive": {
                "enabled": true
            },
            "export": {
              "enabled": true
            }
        })
    }

    ukgchart(chartdata,belowtableuom,theme) {
        this.ukg_char_config = AmCharts.makeChart("dailyukgchart",{
            "type": "serial",
            "theme": theme,
            'marginRight': 60,
            "categoryField": "date",
            'hideCredits': true,
            'mouseWheelZoomEnabled': true,
            "legend": {
              "horizontalGap": 10,
              "useGraphSettings": true,
              "markerSize": 10
            },
            "rotate": false,
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start",
                "position": "left"
            },
            "trendLines": [],
            "graphs": [
                {
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'System',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'system'
                },{
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'Manual',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'manual'
                },{
                    'balloonText': "<b>[[title]]</b> : <b>[[value]] " +belowtableuom+"</b></span>",
                    'fillAlphas': 1,
                    'labelText': "",
                    'title': 'Discrepancy',
                    'lineAlpha': 0.5,
                    'type': 'column',
                    'valueField' : 'discrepancy'
                }
            ],
            'chartScrollbar': {
                'dragIcon': 'dragIconRoundBig',
            },
	        "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "position": "bottom",
                    "axisAlpha": 0,
                    "title": belowtableuom 
                }
            ],
            'listeners': [{
                'event': 'rendered',
                'method': function(e) {
                  e.chart.zoomToIndexes(0, 15);
                }
            }], 
            "allLabels": [],
            "balloon": {},
            "titles": [],
	        "dataProvider": chartdata,
            "responsive": {
                "enabled": true
            },
            "export": {
              "enabled": true
            }
        })
    }


    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ? 
            <>
              <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Daily Data
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Monthly Data
                            </NavLink>
                        </NavItem>
                    </Nav> 
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <TabContent activeTab={this.state.activetab}>
                    <TabPane tabId="1">
                        <CardTitle tag="h5">Production Analysis</CardTitle>
                        <FieldGroup
                            control={this.DailyProductionform}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <FieldControl
                                            name="datarange"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                                        <div className='control-pane'>
                                                            <div className='control-section'>
                                                                <div className='datepicker-control-section'>
                                                                    <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange1(e)} value={this.state.productiondefaultrange}>
                                                                        <PresetsDirective>
                                                                            <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                            <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                            <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                        </PresetsDirective>
                                                                    </DateRangePickerComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <FieldControl
                                            name="process"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <InputLabel id="demo-mutiple-name-label3">Process</InputLabel>
                                                            <Select
                                                                labelId="demo-mutiple-name-label3"
                                                                id="demo-mutiple-name-label3"
                                                                fullWidth variant="outlined"
                                                                multiple
                                                                label="Process"
                                                                value={this.state.def_production_process}
                                                                {...handler("text")}
                                                                >
                                                                {this.state.production_process_list.map(option => (
                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.productionprocesshandleChange(option)}>
                                                                        {option.itemName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <FieldControl
                                            name="process_total"
                                            strict= {false}
                                            render={({ handler, touched }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{paddingTop: '1.25rem'}}>
                                                <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                                    <Grid item>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.DailyProductionform.getRawValue().process_total} value="active"  color="primary" onClick={(event) => this.changeprocesstotal(this.DailyProductionform.getRawValue().process_total)} />}
                                                        label="Process Total"
                                                    />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <div style={{paddingTop: '1.25em'}}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => this.dailyproductionsubmit()}
                                                    >
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            )}
                        />
                        <hr />
                        <div id="dailyprdchart" style={{ width: "100%", height: "500px" }} options={this.prd_char_config} />
                        <hr style={{marginTop: "2rem"}} />
                        <CardTitle tag="h5">Unit Per Kg Analysis</CardTitle>
                        <FieldGroup
                            control={this.DailyUKGform}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <FieldControl
                                            name="datarange"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                                        <div className='control-pane'>
                                                            <div className='control-section'>
                                                                <div className='datepicker-control-section'>
                                                                    <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange2(e)} value={this.state.productiondefaultrange}>
                                                                        <PresetsDirective>
                                                                            <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                            <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                            <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                        </PresetsDirective>
                                                                    </DateRangePickerComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <FieldControl
                                            name="process"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <InputLabel id="demo-simple-select-outlined-label4">Process</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label4"
                                                                id="demo-simple-select-outlined4"
                                                                fullWidth variant="outlined"
                                                                label="Process"
                                                                multiple
                                                                value={this.state.def_ukg_process}
                                                                {...handler("text")}
                                                                >
                                                                {this.state.ukg_process_list.map(option => (
                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.ukgprocesshandleChange(option)}>
                                                                        {option.itemName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <FieldControl
                                            name="uom"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item> 
                                                            <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label2"
                                                                id="demo-simple-select-outlined2"
                                                                label="Units"
                                                                fullWidth variant="outlined"
                                                                onChange={(e) => this.uomhandleChange(e)}
                                                                value={this.state.checkboxvalue}
                                                                >
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'Kgs'}>Unit/Kgs</MenuItem>
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kWh'}>kWh</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                        <div style={{paddingTop: '1.25em'}}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => this.dailyukgsubmit()}
                                                    >
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            )}
                        />
                        <hr />
                        <div id="dailyukgchart" style={{ width: "100%", height: "500px" }} options={this.ukg_char_config} />
                        <hr style={{marginTop: "2rem"}} />
                        <CardTitle tag="h5">Daily Production Data</CardTitle>
                        <FieldGroup
                            control={this.DailyPRDform}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="2">
                                    <FieldControl
                                        name="date"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="date" required fullWidth variant="outlined" label="Date" error={touched && hasError("required")}  {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Date is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                    <FieldControl
                                        name="from_time"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="time" required fullWidth variant="outlined" label="From Time" error={touched && hasError("required")}  {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* From Time is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="2">
                                    <FieldControl
                                        name="to_time"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="time" required fullWidth variant="outlined" label="To Time" error={touched && hasError("required")}  {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* To Time is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="process"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField  fullWidth                     
                                                            select
                                                            label="Select Process"
                                                            variant="outlined"
                                                            {...handler("text")}
                                                            value={this.state.selectedprocess}
                                                            error={touched && hasError("required")}
                                                        >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.processhandleChange('')}>
                                                            Select Process
                                                        </MenuItem>
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='0' onClick={(e) => this.processhandleChange('0')}>
                                                            Process Total
                                                        </MenuItem>
                                                        {this.tableprocesslist.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.processhandleChange(option.id)}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Process is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="production_variation"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField  fullWidth                     
                                                            select
                                                            label="Select Product Variation"
                                                            variant="outlined"
                                                            {...handler("text")}
                                                            value={this.state.selectedprdvar}
                                                            error={touched && hasError("required")}
                                                        >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.prdvariationhandleChange('')}>
                                                            Select Product Variation
                                                        </MenuItem>
                                                        {this.state.tableproductvariationlist.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.prdvariationhandleChange(option.id)}>
                                                            {option.name}
                                                        </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Product Variation is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="production_kgs"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Production (Kgs)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Production (Kgs) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="wastage"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Wastage" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Wastage is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="grid_kwh_manual"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Grid kWh (Manual)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Grid kWh (Manual) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    {this.state.selectedprocess == '0' ?
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="grid_kwh_system"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Grid kWh (System)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Grid kWh (System) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    : ''}
                                </Row>
                                <Row>
                                    <Col className="pr-1" md="3">
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid}
                                                onClick={(event) => this.handledailyprdtableSubmit(event,this.DailyPRDform.getRawValue())}
                                                >
                                                {this.state.dailyprdbtntext}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                onClick={() => this.dailyprdtablecancel()}
                                                variant="contained"
                                                color="danger"
                                                >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    </Col>
                                </Row>
                            </form>
                            )}
                        />
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="dailyprdtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    <tr>
                                        <th style={{width : '100px'}}>Actions</th>
                                        <th style={{width : '100px'}}>Date</th>
                                        <th style={{width : '100px'}}>From Time</th>
                                        <th style={{width : '100px'}}>To Time</th>
                                        <th style={{width : '100px'}}>Process</th>
                                        <th style={{width : '200px'}}>Product Variation</th>
                                        <th style={{width : '200px'}}>Production (Kgs)</th>
                                        <th style={{width : '100px'}}>Wastage</th>
                                        <th style={{width : '225px'}}>Total Production (Kgs)</th>
                                        <th style={{width : '200px'}}>Grid kWh (System)</th>
                                        <th style={{width : '200px'}}>Grish kWh (Manual)</th>
                                        <th style={{width : '150px'}}>Discrepancy</th>
                                        <th style={{width : '200px'}}>Units/Kgs (System)</th>
                                        <th style={{width : '200px'}}>Units/Kgs (Manual)</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    {this.state.final_daily_prd_table.map((value, index) => (
                                        <tr key={index+1} >
                                            {value.process == '0' 
                                                ?
                                                <>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={(event) => this.dailyprdtabledelete(event,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href="javascript:void(0)" onClick={(e) => this.dailyprdtableedit(value)}><i className="fa fa-edit icons"></i></a>
                                                    </td>
                                                    <td style={{color : '#2e7dc1'}}>{value.date}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.from_time}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.to_time}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.process_name}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.product_variation_name}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.production_kgs}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.wastage}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.total_production_kgs}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.grid_kwh_system}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.grid_kwh_manual}</td>
                                                    <td>
                                                        {value.discrepancy < 0 
                                                            ? <span style={{color : 'red'}}>{value.discrepancy}</span>
                                                            : <span style={{color : '#2e7dc1'}}>{value.discrepancy}</span>
                                                        }
                                                    </td>
                                                    <td style={{color : '#2e7dc1'}}>{value.unit_kgs_system}</td>
                                                    <td style={{color : '#2e7dc1'}}>{value.unit_kgs_manual}</td>
                                                </>
                                                :
                                                <>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={(event) => this.dailyprdtabledelete(event,value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href="javascript:void(0)" onClick={(e) => this.dailyprdtableedit(value)}><i className="fa fa-edit icons"></i></a>
                                                    </td>
                                                    <td>{value.date}</td>
                                                    <td>{value.from_time}</td>
                                                    <td>{value.to_time}</td>
                                                    <td>{value.process_name}</td>
                                                    <td>{value.product_variation_name}</td>
                                                    <td>{value.production_kgs}</td>
                                                    <td>{value.wastage}</td>
                                                    <td>{value.total_production_kgs}</td>
                                                    <td>{value.grid_kwh_system}</td>
                                                    <td>{value.grid_kwh_manual}</td>
                                                    <td>
                                                        {value.discrepancy < 0 
                                                            ? <span style={{color : 'red'}}>{value.discrepancy}</span>
                                                            : <span>{value.discrepancy}</span>
                                                        }
                                                    </td>
                                                    <td>{value.unit_kgs_system}</td>
                                                    <td>{value.unit_kgs_manual}</td>
                                                </>
                                            }  
                                        </tr>
                                    ))}
                                    </tbody>
                            </table>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <form>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Period</InputLabel>  
                                                <Select
                                                    labelId="demo-simple-select-outlined-label1"
                                                    id="demo-simple-select-outlined1"
                                                    label="Period"
                                                    fullWidth variant="outlined"
                                                    onChange={(e) => this.monthlyperiodhandleChange(e)}
                                                    value={this.state.period}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'MONTHLY'}>Monthly</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'QUARTERLY'}>Quarterly</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'YEARLY'}>Yearly</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-mutiple-name-label">Range</InputLabel>
                                                {this.state.period == 'MONTHLY' ?
                                                    <div>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Select
                                                            labelId="demo-mutiple-name-label"
                                                            id="demo-mutiple-name"
                                                            multiple variant="outlined" fullWidth
                                                            value={this.state.selectedmonth}
                                                            label="Range"
                                                            >
                                                            {this.state.monthrange.map((option) => (
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange3(option.itemName)}>
                                                                {option.itemName}
                                                                </MenuItem>
                                                            ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                        
                                                : 
                                                this.state.period == 'QUARTERLY' ? 
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label4"
                                                        id="demo-simple-select-outlined4"
                                                        label="Range"
                                                        fullWidth variant="outlined"
                                                        value={this.state.selectedquarter}
                                                        >
                                                        {this.state.quarterrange.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.value} onClick={(e) => this.rangehandleChange3(option.value)}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                :
                                                this.state.period == 'YEARLY' ?
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label4"
                                                        id="demo-simple-select-outlined4"
                                                        label="Range"
                                                        fullWidth variant="outlined"
                                                        value={this.state.selectedyear}
                                                        >
                                                        {this.state.yearrange.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange3(option.itemName)}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                :
                                                ''}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="2">
                                    <div style={{paddingTop: '1.25em'}}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.monthlyprdSubmit()}
                                                >
                                                Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <hr />
                        <div id="monthlyprdchartdiv" style={{ width: "100%", height: "500px" }} options={this.monthly_prd_chart_config} />
                        <hr style={{marginTop: "2rem"}} />
                        <CardTitle tag="h5">Monthly Production Data</CardTitle>
                        <FieldGroup
                            control={this.MonthlyPRDform}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="date"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="month" required fullWidth variant="outlined" label="Date" error={touched && hasError("required")}  {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Date is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="production_kgs"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Production (Kgs)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Production (Kgs) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="wastage"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Wastage" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Wastage is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="grid_kwh_system"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Grid kWh (System)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Grid kWh (System) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="grid_kwh_manual"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Grid kWh (Manual)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Grid kWh (Manual) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <FieldControl
                                            name="solar_kwh"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Solar(kWh)" {...handler("text")}  />
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Solar(kWh) is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid}
                                                onClick={(event) => this.handlemonthlyprdtableSubmit(event,this.MonthlyPRDform.getRawValue())}
                                                >
                                                {this.state.monthlyprdbtntext}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                onClick={() => this.monthlyprdtablecancel()}
                                                variant="contained"
                                                color="danger"
                                                >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            )}
                        />
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="monthlyprdtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    <tr>
                                        <th style={{width : '100px'}}>Actions</th>
                                        <th style={{width : '200px'}}>Month</th>
                                        <th style={{width : '225px'}}>Production (Kgs)</th>
                                        <th style={{width : '100px'}}>Wastage</th>
                                        <th style={{width : '225px'}}>Total Production (Kgs)</th>
                                        <th style={{width : '200px'}}>Grid kWh (System)</th>
                                        <th style={{width : '200px'}}>Grid kWh (Manual)</th>
                                        <th style={{width : '200px'}}>Discrepancy</th>
                                        <th style={{width : '200px'}}>Unit/Kgs (System)</th>
                                        <th style={{width : '200px'}}>Unit/Kgs (Manual)</th>
                                        <th style={{width : '200px'}}>Solar (Kwh)</th>
                                        <th style={{width : '225px'}}>Grid (System) + Solar (kWh)</th>
                                        <th style={{width : '225px'}}>Grid (Manual) + Solar (kWh)</th>
                                        <th style={{width : '225px'}}>Solar Unit/Kgs (System)</th>
                                        <th style={{width : '225px'}}>Solar Unit/Kgs (Manual)</th>
                                        <th style={{width : '225px'}}>Solar Unit/Kgs (Discrepancy)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.final_monthly_prd_table.map((value, index) => (
                                    <tr key={index+1}>
                                        <td>
                                            <a href="javascript:void(0)" onClick={(event) => this.monthlyprdtabledelete(event,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a href="javascript:void(0)" onClick={(e) => this.monthlyprdtableedit(value)}><i className="fa fa-edit icons"></i></a>
                                        </td>
                                        <td>{value.date_value}</td>
                                        <td>{value.production_kgs}</td>
                                        <td>{value.wastage}</td>
                                        <td>{value.total_production_kgs}</td>
                                        <td>{value.grid_kwh_system}</td>
                                        <td>{value.grid_kwh_manual}</td>
                                        <td>{value.discrepancy}</td>
                                        <td>{value.unit_kgs_system}</td>
                                        <td>{value.unit_kgs_manual}</td>
                                        <td>{value.solar_kwh}</td>
                                        <td>{value.grid_system_solar}</td>
                                        <td>{value.grid_manual_solar}</td>
                                        <td>{value.solar_unit_kgs_system}</td>
                                        <td>{value.solar_unit_kgs_manual}</td>
                                        <td>{value.solar_unit_kgs_discrepancy}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </TabPane>
                    </TabContent>
                </CardBody>
              </Card>
            </Col>
              </Row>
              </>
              : 
              <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Production);