import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col ,TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {FormControl} from "@material-ui/core"
import TextField from '@material-ui/core/TextField';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
const $ = require('jquery');
$.DataTable = require('datatables.net');

export class DigitalAsset extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            showcontent: false,
            activetab: "1",
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''

        this.digitalassettable = [
            {
                "board_id": "TWD31",
                "load_name": "CHILLER MOTOR2SF",
                "model_no": "M40MAHR4",
                "base_rpm": "1300",
                "rated_v": "415",
                "rated_kw": "150",
                "rated_hp": "200",
                "peak_kw": "114",
                "peak_kva": "124",
                "peak_amps": "166",
            },
            {
                "board_id": "TWD36",
                "load_name": "STEPPER MC 4YTF",
                "model_no": "42TYR548",
                "base_rpm": "1400",
                "rated_v": "400",
                "rated_kw": "120",
                "rated_hp": "160",
                "peak_kw": "88",
                "peak_kva": "95",
                "peak_amps": "126",
            },
            {
                "board_id": "TWD51",
                "load_name": "WOVEN MOTOR 21R",
                "model_no": "AESU25E",
                "base_rpm": "1250",
                "rated_v": "400",
                "rated_kw": "120",
                "rated_hp": "160",
                "peak_kw": "82",
                "peak_kva": "90",
                "peak_amps": "120",
            },
            {
                "board_id": "TWD53",
                "load_name": "COOLING TOWER 16Q",
                "model_no": "25465G377R",
                "base_rpm": "1400",
                "rated_v": "415",
                "rated_kw": "120",
                "rated_hp": "160",
                "peak_kw": "59",
                "peak_kva": "65",
                "peak_amps": "87",
            },
            {
                "board_id": "TWD61",
                "load_name": "STEPPER MC 45RFC",
                "model_no": "42BYG228",
                "base_rpm": "1300",
                "rated_v": "380",
                "rated_kw": "180",
                "rated_hp": "240",
                "peak_kw": "114",
                "peak_kva": "124",
                "peak_amps": "165",
            },
            {
                "board_id": "TWD64",
                "load_name": "CHILLER MOTOR43",
                "model_no": "M8G180NU5",
                "base_rpm": "1200",
                "rated_v": "380",
                "rated_kw": "150",
                "rated_hp": "200",
                "peak_kw": "119",
                "peak_kva": "130",
                "peak_amps": "173",
            },
            {
                "board_id": "TWD67",
                "load_name": "COOLING TOWER 34U",
                "model_no": "208G3TRF4T",
                "base_rpm": "1200",
                "rated_v": "380",
                "rated_kw": "200",
                "rated_hp": "265",
                "peak_kw": "141",
                "peak_kva": "154",
                "peak_amps": "205",
            },
            {
                "board_id": "TWD69",
                "load_name": "WOVEN  MOTRO57J",
                "model_no": "AESRE4D",
                "base_rpm": "1400",
                "rated_v": "415",
                "rated_kw": "200",
                "rated_hp": "265",
                "peak_kw": "121",
                "peak_kva": "132",
                "peak_amps": "176",
            },
            {
                "board_id": "TWD72",
                "load_name": "COMP MOTOR114C",
                "model_no": "2435RT65DT",
                "base_rpm": "1450",
                "rated_v": "415",
                "rated_kw": "150",
                "rated_hp": "200",
                "peak_kw": "101",
                "peak_kva": "110",
                "peak_amps": "146",
            },
            {
                "board_id": "TWD73",
                "load_name": "CHILLER MOTOR66A",
                "model_no": "M2B250NY74",
                "base_rpm": "1250",
                "rated_v": "400",
                "rated_kw": "180",
                "rated_hp": "240",
                "peak_kw": "133",
                "peak_kva": "145",
                "peak_amps": "193",
            },
        ]
    }

    digitalassetForm = FormBuilder.group({
        load_name: [{ value: 'CHILLER MOTOR2SF', disabled: true }],
        model_no: [{ value: 'M40MAHR4', disabled: true }],
        base_rpm: [{ value: '1300', disabled: true }],
        rated_v: [{ value: '415', disabled: true }],
        rated_kw: [{ value: '150', disabled: true }],
        rated_hp: [{ value: '200', disabled: true }],
        peak_kw: [{ value: '114', disabled: true }],
        peak_kva: [{ value: '124', disabled: true }],
        peak_amps: [{ value: '166', disabled: true }],
    });

    componentDidMount() {
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('is_admin') == 'true' || localStorage.getItem('is_admin') == true) {
                this.props.history.push('/neurobotz/digitalassets')
                this.getdigitalasset()
            } else {
                this.props.history.push('/neurobotz/dashboard')
            }
        } else {
            this.props.history.push('/neurobotz/login')
          }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
        })
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
        })
    }

    toggle(option) {
        this.setState({
            activetab: option
        })  
    }

    getdigitalasset() {
        if ($.fn.dataTable.isDataTable('#digitalassettable')) {
            $('#digitalassettable').DataTable().clear().destroy();
        }   
        $(document).ready(function () {
            $('#digitalassettable').DataTable({
              "bLengthChange": false,
              "pageLength": 10,
              "searching": true,
              "bInfo": true,
              "bSort": true,
              "bPaginate": true,
              "dom": 'Bfrtip',
              "buttons": [{
                'extend': "excel",
                "title": "Digital Asset",
              }]
            });
        });
    }

    handledailyreportSubmit(e,formvalue) {
        e.preventDefault()
        console.log(formvalue)
    }

    canceldigitalasset(e) {
        e.preventDefault()
        this.digitalassetForm.reset()
    }

    render() {
        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Create Digital Asset
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            View Digital Asset
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">
                                                <FieldGroup
                                                    control={this.digitalassetForm}
                                                    strict= {false}
                                                    render={({ get, invalid, value }) => (
                                                    <form>
                                                        <Row>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="load_name"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Load Name" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Load Name is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="model_no"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Model No" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Model No is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="base_rpm"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Base RPM" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Base RPM is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="rated_v"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Rated (V)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Rated (V) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="rated_kw"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Rated (KW)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Rated (KW) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="rated_hp"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Rated (HP)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Rated (HP) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="peak_kw"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Peak (KW)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Peak (KW) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="peak_kva"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Peak (KVA)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Peak (KVA) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="4">
                                                            <FieldControl
                                                                name="peak_amps"
                                                                strict= {false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>                
                                                                            <Grid item>
                                                                                <TextField type="text" required fullWidth variant="outlined" label="Peak (AMPS)" error={touched && hasError("required")}  {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Peak (AMPS) is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.handledailyreportSubmit(event,this.digitalassetForm.getRawValue())}
                                                                        >
                                                                        Create
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="danger"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.canceldigitalasset(event)}
                                                                        >
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                    )}
                                                />
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <table id="digitalassettable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{width : '200px'}}>Board ID</th>
                                                                        <th style={{width : '200px'}}>Load Name</th>
                                                                        <th style={{width : '200px'}}>Model NO</th>
                                                                        <th style={{width : '200px'}}>Base RPM</th>
                                                                        <th style={{width : '200px'}}>Rated (V)</th>
                                                                        <th style={{width : '200px'}}>Rated (KW)</th> 
                                                                        <th style={{width : '200px'}}>Rated (HP)</th>
                                                                        <th style={{width : '200px'}}>Peak (KW)</th>
                                                                        <th style={{width : '200px'}}>Peak (KVA)</th> 
                                                                        <th style={{width : '200px'}}>Peak (AMPS)</th> 
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.digitalassettable.map((value, index) => {
                                                                        return <tr key={index}>
                                                                                    <td>{value.board_id}</td>
                                                                                    <td>{value.load_name}</td>
                                                                                    <td>{value.model_no}</td> 
                                                                                    <td>{value.base_rpm}</td> 
                                                                                    <td>{value.rated_v}</td>
                                                                                    <td>{value.rated_kw}</td> 
                                                                                    <td>{value.rated_hp}</td>
                                                                                    <td>{value.peak_kw}</td>
                                                                                    <td>{value.peak_kva}</td> 
                                                                                    <td>{value.peak_amps}</td> 
                                                                                </tr>
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(DigitalAsset);