import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {logoimage} from "../routes"
import '../assets/css/auth.css';
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import * as apiService from '../services/apiService';

const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    sigintext : {
      align: 'center'
    }
});

class Login extends Component {
    constructor(props) {
        super();
        this.state = {
            loader: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('is_admin') == 'true') {
                this.props.history.push('/neurobotz/customers')
            } else {
                this.props.history.push('/neurobotz/dashboard')
            }
        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    loginForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
        master: [false],
    });

    handleSubmit(e, value) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.Login(value).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Logged in Successfully !!!!")
            localStorage.setItem('email',response.data[0].email);
            localStorage.setItem('is_admin',response.data[0].is_admin);
            localStorage.setItem('token',response.data[0].token);
            // localStorage.setItem('user_id',response.data[0].user_id);
            localStorage.setItem('user_id',response.data[0].customer_id);            
            // localStorage.setItem('username',response.data[0].username);
            localStorage.setItem('username',response.data[0].customername);
            localStorage.setItem('master',response.data[0].master);
            localStorage.setItem('currency',response.data[0].currency);
            localStorage.setItem('currency_name',response.data[0].currency_name);
            localStorage.setItem('board_id',response.data[0].board_id);
            localStorage.setItem('time_zone',response.data[0].time_zone);
            if (response.data[0].is_admin == true) {
                this.props.history.push('/neurobotz/customers')
            } else {
                this.props.history.push('/neurobotz/dashboard')
            }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="login-bg" >
                {
                    this.state.loader && <Loader />
                }
                <CssBaseline />
                <div className={classes.paper}>
                    <Card className="login-box">
                    <CardContent className="login-box-text">    
                        <img src={logoimage()} alt="logo" height="80" width="300" />
                        <Typography component="h1" variant="h5" className="authfontfamily">
                            Sign in to your Account
                        </Typography>
                        <FieldGroup
                            control={this.loginForm}
                            render={({ get, invalid, value }) => (
                            <form onSubmit={(e) => this.handleSubmit(e, value)}>
                                <FieldControl
                                    name="email"
                                    render={({ handler, touched, hasError }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <TextField required fullWidth variant="outlined" error={touched && hasError("required")} label="Email" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">{touched
                                                && hasError("required")
                                                && `* Email is required`}
                                                {touched
                                                    && hasError("email")
                                                    && `* Enter Valid Email Id`}
                                            </span>

                                        </Grid>
                                    </div>
                                    )}
                                />
                                <FieldControl
                                    name="password"
                                    render={({ handler, touched, hasError }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <TextField type="password" required fullWidth variant="outlined" error={touched && hasError("required")} label="Password"  {...handler("password")} />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Password is required`}
                                            </span>
                                        </Grid>
                                    </div>
                                    )}
                                />
                                {/* <FieldControl
                                    name="master"
                                    render={({ handler, touched }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox value="master" color="primary" />}
                                                label="Master"
                                            />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    )}
                                /> */}
                                <div className="login-button">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={invalid}
                                        >
                                        Login
                                    </Button>
                                </div>
                            </form>
                        )} 
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link to="/neurobotz/forgetpassword" variant="body2">
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/neurobotz/forgetpassword" className="atextdecoration authfontfamily2">Forget Password ?</Link>
                        </Grid>
                    </Grid>
                    </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Login);
