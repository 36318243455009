import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../services/normalloader';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import * as apiService from '../services/apiService';
import { CardHeader, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Switch from '@material-ui/core/Switch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import './ScheduleCreation.css';
import './fonts.css';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Paper, Select, Slider, Tab, Tabs } from '@material-ui/core';
import swal from 'sweetalert';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import Thermostat from './Thermostat';
import floor_image from '../assets/images/poorvika_blueprint.jpg';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing.png';
import pwr_off from '../assets/images/pwr_off.png';
import pwr_on from '../assets/images/pwr_on.png';
import fan_speed from '../assets/images/fan_speed.png';
import swing from '../assets/images/swing.png';
import temp_decrease from '../assets/images/temp_decrease.png';
import temp_increase from '../assets/images/temp_increase.png';
import swingicon1 from '../assets/images/displayswing.png';
import schedule from '../assets/images/schedule.png';
import remote from '../assets/images/remote.png';
import { DateTime } from 'luxon';
import SemiCircularProgressBar from './SemiCircularProgressBar';
import BorderLinearProgress from './BorderLinearProgress';
import "./SegmentedProgressBar.css";
import PageWithProgressBar from './TestProgressBar';
import WaveProgressBar from './WaveProgressBar';
import { height } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { PowerOff } from '@material-ui/icons';
const $ = require('jquery');
$.DataTable = require('datatables.net');
const styles = (theme) => ({
    root: {
        display: "flex",
        height: "100vh",
    },
    progressContainer: {
        width: 40,
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
        padding: theme.spacing(2),
    },
    progressBar: {
        borderRadius: 5,
    },
    tabsContainer: {
        flex: 1,
        padding: theme.spacing(2),
    },
});
// Custom styled LinearProgress for vertical progress bar
const VerticalLinearProgress = withStyles((theme) => ({
    root: {
        height: "100%",
        borderRadius: 5,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff",
    },
}))(WaveProgressBar);
class ScheduleComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isToggled: false,
            loader: false,
            showcontent: false,
            modal: false,
            activetab: "3",
            dataChange: false,
            buttontext: 'Add',
            schedules: [],
            isAddingSchedule: false,
            isEditingSchedule: false,
            selectedSchedule: null,
            ChoosedThermoStat: null,
            thermodropDownList: [],
            BoardList: [],
            AllCustomerList: [],
            dropDownList: {
                equipment: [],
                operation: [],
                speed: [],
                swing: [],
                temperature: []
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            newEvent: {
                time: '',
                equipment: '',
                mode: '',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            },
            newAssignment: {
                //from_date: "",
                to_date: '',
                hvac_schedule_id: '',
                thermo_id: ''
            },
            thermostatList: [],
            dateselected: [new Date(new Date().toDateString()), new Date(new Date().toDateString())],
            from_date: "",
            to_date: '',

            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            }
        };
        this.selectedcustomer = localStorage.getItem('selectedcustomer');
        this.selectedboard = ''
        this.customerhandler = this.customerhandler.bind(this);
        this.boardhandler = this.boardhandler.bind(this)

        this.togglemodal = this.togglemodal.bind(this)
        this.getRemote = this.getRemote.bind(this)
        this.fanControl = this.fanControl.bind(this)
        this.swingControl = this.swingControl.bind(this)
        this.increaseTemp = this.increaseTemp.bind(this)
        this.decreaseTemp = this.decreaseTemp.bind(this)
        this.closeRemote = this.closeRemote.bind(this)

        this.allScheduleList = [];
        this.allAssignList = [];
        this.allthermostatList = [];
        this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
        this.getdropdowndata();
        this.getThermoList();
        this.boardData();
        this.getallcustomerdata();
    }
    valuetext(value) {
        return `${value}°C`;
    }
    HandleProgresBar = (event,value) => {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataTemp == value) {
                // this.setState({
                //     remoteDataTemp: value
                // }, () => {
                //     this.remoteApicall('temperature', this.state.remoteDataTemp)
                // })
            }
            else {
                this.setState({
                    remoteDataTemp: value
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }
    marks = [
        {
            value: 16,
            label: '16°C',
        },
        {
            value: 17,
            label: '',
        },
        {
            value: 18,
            label: '',
        },
        {
            value: 19,
            label: '',
        },
        {
            value: 20,
            label: '',
        },
        {
            value: 21,
            label: '',
        },
        {
            value: 22,
            label: '',
        },
        {
            value: 23,
            label: '',
        },
        {
            value: 24,
            label: '',
        },
        {
            value: 25,
            label: '',
        },
        {
            value: 26,
            label: '',
        },
        {
            value: 27,
            label: '',
        },
        {
            value: 28,
            label: '',
        },
        {
            value: 29,
            label: '',
        },
        {
            value: 30,
            label: '30°C',
        },
    ];
    thermostatForm = FormBuilder.group({
        id: [""],
        name: ["", [Validators.required]],
        desc: ["", [Validators.required]],
        board: ["", [Validators.required]],
        temperature: ["", [Validators.required]]
    });
    boardData() {

        this.setState({ loader: true })
        var x = localStorage.getItem('selectedcustomer')
        apiService.topBoardDropdown(x).then(response => {
            this.setState({ BoardList: response.data })

            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    thermocancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.thermostatForm.reset()
    }
    getthermodropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {


            this.setState({ thermodropDownList: response.data[4].temperature, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    boardData() {

        this.setState({ loader: true })
        var x = localStorage.getItem('selectedcustomer')
        apiService.topBoardDropdown(x).then(response => {
            this.setState({ BoardList: response.data })

            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    handleSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Thermostat Added Successfully")
                this.getThermostat();
                this.boardData();
                this.thermocancel();
                this.toggle("3");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Board Updated Successfully")
                this.getThermostat();
                this.thermocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    thermostatedit(value) {
        this.thermostatForm.patchValue({
            id: value.id,
            name: value.thermo_name,
            desc: value.thermo_desc,
            board: value.board_id,
            temperature: value.set_temp,

        })
        this.setState({
            buttontext: 'Update'
        })
    }

    thermostatdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Thermostat!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteThermostat(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Thermostat Deleted Successfully")
                        this.getThermostat();
                        this.thermocancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    getdropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {
            var x = {
                equipment: response.data[0].Equipment,
                operation: response.data[1].operation,
                speed: response.data[2].speed,
                swing: response.data[3].swing,
                temperature: response.data[4].temperature,

            }
            this.setState({ dropDownList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel() {
        this.setState({
            newEvent: {
                time: '',
                equipment: '',
                mode: '',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            }
        });
    }
    getThermoList() {
        apiService.getThermostat().then(response => {
            this.setState({ thermostatList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        });
        localStorage.setItem('selectedcustomer', customerId)
        this.fetchSchedules();
        this.toggle("3");
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            schedules: [],
            isAddingSchedule: false,
            isEditingSchedule: false,
            selectedSchedule: null,
            newSchedule: {
                schedule_name: '',
                events: [],
            },
            newEvent: {
                time: '',
                equipment: '',
                mode: 'cool',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            },
        })
        localStorage.setItem('selectedboard', board_id)
        //this.getThermostat()
        //this.cancel()
        this.setState({
            showcontent: true
        })
        this.fetchSchedules();
        this.toggle("3");
        if (this.state.activetab == '1') {

        } else {
            if (this.state.activetab == '2') {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.fetchAssignment();
            } else {
                if (this.state.activetab == '3') {
                    this.setState({
                        showcontent: true,
                        thermostatList: []
                    }, () => {
                        this.getThermostat()
                    })
                    this.hvaccancel()
                } else {
                    if (this.state.activetab == '4') {
                        this.setState({
                            showcontent: true,
                            ChoosedThermoStat: null,
                            thermostatName: "",
                            autoStatusSetting: "",
                            dataChange: false,
                            displayData: {
                                temp: 0,
                                power: 'AUTO',
                                fan: 'AUTO',
                                swing: 'AUTO',

                            },
                            buttontext: 'Add',

                            scheduleHistoryList: [],
                            ScheduleList: [],
                        })
                        this.getThermostat()

                        this.setState({
                            showcontent: true
                        })
                    }
                }
            }
        }

    }
    componentDidMount() {
        // Fetch the list of schedules from the API and update the state
        this.fetchSchedules();
        this.getthermodropdowndata();
    }
    toggle(option) {
        var changeToggle = false;
        if (this.state.activetab != option) {
            if (this.state.activetab == '1' && (this.state.isAddingSchedule || this.state.isEditingSchedule)) {
                swal({
                    title: "There are some unsaved changes in the schedule!",
                    text: "Once the tab is switched, you will not be able to recover the entered schedule",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            if (true) {
                                this.setState({
                                    activetab: option
                                })
                                if (option == "1") {
                                    this.fetchSchedules();
                                    this.scheduleCancel();
                                }
                                if (option == "2") {
                                    this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                                    this.fetchAssignment();
                                    this.getThermoList();

                                }
                                if (option == "3") {
                                    this.getthermodropdowndata();
                                    this.boardData();
                                }
                            }
                        } else {
                            changeToggle = false;
                        }
                    });
            } else {
                changeToggle = true;
            }
        } else {
            if (this.state.activetab == '3') {
                changeToggle = true;
            }
            else {
                changeToggle = false;
                return false;
            }

        }
        if (changeToggle) {
            this.setState({
                activetab: option
            })
            if (option == "1") {
                this.fetchSchedules();
                this.scheduleCancel();
            }
            if (option == "2") {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.fetchAssignment();
                this.getThermoList();

            }
            if (option == "3") {
                this.getthermodropdowndata();
                this.boardData();
            }
        }
    }
    scheduleCancel() {
        // this.setState({ isAddingSchedule: false, isEditingSchedule: false })
        this.setState({
            newEvent: {
                time: '',
                equipment: '',
                mode: '',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            isAddingSchedule: false,
            isEditingSchedule: false
        });
    }
    fetchAssignment = () => {
        this.setState({ loader: true })
        apiService.getAssignmentList(this.selectedcustomer).then(response => {
            this.allAssignList = response.data;
            this.setState({ loader: false })
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        });
    };
    fetchSchedules = () => {
        this.setState({ loader: true })
        apiService.getScheduleList(this.selectedcustomer).then(response => {
            this.allScheduleList = response.data;
            this.setState({ schedules: response.data, loader: false })
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newSchedule: { ...this.state.newSchedule, [name]: value } });
    };

    handleEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newEvent: { ...this.state.newEvent, [name]: value } });
    };
    handleAssignEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newAssignment: { ...this.state.newAssignment, [name]: value } });
    };
    datehandleChange1(e) {
        const { newEvent, newAssignment } = this.state;

        this.setState({
            from_date: this.dateconvertion(e[0]),
            to_date: this.dateconvertion(e[1])
        });
    }

    dateconvertion(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        return x
    }
    addEvent = () => {
        const { newEvent, newSchedule } = this.state;
        if (this.state.newEvent.equipment == '' || this.state.newEvent.time == '' || this.state.newEvent.operation == '') {
            apiService.Alerts_error('Enter Event Details');
            return false;
        }
        if (this.state.newEvent.operation != 1 || this.state.newEvent.equipment != 1) {
            newEvent.fan_speed = undefined;
            newEvent.temperature = undefined;
            newEvent.swing_status = undefined;
        } else {
            if (this.state.newEvent.fan_speed == '' || this.state.newEvent.temperature == '' || this.state.newEvent.swing_status == '') {
                apiService.Alerts_error('Enter Event Details');
                return false;
            }
        }

        this.setState({
            newSchedule: {
                ...newSchedule,
                events: [...newSchedule.events, { ...newEvent }],
            },
            newEvent: {
                time: '',
                equipment: '',
                mode: '',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            },
        });
    };
    BindScheduleName(id) {
        if (this.allScheduleList.filter(s => s.id == id).length > 0)
            return this.allScheduleList.filter(s => s.id == id)[0]['schedule_name']
        else {
            return "";
        }
    }
    BindEquipmentValue(id) {
        return this.state.dropDownList.equipment.filter(s => s.id == id)[0]['value']
    }
    BindModeValue(id) {
        return this.state.dropDownList.operation.filter(s => s.id == id)[0]['value']
    }
    BindSpeedValue(id) {
        return this.state.dropDownList.speed.filter(s => s.id == id)[0]['value']
    }
    BindSwingValue(id) {
        return this.state.dropDownList.swing.filter(s => s.id == id)[0]['value']
    }
    BindTemperatureValue(id) {
        return this.state.dropDownList.temperature.filter(s => s.id == id)[0]['value']
    }
    getallcustomerdata() {
        this.setState({ loader: true })
        apiService.topCustomerDropdown().then(response => {
            var x = response.data;
            this.setState({ AllCustomerList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    convertToIndianTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' || timeZone == '') {
            return inputTime;
        } else {
            const newYorkTime = DateTime.fromFormat(inputTime, 'HH:mm', { zone: timeZone });
            const istTime = newYorkTime.setZone('Asia/Kolkata');
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    addSchedule = () => {
        if (this.state.newSchedule.schedule_name == '' || this.state.newSchedule.events.length == 0) {
            apiService.Alerts_error("Enter Schedule Name and Events");
            return false;
        }
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        param.schedule[0].events = updatedEvents
        this.setState({ loader: true })
        apiService.addScheduleCreation(param).then(response => {
            apiService.Alerts("Schedule Created Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isAddingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    convertToZoneTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' && timeZone == '') {
            return inputTime;
        } else {
            const [hoursS, minutesS] = inputTime.split(':');
            let Nhours = hoursS;
            let Nminutes = minutesS;
            if (hoursS.length == 1) {
                Nhours = hoursS.padStart(2, '0');
            }
            if (minutesS.length == 1) {
                Nminutes = minutesS.padStart(2, '0');
            }
            const newTime = `${Nhours}:${Nminutes}`;
            const newYorkTime = DateTime.fromFormat(newTime, 'HH:mm', { zone: 'Asia/Kolkata' });
            const istTime = newYorkTime.setZone(timeZone);
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    addAssignment = () => {
        if (this.state.newAssignment.thermo_id == '' || this.state.newAssignment.hvac_schedule_id == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        if (this.state.from_date == '' || this.state.to_date == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        const { newAssignment, assignments } = this.state;
        this.setState({ loader: true });
        var param = this.state.newAssignment;
        param.thermo_desc = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_desc'];
        param.thermo_name = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_name'];
        apiService.addAssignment(param, this.state.from_date, this.state.to_date).then(response => {
            apiService.Alerts("Assign Created Successfully")
            this.setState({ loader: false })
            this.fetchAssignment();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    editSchedule = (schedule) => {
        var newSchedules = {};
        newSchedules.schedule_name = schedule.schedule_name;
        newSchedules.events = schedule.events;
        this.setState({
            selectedSchedule: schedule,
            isEditingSchedule: true,
        });
        this.setState({
            newSchedule: newSchedules,
        });
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = newSchedules.events.map(event => {
            return {
                ...event,
                time: this.convertToZoneTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        // const { newEvent, newSchedule } = this.state;
        // this.setState({ newSchedule: { ...this.state.newSchedule, ['schedule_name']: schedule.schedule_name } });
        this.setState({
            newEvent: {
                time: '',
                equipment: '',
                mode: '',
                operation: '',
                fan_speed: '',
                swing_status: '',
                temperature: '',
            },
        });
    };
    updateSchedule = () => {
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        this.setState({ loader: true })
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        param.schedule[0].events = updatedEvents
        apiService.updateScheduleCreation(param, this.state.selectedSchedule.id).then(response => {
            apiService.Alerts("Schedule Updated Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isEditingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    scheduleDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Assignment!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteScheduleCreation(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Schedule Deleted Successfully")
                        this.fetchSchedules();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    assignmentDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteAssignSchedule(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Assignment Deleted Successfully")
                        this.fetchAssignment();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    TupdateSchedule = () => {
        const { selectedSchedule } = this.state;
        axios
            .put(
                `http://127.0.0.1:8000/api/hvac/schedule/new_update/${selectedSchedule.id}/`,
                { schedule: [selectedSchedule] }
            )
            .then(() => {
                this.setState({
                    selectedSchedule: null,
                    isEditingSchedule: false,
                });
            })
            .catch((error) => {
                console.error('Error updating schedule:', error);
            });
    };

    deleteEvent = (index) => {
        const { newSchedule } = this.state;
        const updatedEvents = [...newSchedule.events];
        updatedEvents.splice(index, 1);
        this.setState({
            newSchedule: {
                ...newSchedule,
                events: updatedEvents,
            },
        });
    };


    //-----------------------------REMOTE CONTROLS START-----------------------------------------------------------------
    hvaccancel() {

        // this.scheduleForm.reset()
        this.setState({
            buttontext: 'Add',
            dateselected: this.state.today
        })

    }

    selectThermoStat(data) {
        var x = {
            temp: data.set_temp,
            power: data.operation,
            fan: "AUTO",
            swing: "AUTO",

        }


        if (data.operation == "ON") {
            x = {
                temp: data.schedule_temp,
                power: data.operation,
                fan: data.fan_speed,
                swing: data.swing_status,

            }
        } else if (data.operation == "OFF") {
            x = {
                temp: data.set_temp,
                power: data.operation,
                fan: "--",
                swing: "--",

            }
        }

        if (data.fan_speed == "") {
            this.setState({
                remoteDataFan: 'LOW'
            })
        }
        else {
            this.setState({
                remoteDataFan: data.fan_speed
            })
        }

        if (data.swing_status == "") {
            this.setState({
                remoteDataSwing: 'OFF'
            })
        }
        else {
            this.setState({
                remoteDataSwing: data.swing_status
            })
        }

        if (data.operation == 'ON') {
            this.setState({
                remoteDataPower: data.operation,
                remoteDataPowerid: 1,
                remoteDataTemp: data.schedule_temp,
            })
        }
        else {
            this.setState({
                remoteDataPower: data.operation,
                remoteDataPowerid: 2,
                remoteDataTemp: data.set_temp,
            })
        }
        this.setState({
            ChoosedThermoStat: data,
            thermostatName: data.thermo_desc,
            autoStatusSetting: data.status,
            displayData: x,
            remoteThermo_id: data.id,
            remoteThermo_name: data.thermo_name
        }
        )

        sessionStorage.setItem("selectedThermoStatID", data.id);

    }

    getThermostat() {

        this.setState({ loader: true })
        apiService.getThermostat().then(response => {
            this.setState({ thermostatList: response.data });
            this.allthermostatList = response.data;
            this.setState({ loader: false })
            var obj = JSON.parse(sessionStorage.getItem('selectedThermoStatID'))
            if (sessionStorage.getItem('selectedThermoStatID').length == 0) {
                this.setState({
                    thermostatList: response.data
                })
            }
            else {
                var item = this.state.thermostatList.find(item => item.id === obj);
                this.selectThermoStat(item)
            }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    togglemodal() {
        this.setState({
            modal: !this.state.modal
        });
        // this.getThermostat()
    }

    closeRemote() {
        this.getThermostat()
    }

    remoteApicall(name, value) {
        if (name == '') {
            this.setState({ loader: true })
            apiService.hvacRemoteApi(this.state.remoteThermo_id, this.state.remoteThermo_name, this.state.remoteDataPowerid, this.state.remoteDataFanid, this.state.remoteDataSwingid, this.state.remoteDataTemp).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,

                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

        else {
            this.setState({ loader: true })
            apiService.hvacRemoteApiSingle(this.state.remoteThermo_id, this.state.remoteThermo_name, name, value).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,

                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    getRemote() {
        if (this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO') {
            this.setState({
                remoteDataPower: 'ON',
                remoteDataPowerid: 1
            }, () => {
                this.remoteApicall("", "")
            })
        }
        else {
            this.setState({
                remoteDataPower: 'OFF',
                remoteDataPowerid: 2
            }, () => {
                this.remoteApicall('operation', this.state.remoteDataPowerid)
            })
        }
    }

    fanControl() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataFan == 'LOW') {
                this.setState({
                    remoteDataFan: 'HIGH',
                    remoteDataFanid: 4
                }, () => {
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
            else {
                this.setState({
                    remoteDataFan: 'LOW',
                    remoteDataFanid: 3
                }, () => {
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
        }
    }

    swingControl() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataSwing == 'OFF') {
                this.setState({
                    remoteDataSwing: 'ON',
                    remoteDataSwingid: 5
                }, () => {
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
            else {
                this.setState({
                    remoteDataSwing: 'OFF',
                    remoteDataSwingid: 6
                }, () => {
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
        }
    }

    increaseTemp() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataTemp == 32) {
                this.setState({
                    remoteDataTemp: 32
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
            else {
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp + 1
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

    decreaseTemp() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataTemp == 16) {
                this.setState({
                    remoteDataTemp: 16
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
            else {
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp - 1
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }
    handleToggle = () => {
        this.setState((prevState) => ({
            isToggled: !prevState.isToggled,
        }));
    };
    //-----------------------------REMOTE CONTROLS END-----------------------------------------------------------------


    render() {

        const currentDate = new Date();
        const { isToggled } = this.state;
        const temperatureValue = 24;
        function firstcolor(data) {
            var x = '#1092C1'
            if (data == 'ON') {
                x = 'green'
            } else if (data == 'OFF') {
                x = 'red'
            }
            return x
        }
        const {
            schedules,
            isAddingSchedule,
            isEditingSchedule,
            selectedSchedule,
            newSchedule,
            newEvent,
        } = this.state;

        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5" className="dashboardcardtitle" style={{ marginTop: 0 }}>Iglupod Control Centre</CardTitle>
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('3'); }}
                                                        >
                                                            Management
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Schedule Creation
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Schedule Assignment
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '4' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('4'); }}
                                                        >
                                                            Monitor
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">

                                                    {/* {!isAddingSchedule && !isEditingSchedule && (
                                                        <button onClick={() => this.setState({ isAddingSchedule: true })}>Add New Schedule</button>
                                                    )} */}
                                                    <div>
                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    {this.state.thermostatName} {isAddingSchedule ? 'Create Schedule' : isEditingSchedule ? 'Edit Schedule' : 'Schedule List'}
                                                                    {!isAddingSchedule && !isEditingSchedule && (
                                                                        <span><Button
                                                                            onClick={() => this.setState({ isAddingSchedule: true })}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="flexblockbutton"
                                                                        >
                                                                            New Schedule
                                                                        </Button></span>
                                                                    )}

                                                                </CardTitle>

                                                            </CardHeader>
                                                            <CardBody style={{ textAlign: "center" }}>

                                                                {/* List */}
                                                                {!isAddingSchedule && !isEditingSchedule && (
                                                                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: '100px' }}>Actions</th>
                                                                                    <th style={{ width: '100px' }}>Schedule Name</th>
                                                                                    <th style={{ width: '200px' }}>Created At</th>
                                                                                    <th style={{ width: '200px' }}>Updated At</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.allScheduleList.map((value, index) => (
                                                                                    <tr key={index + 1}>
                                                                                        {/* <td>{index+1}</td> */}
                                                                                        <td>
                                                                                            <a href="javascript:void(0)" onClick={(e) => this.editSchedule(value)}><i className="fa fa-edit icons"></i></a>
                                                                                            <a href="javascript:void(0)" onClick={(e) => this.scheduleDelete(value.id)}><i className="fa fa-trash icons"></i></a>

                                                                                        </td>
                                                                                        <td>{value.schedule_name}</td>
                                                                                        <td>{Moment(value.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                        <td>{Moment(value.last_updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                    </tr>

                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )}

                                                                {/* Edit/Add */}
                                                                {(isAddingSchedule || isEditingSchedule) && (
                                                                    <div>
                                                                        <Row>
                                                                            <Col className="pr-1" md="3">
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField required fullWidth variant="outlined" type="text"
                                                                                                name="schedule_name"
                                                                                                label="Schedule Name"
                                                                                                value={newSchedule.schedule_name}
                                                                                                onChange={this.handleInputChange} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {/* Event input fields */}
                                                                        <div>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Add Event
                                                                                    <p>
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            // disabled={invalid}
                                                                                            onClick={this.addEvent}
                                                                                        >
                                                                                            Add
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => this.cancel()}
                                                                                            variant="contained"
                                                                                            color="danger"
                                                                                        >
                                                                                            Reset
                                                                                        </Button>
                                                                                    </p>
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <Row>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField fullWidth
                                                                                                    select
                                                                                                    label="Select Equipment"
                                                                                                    variant="outlined"
                                                                                                    name="equipment"
                                                                                                    value={this.state.newEvent.equipment}
                                                                                                    onChange={this.handleEventInputChange}
                                                                                                >
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                        Select Equipment
                                                                                                    </MenuItem>
                                                                                                    {this.state.dropDownList.equipment.map(option => (
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                            {option.value}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </TextField>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="time" required fullWidth variant="outlined" name="time" onChange={this.handleEventInputChange} label="Time " value={this.state.newEvent.time} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField fullWidth
                                                                                                    select
                                                                                                    label="Select Operation"
                                                                                                    variant="outlined"
                                                                                                    name="operation"
                                                                                                    value={this.state.newEvent.operation}
                                                                                                    onChange={this.handleEventInputChange}
                                                                                                >
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                        Select Operation
                                                                                                    </MenuItem>
                                                                                                    {this.state.dropDownList.operation.map(option => (
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                            {option.value}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </TextField>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </div>

                                                                                </Col>
                                                                                {(this.state.newEvent.operation == 1 && this.state.newEvent.equipment == 1) && <>
                                                                                    <Col className="pr-1" md="3">
                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField fullWidth
                                                                                                        select
                                                                                                        label="Select Speed"
                                                                                                        variant="outlined"
                                                                                                        name="fan_speed"
                                                                                                        value={this.state.newEvent.fan_speed}
                                                                                                        onChange={this.handleEventInputChange}
                                                                                                    >
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                            Select Speed
                                                                                                        </MenuItem>
                                                                                                        {this.state.dropDownList.speed.map(option => (
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                {option.value}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </TextField>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="3">
                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField fullWidth
                                                                                                        select
                                                                                                        label="Select Swing"
                                                                                                        variant="outlined"
                                                                                                        name="swing_status"
                                                                                                        value={this.state.newEvent.swing_status}
                                                                                                        onChange={this.handleEventInputChange}
                                                                                                    >
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                            Select Swing
                                                                                                        </MenuItem>
                                                                                                        {this.state.dropDownList.swing.map(option => (
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                {option.value}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </TextField>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="3">
                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField fullWidth
                                                                                                        select
                                                                                                        label="Select Temperature"
                                                                                                        variant="outlined"
                                                                                                        name="temperature"
                                                                                                        value={this.state.newEvent.temperature}
                                                                                                        onChange={this.handleEventInputChange}
                                                                                                    >
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                            Select Temperature
                                                                                                        </MenuItem>
                                                                                                        {this.state.dropDownList.temperature.map(option => (
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                {option.value}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </TextField>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </div>
                                                                                    </Col>

                                                                                </>
                                                                                }

                                                                            </Row>
                                                                        </div>

                                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Event List
                                                                                    {(isAddingSchedule || isEditingSchedule) && (<p>
                                                                                        {isAddingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.addSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Save
                                                                                            </Button></span>
                                                                                        )}

                                                                                        {isEditingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.updateSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Update
                                                                                            </Button></span>
                                                                                        )}
                                                                                        {(isAddingSchedule || isEditingSchedule) && (
                                                                                            <span><Button
                                                                                                onClick={() => this.scheduleCancel()}
                                                                                                variant="contained"
                                                                                                color="danger"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Cancel
                                                                                            </Button></span>
                                                                                        )}
                                                                                    </p>)}
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                                        <th style={{ width: '100px' }}>Time</th>
                                                                                        <th style={{ width: '100px' }}>Equipment</th>
                                                                                        <th style={{ width: '100px' }}>Operation</th>
                                                                                        <th style={{ width: '100px' }}>Fan Speed</th>
                                                                                        <th style={{ width: '100px' }}>Swing Status</th>
                                                                                        <th style={{ width: '100px' }}>Temperature</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {newSchedule.events.map((event, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <a href="javascript:void(0)" onClick={() => this.deleteEvent(index)}><i className="fa fa-trash icons"></i></a>
                                                                                            </td>
                                                                                            <td>{event.time}</td>
                                                                                            <td>{this.BindEquipmentValue(event.equipment)}</td>
                                                                                            <td>{this.BindModeValue(event.operation)}</td>
                                                                                            {(event.fan_speed != undefined) && (<td>{this.BindSpeedValue(event.fan_speed)}</td>)}
                                                                                            {(event.swing_status != undefined) && (<td>{this.BindSwingValue(event.swing_status)}</td>)}
                                                                                            {(event.temperature != undefined) && (<td>{this.BindTemperatureValue(event.temperature)}</td>)}
                                                                                            {/* <td>{this.state.dropDownList.equipment.filter(s=>s.id==event.equipment)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.speed.filter(s=>s.id==event.fan_speed)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.swing_status.filter(s=>s.id==event.swing_status)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.temperature.filter(s=>s.id==event.temperature)[0]['value']}</td> */}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>
                                                                )}
                                                            </CardBody>

                                                        </Card>
                                                        {/* <h3>Schedules</h3>
                                                        <ul>
                                                            {schedules.map((schedule) => (
                                                                <li key={schedule.id}>
                                                                    {schedule.schedule_name}
                                                                    <button onClick={() => this.editSchedule(schedule)}>Edit</button>
                                                                </li>
                                                            ))}
                                                        </ul> */}
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div>

                                                        {/* Event input fields */}
                                                        <div>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Assign Schedule
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            {/* <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>   */}
                                                                                            <div className='control-pane'>
                                                                                                <div className='control-section'>
                                                                                                    <div className='datepicker-control-section'>
                                                                                                        <DateRangePickerComponent placeholder='Select a range' min={currentDate} onChange={(e) => this.datehandleChange1(e.target.value)} value={this.state.dateselected}>
                                                                                                        </DateRangePickerComponent>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Schedule"
                                                                                    variant="outlined"
                                                                                    name="hvac_schedule_id"
                                                                                    value={this.state.newAssignment.hvac_schedule_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Schedule
                                                                                    </MenuItem>
                                                                                    {this.allScheduleList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.schedule_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Thermostat"
                                                                                    variant="outlined"
                                                                                    name="thermo_id"
                                                                                    value={this.state.newAssignment.thermo_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Thermostat
                                                                                    </MenuItem>
                                                                                    {this.state.thermostatList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.thermo_desc}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="1.5">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            // disabled={invalid}
                                                                            onClick={this.addAssignment}
                                                                        >
                                                                            Assign
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    History
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                        <th style={{ width: '200px' }}>Thermostat</th>
                                                                        <th style={{ width: '200px' }}>Schedule Name</th>
                                                                        <th style={{ width: '200px' }}>Start Date - End Date</th>
                                                                        <th style={{ width: '200px' }}>Created At</th>
                                                                        {/* <th style={{ width: '200px' }}>Updated At</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.allAssignList.map((event, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => this.assignmentDelete(event.id)}><i className="danger fa fa-trash icons"></i></a>
                                                                            </td>
                                                                            <td>{event.thermo_desc}</td>
                                                                            <td>{this.BindScheduleName(event.hvac_schedule_id)}</td>
                                                                            <td>{Moment(event.from_date).format('MMM DD YYYY')} to {Moment(event.to_date).format('MMM DD YYYY')}</td>
                                                                            <td>{Moment(event.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                            {/* <td>{Moment(event.last_updated_at).format('MMM DD YYYY HH:mm:ss')}</td> */}

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <FieldGroup
                                                                                    control={this.thermostatForm}
                                                                                    strict={false}
                                                                                    render={({ get, invalid, value }) => (
                                                                                        <form>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="name"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Thermostat ID" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Thermostat ID is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="desc"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Description" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Description is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="board"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField fullWidth
                                                                                                                            select
                                                                                                                            label="Select Board *"
                                                                                                                            variant="outlined"
                                                                                                                            {...handler("text")}
                                                                                                                            //   value={this.state.selectedCountryValue}
                                                                                                                            error={touched && hasError("required")}
                                                                                                                        >
                                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                                Select Board
                                                                                                                            </MenuItem>
                                                                                                                            {this.state.BoardList.map(option => (
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                                    {option.itemName}
                                                                                                                                </MenuItem>
                                                                                                                            ))}
                                                                                                                        </TextField>
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Board is required`}
                                                                                                                    </span>
                                                                                                                </Grid>

                                                                                                            </div>
                                                                                                        )}
                                                                                                    />

                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="temperature"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField fullWidth
                                                                                                                            select
                                                                                                                            label="Select Temperature *"
                                                                                                                            variant="outlined"
                                                                                                                            {...handler("text")}
                                                                                                                            //   value={this.state.selectedCountryValue}
                                                                                                                            error={touched && hasError("required")}
                                                                                                                        >
                                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                                Select Temperature
                                                                                                                            </MenuItem>
                                                                                                                            {this.state.thermodropDownList.map(option => (
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                                    {option.value}
                                                                                                                                </MenuItem>
                                                                                                                            ))}
                                                                                                                        </TextField>
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Board is required`}
                                                                                                                    </span>
                                                                                                                </Grid>

                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>

                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            type="submit"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={invalid}
                                                                                                            onClick={(event) => this.handleSubmit(event, this.thermostatForm.getRawValue())}
                                                                                                        >
                                                                                                            {this.state.buttontext}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            onClick={() => this.thermocancel()}
                                                                                                            variant="contained"
                                                                                                            color="danger"
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    )}
                                                                                />
                                                                            </CardBody>

                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '100px' }}>Actions</th>
                                                                                                <th style={{ width: '100px' }}>ID</th>
                                                                                                <th style={{ width: '100px' }}>Description</th>
                                                                                                {/* <th style={{ width: '100px' }}>Board</th> */}
                                                                                                {/* <th style={{ width: '100px' }}>Temperature(C)</th> */}
                                                                                                <th style={{ width: '200px' }}>Created at</th>
                                                                                                <th style={{ width: '200px' }}>Updated at</th>

                                                                                            </tr>
                                                                                            {/* <tr>
                                            <th style={{width : '100px'}}>Actions</th>
                                            <th style={{width : '200px'}}>Board Name</th>
                                            <th style={{width : '200px'}}>IMEI</th>
                                            <th style={{width : '200px'}}>Expiry Date</th>
                                            <th style={{width : '100px'}}>Active</th>
                                            
                                        </tr> */}
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.allthermostatList.map((value, index) => (
                                                                                                <tr key={index + 1}>
                                                                                                    {/* <td>{index+1}</td> */}
                                                                                                    <td>
                                                                                                        <a href="javascript:void(0)" onClick={(event) => this.thermostatdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <a href="javascript:void(0)" onClick={(e) => this.thermostatedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                    </td>
                                                                                                    <td>{value.thermo_name}</td>
                                                                                                    <td>{value.thermo_desc}</td>
                                                                                                    {/* <td>{value.board_name}</td> */}
                                                                                                    {/* <td>{value.set_temp}°</td> */}

                                                                                                    <td>{Moment(value.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                    <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                    {/* <td>{value.board_name}</td>
                                            <td>{value.imei}</td>
                                            <td>{value.expiry_date}</td>
                                            <td>
                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                            </td> */}

                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="4">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    {/* Remote Modal */}
                                                                    <MDBContainer >
                                                                        <MDBModal isOpen={this.state.modal} side position="top-right" data-mdb-backdrop="static" >
                                                                            {/* <MDBModalHeader style={{ display: "unset" }}>
                                                                                <span className="ml-3">Remote - {this.state.thermostatName}</span>
                                                                                <button type="button" onClick={() => { this.togglemodal(); this.closeRemote(); }} className="close text-right mr-4 p-0" aria-label="Close">
                                                                                    <span style={{ fontSize: '50px', color: 'red' }} aria-hidden="true">×</span>
                                                                                </button>
                                                                            </MDBModalHeader> */}
                                                                            <div style={{ padding: '60px' }}>
                                                                                {/* <i class="fa fa-close" id="remotepower" aria-hidden="true" style={{color: 'rgb(230 49 74)',fontSize: '18px',position: 'fixed',backgroundColor: 'beige',borderRadius: '8px'}}></i> */}
                                                                                <div style={{float:'right',marginInline:'25px'}}>
                                                                                <div className="close-button" style={{ position: 'fixed', background: '#f5f5f6', borderRadius: '20px', fontSize: '18px' }} onClick={() => { this.togglemodal(); this.closeRemote(); }}>
                                                                                    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="15" cy="15" r="14" />
                                                                                        <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" fill="red">
                                                                                            X
                                                                                        </text>
                                                                                    </svg>
                                                                                </div>
                                                                                </div>
                                                                                {/* <MDBModalHeader style={{ display: "unset" }}>
                                                                                    <span className="ml-3">Remote - {this.state.thermostatName}</span>
                                                                                    <button type="button" onClick={() => { this.togglemodal(); this.closeRemote(); }} className="close text-right mr-4 p-0" aria-label="Close">
                                                                                        <span style={{ fontSize: '50px', color: 'red' }} aria-hidden="true">×</span>
                                                                                    </button>
                                                                                </MDBModalHeader> */}
                                                                                <Row className="m-2" style={{ boxShadow: '0 4px 6px rgb(0 0 0 / 51%)', borderRadius: '10px', border: "2px solid #0099ff42", backgroundColor: 'white' }}>
                                                                                    <Col sm="12 p-0" className="" style={{}}>
                                                                                        <Row className="m-2" style={{ padding: '7px' }}>
                                                                                            <Row>
                                                                                                <span className="ml-3" style={{ fontWeight: 500, fontSize: '20px' }}>Remote - {this.state.thermostatName}</span>
                                                                                                {/* {this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ?

                                                                                                    <Col className=" d-flex" style={{ marginLeft: '3rem' }}>
                                                                                                        <a href='javascript:void(0)' className="p-1 rounded" id="remotepower" onClick={this.getRemote}><i class="fa fa-power-off remote_temp_icon" id="remotepower" aria-hidden="true" style={{ color: "rgb(49 230 137)", fontSize: '25px' }}></i></a>
                                                                                                    </Col>
                                                                                                    :
                                                                                                    <Col className="d-flex" style={{ marginLeft: '3rem' }}>
                                                                                                        <a href='javascript:void(0)' className="p-1 rounded" id="remotepower" onClick={this.getRemote}><i class="fa fa-power-off remote_temp_icon" id="remotepower" aria-hidden="true" style={{ color: "#ff4d4d", fontSize: '25px' }} ></i></a>
                                                                                                    </Col>} */}
                                                                                            </Row>

                                                                                        </Row>
                                                                                        <Row className="m-2">
                                                                                            <div style={{ background: "white" }}>
                                                                                                <div style={{ display: 'flex' }}>
                                                                                                    {/* Tabs */}
                                                                                                    {/* <Paper className={styles.tabsContainer}> */}
                                                                                                    {/* <div
                                                                                                        >
                                                                                                            <div className='remote-tab-align'>Tab 1</div>
                                                                                                            <div className='remote-tab-align'>Tab 1</div>
                                                                                                            <div className='remote-tab-align'>Tab 1</div>
                                                                                                            <div className='remote-tab-align'>Tab 1</div>
                                                                                                        </div> */}
                                                                                                    {/* </Paper> */}
                                                                                                    <Col>
                                                                                                        <div>
                                                                                                            <span style={{ display: 'flex' }}> <p style={{ marginLeft: '11px',color: '#1b3b5f', fontSize: '35px',fontFamily:'GoldenWings' }}>{this.state.remoteDataTemp}</p><p style={{ color: '#1b3b5f', fontSize: '18px',fontFamily:'GoldenWings' }}>°C</p></span>
                                                                                                        </div>
                                                                                                        <table >
                                                                                                            <tr>
                                                                                                                <td style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                                                    <Col className="justify-content-center" style={{ textAlign: 'center' }}>
                                                                                                                        {/* <i className="fa fa-power-off" style={{ color: firstcolor(this.state.displayData.power) }} /><br /> */}
                                                                                                                        {this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ?
                                                                                                                            <div><img src={pwr_off} height={35} width={35} onClick={this.getRemote} />
                                                                                                                                <br></br>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div><img src={pwr_on} height={35} width={35} onClick={this.getRemote} />
                                                                                                                                <br></br>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <span
                                                                                                                            className="batch" style={{fontFamily:'GoldenWings',fontSize:'11px'}}>Power</span><br />
                                                                                                                        <p style={{fontFamily:'GoldenWings'}}>
                                                                                                                            {this.state.displayData.power}
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <br></br>
                                                                                                            <tr>
                                                                                                                <td style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                                                    <Col className="justify-content-center" style={{ textAlign: 'center' }}>
                                                                                                                        {/* <i className="fas fa-fan" style={{ color: firstcolor(this.state.displayData.fan) }} /><br /> */}
                                                                                                                        <img src={fan_speed} height={35} width={35} onClick={this.fanControl} />
                                                                                                                        <br></br>
                                                                                                                        <span
                                                                                                                            className="batch" style={{fontFamily:'GoldenWings',fontSize:'11px'}}>Fan</span>
                                                                                                                        <p style={{fontFamily:'GoldenWings'}}>{this.state.displayData.fan}</p>
                                                                                                                    </Col>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <br></br>
                                                                                                            <tr>
                                                                                                                <td style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                                                                    <Col className="justify-content-center" style={{ textAlign: 'center' }}>
                                                                                                                        {/* <i class="fas fa-wind" style={{ color: firstcolor(this.state.displayData.swing) }} /><br /> */}
                                                                                                                        <img src={swingicon} height={35} width={35} onClick={this.swingControl} />
                                                                                                                        <br></br>
                                                                                                                        <span
                                                                                                                            className="batch" style={{fontFamily:'GoldenWings',fontSize:'11px'}}>Swing</span>
                                                                                                                        <p style={{fontFamily:'GoldenWings'}}>{this.state.displayData.swing}
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </Col>
                                                                                                    {/* Vertical Progress Bar */}
                                                                                                    {/* <Paper> */}
                                                                                                    <Col style={{ display: 'flex !important' }}>
                                                                                                        <div className='d-flex' style={{ display: 'flex !important',marginTop:'28px',marginLeft:'6px' }}>
                                                                                                            {/* <VerticalLinearProgress variant="determinate" value={value} /> */}
                                                                                                            <WaveProgressBar variant="determinate" progress={this.state.remoteDataTemp}></WaveProgressBar>
                                                                                                            {/* </Paper> */}
                                                                                                            <div style={{ height: '370px' }}>
                                                                                                                <Slider
                                                                                                                    orientation="vertical"
                                                                                                                    aria-labelledby="vertical-slider"
                                                                                                                    getAriaValueText={this.valuetext}
                                                                                                                    marks={this.marks}
                                                                                                                    step={0}
                                                                                                                    onChange={this.HandleProgresBar}
                                                                                                                    valueLabelDisplay="auto"
                                                                                                                    value={this.state.remoteDataTemp}
                                                                                                                    min={16}
                                                                                                                    max={30}>
                                                                                                                </Slider>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            {/* <MDBModalFooter>
                                                                                <MDBBtn color="secondary" onClick={() => { this.togglemodal(); this.closeRemote(); }}>Close</MDBBtn>
                                                                            </MDBModalFooter> */}
                                                                        </MDBModal>
                                                                    </MDBContainer>

                                                                    <Col md="12">

                                                                        <Row>

                                                                            <Col md="6">
                                                                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <CardHeader>
                                                                                        <CardTitle tag="h5">
                                                                                            <Nav tabs>
                                                                                                <NavItem>
                                                                                                    <NavLink
                                                                                                        className={classnames({ active: '1' === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                                                    >
                                                                                                        List
                                                                                                    </NavLink>
                                                                                                </NavItem>
                                                                                            </Nav>
                                                                                        </CardTitle>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <TabContent activeTab='1'>
                                                                                            <TabPane tabId="1">
                                                                                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                    <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                        <tbody>
                                                                                                            {this.state.thermostatList.map((value, index) => (
                                                                                                                <tr key={index + 1} onClick={() => this.selectThermoStat(value)}>
                                                                                                                    <th style={{ width: '150px' }}>
                                                                                                                        {this.state.ChoosedThermoStat == value && <i class="fa fa-check icons selectedthermo" aria-hidden="true"></i>}
                                                                                                                        {value.thermo_desc}</th>
                                                                                                                    <td style={{ width: '150px' }}>{value.operation == 'AUTO' && <span>{value.set_temp + '°'}</span>}
                                                                                                                        {value.operation == 'OFF' && <span>--</span>}
                                                                                                                        {value.operation == 'ON' && <span>{value.schedule_temp + '°'}</span>}</td>

                                                                                                                </tr>

                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                        {/* <tbody> */}

                                                                                                    </table>
                                                                                                </div>

                                                                                            </TabPane>
                                                                                        </TabContent>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <CardHeader>
                                                                                        <CardTitle tag="h5" className="dashboardcardtitle">
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="6">
                                                                                                    {this.state.thermostatName}
                                                                                                </Col>
                                                                                                <Col className="pr-1 d-flex justify-content-center" lg="3">

                                                                                                </Col>
                                                                                                <Col className="pr-1 d-flex justify-content-center" lg="3">
                                                                                                    <button style={{ cursor: 'pointer', display: 'grid', padding: '5px', border: 'none', backgroundColor: "Transparent" }} onClick={this.togglemodal} data-backdrop="static" data-keyboard="false" disabled={this.state.ChoosedThermoStat == null}><img src={remote} height={50} width={50} style={{ display: 'block', margin: '0 auto' }} />
                                                                                                        <span className={"card-user pl-3 pr-3 " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{ fontSize: '18px' }}>Remote</span>
                                                                                                    </button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </CardTitle>
                                                                                    </CardHeader>
                                                                                    <CardBody style={{ textAlign: "center" }}>
                                                                                        <div className={"dialog"}>
                                                                                            <img src={dial_image} style={{ position: "center", objectFit: "cover" }} className={"img-rounded"} alt="Cinque Terre" width="100%" />
                                                                                            <div>
                                                                                                <label>Set to</label><br />
                                                                                                {this.state.displayData.power != 'OFF' && <span>{this.state.displayData.temp}°</span>}

                                                                                            </div>
                                                                                        </div>
                                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor) + ' ' + "thermocard"} >
                                                                                            <CardHeader>
                                                                                                <table >
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {/* <i style="font-size: 28px !important;padding-top: 2px;	color: green;"
																			[ngStyle]="displayData.power=='ON' ? { color:'green' } : displayData.power=='OFF' ? {color: 'red'} : displayData.power=='AUTO' ? {color: '#1092C1'} : {color: ''}"
                                                                            className='fas fa-power-off'></i><br> */}
                                                                                                            <i className="fa fa-power-off" style={{ color: firstcolor(this.state.displayData.power) }} /><br />
                                                                                                            <span
                                                                                                                className="batch">POWER</span><br />
                                                                                                            <h5
                                                                                                            >
                                                                                                                {this.state.displayData.power}
                                                                                                            </h5>
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            {/* <img src="../../assets/images/IRimage/fan.new.png"
																			width="30px" height="30px"><br> */}
                                                                                                            <i className="fas fa-fan" style={{ color: firstcolor(this.state.displayData.fan) }} /><br />
                                                                                                            <span
                                                                                                                className="batch">FAN</span>
                                                                                                            <h5 >{this.state.displayData.fan}</h5>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {/* <img src="../../assets/images/IRimage/newAC.png"
																			width="30px" height="30px"><br> */}
                                                                                                            <i class="fas fa-wind" style={{ color: firstcolor(this.state.displayData.swing) }} /><br />
                                                                                                            <span
                                                                                                                className="batch">SWING</span>
                                                                                                            <h5 >{this.state.displayData.swing}
                                                                                                            </h5>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </table>
                                                                                            </CardHeader>
                                                                                        </Card>

                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>

                        </Row>
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}
export default connect(mapStateToProps)(ScheduleComponent);

