import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

class TopNavDropdown extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            customerlist: [],
            selectedcustomer: '',
            boardlist: [],
            selectedboard: '',
        }
        this.is_admin = false;
        this.classname = ''
    }

    TopNavForm = FormBuilder.group({
        customer: [""],
        board: [""],
    });

    componentDidMount() {
        if (localStorage.getItem('is_admin') == 'true') {
            this.is_admin = true;
            if (localStorage.getItem('selectedcustomer')) {
                this.getcustomers(localStorage.getItem('selectedcustomer'));
            } else {
                this.getcustomers('');
            }
            this.TopNavForm.get('customer').enable();
        } else {
            this.is_admin = false;
            this.getcustomers(localStorage.getItem('user_id'))
            this.TopNavForm.get('customer').disable();
        }
    }

    TopNavForm = FormBuilder.group({
        customer: [""],
        board: [""],
    });

    getcustomers(localcustomer) {
        this.setState({ loader: true })
        apiService.topCustomerDropdown().then(response => {
          if (response.data.length == 0) {
            this.setState({ loader: false, customerlist: [], selectedcustomer: '' })
            localStorage.setItem('selectedcustomer', '')
            this.TopNavForm.patchValue({
                customer: ''
            })
          } else {
              var  custom=response.data[0]['id']
              if (localcustomer == '' || localcustomer == undefined || localcustomer == null) {
                this.setState({ loader: false, customerlist: this.state.customerlist.concat(response.data), selectedcustomer: response.data[0]['id'] },()=>{
                  localStorage.setItem('selectedcustomer', response.data[0]['id'])
                  this.getboards(this.state.selectedcustomer)
                  this.TopNavForm.patchValue({
                    customer: this.state.selectedcustomer
                })
                })
                
              } else {
                this.setState({ loader: false, customerlist: this.state.customerlist.concat(response.data), selectedcustomer: localcustomer },()=>{
                  localStorage.setItem('selectedcustomer', localcustomer)
                  custom=localcustomer
                  this.getboards(this.state.selectedcustomer)
                  this.TopNavForm.patchValue({
                  customer: this.state.selectedcustomer
                })
                })
                
              }
              this.currencystorage(custom)

          }
        }).catch(error => {
            this.setState({ loader: false })      
            apiService.ErrorMessage(error)
        })
      }
    
      getboards(customerid) {
        if (customerid == '' || customerid == undefined || customerid == null) {
          apiService.Alerts_info("Choose some Customer with Board")
        } else {
          this.setState({ loader: true })
          apiService.topBoardDropdown(customerid).then(response => {
            if (response.data.length == 0) {
                this.setState({ loader: false, boardlist: [], selectedboard: '' })
                localStorage.setItem('selectedboard', '')
                this.TopNavForm.patchValue({
                    board: ''
                })
            } else {
                if (localStorage.getItem('selectedboard') == '' || localStorage.getItem('selectedboard') == undefined || localStorage.getItem('selectedboard') == null) {
                    var y=localStorage.getItem("selectedcustomer")
                      var filter =this.state.customerlist.filter((obj)=>obj.id == y)
                      var t= response.data[0]['id']
                      if(filter.length!=0){
                        var z=filter[0]["primary_board"]
                        var filter1=response.data.filter((obj)=>obj.id ==z)
                        if(filter1.length !=0){
                            t=filter[0]["primary_board"]

                        }
                      }
                    this.setState({ loader: false, boardlist: response.data, selectedboard:t })
                    localStorage.setItem('selectedboard', t)
                    this.TopNavForm.patchValue({
                        board: t
                    })      
                    this.props.boardhandler(localStorage.getItem('selectedboard'))
                } else {
                    this.setState({ loader: false, boardlist: response.data, selectedboard: localStorage.getItem('selectedboard') })
                    localStorage.setItem('selectedboard', localStorage.getItem('selectedboard'))
                    this.TopNavForm.patchValue({
                        board: localStorage.getItem('selectedboard')
                    })
                    this.props.boardhandler(localStorage.getItem('selectedboard'))       
                }
                this.props.changeTimestamp()
            }
          }).catch(error => {
              this.setState({ loader: false })      
              apiService.ErrorMessage(error)
          })
        }
      }
     currencystorage(customerid){
         var p=[{name:"OMR",value:"OMR"},
         {name:"USD",value:"USD"},
         {name:"Indian Rupee",value:"INR"},
         {name:"UAE Dirham",value:"Dhiram"},
         {name:"US Dollar",value:"USD"},
         {name:"Rial Omani",value:"Rials"}]
        //  console.log("comes")
        var filter= this.state.customerlist.filter((obj)=>obj.id==customerid)
        var x="INR"
        var y="INR"
        var meter="1"
        if(filter.length!=0){
            var z=filter[0].currency
          if(z != null && z!= undefined && z!="N/A" ){
              y=filter[0].currency
              x=filter[0].currency

          }
          var fill=p.filter((row)=>row.name==y)
          if(fill.length!=0){
       x=fill[0].value
          }
          meter=filter[0].meter_type
        }

        localStorage.setItem('currency',x);
        localStorage.setItem('meter',meter);

     }
      customerhandleChange(customerid) {
        if (customerid == '' || customerid == undefined || customerid == null) {
          this.setState({ selectedcustomer: ''})
          localStorage.setItem('selectedcustomer', '')
          localStorage.setItem('selectedboard', '')
        } else { 
          this.setState({ selectedcustomer: customerid})
         this.currencystorage(customerid)

          localStorage.setItem('selectedcustomer', customerid)
          localStorage.setItem('selectedboard', '')
         
          this.getboards(customerid)
        }
        this.props.changeTimestamp()
      }
    
      boardhandleChange(boardid) {
        if (boardid == '' || boardid == undefined || boardid == null) {
          this.setState({ selectedboard: ''})
          localStorage.setItem('selectedboard', '')
        } else { 
          this.setState({ selectedboard: boardid})
          localStorage.setItem('selectedboard', boardid)
        }
      }

      componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {

        }
      }


    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
           
              <Row>
              {this.props.dataFromParent}
              <Col md="12">
              <Card className={"card " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                <FieldGroup
                    control={this.TopNavForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="6">
                                <FieldControl
                                    name="customer"
                                    strict= {false}
                                    render={({ handler, touched, hasError}) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField  fullWidth                     
                                                        select
                                                        label="Select Customer"
                                                        variant="outlined"
                                                        value={this.props.selectedcustomer}
                                                        {...handler("text")}
                                                    >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => {this.props.customerhandler('');this.customerhandleChange('')}}>
                                                            Select Customer
                                                        </MenuItem>
                                                        {this.state.customerlist.map(option => {
                                                            return  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => {this.props.customerhandler(option.id);;this.customerhandleChange(option.id)}}>
                                                                        {option.itemName}
                                                                    </MenuItem>
                                                        })}
                                                </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                />
                            </Col>
                            {this.props.showProcessDropdown==true ? <Col className="pr-1 TopNavProcess1" md="6">
                                <FieldControl
                                    name="board"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField  fullWidth                     
                                                        select
                                                        label="Select Process"
                                                        variant="outlined"
                                                        value={this.props.selectedboard}
                                                        {...handler("text")}
                                                    >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => {this.props.boardhandler('');this.boardhandleChange('')}}>
                                                            Select Process
                                                        </MenuItem>
                                                        {this.state.boardlist.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => {this.props.boardhandler(option.id);this.boardhandleChange(option.id)}}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                />
                            </Col>:<Col className="pr-1" md="6">
                                <FieldControl
                                    name="board"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField  fullWidth                     
                                                        select
                                                        label="Select Process"
                                                        variant="outlined"
                                                        value={this.props.selectedboard}
                                                        {...handler("text")}
                                                    >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => {this.props.boardhandler('');this.boardhandleChange('')}}>
                                                            Select Process
                                                        </MenuItem>
                                                        {this.state.boardlist.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => {this.props.boardhandler(option.id);this.boardhandleChange(option.id)}}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                />
                            </Col>}

                        </Row>  
                    </form>
                    )} 
                />
                </CardBody>
              </Card>
            </Col>
              </Row>
            </div>
          </>
        );
    }
} 

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor 
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
      changeTimestamp: () => {dispatch({type: 'TimeStamp'})}
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(TopNavDropdown);