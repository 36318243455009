import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { connect } from 'react-redux';


//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables.js";
import jsZip from 'jszip';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import $ from 'jquery';
import "../assets/css/auth.css"



// This line was the one missing
window.JSZip = jsZip;

// const $ = require('jquery');
$.DataTable = require('datatables.net');

class Boards extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            editmode: false,
            buttontext: 'Add',
            boardslist: [],
        }
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('is_admin') == 'true') {
            this.props.history.push('/neurobotz/boards')
            this.getboards();
            this.convertdate(new Date())
        } else {
            this.props.history.push('/neurobotz/dashboard')
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    boardForm = FormBuilder.group({
        id: [""],
        board_name: ["", [Validators.required,Validators.pattern('[a-zA-Z0-9 ]*')]],
        imei: ["", [Validators.required,Validators.pattern('[a-zA-Z0-9 ]*')]],
        expiry_date: ["", [Validators.required]],
        active: true,
        created_by: localStorage.getItem('user_id'),
        updated_by: localStorage.getItem('user_id'),
    });

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.boardForm.patchValue({
            expiry_date:this.today
        })
    }

    cancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.boardForm.reset()
        this.boardForm.patchValue({
            id: '',
            board_name: '',
            imei: '',
            expiry_date: this.today,
            active: true,
            created_by: localStorage.getItem('user_id'),
            updated_by: localStorage.getItem('user_id'),
        })
    }

    getboards() {
        if ($.fn.dataTable.isDataTable('#boardtable')) {
            $('#boardtable').DataTable().clear().destroy();
        }  
        this.setState({ loader: true })
        apiService.getBoards().then(response => {
            this.setState({boardslist: this.state.boardslist.concat(response.data)}) 
            $(document).ready(function () {
                $('#boardtable').DataTable({
                  "bLengthChange": false,
                  "pageLength": 15,
                  "searching": true,
                  "bInfo": true,
                  "bSort": true,
                  "bPaginate": true,
                  "dom": 'Bfrtip',
                  "buttons": [{
                    'extend': "excel",
                    "title": "Board Details",
                    "exportOptions": {
                      "columns": ':not(:first-child)',
                    }
                  }]
                });
            });
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleSubmit(event,value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.AddBoards(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Board Added Successfully")          
                this.getboards();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.UpdateBoards(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Board Updated Successfully")       
                this.getboards();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
        
    }

    boardedit(value) {
        this.boardForm.patchValue({
            id: value.id,
            board_name: value.board_name,
            imei: value.imei,
            expiry_date: value.expiry_date,
            active: value.active,
            created_by: localStorage.getItem('user_id'),
            updated_by: localStorage.getItem('user_id'),
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    boarddelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Board!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.DeleteBoards(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Board Deleted Successfully")      
                    this.getboards();
                    this.cancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    can_active(e) {
        if (e == false) {
            this.boardForm.patchValue({
                active: true
            })
        } else {
            this.boardForm.patchValue({
                active: false
            })
        }
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
              <Row>
              <Col md="12">
              <Card className={"card " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5"></CardTitle>
                </CardHeader>
                <CardBody>
                <FieldGroup
                    control={this.boardForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="3">
                            <FieldControl
                                name="board_name"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className={"Formfieldcss"} container spacing={1}>                
                                            <Grid item>
                                            <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required") || hasError("pattern")} label="Board Name" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Board Name is required`}
                                                     {touched
                                                    && hasError("pattern")
                                                    && `* Board Name must be alphanumeric`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md="3">
                            <FieldControl
                                name="imei"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required") || hasError("pattern")} label="IMEI" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* IMEI is required`}
                                                     {touched
                                                    && hasError("pattern")
                                                    && `* IMEI must be alphanumeric`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md="3">
                            <FieldControl
                                name="expiry_date"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <TextField type="date" required fullWidth variant="outlined" label="Expiry Date" error={touched && hasError("required")}  {...handler("text")} />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Expiry Date is required`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1 text-center" md="3">
                            <FieldControl
                                    name="active"
                                    strict= {false}
                                    render={({ handler, touched }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.boardForm.getRawValue().active} value="active"  color="primary" onClick={(event) => this.can_active(this.boardForm.getRawValue().active)} />}
                                                label="Active"
                                            />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row>
                        <Col className="pr-1" md="3">
                        </Col>
                        <Col className="pr-1" md="3">
                            <div className="login-button changepassword text-center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={invalid}
                                    onClick={(event) => this.handleSubmit(event,this.boardForm.getRawValue())}
                                    >
                                    {this.state.buttontext}
                                </Button>
                            </div>
                            </Col>
                            <Col className="pr-1" md="3">
                            <div className="login-button changepassword text-center">
                            <Button
                                onClick={() => this.cancel()}
                                variant="contained"
                                color="danger"
                                >
                                Cancel
                            </Button>
                            </div>
                            </Col>
                            <Col className="pr-1" md="3">
                            </Col>
                        </Row>
                    </form>
                    )} 
                /> 
                </CardBody>
              </Card>
            </Col>
              </Row>
              <Row>
                <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                        <CardTitle tag="h5"></CardTitle>
                        </CardHeader>
                        <CardBody>
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <thead>
                                        <tr>
                                            {/* <th style={{width : '50px'}}>S.No</th> */}
                                            <th style={{width : '100px'}}>Actions</th>
                                            <th style={{width : '100px'}}>Board ID</th>
                                            <th style={{width : '200px'}}>Board Name</th>
                                            <th style={{width : '200px'}}>IMEI</th>
                                            <th style={{width : '200px'}}>Expiry Date</th>
                                            <th style={{width : '100px'}}>Active</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.boardslist.map((value, index) => (
                                        <tr key={index+1}>
                                            {/* <td>{index+1}</td> */}
                                            <td>
                                                <a href="javascript:void(0)" onClick={(event) => this.boarddelete(event,value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.boardedit(value)}><i className="fa fa-edit icons"></i></a>
                                            </td>
                                            <td>{value.id}</td>
                                            <td>{value.board_name}</td>
                                            <td>{value.imei}</td>
                                            <td>{value.expiry_date}</td>
                                            <td>
                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                            </td>
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor 
    }
  }

export default connect(mapStateToProps)(Boards);