/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Button } from "reactstrap";

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown"
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    if (this.state.classes === "dropdown") {
      this.setState({ classes: "dropdown show" });
    } else {
      this.setState({ classes: "dropdown" });
    }
  }
  render() {
    var x={
      top:40,
      // bottom:20 
    }
    return (
      <div className="fixed-plugin" style={x}>
        <div className={this.state.classes}>
          <div onClick={this.handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show">
            <li className="header-title">Select Theme</li>
            <li className="adjustments-line">
              <div className="badge-colors text-center">
                <span
                  className={
                    this.props.bgColor === "black"
                      ? "badge filter badge-light active"
                      : "badge filter badge-light"
                  }
                  data-color="black"
                  onClick={() => {
                    this.props.handleBgClick("black");
                  }}
                  title="Light"
                />
                <span
                  className={
                    this.props.bgColor === "blue"
                      ? "badge filter badge-info active"
                      : "badge filter badge-info"
                  }
                  data-color="blue"
                  onClick={() => {
                    this.props.handleBgClick("blue");
                  }}
                  title="NBZ v2"
                />
                <span
                  className={
                    this.props.bgColor === "grey"
                      ? "badge filter badge-grey active"
                      : "badge filter badge-grey"
                  }
                  data-color="grey"
                  onClick={() => {
                    this.props.handleBgClick("grey");
                  }}
                  title="Space Gray"
                />
                <span
                  className={
                    this.props.bgColor === "white"
                      ? "badge filter badge-dark active"
                      : "badge filter badge-dark"
                  }
                  data-color="white"
                  onClick={() => {
                    this.props.handleBgClick("white");
                  }}
                  title="Dark"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;
