import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,CardText
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import classnames from 'classnames';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import swal from 'sweetalert';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {comparison_baseline} from "../routes"
import {v3_server_check} from "../routes"

var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated); 
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Comparison extends React.Component {
    constructor(props) {
        super();
        let daysOfYear = [];
        let getyear = new Date();
		let s = getyear.getFullYear();
		let t = getyear.getFullYear() - 10;
		for (let d = t; d <= s; d++) {
			daysOfYear.push(d);
        }
        this.daysOfYear = daysOfYear
		this.currentyear = getyear.getFullYear().toString();
        this.state = {
            loader: false,
            TariffStructureFormValid:true,
            charttype: 'column',
            checkboxvalue:  'kWh',
            period: 'DAILY',
            prefix: '',
            suffix: '',
            fillAlphas: 1,
            graphtype: 'column',
            belowtable: [],
            chartdata: [],
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1,
            belowtableuom: 'kWh',
            activetab: '1',
            buttontext: 'Add',
            baseline_list: [],
            tariffstruct_list: [],
            tariff_list: [],
            tariffbuttontext: 'Add',   
            modal: false,     
            showcontent: false,     
            yearrange: [
                { 'id': 1, "itemName": this.daysOfYear[10] },
                { 'id': 2, "itemName": this.daysOfYear[9] },
                { 'id': 3, 'itemName': this.daysOfYear[8] },
                { 'id': 4, 'itemName': this.daysOfYear[7] },
                { 'id': 5, 'itemName': this.daysOfYear[6] },
                { 'id': 6, "itemName": this.daysOfYear[5] },
                { 'id': 7, 'itemName': this.daysOfYear[4] },
                { 'id': 8, "itemName": this.daysOfYear[3] },
                { 'id': 9, 'itemName': this.daysOfYear[2] },
                { 'id': 10, 'itemName': this.daysOfYear[1] },
                { 'id': 11, 'itemName': this.daysOfYear[0] }
            ],
            selectedyear: this.daysOfYear[10],     
            monthrange: [
                { 'id': 1, "itemName": 'Jan ' + this.currentyear },
                { 'id': 2, 'itemName': 'Feb ' + this.currentyear },
                { 'id': 3, 'itemName': "Mar " + this.currentyear },
                { 'id': 4, "itemName": 'Apr ' + this.currentyear },
                { 'id': 5, 'itemName': 'May ' + this.currentyear },
                { 'id': 6, 'itemName': 'Jun ' + this.currentyear },
                { 'id': 7, 'itemName': 'Jul ' + this.currentyear },
                { 'id': 8, "itemName": "Aug " + this.currentyear },
                { 'id': 9, "itemName": "Sep " + this.currentyear },
                { 'id': 10, "itemName": "Oct " + this.currentyear },
                { 'id': 11, "itemName": "Nov " + this.currentyear },
                { 'id': 12, 'itemName': 'Dec ' + this.currentyear }
            ],
            selectedmonth: ['Jan ' + this.currentyear],
            quarterrange: [
                { 'id': 1, 'itemName': 'Q1 ' + this.currentyear, "value": 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear },
                { 'id': 2, "itemName": "Q2 " + this.currentyear, "value": "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear },
                { 'id': 3, 'itemName': "Q3 " + this.currentyear, "value": "Jul " + this.currentyear + "," + 'Aug ' + this.currentyear + "," + 'Sep ' + this.currentyear },
                { 'id': 4, "itemName": 'Q4 ' + this.currentyear, 'value': "Oct " + this.currentyear + ',' + "Nov " + this.currentyear + ',' + 'Dec ' + this.currentyear },
            ],
            selectedquarter: ['Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear],
            halfyearrange: [
              { 'id': 1, 'itemName': 'H1 ' + this.currentyear, "value": 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear + "," + "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear },
              { 'id': 2, "itemName": "H2 " + this.currentyear, "value": "Jul " + this.currentyear + "," + 'Aug ' + this.currentyear + "," + 'Sep ' + this.currentyear + "," + "Oct " + this.currentyear + ',' + "Nov " + this.currentyear + ',' + 'Dec ' + this.currentyear },
          ],
          selectedhalfyear: ['Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear + "," + "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear],
        }
        

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = ['Jan ' + this.currentyear];
        this.graphtype = 'column'
        this.fillAlphas= 1
        this.compareconfig = {}
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.convertdate(new Date())
        // this.props.history.push('/neurobotz/comparison')
        if (localStorage.getItem('selectedboard')) {
            // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            // this.getbaselinetable(localStorage.getItem('selectedboard'))
            this.setState({
                showcontent: true
              })
        }
        if(comparison_baseline()==false){
            this.setState({activetab:"2"})
        }
        if (localStorage.getItem('currency') == 'INR') {
            this.setState({suffix : localStorage.getItem('currency')})
        } else {
            this.setState({prefix : '$'})
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.today = sy.toString() + '-' + sm.toString() ;
        this.BaselineForm.patchValue({
            period:this.today
        })
    }

    togglemodal() {
        this.setState({
          modal: !this.state.modal
        });
      }

    BaselineForm = FormBuilder.group({
        id: [""],
        monthly_kwh: ["", [Validators.required]],
        period: [this.today],
        monthly_peak_kw:["", [Validators.required]],
        monthly_bills: ["", [Validators.required]],
        customer_id: [localStorage.getItem('selectedcustomer')],
        created_by: [localStorage.getItem('user_id')],
        updated_by: [localStorage.getItem('user_id')],
        monthly_peak_kva:["", [Validators.required]],
        board_id: [localStorage.getItem('selectedboard')],
    });

    TariffForm = FormBuilder.group({
        id: [""],
        tariff_structure_id: [""],
        price: ["", [Validators.required]],
        tax: ["", [Validators.required]],
        customer_id: [localStorage.getItem('selectedcustomer')],
        created_by: [localStorage.getItem('user_id')],
        updated_by: [localStorage.getItem('user_id')],
        board_id: [localStorage.getItem('selectedboard')],
    })

    TariffStructureForm = FormBuilder.group({
        id: [""],
        billing_cycle: ["", [Validators.required]],
        charges: ["", [Validators.required]],
        billing_type: ['Flat', [Validators.required]],
        customer_id: [localStorage.getItem('selectedcustomer')],
        created_by: [localStorage.getItem('user_id')],
        updated_by: [localStorage.getItem('user_id')],
        board_id: [localStorage.getItem('selectedboard')],
    })

    baselinecancel() {
        this.BaselineForm.reset()
        this.BaselineForm.patchValue({
            id: '',
            period: this.today,
            monthly_kwh: "",
            monthly_peak_kw:"",
            monthly_peak_kva:"",
            monthly_bills: "",
            customer_id:localStorage.getItem('selectedcustomer'),
            created_by: localStorage.getItem('user_id'),
            updated_by: localStorage.getItem('user_id'),
            board_id: localStorage.getItem('selectedboard'),
        })
        this.setState({
            buttontext: 'Add'
        })
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
          })
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true
          })
        this.BaselineForm.patchValue({
            board_id: board_id,
            customer_id: localStorage.getItem('selectedcustomer'),
        })
        if (this.state.activetab == '1') {
            if (this.state.period == 'MONTHLY' || this.state.period == 'DAILY') {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'QUARTERLY') {
                this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'HALF_YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedhalfyear,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
            } else {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            }
            this.getbaselinetable(board_id)
            this.baselinecancel();
            this.setState({
                buttontext: 'Add'
            })
        } else if (this.state.activetab == '2') {
            this.gettarifftable(board_id)
            this.gettariffstructtable(board_id)
            this.tariffhandleCancel();
        } else {
            if (this.state.period == 'MONTHLY' || this.state.period == 'DAILY') {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'QUARTERLY') {
                this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'HALF_YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedhalfyear,this.state.checkboxvalue,this.selectedboard)
            } else if (this.state.period == 'YEARLY') {
                this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
            } else {
                this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
            }
            this.getbaselinetable(board_id)
            this.baselinecancel();
            this.setState({
                buttontext: 'Add'
            })
        }
        
    }

    periodhandleChange(e) {
        e.preventDefault();
        this.setState({
            period: e.target.value
        })
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.setState({
            checkboxvalue: e.target.value
        })
    }

    charthandleChange(e) {
        e.preventDefault();
        if (e.target.value == 'line') {
            this.setState({
                charttype: 'line',
                fillAlphas: 0
            })
            this.graphtype = 'line'
            this.fillAlphas= 0
        } else {
            this.setState({
                charttype: 'column',
                fillAlphas: 1
            })
            this.graphtype = 'column'
            this.fillAlphas= 1
        }
        this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
    }

    rangehandleChange(range) {
        if (this.state.period == 'MONTHLY' || this.state.period == 'DAILY') {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i] == range) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj != range)
            } else {
                this.pri_list.push(range)
            }
            this.setState({
                selectedmonth: this.pri_list
            })
        } else if (this.state.period == 'QUARTERLY') {
          this.setState({
              selectedquarter: range
          })
        } else if (this.state.period == 'HALF_YEARLY') {
            this.setState({
                selectedhalfyear: range
            })
        } else if (this.state.period == 'YEARLY') {
            this.setState({
                selectedyear: range
            })
        } else {
            this.setState({
                selectedmonth: this.pri_list
            })
        }
    }

    handleSubmit() {
        this.setState({
            belowtableuom: this.state.checkboxvalue,
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1
        })
        if (this.state.period == 'MONTHLY' || this.state.period == 'DAILY') {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'QUARTERLY') {
            this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'HALF_YEARLY') {
            this.fetchdata(this.state.period,this.state.selectedhalfyear,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'YEARLY') {
            this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            this.setState({
                currentbelowtableyear: this.state.selectedyear,
                previousbelowtableyear: this.state.selectedyear - 1
            })
        } else {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        }
    }

    baselinehandleSubmit(event,formvalues) {
        event.preventDefault();
        formvalues.period = formvalues.period + '-01'
        if (formvalues.monthly_kwh == '' || formvalues.monthly_kwh == undefined || formvalues.monthly_kwh == null) {
            formvalues.monthly_kwh = 0
        } if (formvalues.monthly_peak_kva == '' || formvalues.monthly_peak_kva == undefined || formvalues.monthly_peak_kva == null) {
            formvalues.monthly_peak_kva = 0
        } if (formvalues.monthly_peak_kw == '' || formvalues.monthly_peak_kw == undefined || formvalues.monthly_peak_kw == null) {
            formvalues.monthly_peak_kw = 0
        } if (formvalues.monthly_bills == '' || formvalues.monthly_bills == undefined || formvalues.monthly_bills == null) {
            formvalues.monthly_bills = 0
        } 
        if (formvalues.id == '' || formvalues.id == undefined || formvalues.id == null) {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Add Baseline")
            } else {
                this.setState({ loader: true })
                apiService.AddBaseline(formvalues).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Baseline Added Successfully")          
                    this.getbaselinetable(formvalues.board_id)
                    this.baselinecancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            }
        } else {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Update Baseline")
            } else {
                this.setState({ loader: true })
                apiService.UpdateBaseline(formvalues).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Baseline Updated Successfully")       
                    this.getbaselinetable(formvalues.board_id)
                    this.baselinecancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } 
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
    }

    fetchdata(period,range,checkboxvalue,board_id) {
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Board needed")  
        } else {
            if(v3_server_check()==true){
                this.summary_chart_v3(period,range,checkboxvalue,board_id)
          
              }else{
               this.summary_chart_v2(period,range,checkboxvalue,board_id)
              }
        } 
    }
   summary_chart_v2(period,range,checkboxvalue,board_id){
    this.setState({ loader: true })
    apiService.Summarychartdata(period,range,checkboxvalue,board_id).then(response => {
        if (this.state.checkboxvalue == 'kWh') {
            this.setState({
                belowtable: response.data.meter_data[0]['kwh'],
                chartdata: response.data.meter_data[0]['kwh']
            })
        } else if (this.state.checkboxvalue == 'kW') {
            this.setState({
                belowtable: response.data.meter_data[0]['kw'],
                chartdata: response.data.meter_data[0]['kw']
            })
        } else if (this.state.checkboxvalue == 'kVA') {
            this.setState({
                belowtable: response.data.meter_data[0]['kva'],
                chartdata: response.data.meter_data[0]['kva']
            })
        } else if (this.state.checkboxvalue == 'Cost') {
            this.setState({
                belowtable: response.data.meter_data[0]['cost'],
                chartdata: response.data.meter_data[0]['cost']
            })
        } else {
            this.setState({
                belowtable: response.data.meter_data[0]['kwh'],
                chartdata: response.data.meter_data[0]['kwh']
            })
        }
        this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        this.setState({
            loader: false,
        }) 
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
   }
   summary_chart_v3(period,range,checkboxvalue,board_id){
    this.setState({ loader: true })
    apiService.newSummarychartdata(period,range,checkboxvalue,board_id).then(response => {
        if (this.state.checkboxvalue == 'kWh') {
            this.setState({
                belowtable: response.data.meter_data[0]['kWh'],
                chartdata: response.data.meter_data[0]['kWh']
            })
        } else if (this.state.checkboxvalue == 'kW') {
            this.setState({
                belowtable: response.data.meter_data[0]['kW'],
                chartdata: response.data.meter_data[0]['kW']
            })
        } else if (this.state.checkboxvalue == 'kVA') {
            this.setState({
                belowtable: response.data.meter_data[0]['kVA'],
                chartdata: response.data.meter_data[0]['kVA']
            })
        } else if (this.state.checkboxvalue == 'Cost') {
            this.setState({
                belowtable: response.data.meter_data[0]['Cost'],
                chartdata: response.data.meter_data[0]['Cost']
            })
        } else {
            this.setState({
                belowtable: response.data.meter_data[0]['kWh'],
                chartdata: response.data.meter_data[0]['kWh']
            })
        }
        this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        this.setState({
            loader: false,
        }) 
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
   }
    toggle(option) {
        this.setState({
            activetab: option
        })
        if (option == '2') {
            this.gettarifftable(localStorage.getItem('selectedboard'))
            this.gettariffstructtable(localStorage.getItem('selectedboard'))            
        } else {
            this.getbaselinetable(localStorage.getItem('selectedboard'))
        }
    }

    gettarifftable(board_id){
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Choose Some Board")   
        } else {
            if ($.fn.dataTable.isDataTable('#tarifftable')) {
                $('#tarifftable').DataTable().clear().destroy();
            }  
            this.setState({ loader: true })
            apiService.GetTariffTable(board_id).then(response => {
                this.setState({tariff_list: this.state.tariff_list.concat(response.data)}) 
                $(document).ready(function () {
                    $('#tarifftable').DataTable({
                      "bLengthChange": false,
                      "pageLength": 5,
                      "searching": true,
                      "bInfo": true,
                      "bSort": true,
                      "bPaginate": true,
                      "dom": 'Bfrtip',
                      "buttons": []
                    });
                });
                this.setState({ loader: false })
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    gettariffstructtable(board_id){
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Choose Some Board")   
        } else {
            if ($.fn.dataTable.isDataTable('#tariffstructtable')) {
                $('#tariffstructtable').DataTable().clear().destroy();
            }  
            this.setState({ loader: true })
            apiService.GetHistoryTable(board_id).then(response => {
                this.setState({tariffstruct_list: this.state.tariffstruct_list.concat(response.data)}) 
                $(document).ready(function () {
                    $('#tariffstructtable').DataTable({
                      "bLengthChange": false,
                      "pageLength": 5,
                      "searching": true,
                      "bInfo": true,
                      "bSort": true,
                      "bPaginate": true,
                      "dom": 'Bfrtip',
                      "buttons": []
                    });
                });
                this.setState({ loader: false })
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    getbaselinetable(board_id) {
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Choose Some Board")   
        } else {
            if ($.fn.dataTable.isDataTable('#baselinetable')) {
                $('#baselinetable').DataTable().clear().destroy();
            }  
            this.setState({ loader: true })
            apiService.GetBaseline(board_id).then(response => {
                this.setState({baseline_list: this.state.baseline_list.concat(response.data)},()=>{
                console.log(this.state.baseline_list,response.data)
                }) 
                $(document).ready(function () {
                    $('#baselinetable').DataTable({
                      "bLengthChange": false,
                      "pageLength": 5,
                      "searching": true,
                      "bInfo": true,
                      "bSort": true,
                      "bPaginate": true,
                      "dom": 'Bfrtip',
                      "buttons": []
                    });
                });
                this.setState({ loader: false })
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    baselineedit(values) {
        var finalmonth = values.period.replace("-01","")
        this.BaselineForm.patchValue({
            id: values.id,
            period: finalmonth,
            monthly_kwh: values.monthly_kwh,
            monthly_peak_kw:values.monthly_peak_kw,
            monthly_peak_kva:values.monthly_peak_kva,
            monthly_bills: values.monthly_bills,
            customer_id:values.customer_id,
            created_by: values.created_by,
            updated_by:localStorage.getItem('user_id'),
            board_id: values.board_id,
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    baselinedelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Baseline!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.DeleteBaseline(value.id,value.board_id).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Baseline Deleted Successfully")      
                    this.getbaselinetable(localStorage.getItem('selectedboard'))
                    this.baselinecancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    tariffhandleCancel() {
        this.TariffStructureForm.reset()
        this.TariffForm.reset()
        this.TariffStructureForm.patchValue({
            id: "",
            billing_cycle: "",
            charges: "",
            billing_type: 'Flat',
            customer_id: localStorage.getItem('selectedcustomer'),
            created_by: localStorage.getItem('user_id'),
            updated_by: localStorage.getItem('user_id'),
            board_id: localStorage.getItem('selectedboard'),
        })
        this.TariffForm.patchValue({
            id: "",
            tariff_structure_id: "",
            price: "",
            tax: "",
            customer_id: localStorage.getItem('selectedcustomer'),
            created_by: localStorage.getItem('user_id'),
            updated_by: localStorage.getItem('user_id'),
            board_id: localStorage.getItem('selectedboard'),
        })
        this.TariffStructureFormValidFunction()
        this.setState({
            tariffbuttontext: 'Add'
        })
    }

    tariffhandleSubmit(event,tarifstructform,tariffform) {
        event.preventDefault();
        if(tarifstructform.billing_cycle==""){
            tarifstructform.billing_cycle=0
        }
        if(tarifstructform.charges==""){
            tarifstructform.charges=0
        }
        this.setState({ loader: true })
        apiService.AddTariffStructure(tarifstructform).then(response => {
            tariffform.tariff_structure_id = response.data.id
            apiService.AddTariff(tariffform).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Tariff Added Successfully")      
                this.gettarifftable(localStorage.getItem('selectedboard'))
                this.gettariffstructtable(localStorage.getItem('selectedboard'))
                this.tariffhandleCancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    tariffhandleUpdate(event,tariffform) {
        event.preventDefault();
        this.setState({ loader: true })
        apiService.UpdateTariff(tariffform).then(response => {
            this.setState({ loader: false})
            apiService.Alerts("Tariff Updated Successfully")      
            this.gettarifftable(localStorage.getItem('selectedboard'))
            this.gettariffstructtable(localStorage.getItem('selectedboard'))
            this.tariffhandleCancel();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    tariffedit (values) {
        this.TariffForm.patchValue({
            id: values.id,
            tariff_structure_id: values.tariff_structure_id,
            price: values.price,
            tax: values.tax,
            customer_id: values.customer_id,
            created_by: values.created_by,
            updated_by: localStorage.getItem('user_id'),
            board_id: values.board_id,
        })
        this.setState({
            tariffbuttontext: 'Update'
        })
    }

    tariffdelete (event,values) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Tariff!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.DeleteTariff(values.id,values.board_id).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Tariff Deleted Successfully")      
                    this.gettarifftable(localStorage.getItem('selectedboard'))
                    this.gettariffstructtable(localStorage.getItem('selectedboard'))
                    this.tariffhandleCancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    createchart(chartdata,belowtableuom,theme) {
        this.compareconfig = AmCharts.makeChart("comparechart",{
            "type": "serial",
            'hideCredits': true,
             "theme": theme,
             "legend": {
                "horizontalGap": 10,
                "maxColumns": 2,
                "position": "top",
                // "useGraphSettings": true,
                "markerSize": 10,
                "data": [{
                    'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
                    "color": "#f44336"
                  }, {
                    'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',
                    "color": "#9c27b0"
                  }]
            },
            
            "categoryField": "month",
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start",
                "position": "left"
            },
            "trendLines": [],
            "graphs":[
                {
                    "balloonText": "Previous:[[value]] " + belowtableuom,
                    "fillColors": "#f44336",
                    'lineColor': '#f44336',
                    "fillAlphas": this.fillAlphas,
                    "id": "AmGraph-2",
                    "lineAlpha": 1,
                    'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
                    "type": this.graphtype,
                    "valueField": "previous",
                    "labelPosition": "top",
                        "labelText": "[[previous_intervention]]"
                },
                {
                    "balloonText": "Current:[[value]] " + belowtableuom,
                    "fillColors": "#9c27b0",
                    'lineColor': '#9c27b0',
                    "fillAlphas": this.fillAlphas,
                    "id": "AmGraph-1",
                    "lineAlpha": 1,
                    'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',
                    "type": this.graphtype,
                    "valueField": "current",
                    "labelPosition": "top",
                        "labelText": "[[current_intervention]]",
                    
                },
                // {
                //     "balloonText": "Percentage:[[percentage]] " + this.state.belowtableuom,
                //     "fillColorsField":"flag",
                //     "fillAlphas": this.state.fillAlphas,
                //     "id": "AmGraph-3",
                //     "lineAlpha": 1,
                //     "type": this.state.charttype,
                //     "valueField": "percentage",
                //     "labelPosition": "top",
                //     "labelText": "[[percentage]] %"
                // }
            ],
            "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "position": "bottom",
                    "axisAlpha": 0,
                    'title': belowtableuom
                }
            ],
            "allLabels": [],
            "balloon": {},
            "titles": belowtableuom,
            "dataProvider": chartdata,
            "responsive": {
                "enabled": true
            },
            "export": {
                "enabled": true
             }
        })
    }
    TariffStructureFormValidFunction(){
        var x=this.TariffStructureForm.value
        var y=false
        if(x.billing_cycle=="" || x.charges==""){
         y=true
        }
        this.setState({TariffStructureFormValid:y},()=>{
            console.log(this.state.TariffStructureFormValid)
        })
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ? 
            <>
              <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">
                    <Nav tabs>
                        {comparison_baseline()==true &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Baseline V Savings
                            </NavLink>
                            </NavItem>
    }
                            <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Tariff
                            </NavLink>
                        </NavItem>
                    </Nav> 
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <TabContent activeTab={this.state.activetab}>
                    <TabPane tabId="1">
                    <form>
                        <Row>
                            <Col className="pr-1" md="3">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                        <InputLabel id="demo-simple-select-outlined-label1">Period</InputLabel>  
                                            <Select
                                                labelId="demo-simple-select-outlined-label1"
                                                id="demo-simple-select-outlined1"
                                                label="Period"
                                                fullWidth variant="outlined"
                                                onChange={(e) => this.periodhandleChange(e)}
                                                value={this.state.period}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'DAILY'}>Daily Average</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'MONTHLY'}>Monthly</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'QUARTERLY'}>Quarterly</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'HALF_YEARLY'}>Half Yearly</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'YEARLY'}>Yearly</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="3">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <InputLabel id="demo-mutiple-name-label">Range</InputLabel>
                                            {this.state.period == 'DAILY' ?
                                              <div>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple variant="outlined" fullWidth
                                                    value={this.state.selectedmonth}
                                                    label="Range"
                                                    >
                                                    {this.state.monthrange.map((option) => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                        {option.itemName}
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            :
                                            this.state.period == 'MONTHLY' ?
                                                <div>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Select
                                                        labelId="demo-mutiple-name-label"
                                                        id="demo-mutiple-name"
                                                        multiple variant="outlined" fullWidth
                                                        value={this.state.selectedmonth}
                                                        label="Range"
                                                        >
                                                        {this.state.monthrange.map((option) => (
                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                            {option.itemName}
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                    
                                            : 
                                            this.state.period == 'QUARTERLY' ? 
                                                <Select
                                                    labelId="demo-simple-select-outlined-label4"
                                                    id="demo-simple-select-outlined4"
                                                    label="Range"
                                                    fullWidth variant="outlined"
                                                    value={this.state.selectedquarter}
                                                    >
                                                    {this.state.quarterrange.map(option => (
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.value} onClick={(e) => this.rangehandleChange(option.value)}>
                                                        {option.itemName}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            : this.state.period == 'HALF_YEARLY' ? 
                                            <Select
                                                labelId="demo-simple-select-outlined-label4"
                                                id="demo-simple-select-outlined4"
                                                label="Range"
                                                fullWidth variant="outlined"
                                                value={this.state.selectedhalfyear}
                                                >
                                                {this.state.halfyearrange.map(option => (
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.value} onClick={(e) => this.rangehandleChange(option.value)}>
                                                    {option.itemName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            : 
                                            this.state.period == 'YEARLY' ?
                                                <Select
                                                    labelId="demo-simple-select-outlined-label4"
                                                    id="demo-simple-select-outlined4"
                                                    label="Range"
                                                    fullWidth variant="outlined"
                                                    value={this.state.selectedyear}
                                                    >
                                                    {this.state.yearrange.map(option => (
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                        {option.itemName}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            :
                                            ''}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item> 
                                            <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                            <Select
                                                labelId="demo-simple-select-outlined-label2"
                                                id="demo-simple-select-outlined2"
                                                label="Units"
                                                fullWidth variant="outlined"
                                                onChange={(e) => this.uomhandleChange(e)}
                                                value={this.state.checkboxvalue}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kWh'}>kWh</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kW'}>kW</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kVA'}>kVA</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'Cost'}>Cost</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label3"
                                                id="demo-simple-select-outlined3"
                                                fullWidth variant="outlined"
                                                label="Charts"
                                                onChange={(e) => this.charthandleChange(e)}
                                                value={this.state.charttype}
                                                >
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                            <Col className="pr-1" md="2">
                                <div style={{paddingTop: '0.8em'}}>
                                    <Grid className="Formfieldcss" container spacing={1}>                
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleSubmit()}
                                            >
                                            Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Col>
                        </Row>
                    </form>
                    <hr />
                    <div id="comparechart" style={{ width: "100%", height: "500px" }} options={this.compareconfig} />
                    <hr style={{marginTop: "2rem"}} />
                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <table id="historybelowtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Previous Year ({this.state.previousbelowtableyear}) {this.state.belowtableuom}</th>
                                    <th>Current Year ({this.state.currentbelowtableyear}) {this.state.belowtableuom}</th>
                                    <th>Savings (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.belowtable.map((value, index) => (
                                <tr key={index+1}>
                                    <td>{value.month}</td>
                                    <td>{value.previous}</td>
                                    <td>{value.current}</td>  
                                    <td>{value.percentage}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <hr style={{marginTop: "2rem"}} />
                    <FieldGroup
                        control={this.BaselineForm}
                        strict= {false}
                        render={({ get, invalid, value }) => (
                        <form>
                            <Row>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="period"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField type="month" required fullWidth variant="outlined" error={touched && hasError("required")}  label="Choose Month" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Month is required`}
                                                     
                                            </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="monthly_kwh"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField type="number" fullWidth variant="outlined" error={touched && hasError("required")}   label="Monthly kWh *" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Month kWh is required`}
                                                     
                                            </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="monthly_peak_kw"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField type="number" fullWidth variant="outlined" error={touched && hasError("required")} label="Monthly peak kW *" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Monthly peak kW is required`}
                                                     
                                            </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="monthly_peak_kva"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField type="number" fullWidth variant="outlined" error={touched && hasError("required")}  label="Monthly peak kVA *" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Monthly peak kVa is required`}
                                                     
                                            </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                <FieldControl
                                    name="monthly_bills"
                                    strict= {false}
                                    render={({ handler, touched, hasError }) => (
                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                            <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                <TextField type="number" fullWidth variant="outlined" error={touched && hasError("required")}  label="Monthly bills *" {...handler("text")}  />
                                                </Grid>
                                                <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Monthly Bill is required`}
                                                     
                                            </span>
                                            </Grid>
                                        </div>
                                    )}
                                    />
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={invalid}
                                        onClick={(event) => this.baselinehandleSubmit(event,this.BaselineForm.getRawValue())}
                                        >
                                        {this.state.buttontext}
                                    </Button>
                                    
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Button
                                        onClick={() => this.baselinecancel()}
                                        variant="contained"
                                        color="danger"
                                        >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        )} 
                    /> 
                        <hr />
                        <Row>
                            <Col>
                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="baselinetable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <thead>
                                        <tr>
                                            <th style={{width : '100px'}}>Actions</th>
                                            <th style={{width : '100px'}}>Month</th>
                                            <th style={{width : '150px'}}>Monthly kWh</th>
                                            <th style={{width : '150px'}}>Monthly Peak kW</th>
                                            <th style={{width : '150px'}}>Monthly Peak kVA</th>
                                            <th style={{width : '150px'}}>Monthly Bills</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.baseline_list.map((value, index) => (
                                        <tr key={index+1}>
                                            <td>
                                                <a href="javascript:void(0)" onClick={(event) => this.baselinedelete(event,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.baselineedit(value)}><i className="fa fa-edit icons"></i></a>
                                            </td>
                                            <td>{value.month}</td>
                                            <td>{value.monthly_kwh}</td>
                                            <td>{value.monthly_peak_kw}</td>  
                                            <td>{value.monthly_peak_kva}</td>
                                            <td>{value.monthly_bills}</td>      
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <Col className="pr-1" md="12">
                        <FieldGroup
                            control={this.TariffStructureForm}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="billing_cycle"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                        <TextField type="number"
                                                        onKeyUp={()=>this.TariffStructureFormValidFunction()}
                                                         required fullWidth variant="outlined" error={touched && hasError("required")} label="Billing Cycle" {...handler("text")}  />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Billing Cycle is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="charges"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                <Grid item>
                                                        <TextField type="number" 
                                                        onKeyUp={()=>this.TariffStructureFormValidFunction()}
                                                        required fullWidth variant="outlined" error={touched && hasError("required")} label="Charges" {...handler("text")}  />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Charges is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="billing_type"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" required fullWidth variant="outlined" label="Billing Type" error={touched && hasError("required")}  {...handler("text")} disabled/>
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Billing Type is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    {/* <Col className="pr-1 text-center" md="3">
                                    <div className="login-button changepassword text-center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.togglemodal()}
                                            >
                                            History
                                        </Button>
                                    </div>
                                    </Col> */}
                                </Row>
                            </form>
                            )} 
                        /> 
                        <FieldGroup
                            control={this.TariffForm}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="price"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Price" {...handler("text")}  />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Price is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="tax"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Tax" {...handler("text")}  />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Tax is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    {this.state.tariffbuttontext == 'Add' ? 
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid || this.state.TariffStructureFormValid}
                                                onClick={(event) => this.tariffhandleSubmit(event,this.TariffStructureForm.getRawValue(),this.TariffForm.getRawValue())}
                                                >
                                                Add
                                            </Button>
                                        </div>
                                    :
                                        <div className="login-button changepassword text-center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid}
                                                onClick={(event) => this.tariffhandleUpdate(event,this.TariffForm.getRawValue())}
                                                >
                                                Update
                                            </Button>
                                        </div>
                                    }
                                    </Col>
                                    <Col className="pr-1 text-center" md="3">
                                    <div className="login-button changepassword text-center">
                                        <Button
                                            variant="contained"
                                            color="danger"
                                            onClick={() => this.tariffhandleCancel()}
                                            >
                                            Cancel
                                        </Button>
                                    </div>
                                    </Col>
                                </Row>
                            </form>
                            )} 
                        /> 
                        <hr />
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="tarifftable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Electricity Price</th>
                                            <th>Tax Percentage(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tariff_list.map((value, index) => (
                                        <tr key={index+1}>
                                            <td>
                                                <a href="javascript:void(0)" onClick={(event) => this.tariffdelete(event,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.tariffedit(value)}><i className="fa fa-edit icons"></i></a>
                                            </td>
                                            <td>{value.price}</td>
                                            <td>{value.tax}</td>     
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col className="pr-1" md="12">
                        <hr />
                        <h5>History</h5>
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="tariffstructtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <thead>
                                        <tr>
                                            <th>Billing Cycle</th>
                                            <th>Billing Type</th>
                                            <th>Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tariffstruct_list.map((value, index) => (
                                        <tr key={index+1}>
                                            <td>{value.billing_cycle}</td>
                                            <td>{value.billing_type}</td>     
                                            <td>{value.charges}</td> 
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <MDBContainer>
                            <MDBModal isOpen={this.state.modal} toggle={this.togglemodal()} size="lg">
                                <MDBModalHeader toggle={this.togglemodal()}>MDBModal title</MDBModalHeader>
                                <MDBModalBody>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                                </MDBModalBody>
                                <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.togglemodal()}>Close</MDBBtn>
                                </MDBModalFooter>
                            </MDBModal>
                            </MDBContainer> */}
                        </Col>
                        </Row>    
                    </TabPane>
                    </TabContent>
                </CardBody>
              </Card>
            </Col>
              </Row>
              </>
              : 
              <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Comparison);