import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Thermostat extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            thermostatList: [],
            showcontent: false,
            editmode: false,
            buttontext: 'Add',
            dropDownList: [],
            BoardList: []
        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''

    }
    thermostatForm = FormBuilder.group({
        id: [""],
        name: ["", [Validators.required]],
        desc: ["", [Validators.required]],
        board: ["", [Validators.required]],
        temperature: ["", [Validators.required]]
    });
    cancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.thermostatForm.reset()
    }
    getdropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {


            this.setState({ dropDownList: response.data[4].temperature, loader: false })
            // console.log(this.state.dropDownList,response,"worsk")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    getThermostat() {
        if ($.fn.dataTable.isDataTable('#boardtable')) {
            $('#boardtable').DataTable().clear().destroy();
        }
        this.setState({ loader: true })
        apiService.getThermostat().then(response => {
            //  console.log(response.data)
            // this.setState({ thermostatList: response.data })
            // this.setState({ thermostatList: [] })
            // this.setState({ thermostatList: response.data })
       
            this.setState({thermostatList: this.state.thermostatList.concat(response.data)}) 
            $(document).ready(function () {
                $('#boardtable').DataTable({
                    "bLengthChange": false,
                    "pageLength": 5,
                    "searching": true,
                    "bInfo": true,
                    "bSort": true,
                    "bPaginate": true,
                    "dom": 'Bfrtip',
                    "buttons": []
                });
            });
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    boardData() {

        this.setState({ loader: true })
        var x = localStorage.getItem('selectedcustomer')
        apiService.topBoardDropdown(x).then(response => {
            this.setState({ BoardList: response.data })

            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleSubmit(event, value) {
        // console.log(value)
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Thermostat Added Successfully")
                this.getThermostat();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Board Updated Successfully")
                this.getThermostat();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    thermostatedit(value) {
        // console.log(value)
        this.thermostatForm.patchValue({
            id: value.id,
            name: value.thermo_name,
            desc: value.thermo_desc,
            board: value.board_id,
            temperature: value.set_temp,
           
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    thermostatdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Thermostat!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteThermostat(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Thermostat Deleted Successfully")
                        this.getThermostat();
                        this.cancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/thermostat')
            if (localStorage.getItem('selectedboard')) {
                this.getdropdowndata()
                this.boardData()
                this.setState({
                    showcontent: true,
                    thermostatList:[]
                })
                // this.getThermostat()

                // console.log(this.state.dropDownList)
                // this.boardData()
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
               
            }

        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            thermostatList:[]
        },()=> this.boardData())
        this.thermostatForm.reset()
       

    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        localStorage.setItem('selectedboard',board_id)

        this.setState({
            showcontent: true,
            thermostatList:[]
        },()=>{
            this.getThermostat()
        })
        // if (this.state.period == 'MONTHLY') {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'QUARTERLY') {
        //     this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'YEARLY') {
        //     this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
        // } else {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // }
        this.setState({
            showcontent: true
        })
    }


    periodhandleChange(e) {
        e.preventDefault();
        this.setState({
            period: e.target.value
        })
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.setState({
            checkboxvalue: e.target.value
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            // this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
    }


    render() {

        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5"></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <FieldGroup
                                                control={this.thermostatForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="name"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Thermostat ID" {...handler("text")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Thermostat ID is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="desc"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Description" {...handler("text")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Description is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="board"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Board *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Board
                                                  </MenuItem>
                                                                                        {this.state.BoardList.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                {option.itemName}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Board is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />

                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="temperature"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Temperature *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Temperature
                                                  </MenuItem>
                                                                                        {this.state.dropDownList.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                {option.value}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Board is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.handleSubmit(event, this.thermostatForm.getRawValue())}
                                                                    >
                                                                        {this.state.buttontext}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                )}
                                            />
                                        </CardBody>

                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5"></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width : '100px'}}>Actions</th>
                                                            <th style={{width : '100px'}}>ID</th>
                                                            <th style={{width : '100px'}}>Description</th>
                                                            <th style={{width : '100px'}}>Board</th>
                                                            <th style={{width : '100px'}}>Temperature(C)</th>
                                                            <th style={{width : '200px'}}>Created / Updated at</th>

                                                        </tr>
                                                        {/* <tr>
                                            <th style={{width : '100px'}}>Actions</th>
                                            <th style={{width : '200px'}}>Board Name</th>
                                            <th style={{width : '200px'}}>IMEI</th>
                                            <th style={{width : '200px'}}>Expiry Date</th>
                                            <th style={{width : '100px'}}>Active</th>
                                            
                                        </tr> */}
                                                    </thead>
                                                    <tbody>
                                                        {this.state.thermostatList.map((value, index) => (
                                                            <tr key={index + 1}>
                                                                {/* <td>{index+1}</td> */}
                                                                <td>
                                                                    <a href="javascript:void(0)" onClick={(event) => this.thermostatdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.thermostatedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                </td>
                                                                <td>{value.thermo_name}</td>
                                                                <td>{value.thermo_desc}</td>
                                                                <td>{value.board_name}</td>
                                                                <td>{value.set_temp}°</td>

                                                                <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                {/* <td>{value.board_name}</td>
                                            <td>{value.imei}</td>
                                            <td>{value.expiry_date}</td>
                                            <td>
                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                            </td> */}

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(Thermostat);
