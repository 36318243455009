import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, CardText, CardFooter
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import classnames from 'classnames';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import swal from 'sweetalert';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);
const $ = require('jquery');
const moment = require('moment');
$.DataTable = require('datatables.net');

export class ColdStorage extends React.Component {
    constructor(props) {
        super();
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = this.today;
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastStart2 = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(30)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        this.state = {
            loader: false,
            showcontent: false,
            activetab: '1',
            defaultrange: [this.lastStart, this.lastStart2],
            operationlist: ["Facility_L103", "Facility_L104", "Facility_L015"],
            operationvalue: "Facility_L103",
            kwhdata: [],
            temprhtable: [],
            temprhchart: [],
            backgroundcolor: 'white',
            textcolor: 'black',
            tempchart1series: [],
            tempchart2series: [],
            upperLimit: 1,
            upperLimitBtn: false,
            lowerLimitBtn: false,
            lowerLimit: 1,
            bypassStatusList: [{ id: 'active', value: "Active" }, { id: 'inactive', value: "Inactive" }],
            selectedBypassValue: 'active',
            highCold: '',
            lowCold: '',
            AvgCold: '',
            CurrentTemp: '',
            pageloadcheck: 1,
        }

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.newtest = this.newtest.bind(this);
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.airflowchartconfig1 = {}
        this.airflowchartconfig2 = {}
        this.rhchartconfig = {}
        this.co2chartconfig = {}
        this.tempchartconfig1 = {}
        this.tempchartconfig2 = {}
        this.occupancychartconfig = {}
        this.seriesdata = []
        this.seriesData2 = []
        this.var2 = [];
        //this.button = React.createRef()
        this.GetLoadData();
        // document.getElementById("button").click()
        // this.button.onClick()
        this.buttonRef = React.createRef($.event);
        //if (this.buttonRef.current) {
            //this.buttonRef.current.click();
          //}
    
    }
    GetLoadData() {
        //this.InitiateSubmit();
        apiService.coldstoragecurrentdata(this.selectedboard).then(response => {
            //console.log(response.data);
            this.setState({
                highCold: response.data[1].high_temp,
                lowCold: response.data[2].low_temp,
                AvgCold: response.data[3].avg_temp,
                CurrentTemp: response.data[0].current_temp
            })
            //this.createtemprhchart(response.data, '', this.props.charttheme)
        }).catch(error => {
            //this.setState({ loader: false })
            apiService.ErrorMessage(error)
        });
    }
    sensorForm = FormBuilder.group({
        daterange: [""],
        operation: ["Facility_L103"],
    });

    TempRHForm = FormBuilder.group({
        start_date: [""],
        end_date: [""],
        start_time: ['00:00'],
        end_time: ['23:59'],
    });

    TempRHLogForm = FormBuilder.group({
        start_date: [this.today],
        end_date: [this.today],
        start_time: ['00:00'],
        end_time: ['23:59'],
    });

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.convertdate1(new Date())
            this.props.history.push('/neurobotz/cold_storage')
            if (localStorage.getItem('selectedboard')) {
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
                this.setState({
                    showcontent: true
                })
            }
            // this.firstdisplayTempRHSubmit(this.TempRHLogForm.getRawValue())
            // this.gettemprhchart(this.TempRHForm.getRawValue())
            // this.getairflowchart1data(297,this.today)
            // this.createrhchart(this.props.charttheme)
            // this.createco2chart(this.props.charttheme)
            // if (localStorage.getItem('currency') == 'INR') {
            //     this.setState({suffix : localStorage.getItem('currency')})
            // } else {
            //     this.setState({prefix : '$'})
            // }
        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    componentDidUpdate(prevProps) {
        // if (this.props.themecolor == 'greycolor' || this.props.themecolor ==  'whitecolor') {
        //     this.setState({
        //         backgroundcolor : 'transparent',
        //         textcolor: 'white'
        //     })
        // } else {
        //     this.setState({
        //         backgroundcolor : 'white',
        //         textcolor: 'black'
        //     })
        // }
        if (prevProps.theme != this.props.theme) {
            this.createtemprhchart(this.state.temprhchart, '', this.props.charttheme)
            //   this.createtempchart2(this.state.kwhdata,'',this.props.charttheme)
        }
    }

    // getairflowchart1data(board,today) {
    //     const sy = today.getFullYear();
    //     const sm = today.getMonth() + 1;
    //     const sd = today.getDate();
    //     var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    //     this.setState({ loader: true })
    //     apiService.coldChart(dates,dates,board,'kWh').then(response => {
    //         console.log(response)
    //         this.setState({ loader: false, kwhdata: response.data.chart_data[0].kwh}) 
    //         // this.createairflowchart1(response.data.chart_data[0].kwh,'',this.props.charttheme);
    //         // this.createairflowchart2(response.data.chart_data[0].kwh,'',this.props.charttheme);
    //         // this.createoccupancychart(response.data.chart_data[0].kwh,'',this.props.charttheme)
    //         // this.createtempchart2(response.data.chart_data[0].kwh,'',this.props.charttheme)
    //     }).catch(error => {
    //         this.setState({ loader: false })
    //         apiService.ErrorMessage(error)
    //     })
    // }



    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            temprhtable: [],
            temprhchart: [],
            tempchart1series: [],
            tempchart2series: [],
        })
        this.seriesdata = []
        this.seriesData2 = []
        this.createtemprhchart(this.state.temprhchart, '', this.props.charttheme)
        this.GetLoadData();
    }

    boardhandler(board_id) {
        this.selectedboard = board_id
        this.convertdate(this.state.defaultrange, board_id)
        this.setState({
            showcontent: true,
            temprhtable: [],
            temprhchart: [],
            tempchart1series: [],
            tempchart2series: [],
        })
        this.seriesdata = []
        this.seriesData2 = []
        this.createtemprhchart(this.state.temprhchart, '', this.props.charttheme)
        this.GetLoadData();
    }

    convertdate(e, board_id) {
        const sy = e[0].getFullYear();
        const sm = e[0].getMonth() + 1;
        const sd = e[0].getDate();
        const ey = e[1].getFullYear();
        const em = e[1].getMonth() + 1;
        const ed = e[1].getDate();
        this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        const finaldate = [this.startdate, this.enddate]
        return finaldate
        // this.fetchdata(this.startdate,this.enddate,this.state.checkboxvalue,board_id,this.state.minutevalue);
    }

    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.TempRHForm.patchValue({
            start_date: this.dailytoday,
            end_date: this.dailytoday,
        })
        this.TempRHLogForm.patchValue({
            start_date: this.dailytoday,
            end_date: this.dailytoday,
        })
    }

    datehandleChange(e) {
        this.setState({
            defaultrange: e.target.value
        })
        // this.convertdate(e.target.value,localStorage.getItem('selectedboard'))
    }

    toggle(option) {
        this.setState({
            activetab: option
        })
    }

    operationhandleChange(e) {
        e.preventDefault();
        this.sensorForm.patchValue({
            operation: e.target.value
        })
        this.setState({
            operationvalue: e.target.value
        })
        // this.show=false
    }

    handlesensorSubmit(event, values) {
        event.preventDefault()
        console.log(values)
    }

    createtemprhchart(data, uom, theme) {
        this.setState({
            pageloadcheck: 1
        })
        var chartdata2 = [];
        if (theme == 'dark') {
            this.setState({
                backgroundcolor: 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor: 'white',
                textcolor: 'black'
            })
        }
        for (var i = 0; i <= data.length - 1; i++) {
            var time = new Date(data[i].time)
            var y = time.getUTCFullYear();
            var m = time.getUTCMonth();
            var d = time.getUTCDate();
            var h = time.getUTCHours();
            var min = time.getUTCMinutes();
            var s = time.getUTCSeconds();
            // var tZ = new Date().getTimezoneOffset()

            var tZ = new Date().getTimezoneOffset() / 30
            if (tZ === -17.5) {
                h -= -17;
                min -= -30;
            } else {
                h -= tZ;
            }
        
        // var timestamp = new Date(y, m, d, h + offSET, min, s)
            var timestamp = new Date(y, m, d, h, min, s)

            // var offSET = 11;
            // if (tZ == -120) {
            //     offSET = 4
            // } else {
            //     offSET = 11
            // }
            // var timestamp = new Date(y, m, d, h + offSET, min, s)
            var dat = new Date(timestamp);
            var final = dat.getTime();
            chartdata2.push([final, data[i].temperature])
        }
        var var2 = chartdata2
        this.var2 = var2

        this.seriesData2 = [{
            name: 'Temperature',
            data: var2,
            type: 'line',
            color: '#318CE7',
            fillOpacity: 1,
            threshold: null,
            tooltip: {
                valueSuffix: '°C'
            }
        }]
        this.setState({
            tempchart1series: this.seriesData2,
        })
    }


    firstdisplayTempRHSubmit(formValue) {
        if ($.fn.dataTable.isDataTable('#temprhtable')) {
            $('#temprhtable').DataTable().clear().destroy();
        }
        formValue.board_id = localStorage.getItem('selectedboard')
        this.setState({ loader: true })
        apiService.coldstoragedisplayreport(formValue).then(response => {
            var x = []
            if (Array.isArray(response.data)) {
                x = response.data.map((obj) => {
                    var z = obj['created_at']
                    obj['created_at'] = this.date_conversion(z)
                    return obj
                })
            }
            $(document).ready(function () {
                $('#temprhtable').DataTable({
                    "bLengthChange": false,
                    "pageLength": 15,
                    "searching": false,
                    "bInfo": false,
                    "bSort": false,
                    "bPaginate": false,
                    "dom": 'rtip',
                });
            });
            this.setState({ temprhtable: this.state.temprhtable.concat(x), loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
InitiateSubmit() {
    // debugger;
    const formValue= FormBuilder.group({
        start_date: [this.today],
        end_date: [this.today],
        start_time: ['00:00'],
        end_time: ['23:59'],
    }).getRawValue();
    if ($.fn.dataTable.isDataTable('#temprhtable')) {
        $('#temprhtable').DataTable().clear().destroy();
    }
    formValue.board_id = localStorage.getItem('selectedboard');
    this.setState({ loader: true })
    apiService.coldstoragedisplayreport(formValue).then(response => {
        var x = []
        if (Array.isArray(response.data)) {
            x = response.data.map((obj) => {
                var z = obj['created_at']
                obj['created_at'] = this.date_conversion(z)
                return obj
            })
        }
        this.setState({ temprhtable: this.state.temprhtable.concat(x), loader: false })
        $(document).ready(function () {
            $('#temprhtable').DataTable({
                "bLengthChange": false,
                "pageLength": 15,
                "searching": false,
                "bInfo": false,
                "bSort": false,
                "bPaginate": false,
                "dom": 'rtip',
            });
        });
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })

}
    displayTempRHSubmit(e, formValue) {
        e.preventDefault()
        if ($.fn.dataTable.isDataTable('#temprhtable')) {
            $('#temprhtable').DataTable().clear().destroy();
        }
        formValue.board_id = this.selectedboard
        this.setState({ loader: true })
        apiService.coldstoragedisplayreport(formValue).then(response => {
            var x = []
            if (Array.isArray(response.data)) {
                x = response.data.map((obj) => {
                    var z = obj['created_at']
                    obj['created_at'] = this.date_conversion(z)
                    return obj
                })
            }
            this.setState({ temprhtable: this.state.temprhtable.concat(x), loader: false })
            $(document).ready(function () {
                $('#temprhtable').DataTable({
                    "bLengthChange": false,
                    "pageLength": 15,
                    "searching": false,
                    "bInfo": false,
                    "bSort": false,
                    "bPaginate": false,
                    "dom": 'rtip',
                });
            });
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })

    }

    downloadTempRHSubmit(e, formValue) {
        e.preventDefault()
        formValue.board_id = this.selectedboard
        this.setState({ loader: true })
        apiService.coldstoragedownloadreport(formValue).then(response => {
            this.setState({ loader: false })
            swal({
                title: "Cold Storage Temperature Report has been generated",
                text: "The report is ready to download",
                icon: "success",
                buttons: true,
                dangerMode: false,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        window.open(response.data.url);
                    } else {
                        // swal("Your file is safe!");
                    }
                });
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    date_conversion(date) {
        var x = date.split(" ")
        var z = "--"
        if (Array.isArray(x) && x.length == 2) {
            var y = moment(new Date(x[0])).format('MMM Do,YYYY')
            var timeString = x[1];
            var H = +timeString.substr(0, 2);
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "AM" : "PM";
            timeString = h + timeString.substr(2, 3) + ampm;
            z = y + " at " + timeString

        }
        return z
    }

    gettemprhchart(formValue) {
        formValue.board_id = localStorage.getItem('selectedboard')
        this.setState({ loader: true })
        apiService.coldChart(formValue).then(response => {
            this.setState({ loader: false, temprhchart: response.data })
            this.createtemprhchart(response.data, '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    gettemprhchartsubmit(e, formValue) {
        e.preventDefault()
        formValue.board_id = this.selectedboard
        apiService.coldChart(formValue).then(response => {
            this.setState({ loader: false, temprhchart: response.data })
            this.createtemprhchart(response.data, '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    handleUpperLimitSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterUpperlimit(this.state.upperLimit).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Upper Limit set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleLowerLimitSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterLowerlimit(this.state.lowerLimit).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Lower Limit set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleBypassSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterBypassStatus(this.state.selectedBypassValue).then(response => {
            this.setState({ loader: false })
            apiService.Alerts(response?.data[0]?.message || "Bypass Status set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    upperlimitregex(e) {
        e.preventDefault();
        if (e.target.value >= -100 === false || e.target.value <= 100 == false) {
            this.setState({ upperLimitBtn: true })
        } else if (e.target.value == '') {
            this.setState({ upperLimitBtn: true })
        } else {
            this.setState({ upperLimitBtn: false })
        }
        this.setState({ upperLimit: e.target.value })
    }

    lowerlimitregex(e) {
        e.preventDefault();
        if (e.target.value >= -100 === false || e.target.value <= 100 == false) {
            this.setState({ lowerLimitBtn: true })
        } else if (e.target.value == '') {
            this.setState({ lowerLimitBtn: true })
        } else {
            this.setState({ lowerLimitBtn: false })
        }
        this.setState({ lowerLimit: e.target.value })
    }

    handleClose(e) {
        e.preventDefault();
        this.setState({ Modalopen: false })
    }

    openSwalModel(e) {
        e.preventDefault();
        this.setState({ Modalopen: true })
        apiService.coldstoragecurrentdisplay().then(response => {
            this.setState({
                upperLimit: response.data[0].upper_limit,
                lowerLimit: response.data[1].lower_limit,
                selectedBypassValue: response.data[2].bypass
            })
        }).catch(error => {

        })
    }
    newtest(event) {
        console.log('load');
        var timearray1 = []
        var timearray2 = []
        var timearray3 = []
        var array = []
        var max1 = null
        var min1 = null
        var sum = 0
        var x = 0
        var max = event.max;
        var min = event.min;
        //console.log(event.target.series[0]['xData'].length);
        if (event.target.series[0] && event.target.series[0]['xData']) {

            console.log(event.target.series[0]['xData'].length);
            if (event.target.series[0]['xData'].length > 0) {
                for (let i = 0; i <= event.target.series[0]['xData'].length - 1; i++) {
                    timearray1.push([event.target.series[0]['xData'][i], event.target.series[0]['yData'][i]])
                }
                this.var2.forEach(row => {
                    if (row[0] > min) {
                        x = 1
                    }
                    if (row[0] > max) {
                        x = 0
                    }
                    if (x == 1) {
                        array.push(row)
                    }
                });
                if (array.length == 0) {
                    max1 = 0
                    min1 = 0
                    sum = 0
                    var average1 = 0
                    var obj = {
                        max: max1,
                        min: min1,
                        sum: sum,
                        average: average1
                    }
                } else {
                    max1 = Math.max.apply(Math, array.map(function (o) { return o[1]; }))
                    min1 = Math.min.apply(Math, array.map(function (o) { return o[1]; }))
                    sum = 0
                    array.forEach((key) => {
                        sum = sum + key[1]
                    })

                    var finalsum = 0
                    var average1
                    if (false) {
                        finalsum = array[array.length - 1][2] - array[0][2]
                        var l = array[array.length - 1][0] - array[0][0]
                        var diff = l / 3600000;
                        if (finalsum > 0) {
                            average1 = finalsum / diff

                        } else {
                            average1 = 0
                        }
                    } else {
                        finalsum = sum
                        average1 = sum / (array.length)

                    }

                    var obj = {
                        max: max1,
                        min: min1,
                        sum: finalsum,
                        average: average1
                    }
                }
                if (this.state.pageloadcheck == 1) {


                    this.setState({
                        pageloadcheck: 2
                    })
                }
                else if (this.state.pageloadcheck == 2) {

                    this.setState({
                        pageloadcheck: 3
                    })
                }
                else if (this.state.pageloadcheck == 3) {
                    this.setState({
                        highCold: max1.toFixed(2),
                        lowCold: min1.toFixed(2),
                        AvgCold: average1.toFixed(2)
                    })
                }
            }
        }

    }
    render() {

        var tempchartconfig2 = {
            chart: {
                renderTo: 'tempchartdiv2',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },

            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                events: {
                    afterSetExtremes: this.newtest,
                    //setExtremes: this.newtest
                }
            },
            yAxis: {
                opposite: false,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: 'Temperature °C',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                valueDecimals: 2,
                split: false,
                shared: true
            },
            // plotOptions: {
            //     series: {
            //         connectNulls: true
            //     }
            // },
            series: this.state.tempchart1series
        }


        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                            <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5" className="dashboardcardtitle" style={{ marginTop: 0 }}>Cold Storage Control Centre</CardTitle>
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">

                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">
                                                    <FieldGroup
                                                        control={this.TempRHForm}
                                                        strict={false}
                                                        render={({ get, invalid, value }) => (
                                                            <form>
                                                                <Row>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="Start Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="End Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="Start Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="End Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                disabled={invalid}
                                                                                onClick={(event) => this.gettemprhchartsubmit(event, this.TempRHForm.getRawValue())}
                                                                            >
                                                                                Display
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="button"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={(event) => this.openSwalModel(event)}
                                                                            >
                                                                                Set Limits
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </form>

                                                        )}
                                                    />
                                                    <hr />
                                                    <div style={{ width: "100%" }}>
                                                        {/* <h5>Temperature values - ON/OFF condition of the Motor</h5> */}
                                                        {/* <div id="tempchartdiv1" style={{ width: "100%", height: '500px'}} options={this.tempchartconfig1}></div> */}
                                                        <HighchartsReact
                                                            style={{ width: "100%", height: '500px' }}
                                                            highcharts={Highcharts}
                                                            constructorType={'stockChart'}
                                                            options={tempchartconfig2}
                                                            containerProps={{ className: 'chartContainer' }}
                                                            id="tempchartdiv2"
                                                        />
                                                        {/* <HighchartsReact 
                                                        style={{width:"100%", height: '500px'}}
                                                        highcharts={Highcharts}
                                                        constructorType={'stockChart'}
                                                        options={tempchartconfig1}
                                                        containerProps = {{ className: 'chartContainer' }}
                                                        id="tempchartdiv1"
                                                    /> */}

                                                    </div>
                                                    <Row>
                                                        <Col lg="3" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-success">
                                                                                <i className="fas fa-snowflake text-success" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">Current Temperature</p>
                                                                                <CardTitle tag="p" style={{ fontWeight: 600, fontSize: '37px !important' }} className="newsizetext">{this.state.CurrentTemp} °C {this.selecteduom}</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="3" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-snowflake text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.highCold} °C {this.selecteduom}</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="3" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-snowflake text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowCold} °C {this.selecteduom}</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="3" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-snowflake text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.AvgCold} °C {this.selecteduom}</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        {
                                                            <>
                                                                <Col lg="3" md="6" sm="6">
                                                                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md="12" xs="12">
                                                                                    <div className="numbers text-center">
                                                                                        <CardTitle tag="p">
                                                                                            {this.state.highCold}
                                                                                        </CardTitle>
                                                                                        <p />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <CardFooter>
                                                                            <hr />
                                                                            <div className="stats text-center">
                                                                                {this.state.checkboxvalue == 'Cost' ? <i className="fas fa-snowflake text-info" /> : <i className="fas fa-snowflake text-info" />} High
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="3" md="6" sm="6">
                                                                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md="12" xs="12">
                                                                                    <div className="numbers text-center">
                                                                                        <CardTitle tag="p">
                                                                                            {this.state.prefix} {this.state.lowCold} {this.state.suffix}
                                                                                        </CardTitle>
                                                                                        <p />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <CardFooter>
                                                                            <hr />
                                                                            <div className="stats text-center">
                                                                                {this.state.checkboxvalue == 'Cost' ? <i className="fas fa-snowflake text-info" /> : <i className="fas fa-snowflake text-warning" />} Low
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="3" md="6" sm="6">
                                                                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md="12" xs="12">
                                                                                    <div className="numbers text-center">
                                                                                        <CardTitle tag="p">
                                                                                            {this.state.prefix} {this.state.AvgCold} {this.state.suffix}
                                                                                        </CardTitle>
                                                                                        <p />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <CardFooter>
                                                                            <hr />
                                                                            <div className="stats text-center">
                                                                                {this.state.checkboxvalue == 'Cost' ? <i className="fas fa-snowflake text-warning" /> : <i className="fas fa-snowflake text-warning" />} Averge
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="3" md="6" sm="6">
                                                                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md="12" xs="12">
                                                                                    <div className="numbers text-center">
                                                                                        <CardTitle tag="p">
                                                                                            {this.state.prefix} {this.state.CurrentTemp} {this.state.suffix}
                                                                                        </CardTitle>
                                                                                        <p />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <CardFooter>
                                                                            <hr />
                                                                            <div className="stats text-center">
                                                                                {this.state.checkboxvalue == 'Cost' ? <i className="fas fa-snowflake text-warning" /> : <i className="fas fa-snowflake text-warning" />} Current Temperature
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row> */}
                                                    <hr />
                                                    <FieldGroup
                                                        control={this.TempRHLogForm}
                                                        strict={false}
                                                        render={({ get, invalid, value }) => (
                                                            <form>
                                                                <Row>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="Start Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="End Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="Start Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="End Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                disabled={invalid}
                                                                                // ref={this.button}
                                                                                id="button"
                                                                                ref={this.buttonRef}
                                                                                onClick={(event) => this.displayTempRHSubmit(event, this.TempRHLogForm.getRawValue())}
                                                                            >
                                                                                Display
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                disabled={invalid}
                                                                                onClick={(event) => this.downloadTempRHSubmit(event, this.TempRHLogForm.getRawValue())}
                                                                            >
                                                                                Download
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </form>
                                                        )}
                                                    />
                                                    <div style={{ width: "100%" }}>
                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <table id="temprhtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Created At</th>
                                                                        <th>Board Name</th>
                                                                        <th>Temperature</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.temprhtable.map((value, index) => (
                                                                        <tr key={index + 1}>
                                                                            <td>{value.created_at}</td>
                                                                            <td>{value.board_name}</td>
                                                                            <td>{value.temperature}°C</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                            <Dialog fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.Modalopen}>
                                                <DialogTitle id="customized-dialog-title">
                                                    Set Limits for the cold storage
                                                </DialogTitle>
                                                <DialogContent dividers>
                                                    <div style={{ textAlign: "center" }}>

                                                        <Row>
                                                            <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                                <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                        <Grid item>
                                                                            <TextField type="number" helperText="Enter between -100 to +100" required fullWidth variant="outlined" label="Upper Limit" value={this.state.upperLimit} onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()} onChange={(e) => { this.upperlimitregex(e) }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={this.state.upperLimitBtn}
                                                                        onClick={(event) => this.handleUpperLimitSubmit(event)}
                                                                    >
                                                                        Set upper limit
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                                <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                        <Grid item>
                                                                            <TextField type="number" helperText="Enter between -100 to +100" required fullWidth variant="outlined" label="Lower Limit" value={this.state.lowerLimit} onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()} onChange={(e) => { this.lowerlimitregex(e) }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={this.state.lowerLimitBtn}
                                                                        onClick={(event) => this.handleLowerLimitSubmit(event)}
                                                                    >
                                                                        Set lower limit
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                                <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                        <Grid item>
                                                                            <TextField fullWidth
                                                                                select
                                                                                label="Select Bypass Status"
                                                                                variant="outlined"
                                                                                onChange={(e) => this.setState({ selectedBypassValue: e.target.value })}
                                                                                value={this.state.selectedBypassValue}
                                                                            >
                                                                                {this.state.bypassStatusList.map(option => (
                                                                                    <MenuItem
                                                                                        className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                                        key={option.id}
                                                                                        value={option.id}
                                                                                    >
                                                                                        {option.value}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={(event) => this.handleBypassSubmit(event)}
                                                                    >
                                                                        Set bypass status
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button variant="contained" color="danger" onClick={(e) => this.handleClose(e)}>
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(ColdStorage);
