import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {v3_server_check} from "../routes"

var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);

class History extends React.Component {
    constructor(props) {
        super();
        let daysOfYear = [];
        let getyear = new Date();
		let s = getyear.getFullYear();
		let t = getyear.getFullYear() - 10;
		for (let d = t; d <= s; d++) {
			daysOfYear.push(d);
        }
        this.daysOfYear = daysOfYear
		this.currentyear = getyear.getFullYear().toString();
        this.state = {
            loader: false,
            charttype: 'column',
            checkboxvalue:  'kWh',
            period: 'MONTHLY',
            prefix: '',
            suffix: '',
            fillAlphas: 1,
            graphtype: 'column',
            belowtable: [],
            chartdata: [],
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1,
            belowtableuom: 'kWh', 
            showcontent: false,            
            yearrange: [
                { 'id': 1, "itemName": this.daysOfYear[10] },
                { 'id': 2, "itemName": this.daysOfYear[9] },
                { 'id': 3, 'itemName': this.daysOfYear[8] },
                { 'id': 4, 'itemName': this.daysOfYear[7] },
                { 'id': 5, 'itemName': this.daysOfYear[6] },
                { 'id': 6, "itemName": this.daysOfYear[5] },
                { 'id': 7, 'itemName': this.daysOfYear[4] },
                { 'id': 8, "itemName": this.daysOfYear[3] },
                { 'id': 9, 'itemName': this.daysOfYear[2] },
                { 'id': 10, 'itemName': this.daysOfYear[1] },
                { 'id': 11, 'itemName': this.daysOfYear[0] }
            ],
            selectedyear: this.daysOfYear[10],     
            monthrange: [
                { 'id': 1, "itemName": 'Jan ' + this.currentyear },
                { 'id': 2, 'itemName': 'Feb ' + this.currentyear },
                { 'id': 3, 'itemName': "Mar " + this.currentyear },
                { 'id': 4, "itemName": 'Apr ' + this.currentyear },
                { 'id': 5, 'itemName': 'May ' + this.currentyear },
                { 'id': 6, 'itemName': 'Jun ' + this.currentyear },
                { 'id': 7, 'itemName': 'Jul ' + this.currentyear },
                { 'id': 8, "itemName": "Aug " + this.currentyear },
                { 'id': 9, "itemName": "Sep " + this.currentyear },
                { 'id': 10, "itemName": "Oct " + this.currentyear },
                { 'id': 11, "itemName": "Nov " + this.currentyear },
                { 'id': 12, 'itemName': 'Dec ' + this.currentyear }
            ],
            selectedmonth: ['Jan ' + this.currentyear],
            quarterrange: [
                { 'id': 1, 'itemName': 'Q1 ' + this.currentyear, "value": 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear },
                { 'id': 2, "itemName": "Q2 " + this.currentyear, "value": "Apr " + this.currentyear + "," + 'May ' + this.currentyear + ',' + 'Jun ' + this.currentyear },
                { 'id': 3, 'itemName': "Q3 " + this.currentyear, "value": "Jul " + this.currentyear + "," + 'Aug ' + this.currentyear + "," + 'Sep ' + this.currentyear },
                { 'id': 4, "itemName": 'Q4 ' + this.currentyear, 'value': "Oct " + this.currentyear + ',' + "Nov " + this.currentyear + ',' + 'Dec ' + this.currentyear },
            ],
            selectedquarter: 'Jan ' + this.currentyear + "," + "Feb " + this.currentyear + "," + 'Mar ' + this.currentyear,
        }
        

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = ['Jan ' + this.currentyear];
        this.graphtype = 'column'
        this.fillAlphas= 1
        this.historyconfig = {}
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/history')
        if (localStorage.getItem('selectedboard')) {
            // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            this.setState({
                showcontent: true
            })
        }
        if (localStorage.getItem('currency') == 'INR') {
            this.setState({suffix : localStorage.getItem('currency')})
        } else {
            this.setState({prefix : '$'})
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        if (this.state.period == 'MONTHLY') {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        } else if (this.state.period == 'QUARTERLY') {
            this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
        } else if (this.state.period == 'YEARLY') {
            this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
        } else {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        }
        this.setState({
            showcontent: true
        })
    }

    periodhandleChange(e) {
        e.preventDefault();
        this.setState({
            period: e.target.value
        })
    }

    uomhandleChange(e) {
        e.preventDefault();
        this.setState({
            checkboxvalue: e.target.value
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
      }

    charthandleChange(e) {
        e.preventDefault();
        if (e.target.value == 'line') {
            this.setState({
                charttype: 'line',
                fillAlphas: 0
            })
            this.graphtype = 'line'
            this.fillAlphas= 0
        } else {
            this.setState({
                charttype: 'column',
                fillAlphas: 1
            })
            this.graphtype = 'column'
            this.fillAlphas= 1
        }
        this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
    }

    rangehandleChange(range) {
        if (this.state.period == 'MONTHLY') {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i] == range) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj != range)
            } else {
                this.pri_list.push(range)
            }
            this.setState({
                selectedmonth: this.pri_list
            })
        } else if (this.state.period == 'QUARTERLY') {
            this.setState({
                selectedquarter: range
            })
        } else if (this.state.period == 'YEARLY') {
            this.setState({
                selectedyear: range
            })
        } else {
            this.setState({
                selectedmonth: this.pri_list
            })
        }
    }

    handleSubmit() {
        this.setState({
            belowtableuom: this.state.checkboxvalue,
            currentbelowtableyear: this.currentyear,
            previousbelowtableyear: this.currentyear - 1
        })
        if (this.state.period == 'MONTHLY') {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'QUARTERLY') {
            this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        } else if (this.state.period == 'YEARLY') {
            this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
            this.setState({
                currentbelowtableyear: this.state.selectedyear,
                previousbelowtableyear: this.state.selectedyear - 1
            })
        } else {
            this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        }
    }

    fetchdata(period,range,checkboxvalue,board_id) {
        if (board_id === '' || board_id === undefined || board_id == null) {
            apiService.Alerts_info("Board needed")  
        } else {
            if(v3_server_check()==true){
                this.chart_data_v3(period,range,checkboxvalue,board_id)
          
              }else{
               this.chart_data_v2(period,range,checkboxvalue,board_id)
              }
        } 
    } 
    chart_data_v2(period,range,checkboxvalue,board_id){
        this.setState({ loader: true })
        apiService.Historychartdata(period,range,checkboxvalue,board_id).then(response => {
            if (this.state.checkboxvalue === 'kWh') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kwh'],
                    chartdata: response.data.meter_data[0]['kwh']
                })
            } else if (this.state.checkboxvalue == 'kW') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kw'],
                    chartdata: response.data.meter_data[0]['kw']
                })
            } else if (this.state.checkboxvalue == 'kVA') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kva'],
                    chartdata: response.data.meter_data[0]['kva']
                })
            } else if (this.state.checkboxvalue == 'Cost') {
                this.setState({
                    belowtable: response.data.meter_data[0]['cost'],
                    chartdata: response.data.meter_data[0]['cost']
                })
            } else {
                this.setState({
                    belowtable: response.data.meter_data[0]['kwh'],
                    chartdata: response.data.meter_data[0]['kwh']
                })
            }
            this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
            this.setState({
                loader: false,
            }) 
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    chart_data_v3(period,range,checkboxvalue,board_id){
        this.setState({ loader: true })
        apiService.newHistorychartdata(period,range,checkboxvalue,board_id).then(response => {
            if (this.state.checkboxvalue == 'kWh') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kWh'],
                    chartdata: response.data.meter_data[0]['kWh']
                })
            } else if (this.state.checkboxvalue == 'kW') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kW'],
                    chartdata: response.data.meter_data[0]['kW']
                })
            } else if (this.state.checkboxvalue == 'kVA') {
                this.setState({
                    belowtable: response.data.meter_data[0]['kVA'],
                    chartdata: response.data.meter_data[0]['kVA']
                })
            } else if (this.state.checkboxvalue == 'Cost') {
                this.setState({
                    belowtable: response.data.meter_data[0]['Cost'],
                    chartdata: response.data.meter_data[0]['Cost']
                })
            } else {
                this.setState({
                    belowtable: response.data.meter_data[0]['kWh'],
                    chartdata: response.data.meter_data[0]['kWh']
                })
            }
            this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
            this.setstate({
                loader: false,
            }) 
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    createchart(chartdata,belowtableuom,theme) {
        this.historyconfig = AmCharts.makeChart("historychart",{
            'type': 'serial',
            'theme': theme,
            'hideCredits': true,
			'legend': {
				'horizontalGap': 10,
				'maxColumns': 2,
				'position': 'top',
				'markerSize': 10,
				"data": [{
					'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
					"color": "#f44336"
				}, {
					'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',

					"color": "#9c27b0"
				}]
			},
			'categoryField': 'category',
			'startDuration': 1,
			'categoryAxis': {
				'gridPosition': 'start',
				'position': 'left'
			},
			'trendLines': [],
			'graphs': [
				{
					'balloonText': 'Previous:[[value]] ' + belowtableuom,
					'fillColors': '#f44336',
					'fillAlphas': this.fillAlphas,
					'id': 'AmGraph-2',
                    'lineAlpha': 1,
                    'lineColor': '#f44336',
					'title': 'Previous Year ' + '(' + this.state.previousbelowtableyear + ')',
					'type': this.graphtype,
					'valueField': 'previous_data'
				},
				{
					'balloonText': 'Current:[[value]] ' + belowtableuom,
					'fillColors': '#9c27b0',
					'fillAlphas': this.fillAlphas,
					'id': 'AmGraph-1',
                    'lineAlpha': 1,
                    'lineColor': '#9c27b0',
					'title': 'Current Year ' + '(' + this.state.currentbelowtableyear + ')',
					'type': this.graphtype,
					'valueField': 'current_data'
				}
			],
			'guides': [],
			'valueAxes': [
				{
					'id': 'ValueAxis-1',
					'position': 'bottom',
					'axisAlpha': 0,
					"title": belowtableuom
				}
			],
			'allLabels': [],
			'balloon': {},
			'titles': belowtableuom,
            'dataProvider': chartdata,
            "responsive": {
                "enabled": true
            },
			'export': {
				'enabled': true
			}
        })
    }

    render() {
  
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ? 
                <>
                <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                    <CardTitle tag="h5"></CardTitle>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <Row>
                                <Col className="pr-1" md="3">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Period</InputLabel>  
                                                <Select
                                                    labelId="demo-simple-select-outlined-label1"
                                                    id="demo-simple-select-outlined1"
                                                    label="Period"
                                                    fullWidth variant="outlined"
                                                    onChange={(e) => this.periodhandleChange(e)}
                                                    value={this.state.period}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'MONTHLY'}>Monthly</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'QUARTERLY'}>Quarterly</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'YEARLY'}>Yearly</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-mutiple-name-label">Range</InputLabel>
                                                {this.state.period == 'MONTHLY' ?
                                                    <div>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Select
                                                            labelId="demo-mutiple-name-label"
                                                            id="demo-mutiple-name"
                                                            multiple variant="outlined" fullWidth
                                                            value={this.state.selectedmonth}
                                                            label="Range"
                                                            >
                                                            {this.state.monthrange.map((option) => (
                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                                {option.itemName}
                                                                </MenuItem>
                                                            ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                        
                                                : 
                                                this.state.period == 'QUARTERLY' ? 
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label4"
                                                        id="demo-simple-select-outlined4"
                                                        label="Range"
                                                        fullWidth variant="outlined"
                                                        value={this.state.selectedquarter}
                                                        >
                                                        {this.state.quarterrange.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.value} onClick={(e) => this.rangehandleChange(option.value)}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                : this.state.period == 'YEARLY' ?
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label4"
                                                        id="demo-simple-select-outlined4"
                                                        label="Range"
                                                        fullWidth variant="outlined"
                                                        value={this.state.selectedyear}
                                                        >
                                                        {this.state.yearrange.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.itemName} value={option.itemName} onClick={(e) => this.rangehandleChange(option.itemName)}>
                                                            {option.itemName}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                :
                                                ''}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="2">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item> 
                                                <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                                <Select
                                                    labelId="demo-simple-select-outlined-label2"
                                                    id="demo-simple-select-outlined2"
                                                    label="Units"
                                                    fullWidth variant="outlined"
                                                    onChange={(e) => this.uomhandleChange(e)}
                                                    value={this.state.checkboxvalue}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kWh'}>kWh</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kW'}>kW</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'kVA'}>kVA</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'Cost'}>Cost</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="2">
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label3"
                                                    id="demo-simple-select-outlined3"
                                                    fullWidth variant="outlined"
                                                    label="Charts"
                                                    onChange={(e) => this.charthandleChange(e)}
                                                    value={this.state.charttype}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                                <Col className="pr-1" md="2">
                                    <div style={{paddingTop: '0.8em'}}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.handleSubmit()}
                                                >
                                                Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <hr />
                        <div id="historychart" style={{ width: "100%", height: "500px" }} options={this.historyconfig} />
                        <hr />
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <table id="historybelowtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Previous Year ({this.state.previousbelowtableyear}) {this.state.belowtableuom}</th>
                                        <th>Current Year ({this.state.currentbelowtableyear}) {this.state.belowtableuom}</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.belowtable.map((value, index) => (
                                    <tr key={index+1}>
                                        <td>{value.category}</td>
                                        <td>{value.previous_data}</td>
                                        <td>{value.current_data}</td>  
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                </Col>
              </Row>
                </>
            :
                <Row>
                    <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader> 
                        </CardHeader>
                        <CardBody>
                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            }
              
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(History);
