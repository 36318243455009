import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,
} from "reactstrap";
import classnames from 'classnames';
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables.js";
import jsZip from 'jszip';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import $ from 'jquery';
import "../assets/css/auth.css"



// This line was the one missing
window.JSZip = jsZip;

// const $ = require('jquery');
$.DataTable = require('datatables.net');

class Datamanagement extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            showcontent: false,
            readingtotal:'',
            first_reading_time:'',
            latest_reading_time:'' ,
            activetab:"1",
            isRecord : false
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.resetReadingForm = this.resetReadingForm.bind(this)
        this.resetRecordForm = this.resetRecordForm.bind(this)
        this.resetRecordId = this.resetRecordId.bind(this)
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('is_admin') == 'true') {
            this.props.history.push('/neurobotz/datamanagement')

            this.convertdate(new Date())
        } else {
            this.props.history.push('/neurobotz/dashboard')
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    //----------------offset Tab form----------------------------------------------
    dateForm = FormBuilder.group({
        expiry_date: ["", [Validators.required]],
    });

    offsetForm = FormBuilder.group({
        offset_percent: ["", [Validators.required]],
    });

    readingForm = FormBuilder.group({
        first_reading_id: ["", [Validators.required]],
        first_reading: ["", [Validators.required]],
        first_reading_update: false,
        latest_reading_id: ["", [Validators.required]],
        latest_reading: ["", [Validators.required]],
        latest_reading_update: false,
    });
    //-------------------------------------------------------------------------------

    //-------------------record Tab Form---------------------------------------------
    recordForm = FormBuilder.group({
        record_id:["", [Validators.required]]
    })

    recordDetailsForm = FormBuilder.group({
        record_id:[{value: ''}, [Validators.required]],
        created_at:[{value: ''},[Validators.required]],
        board_total_active_energy: ["", [Validators.required]],
        total_active_energy:["", [Validators.required]],
        r_phase_active_power:["", [Validators.required]],
        y_phase_active_power:["", [Validators.required]],
        b_phase_active_power:["", [Validators.required]],

        r_phase_current:["", [Validators.required]],
        y_phase_current:["", [Validators.required]],
        b_phase_current:["", [Validators.required]],

    })
    //---------------------------------------------------------------------------------

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        setTimeout(() => {this.convertdate(new Date())}, 1000);        
        this.setState({
          showcontent: false
        })
        this.resetRecordId()
        this.resetRecordForm()
    }

    boardhandler(board_id) {
        this.selectedboard = board_id
        setTimeout(() => {this.convertdate(new Date())}, 1000);
        this.setState({
          showcontent: true
        })
        this.resetRecordId()
        this.resetRecordForm()
      }

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.dateForm.patchValue({
            expiry_date:this.today
        })
        this.readingForm.controls['first_reading'].disable();
        this.readingForm.controls['latest_reading'].disable();
        this.getReadingsData(this.today)
        this.getOffsetReadingsData()

        //Tab2
        this.recordDetailsForm.disable();
    }

    getOffsetReadingsData(){
        this.setState({ loader: true })
        apiService.getoffsetReadings().then(response => {
            const data = response.data
            this.setState({ 
                loader: false,
            })
            this.offsetForm.patchValue({
                offset_percent:data.offset_percent
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getReadingsData(today){
        this.resetReadingForm()
        this.setState({ loader: true })
        apiService.getReadings(today).then(response => {
            const data = response.data
            this.setState({ 
                loader: false,
                readingtotal: data[0]['total'],
                first_reading_time: data[0]['first_reading_timestamp'],
                latest_reading_time: data[0]['latest_reading_timestamp']
            })
            this.readingForm.patchValue({
                first_reading_id: data[0]['first_reading_id'],
                first_reading: data[0]['first_reading'],
                latest_reading_id: data[0]['latest_reading_id'],
                latest_reading: data[0]['latest_reading'],
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    resetReadingForm = () => {
        this.readingForm.patchValue({
            first_reading_id: '',
            first_reading: '',
            first_reading_update: false,
            latest_reading_id: '',
            latest_reading: '',
            latest_reading_update: false,
        })
        this.setState({
            readingtotal:'',
            first_reading_time:'',
            latest_reading_time:''
        })
    }

    handleOffsetSubmit = (e,value) => {
        e.preventDefault()
        console.log(value)
        this.setState({ loader: true })
        apiService.offsetReadings(value).then(response => {
            this.setState({ 
                loader: false,
            })
            apiService.Alerts("Offset Updated Successfully")
            this.getOffsetReadingsData()
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleSubmit = (e,value) =>{
        e.preventDefault()
        this.getReadingsData(value.expiry_date)
    }

    handleSubmitReading = (e,value) =>{
        e.preventDefault()
        this.setState({ loader: true })
        apiService.editReadings(value).then(response => {
            this.setState({ 
                loader: false,
                readingtotal: response.data[0]['total']
            })
            apiService.Alerts("Readings Updated Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    can_active(e) {
        if (e == false) {
            this.readingForm.patchValue({
                first_reading_update: true
            })
            this.readingForm.controls['first_reading'].enable();
        } else {
            this.readingForm.patchValue({
                first_reading_update: false
            })
            this.readingForm.controls['first_reading'].disable();
        }
    }

    can_active_latest(e) {
        if (e == false) {
            this.readingForm.patchValue({
                latest_reading_update: true
            })
            this.readingForm.controls['latest_reading'].enable();
        } else {
            this.readingForm.patchValue({
                latest_reading_update: false
            })
            this.readingForm.controls['latest_reading'].disable();
        }
    }

    toggle(option) {

        this.setState({
          activetab: option
        })
    }

    //----------------------------Tab2--------------------------------------------

    handleRecordSubmit = (e,value) => {
        e.preventDefault()
        this.setState({ loader: true })
        this.resetRecordForm()
        apiService.getRecord(value).then(response => {
            var data = response.data[0]
            this.recordDetailsForm.patchValue({
                record_id: data.id,
                created_at:data.created_at,
                board_total_active_energy: data.board_total_active_energy,
                total_active_energy:data.total_active_energy,
                r_phase_active_power:data.r_phase_active_power,
                y_phase_active_power:data.y_phase_active_power,
                b_phase_active_power:data.b_phase_active_power,
                r_phase_current:data.r_phase_current,
                y_phase_current:data.y_phase_current,
                b_phase_current:data.b_phase_current,
            })
            this.recordDetailsForm.enable();
            this.setState({ 
                loader: false,
                isRecord: true,
            })
            apiService.Alerts("Record Retrived Successfully")
        }).catch(error => {
            this.setState({ 
                loader: false,
                isRecord:false
            })
            apiService.ErrorMessage(error)
        })
    }

    handleRecordDetailsSubmit = (e,value) => {
        e.preventDefault()
        swal({
            title: "Are you sure?",
            text: "Pleaase Confirm before updating the Record Details",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                // event.preventDefault();
                this.setState({ loader: true })
                apiService.updateRecord(value).then(response => {
                    var data = response.data[1].board_list
                    this.recordDetailsForm.patchValue({
                        record_id: data.id,
                        created_at:data.created_at,
                        board_total_active_energy: data.board_total_active_energy,
                        total_active_energy:data.total_active_energy,
                        r_phase_active_power:data.r_phase_active_power,
                        y_phase_active_power:data.y_phase_active_power,
                        b_phase_active_power:data.b_phase_active_power,
                        r_phase_current:data.r_phase_current,
                        y_phase_current:data.y_phase_current,
                        b_phase_current:data.b_phase_current,
                    })
                    this.setState({ 
                        loader: false,
                        isRecord: true,
                    })
                    apiService.Alerts("Record Updated Successfully")
                }).catch(error => {
                    this.setState({ 
                        loader: false,
                        isRecord:false 
                    })
                    apiService.ErrorMessage(error)
                })
            } else {
                // swal("Your file is safe!");
            }
        });  
    }

    handleRecordDetailsDelete = (e) =>{
        e.preventDefault()
        var recordId = this.recordForm.getRawValue()
        swal({
            title: "Are you sure?",
            text: "Pleaase Confirm before deleting the Record Details",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                // event.preventDefault();
                this.setState({ loader: true })
                apiService.deleteRecord(recordId.record_id).then(response => {
                    this.resetRecordForm()
                    this.setState({ 
                        loader: false,
                        isRecord: false,
                    })
                    apiService.Alerts("Record Deleted Successfully")
                }).catch(error => {
                    this.setState({ 
                        loader: false,
                        isRecord:true 
                    })
                    apiService.ErrorMessage(error)
                })
        
            } else {
                // swal("Your file is safe!");
            }
        });
    }

    resetRecordId(){
        this.recordForm.patchValue({
            record_id: "",
        })
    }

    resetRecordForm(){
        this.recordDetailsForm.patchValue({
            record_id: "",
            created_at:"",
            board_total_active_energy: "",
            total_active_energy:"",
            r_phase_active_power:"",
            y_phase_active_power:"",
            b_phase_active_power:"",
            r_phase_current:"",
            y_phase_current:"",
            b_phase_current:"",
        })
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ?
            <div>
            <Row>
                <Col md="12">
                    <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                            <CardTitle tag="h5">
                            <Nav tabs>
                                <NavItem>
                                <NavLink style={{fontSize:18}}
                                    className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Set Meter Offset
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink style={{fontSize:18}}
                                    className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    Record Correction


                                    </NavLink>
                                </NavItem>
                            </Nav>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={this.state.activetab}>
                                <TabPane tabId="1">
                                    <Row className="pt-2">
                                        <Col className="pr-1" md="12">
                                            <h3 className="mb-0">Set Meter Offset % (Enter negative value for decrease)</h3>
                                        </Col>
                                    </Row>
                                    <FieldGroup
                                        control={this.offsetForm}
                                        strict= {false}
                                        render={({ get, invalid, value }) => (
                                        <form>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="offset_percent"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Offset" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Offset is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2" md="3">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={invalid}
                                                        onClick={(event) => this.handleOffsetSubmit(event,this.offsetForm.getRawValue())}
                                                        >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        )} 
                                    />
                                     <hr></hr>
                                    <Row className="pt-2">
                                        <Col className="pr-1" md="6">
                                            <h3 className="mb-0">Meter Reading Correction</h3>
                                        </Col>
                                    </Row>
                                    <FieldGroup
                                        control={this.dateForm}
                                        strict= {false}
                                        render={({ get, invalid, value }) => (
                                        <form>
                                            <Row>
                                                <Col className="pr-1 pt-2" md="3">
                                                    <FieldControl
                                                        name="expiry_date"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="date" required fullWidth variant="outlined" label="Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Date is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2" md="3">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={invalid}
                                                        onClick={(event) => this.handleSubmit(event,this.dateForm.getRawValue())}
                                                        >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        )} 
                                    /> 
                                    <FieldGroup
                                        control={this.readingForm}
                                        strict= {false}
                                        render={({ get, invalid, value }) => (
                                        <form>
                                            <Row className="pt-2">
                                                <Col className="pr-1" md="3">
                                                    <FieldControl
                                                        name="first_reading"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Starting Reading" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Starting Reading is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1" md="1">
                                                    <FieldControl
                                                        name="first_reading_update"
                                                        strict= {false}
                                                        render={({ handler, touched }) => (
                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <Grid className="Formfieldcss" container spacing={1} alignItems="flex-start">
                                                                <Grid item>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={this.readingForm.getRawValue().first_reading_update} value="active"  color="primary" onClick={(event) => this.can_active(this.readingForm.getRawValue().first_reading_update)} />}
                                                                    
                                                                />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1" md="3">
                                                    <h4 className="mt-2"> {this.state.first_reading_time}</h4>
                                                </Col>
                                            </Row>
                                            <Row className="pt-2">
                                                <Col className="pr-1" md="3">
                                                    <FieldControl
                                                        name="latest_reading"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Latest Reading" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Ending Reading is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1" md="1">
                                                    <FieldControl
                                                        name="latest_reading_update"
                                                        strict= {false}
                                                        render={({ handler, touched }) => (
                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                                                <Grid item>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={this.readingForm.getRawValue().latest_reading_update} value="active"  color="primary" onClick={(event) => this.can_active_latest(this.readingForm.getRawValue().latest_reading_update)} />}
                                                                    
                                                                />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1" md="3">
                                                    <h4 className="mt-2"> {this.state.latest_reading_time}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <h4 className="mb-0">Total : {this.state.readingtotal} kWh</h4>
                                                </Col>
                                            </Row>
                                            <Row className="pt-2">
                                                <Col className="pr-1" md="3">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={invalid}
                                                        onClick={(event) => this.handleSubmitReading(event,this.readingForm.getRawValue())}
                                                        >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        )} 
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row className="pt-2">
                                        <Col className="pr-1" md="12">
                                            <h3 className="mb-0">Enter the record ID from the logs</h3>
                                        </Col>
                                    </Row>
                                    <FieldGroup
                                        control={this.recordForm}
                                        strict= {false}
                                        render={({ get, invalid, value }) => (
                                        <form>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="record_id"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Record Id" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Record Id is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2" md="3">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={invalid}
                                                        onClick={(event) => this.handleRecordSubmit(event,this.recordForm.getRawValue())}
                                                        >
                                                        Get Record
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        )} 
                                    />
                                     <hr></hr>
                                    <Row className="pt-2">
                                        <Col className="pr-1" md="6">
                                            <h3 className="mb-0">Record details</h3>
                                        </Col>
                                    </Row>
                                    <FieldGroup
                                        control={this.recordDetailsForm}
                                        strict= {false}
                                        render={({ get, invalid, value }) => (
                                        <form>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="record_id"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Record Id" error={touched && hasError("required")}  {...handler("text")}  InputProps={{readOnly: true, }}/>
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Record Id is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="created_at"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="text" required fullWidth variant="outlined" label="Created at" error={touched && hasError("required")}  {...handler("text")} InputProps={{readOnly: true, }}/>
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Record Id is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="board_total_active_energy"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Meter Reading" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Record Id is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="total_active_energy"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="kWh" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Record Id is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="r_phase_active_power"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="kW (R Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* kW (R Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="y_phase_active_power"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="kW (Y Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* kW (Y Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="b_phase_active_power"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="kW (B Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* kW (B Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="r_phase_current"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Amp (R Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Amp (R Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="y_phase_current"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Amp (Y Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Amp (Y Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="pr-1 pt-2 pb-2" md="3">
                                                    <FieldControl
                                                        name="b_phase_current"
                                                        strict= {false}
                                                        render={({ handler, touched, hasError }) => (
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="number" required fullWidth variant="outlined" label="Amp (B Phase)" error={touched && hasError("required")}  {...handler("text")} />
                                                                    </Grid>
                                                                    <span className="error">
                                                                        {touched
                                                                            && hasError("required")
                                                                            && `* Amp (B Phase) is required`}
                                                                    </span>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {this.state.isRecord &&
                                                <>
                                                    <Col className="pr-1 pt-2" md="2" sm="6">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={invalid}
                                                            onClick={(event) => this.handleRecordDetailsSubmit(event,this.recordDetailsForm.getRawValue())}
                                                            >
                                                            Update
                                                        </Button>
                                                    </Col>
                                                    <Col className="pr-1 pt-2" md="2" sm="6">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="danger"
                                                            disabled={invalid}
                                                            onClick={(e) => this.handleRecordDetailsDelete(e)}
                                                            >
                                                            Delete
                                                        </Button>
                                                    </Col>
                                                </>
                                                }
                                                
                                            </Row>
                                        </form>
                                        )} 
                                    />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
            </div>
            :
            <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            }
        </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor 
    }
  }

export default connect(mapStateToProps)(Datamanagement);