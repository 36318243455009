import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Button
} from "reactstrap";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import {chartPhase} from "../routes"
import {initialRoute} from "../routes"
import {v3_server_check} from "../routes"
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);


class Carbon extends React.Component {
  constructor(props) {
    super();
    this.today = new Date(new Date().toDateString());
    this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
    this.weekEnd =this.today;
    this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
    this.monthEnd = this.today;
    this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
    this.lastEnd = this.today;
    this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
    this.yearEnd = this.today;
    this.state = {
        uom: 'kWh',
        unit: 'kg/CO2e',
        currency: '',
        currentyear: '',
        currentmonth: '',
        trackusage: [],
        trackusagecost: [],
        prefix: '',
        suffix: '',
        loader: false,
        high: '',
        low: '',
        average: '',
        total: '',
        final_chart_data: [],           
        defaultrange : [this.today,this.today],
        chart_data: [],
        processed_data: [],
        graphtype : 'line',
        charttype: 'line',
        carbon7days:[],
        carbon7daystotal:'',
        carbonyesterday:[],
        carbonyesterdaytotal:'',
        energy_so_far_today : '',
        energy_currently_consumping : '',
        energy_7_day_average : '',
        cost_so_far_today : '',
        cost_currently_consumping : '',
        cost_7_day_average : '',
        showcontent: false, 
        dial_data: [],
        total_dial_data:'',  
        from_date: '',
        to_date: '',
        total_consumption : '',
        total_cost : ''
    }
    this.finalchartdata = []
    this.graphtype = 'line'
    this.customerhandler = this.customerhandler.bind(this)
    this.boardhandler = this.boardhandler.bind(this)
    // this.newtest = this.newtest.bind(this);
    this.selectedcustomer = ''
    this.selectedboard = '' 
    this.trackusageconfig = {}
    this.trackusagecostconfig = {}
    this.today = new Date(new Date().toDateString());
    this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
    this.monthEnd = this.today;
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      // this.props.history.push("dashboard")
      const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
      ];
      this.setState({currency: localStorage.getItem('currency'), currentyear: 1900 + new Date().getYear(), currentmonth: monthNames[new Date().getMonth()]});
      if (localStorage.getItem('selectedboard')) {
        this.setState({
          showcontent: true
        })
      }
      this.currency_selected()
      this.convertdate(this.monthStart,this.monthEnd)
     
    } else {
      this.props.history.push('/neurobotz/login')
    }
  }
  currency_selected(){
    var x=localStorage.getItem('currency')
    this.setState({suffix : x,currency:x})

  }

  dialForm = FormBuilder.group({
    from_date: ["", [Validators.required]],
    to_date: ["", [Validators.required]],
  });

  realtimeForm = FormBuilder.group({
    daterange: [""],
    chart_type: ["line"],
});

  convertdate(monthStart,monthEnd) {
    const sy = monthStart.getFullYear();
    const sm = ("0" + (monthStart.getMonth() + 1)).slice(-2);
    const sd = ("0" + monthStart.getDate()).slice(-2);
    const ey = monthEnd.getFullYear();
    const em = ("0" + (monthEnd.getMonth() + 1)).slice(-2);
    const ed = ("0" + monthEnd.getDate()).slice(-2);
    var from_date = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    var to_date = ey.toString() + '-' + em.toString() + '-' + ed.toString();
    this.dialForm.patchValue({
      from_date: from_date,
      to_date: to_date
    })
    this.setState({
      from_date: from_date,
      to_date: to_date
    })
  } 

  getdialresponse(board_id,from_date,to_date) {
      this.dial_v2(board_id,from_date,to_date)
  }
  dial_v2(board_id,from_date,to_date){
    this.setState({loader:true})
    apiService.CarbonNewDashboarddial(board_id,from_date,to_date).then(response => {
      console.log(response.data)
      for (let i = 0 ; i <= response.data.dial_data.length-1 ; i++ ) {
        var lastStr = response.data.dial_data[i]['date'].split("-").splice(-1)[0];
        var lastButStr= response.data.dial_data[i]['date'].split("-").splice(-2)[0];
        response.data.dial_data[i]['new_category'] = lastButStr + '-' + lastStr
      }
      var finalResult = response.data.dial_data.map(({totalcost,...rest}) => ({...rest}));
      this.createchart1(finalResult,this.props.theme);
      this.setState({ loader:false, dial_data: finalResult, total_dial_data:response.data.sum[0].total_value })        
    }).catch(error => {
        this.setState({loader:false})
        apiService.ErrorMessage(error)
    })
  }

  getcardboxesresponse(board_id) {
    this.card_data_v2(board_id)
    // this.card_data_v3(board_id)
    this.carbonPieChart(board_id)
  }
card_data_v2(board_id){
  // this.setState({loader:true})
  apiService.CarbonDashboardcardboxes(board_id).then(response => {
      this.setState({
        energy_7_day_average: response.data.dashboard_data[0]['kwh']['kwh_30days_avg'],
        energy_so_far_today: response.data.dashboard_data[0]['kwh']['kwh_so_far'],
        energy_currently_consumping: response.data.dashboard_data[0]['currently_consuming'],
        // loader:false
      })
  }).catch(error => {
      apiService.ErrorMessage(error)
      // this.setState({loader:false})
  })
}

carbonPieChart(board_id){
  // this.setState({loader:true})
  apiService.CarbonNewDashboardcardboxes(board_id).then(response => {
    this.setState({
      carbonyesterday: response.data[0].previous_kwh_data,
      carbon7days:response.data[0].last7days_kwh_data,
      carbonyesterdaytotal: response.data[0].previous_kwh_data[0].value.toFixed(),
      carbon7daystotal:response.data[0].last7days_kwh_data[0].value.toFixed(),
      // loader:false
    },() => {
      this.createpiechart1(this.state.carbonyesterday,this.props.theme)
      this.createpiechart2(this.state.carbon7days,this.props.theme)
    })
  }).catch(error => {
      // this.setState({loader:false})
      apiService.ErrorMessage(error)
  })
}

  componentDidUpdate(prevProps) {
    if (prevProps.theme != this.props.theme) {
      this.createchart1(this.state.dial_data,this.props.theme);  
      this.createpiechart1(this.state.checkedA,this.state.carbonyesterday,this.state.costyesterday,this.props.theme);
      this.createpiechart2(this.state.checkedB,this.state.carbon7days,this.state.cost7days,this.props.theme);
    }
  }

  createchart1(dial_data,theme) {
    if (theme == 'darktheme' || theme == 'greytheme') {
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.unuseTheme(am4themes_dark);
    } 
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.logo.disabled = true;
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
chart.exporting.menu.verticalAlign = "top";
    // will use this to store colors of the same items
    var colors = {};
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "new_category";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataItems.template.text = "{new_category}";
    categoryAxis.adapter.add("tooltipText", function(tooltipText, target){
      return categoryAxis.tooltipDataItem.dataContext.new_category;
    })
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = this.state.unit;
    valueAxis.tooltip.disabled = true;
    valueAxis.min = 0;
    // single column series for all data
    var columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.columns.template.width = am4core.percent(80);
    columnSeries.tooltipText = "{new_category}: {valueY} kg/CO2e";
    columnSeries.dataFields.categoryX = "new_category";
    columnSeries.dataFields.valueY = "total";
    // second value axis for quantity
    var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = this.state.prefix + " Cost " + this.state.suffix;
    // valueAxis2.renderer.opposite = true;
    // valueAxis2.syncWithAxis = valueAxis;
    // valueAxis2.tooltip.disabled = true;
    // quantity line series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.tooltipText = "{new_category}:" + this.state.prefix + " {valueY} " + this.state.suffix;
    lineSeries.dataFields.categoryX = "new_category";
    lineSeries.dataFields.valueY = "totalcost";
    lineSeries.yAxis = valueAxis2;
    var bullets = lineSeries.bullets.push(new am4charts.CircleBullet());
    lineSeries.stroke = am4core.color("black");
    lineSeries.fill = lineSeries.stroke;
    lineSeries.strokeWidth = 2;
    bullets.circle.radius = 2;
    lineSeries.snapTooltip = true;
    lineSeries.events.on("datavalidated", function(){
     lineSeries.dataItems.each(function(dataItem){
       if(dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)){
       dataItem.setLocation("categoryX", 0);
       }
       else{
        dataItem.setLocation("categoryX", 0.5);
       }
     })
    })
    columnSeries.columns.template.adapter.add("fill", function(fill, target) {
     var name = target.dataItem.dataContext.date;
     if (!colors[name]) {
       colors[name] = chart.colors.next();
     }
     target.stroke = colors[name];
     return colors[name];
    })
    var rangeTemplate = categoryAxis.axisRanges.template;
    rangeTemplate.tick.disabled = false;
    rangeTemplate.tick.location = 0;
    rangeTemplate.tick.strokeOpacity = 0.6;
    rangeTemplate.tick.length = 60;
    rangeTemplate.grid.strokeOpacity = 0.5;
    rangeTemplate.label.tooltip = new am4core.Tooltip();
    rangeTemplate.label.tooltip.dy = -10;
    rangeTemplate.label.cloneTooltip = false;
    chart.data = dial_data;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  customerhandler(customerId) {
    this.selectedcustomer = customerId
    this.setState({
      showcontent: false
    })

  }

  boardhandler(board_id) {
    this.selectedboard = board_id
    this.currency_selected()
    this.getcardboxesresponse(board_id);
    this.getdialresponse(board_id,this.state.from_date,this.state.to_date);
    this.convertdate1(this.state.defaultrange,board_id)
    // this.getrealchartresponse(board_id,this.today);
    // this.getnewrealchartresponse(board_id,this.today)
    this.setState({
      showcontent: true
    })
  }

  filterdial(event,value) {
    event.preventDefault();
    var testdate = new Date(new Date().toDateString())
    const sy = testdate.getFullYear();
    const sm = ("0" + (testdate.getMonth() + 1)).slice(-2);
    const sd = ("0" + testdate.getDate()).slice(-2);
    var today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    if (value.from_date > value.to_date) {
      apiService.Alerts_info('From date must be less than To date')
    } else if (value.from_date > today) {
      apiService.Alerts_info('Future date cannot be accepted')
    } else if (value.to_date > today) {
      apiService.Alerts_info('Future date cannot be accepted')
    } else {
      var date1 = new Date(value.from_date); 
      var date2 = new Date(value.to_date); 
      var Difference_In_Time = date2.getTime() - date1.getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      if (Difference_In_Days > 45) {
        apiService.Alerts_info('Date range should not exceed 45 Days')
      } else {
        this.setState({
          from_date: value.from_date,
          to_date: value.to_date
        })
        this.getdialresponse(this.selectedboard,value.from_date,value.to_date)
      }
    }
    
  }

  convertdate1(e,board_id) {
    const sy = e[0].getFullYear();
    const sm = e[0].getMonth() + 1;
    const sd = e[0].getDate();
    const ey = e[1].getFullYear();
    const em = e[1].getMonth() + 1;
    const ed = e[1].getDate();
    this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
    this.carbonRealtimeChart(this.startdate,this.enddate);
}

  carbonRealtimeChart(startdate,enddate){
    this.setState({loader:true})
    apiService.carbonRealtimeSubmit(startdate,enddate).then(response => {
      this.setState({
          high: response.data.processed_data[0]['kwh'][0]['high'],
          low: response.data.processed_data[0]['kwh'][0]['low'],
          average: response.data.processed_data[0]['kwh'][0]['avg'],
          total: response.data.processed_data[0]['kwh'][0]['total'],
          
          processed_data: response.data.processed_data,
          chart_data: response.data.chart_data,

          loader:false
      })
      this.finalchartdata = response.data.chart_data[0]['kwh']
      this.calcData = response.data.processed_data[0]['kwh']
      this.refinechartdata(this.finalchartdata,'kWh')  
    }).catch(error => {
      this.setState({loader:false})
      apiService.ErrorMessage(error)
    })
  }
  
  datehandleChange(e) {
    this.setState({
        defaultrange: e.target.value
    })
    this.convertdate1(e.target.value,localStorage.getItem('selectedboard'))
  }

  charthandleChange(e) {
    e.preventDefault();
    this.realtimeForm.patchValue({
        chart_type: e.target.value
    })
    this.setState({
        charttype: e.target.value,
    })
    if (e.target.value == 'area' || e.target.value == 'column') {
        this.fillAlphas= 1
        if (e.target.value == 'area') {
            this.graphtype = 'area'
        } else {
            this.graphtype = 'column'
        }
    } else {
        this.graphtype = 'spline'
        this.fillAlphas= 0
    }
    this.refinechartdata(this.finalchartdata, 'kWh')
}

refinechartdata(data,uom) {
  if (uom == 'kWh') {
      var chartdata1 = [];
      var chartdata2 = [];

      for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
          if (tZ === -17.5) {
              h -= -17;
              min -= -30;
          } else {
              h -= tZ;
          }
          var timestamp = new Date(y, m, d, h, min, s)


          var dat = new Date(timestamp);
          // console.log(data[0].time,y, m, d, h, min, s,dat)

          var final = dat.getTime();
          chartdata1.push([final, data[i].value, data[i].board_value])  
          chartdata2.push([final, data[i].board_value])  
      } 
      var var1 = chartdata1
      var var2 = chartdata2
      this.var1 = var1
      this.seriesData = [{
          name: 'Carbon Emission',
          data: var1,
          type: this.graphtype,
          color: '#f44336',
          fillOpacity: 1,
          threshold: null,
          // visible: true
      },
      // {
// 	name: 'Current consumed (kWh)',
// 	data: var2,
// 	type: this.graphtype,
// 	color: '#002DB3',
// 	fillOpacity: 1,
// 	// threshold: null,
// 	// showInNavigator: true,
      //     // visible: false,

//   }
  ]
      this.setState({
          final_chart_data : this.seriesData,
          suffix : this.state.unit, prefix: ''
      })  
     if(this.show==false){
         
      // setTimeout(() => {
      //     this.show=true
      //     var x=document.getElementsByClassName("highcharts-legend-item")
      //     x[x.length-1].onclick()
      // }, 100);
     }
     
  } else if (uom == 'Cost') {
      var chartdata1 = [];
      for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
          if (tZ === -17.5) {
              h -= -17;
              min -= -30;
          } else {
              h -= tZ;
          }
          var timestamp = new Date(y, m, d, h , min, s)
          var dat = new Date(timestamp);
          var final = dat.getTime();
          chartdata1.push([final, data[i].value])
        }
        var var1 = chartdata1
        this.var1 = var1
        this.seriesData = [{
          name: 'Energy Cost ',
          data: var1,
          type: this.graphtype,
          color: '#4CAF50',
          fillOpacity: 1,
          threshold: null,
          // visible: true
        }]
      if (localStorage.getItem('currency') == 'INR') {
          this.setState({suffix : localStorage.getItem('currency'), prefix: ''})
      }
      else if(localStorage.getItem('currency') == 'Rials') {
          this.setState({suffix : localStorage.getItem('currency'), prefix: ''})
      } 
      else {
          this.setState({prefix : '$' , suffix: ''})
      }
        this.setState({
          final_chart_data : this.seriesData,
      })
  } else if (uom == 'kW') {
      var chartdata1 = [];
      var chartdata2 = [];
      var chartdata3 = [];
      for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
          if (tZ === -17.5) {
              h -= -17;
              min -= -30;
          } else {
              h -= tZ;
          }
          var timestamp = new Date(y, m, d, h , min, s)
          var dat = new Date(timestamp);
          var final = dat.getTime();
          chartdata1.push([final, data[i].r_value])
          chartdata2.push([final, data[i].y_value])
          chartdata3.push([final, data[i].b_value])
      }
      var var1 = chartdata1
      var var2 = chartdata2
      var var3 = chartdata3
      this.var1 = var1
      this.var2 = var2
      this.var3 = var3
      if(chartPhase()==true){
      this.seriesData = [{
          name: 'Phase 1 ',
          data: var1,
          type: this.graphtype,
          color: '#CDDC39',
          fillOpacity: 1,
          threshold: null,
          // visible: true

          }, {
          name: 'Phase 2 ',
          data: var2,
          type: this.graphtype,
          threshold: null,
          color: '#673AB7',
          fillOpacity: 1,
          // visible: true
  
          }, {
          name: 'Phase 3 ',
          data: var3,
          type: this.graphtype,
          threshold: null,
          color: '#03A2E9',
          fillOpacity: 1,
          // visible: true
  
          }]
      }else{
          this.seriesData = [{
              name: 'Phase 1 ',
              data: var1,
              type: this.graphtype,
              color: '#CDDC39',
              fillOpacity: 1,
              threshold: null,
              // visible: true
      
              }, {
              name: 'Phase 2 ',
              data: var2,
              type: this.graphtype,
              threshold: null,
              color: '#673AB7',
              fillOpacity: 1,
              // visible: true
      
          }]
      }  
      this.setState({
          final_chart_data : this.seriesData,
          suffix : 'kW', prefix: ''
      })
  } else if (uom == 'kVA') {
      var chartdata1 = [];
      var chartdata2 = [];
      var chartdata3 = [];
      for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
                if (tZ === -17.5) {
                    h -= -17;
                    min -= -30;
                } else {
                    h -= tZ;
                }
          var timestamp = new Date(y, m, d, h, min, s)
          var dat = new Date(timestamp);
          var final = dat.getTime();
          chartdata1.push([final, data[i].r_value])
          chartdata2.push([final, data[i].y_value])
          chartdata3.push([final, data[i].b_value])
      }
      var var1 = chartdata1
      var var2 = chartdata2
      var var3 = chartdata3
      this.var1 = var1
      this.var2 = var2
      this.var3 = var3
      if(chartPhase()==true){

          this.seriesData = [{
          name: 'Phase 1 ',
          data: var1,
          type: this.graphtype,
          color: '#3F51B5',
          fillOpacity: 1,
          threshold: null,
          // visible: true
  
          }, {
          name: 'Phase 2 ',
          data: var2,
          type: this.graphtype,
          threshold: null,
          color: '#009486',
          fillOpacity: 1,
          // visible: true
  
          }, {
          name: 'Phase 3 ',
          data: var3,
          type: this.graphtype,
          threshold: null,
          color: '#B4C132',
          fillOpacity: 1,
          // visible: true
  
      }]
      } else {
              this.seriesData = [{
              name: 'Phase 1 ',
              data: var1,
              type: this.graphtype,
              color: '#3F51B5',
              fillOpacity: 1,
              threshold: null,
              // visible: true
      
              }, {
              name: 'Phase 2 ',
              data: var2,
              type: this.graphtype,
              threshold: null,
              color: '#009486',
              fillOpacity: 1
      
          }]
      } 
      this.setState({
          final_chart_data : this.seriesData,
          suffix : 'kVA', prefix: ''
      })
  } else {
      var chartdata1 = [];
      for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
                if (tZ === -17.5) {
                    h -= -17;
                    min -= -30;
                } else {
                    h -= tZ;
                }
          var timestamp = new Date(y, m, d, h , min, s)
          var dat = new Date(timestamp);
          var final = dat.getTime();
          chartdata1.push([final, data[i].value, data[i].board_value])  
          chartdata2.push([final, data[i].board_value])  
      } 
      var var1 = chartdata1
      var var2 = chartdata2
      this.var1 = var1
      this.seriesData = [{
          name: 'Active Energy (kW)',
          data: var1,
          type: this.graphtype,
          color: '#f44336',
          fillOpacity: 1,
          // threshold: null
      },{
  name: 'Current consumed (kWh)',
  data: var2,
  type: this.graphtype,
  color: '#002DB3',
  fillOpacity: 1,
  // threshold: null,
  // showInNavigator: true,
          // visible: false,

  }
  ]
      this.setState({
          final_chart_data : this.seriesData,
          suffix : 'kWh', prefix: ''
      })  
  }

  
  // this.bottomTableData();   
  setTimeout(() => {
      var x=document.getElementsByClassName("highcharts-button")
      // console.log(x)
      if(x!=undefined && x.length!=0){
          x[x.length-1].onclick()

}
}, 100);

}

// bottomTableData(){
//   var testObj={
//       max:'full',
//       min:'full'
//   }
//   var obj1=JSON.stringify(testObj)
//   sessionStorage.setItem('bottomTableMaxMin',obj1)  
// var obj={
// max:this.calcData[0].high,
// min:this.calcData[0].low,
// sum:this.calcData[0].total,
// average:this.calcData[0].avg
// }
//   sessionStorage.setItem('bottomTableData', JSON.stringify(obj))
// }

// newtest(event) {
//   var timearray1=[]
// var timearray2=[]
//   var timearray3=[]
//   var array=[]
//   var max1=null
// var min1=null
// var sum=0
//   var x= 0
//   var max = event.max;
//   var min = event.min;
//   console.log(event.target.series[0]['yAxis']['axisTitle'])
//   var uom = event.target.series[0]['yAxis']['axisTitle']['textStr']
//   if (uom == 'kWh' || uom == 'Cost') {
//       for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
//           timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
//       }
//       timearray2 = []
//       timearray3 = []
//   } 

//   if (uom == 'kWh') {
//       this.var1.forEach(row => {
//           if(row[0]>min){
//              x=1
//           }
//           if(row[0]>max){
//             x=0
//          }
//           if(x==1){
//             array.push(row)
//           } 
//       });
//   } 
//   if (array.length == 0 ) {
//       max1=0
//       min1=0
//       sum=0
//       var average1= 0
//       var obj={
//           max:max1,
//           min:min1,
//           sum:sum,
//           average:average1
//       }
//     } else {
//       max1=Math.max.apply(Math, array.map(function(o) { return o[1]; }))
//       min1=Math.min.apply(Math, array.map(function(o) { return o[1]; }))
//       sum=0
//       array.forEach((key)=>{
//           sum=sum+key[1]
//       })

//       var finalsum=0
//       var average1
//         if(uom=="kWh"){
//           // console.log("array", array)
//           // console.log("last", array[array.length-1][2])
//           // console.log("first", array[0][2])
//           finalsum=array[array.length-1][2] - array[0][2]
//            var l=array[array.length-1][0] - array[0][0]
//           var diff = l / 3600000;
//           if(finalsum>0){
//               average1=finalsum/diff

//           }else{
//               average1=0
//           }

//       //  console.log(finalsum,array,"asjf")
//         }else{
//           finalsum=sum
//           average1=sum/(array.length)

//         }

//       var obj={
//           max:max1,
//           min:min1,
//           sum:finalsum,
//           average:average1
//       }
//     }
//   var obj1=JSON.stringify(obj)
//   sessionStorage.setItem('bottomTableData',obj1)
//   if(this.state.pageloadcheck == 1){
//       this.setState({
//           high: this.state.high,
//           low: this.state.low,
//           total: this.state.total,
//           average: this.state.average,
//   })

//       this.setState({
//           pageloadcheck:2
//       })
//   }
//   else if(this.state.pageloadcheck == 2){
//       this.setState({
//               high: this.state.high,
//               low: this.state.low,
//               total: this.state.total,
//               average: this.state.average,
//       })
//       this.setState({
//           pageloadcheck:3
//       })
//   }
//   else if(this.state.pageloadcheck == 3){
//       this.setState({
//           high: max1.toFixed(2),
//           low: min1.toFixed(2),
//           total: finalsum.toFixed(2),
//           average: average1.toFixed(2)
//       })
//   }
// }

  createpiechart1(data1,theme) { 
    if (theme == 'darktheme' || theme == 'greytheme') {
      am4core.unuseTheme(am4themes_material);
      am4core.unuseTheme(am4themes_dataviz);
      am4core.unuseTheme(am4themes_kelly);
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.unuseTheme(am4themes_dark);
      am4core.unuseTheme(am4themes_dataviz);
      am4core.unuseTheme(am4themes_kelly);
      am4core.useTheme(am4themes_material);
    } 
    let chart = am4core.create("topchartdiv1", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; 
    chart.innerRadius = am4core.percent(30);
    chart.logo.disabled = true;
    chart.data = data1
    var series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.ticks.template.disabled = true;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.maxWidth = 300;

    chart.legend.valign = "middle"
    chart.legend.scrollable = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-45);
    series.labels.template.fill = am4core.color("white");
    series.labels.template.relativeRotation = 90;
    series.hiddenState.properties.endAngle = -90;
    series.labels.template.adapter.add("radius", function(radius, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
        return 0;
      }
      return radius;
    });
    series.labels.template.adapter.add("fill", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
        return am4core.color("#ffffff");
      }
      return color;
    });
    series.labels.template.adapter.add("opacity", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
        return 0
      }
      return 1;
    });
    if (theme == 'darktheme') {
      series.colors.list = [
        am4core.color("#e91e63"),
        am4core.color("#00bcd4"),
        am4core.color("#f7e53b"),
        am4core.color("#ffc107"),
        am4core.color("#2196f3"),
        am4core.color("#93c360"),
        am4core.color("#ab26c2"),
        am4core.color("#009688"),
        am4core.color("#d73e71"),
        am4core.color("#58e2c2"),
      ];
    }
    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function(target) {
        if (target.pixelWidth <= 400) {
          chart.legend.maxHeight = 150;

          chart.legend.disabled = true;
          return true;
        } 
        if (target.pixelWidth > 400) {
          chart.legend.maxHeight = 300;
          chart.legend.disabled = false;
           return false;
        }
        return false;
      },
      state: function(target, stateId) {
        if (target instanceof am4charts.PieSeries) {
          var state = target.states.create(stateId);
          return state;
        }
        return null;
      }
    });
    this.chart1 = chart;
  }

  createpiechart2(data1,theme) { 
    if (theme == 'darktheme' || theme == 'greytheme') {
      am4core.unuseTheme(am4themes_material);
      am4core.unuseTheme(am4themes_dataviz);
      am4core.unuseTheme(am4themes_kelly);
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.unuseTheme(am4themes_dark);
      am4core.unuseTheme(am4themes_dataviz);
      am4core.unuseTheme(am4themes_kelly);
      am4core.useTheme(am4themes_material);
    } 
    let chart = am4core.create("topchartdiv2", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; 
    chart.innerRadius = am4core.percent(30);
    chart.logo.disabled = true;
    chart.data = data1
    var series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.ticks.template.disabled = true;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";

    chart.legend.valign = "middle"
    chart.legend.maxWidth = 300;
    chart.legend.scrollable = true;
    series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = am4core.percent(-45);
    series.labels.template.fill = am4core.color("white");
    series.labels.template.relativeRotation = 90;
    series.hiddenState.properties.endAngle = -90;
    series.labels.template.adapter.add("radius", function(radius, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
        return 0;
      }
      return radius;
    });
    series.labels.template.adapter.add("fill", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
        return am4core.color("#ffffff");
      }
      return color;
    });
    series.labels.template.adapter.add("opacity", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
        return 0
      }
      return 1;
    });
    if (theme == 'darktheme') {
      series.colors.list = [
        am4core.color("#e91e63"),
        am4core.color("#00bcd4"),
        am4core.color("#f7e53b"),
        am4core.color("#ffc107"),
        am4core.color("#2196f3"),
        am4core.color("#93c360"),
        am4core.color("#ab26c2"),
        am4core.color("#009688"),
        am4core.color("#d73e71"),
        am4core.color("#58e2c2"),
      ];
    }
    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function(target) {
        if (target.pixelWidth <= 400) {
          chart.legend.maxHeight = 150;

          chart.legend.disabled = true;
          return true;
        } 
        if (target.pixelWidth > 400) {
          chart.legend.maxHeight = 300;

          chart.legend.disabled = false;
           return false;
        }
        return false;
      },
      state: function(target, stateId) {
        if (target instanceof am4charts.PieSeries) {
          var state = target.states.create(stateId);
          return state;
        }
        return null;
      }
    });
    this.chart2 = chart;
  }

  render() {
    var realtimeconfig = {
        chart: {
          renderTo: 'realtimechartdiv',
          backgroundColor: this.state.backgroundcolor,
          zoomType: 'x',
          style: {
            fontFamily: 'Montserrat',
            fontSize: '18px',
            color: this.state.textcolor,
            fontWeight: 'bold',
          },

        },
        exporting: { enabled: true },
        credits: {
          enabled: false
        },
        rangeSelector: {
            selected: 5,
            inputEnabled: false,
            buttons: [{
                type: 'all',
                text: 'Reset',
            }],
            buttonTheme: {
                width: 80,
                style:{
                    fontSize:'13px',
                    fontWeight:'400',
                    
                }
            },
            // labelStyle: {
            //     visibility: 'hidden'
            // },
        },
  
        legend: {
          enabled: true,
          itemStyle: {
                fontFamily: 'Montserrat',
                color: this.state.textcolor,
            },
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'Date / Time',
            style: {
              fontFamily: 'Montserrat',
              fontSize: '12px',
              color: this.state.textcolor,
              fontWeight: 'bold',
            }
          },
          labels: {
            style: {
                fontFamily: 'Montserrat',
                color: this.state.textcolor,
            }
        },
          // events: {
          //   afterSetExtremes: function (event) {
          //     let min = event.min;
          //     let max = event.max;
          //     var testObj={
          //       max:max,
          //       min:min
          //     }
          //     var obj1=JSON.stringify(testObj)
          //     sessionStorage.setItem('bottomTableMaxMin',obj1)
          //   },
          //   // setExtremes: this.newtest
          // }
        },
        yAxis: {
          opposite: false,
          min: 0,
          labels: {
            style: {
                fontFamily: 'Montserrat',
                color: this.state.textcolor,
            }
        },
          title: {
            text: this.state.checkboxvalue,
            style: {
                fontFamily: 'Montserrat',
              fontSize: '14px',
               fontWeight: 'bold',
               color: this.state.textcolor,
            },
          },
        },
        // scrollbar: {
        //     barBackgroundColor: 'gray',
        //     barBorderRadius: 7,
        //     barBorderWidth: 0,
        //     buttonBackgroundColor: 'gray',
        //     buttonBorderWidth: 0,
        //     buttonArrowColor: 'yellow',
        //     buttonBorderRadius: 7,
        //     rifleColor: 'yellow',
        //     trackBackgroundColor: 'white',
        //     trackBorderWidth: 1,
        //     trackBorderColor: 'silver',
        //     trackBorderRadius: 7,
        // },
        tooltip: {
            valuePrefix: this.state.prefix + ' ', 
            valueSuffix: ' ' + this.state.suffix,
            valueDecimals:2,
            split: false,
            shared: true
          },
        series:  this.state.final_chart_data
    };
    return (
    <>
        <div className="content">
        {
                this.state.loader && <Loader />
            }
        <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
        {this.state.showcontent == true ?
        <><Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Daily Carbon Emission  - {this.state.unit} <span className="cardtitle-timetitle">({this.state.from_date} - {this.state.to_date})</span> </CardTitle>
                  <div className="card-category">
                  <FieldGroup
                    control={this.dialForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="3">
                              <FieldControl
                                  name="from_date"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                      <Grid className="Formfieldcss" container spacing={1}>                
                                          <Grid item>
                                              <TextField type="date" required fullWidth variant="outlined" label="From Date" error={touched && hasError("required")}  {...handler("text")} />
                                          </Grid>
                                          <span className="error">
                                              {touched
                                                  && hasError("required")
                                                  && `* From Date is required`}
                                          </span>
                                      </Grid>
                                  </div>
                                  )}
                                />
                            </Col>
                            <Col className="pr-1" md="3">
                              <FieldControl
                                  name="to_date"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                      <Grid className="Formfieldcss" container spacing={1}>                
                                          <Grid item>
                                              <TextField type="date" required fullWidth variant="outlined" label="To Date" error={touched && hasError("required")}  {...handler("text")} />
                                          </Grid>
                                          <span className="error">
                                              {touched
                                                  && hasError("required")
                                                  && `* To Date is required`}
                                          </span>
                                      </Grid>
                                  </div>
                                  )}
                                />
                            </Col>
                            <Col className="pr-1" md="2">
                              <div className="login-button changepassword text-center">
                                  <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      disabled={invalid}
                                      className="flexblockbutton"
                                      onClick={(event) => this.filterdial(event,this.dialForm.getRawValue())}
                                      >
                                      Filter
                                  </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      )}
                    />
                  </div>
                </CardHeader>
                <CardBody>
                <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="sorafontclass"></div>
                <Row>
                  <Col className="pr-1 text-center" md="12">
                    <span className="cardtitle-timetitle dialtotal"><i className={"fab fa-cloudversify newcolorblue " + (this.props.theme) + ' ' + (this.props.themecolor)} /> {this.state.total_dial_data} {this.state.unit}</span>
                    </Col>
                </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="fa fa-calendar" /> This Month
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fab fa-cloudversify text-success" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Carbon Emission</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_so_far_today} {this.state.unit}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> So Far Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                      <i className="fab fa-cloudversify text-primary" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Carbon Emission</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_currently_consumping} {this.state.unit}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> Currently Consuming
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                      <i className="fab fa-cloudversify text-danger" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Carbon Emission</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_7_day_average} {this.state.unit}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> 07 Days Average
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">
                    Carbon Emission Realtime
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <FieldGroup
                    control={this.realtimeForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="6">
                            <FieldControl
                                name="datarange"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                            <div className='control-pane'>
                                                <div className='control-section'>
                                                    <div className='datepicker-control-section'>
                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e)} value={this.state.defaultrange}>
                                                            <PresetsDirective>
                                                                <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                            </PresetsDirective>
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md={3}>
                            <FieldControl
                                name="chart_type"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label3"
                                                    id="demo-simple-select-outlined3"
                                                    fullWidth variant="outlined"
                                                    label="Charts"
                                                    onChange={(e) => this.charthandleChange(e)}
                                                    value={this.state.charttype}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'area'}>Area</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                        </Row>
                    </form>
                    )} 
                />
                <hr />
              <div style={{width:"100%"}}>
              <HighchartsReact 
                 style={{width:"100%"}}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={realtimeconfig}
                    containerProps = {{ className: 'chartContainer' }}
                    id="realtimechartdiv"
                />
              </div>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            {this.state.showcontent == true ?
            <>
            <Col lg="3" md="6" sm="6">
                <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardBody>
                    <Row>
                        <Col md="12" xs="12">
                          <div className="numbers text-center">
                              <CardTitle tag="p">
                                  {this.state.high} {this.state.unit}
                              </CardTitle> 
                          </div>
                        </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <i className="fas fa-cloud text-danger" /> High   
                    </div>
                    </CardFooter>
                </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers text-center">
                            <CardTitle tag="p">
                            {this.state.low} {this.state.unit}
                            </CardTitle> 
                        </div>
                      </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <i className="fas fa-cloud text-warning" /> Low
                    </div>
                    </CardFooter>
                </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardBody>
                    <Row>
                    <Col md="12" xs="12">
                        <div className="numbers text-center">
                          <CardTitle tag="p">
                          {this.state.total} {this.state.unit}
                          </CardTitle> 
                        </div>
                        </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <i className="fas fa-cloud text-warning" /> Total
                    </div>
                    </CardFooter>
                </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardBody>
                    <Row>
                    <Col md="12" xs="12">
                        <div className="numbers text-center">
                                <CardTitle tag="p">
                                {this.state.average} {this.state.unit}
                                </CardTitle> 
                            <p />
                        </div>
                        </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                    <hr />
                    <div className="stats text-center">
                      <i className="fas fa-cloud text-success" /> Average
                    </div>
                    </CardFooter>
                </Card>
                </Col>
                </>
                : '' }
            </Row>
            <Row>
                <Col md="6">
                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle"> 
                        Carbon Emission - Yesterday
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                     <div id="topchartdiv1" style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                     <hr />
                     <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total : {this.state.carbonyesterdaytotal} {this.state.unit}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle">
                        Carbon Emission - Last 7 Days
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv2" style={{ width: "100%", height: "362px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                      <hr />
                     <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total : {this.state.carbon7daystotal} {this.state.unit}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>

          :
          <Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader> 
                </CardHeader>
                <CardBody>
                <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </div>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    themecolor: state.themecolor,
    charttheme: state.charttheme 
  }
}

export default connect(mapStateToProps)(Carbon);
