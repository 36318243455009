import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from './views/Login';
import ForgetPassword from './views/Forgetpassword';
import ResetPassword from './views/Resetpassword';
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import AdminLayout from "./layouts/Admin.jsx";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer'
const hist = createBrowserHistory();
const store = createStore(rootReducer);

ReactDOM.render(
<Provider store={store}>  
  <Router history={hist}>
    <Switch>
      <Route exact path="/" render={() => (<Redirect to="/neurobotz/login" />)} />  
      <Route path="/neurobotz/login" component={Login} />  
      <Route path="/neurobotz/forgetpassword" component={ForgetPassword} />
      <Route path="/password/reset" component={ResetPassword} />          
      <Route path="/neurobotz" render={props => <AdminLayout {...props} />} />
      <Route path="*" render={() => (<Redirect to="/neurobotz/login" />)} />
    </Switch>
  </Router>
</Provider>
,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
