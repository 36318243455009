import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col ,TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';

import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import "../assets/css/auth.css"

import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class TapSetting extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            activetab: "1",
            voltageBoolean:true,
            currentBoolean:true,
            phaseBoolean:true,

            LoadList: [],
            AlarmList:[],
            showcontent: false,
            editmode: false,
            buttontext: 'Add',
            dropDownList: [],
            BoardList: []
        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''

    }

    VoltageForm = FormBuilder.group({
        id:[""],
        ibvl_voltage_less_than: ["", [Validators.required]],
        tt1vl_from_voltage: ["", [Validators.required]],
        tt1vl_to_voltage: ["", [Validators.required]],
        tt1vl_delay: ["", [Validators.required]],
        tt2vl_from_voltage: ["", [Validators.required]],
        tt2vl_to_voltage: ["", [Validators.required]],
        tt2vl_delay: ["", [Validators.required]]
    });
  
    PhaseForm = FormBuilder.group({
        id:[""],
        phase: ["", [Validators.required]],
        name:[""],

      
    });

    CurrentForm = FormBuilder.group({
        id:[""],
        asl_current_greater_than: ["", [Validators.required]],
        dbcl_delay: ["", [Validators.required]],
        dbcl_from_current: ["ON", [Validators.required]],
        dbcl_to_current: ["", [Validators.required]],
        ibcl_current_greater_than: ["", [Validators.required]],
    });
    getVoltage() {
      
        this.setState({ loader: true })
        apiService.getVoltage().then(response => {
            //  console.log(response.data)
             var data=response.data
            //  this.setState({LoadList: response.data}) 
             var x=true
           if(data.length==0){
               this.VoltageForm.reset()
           }else{
               x=false
               this.VoltageForm.setValue({
                   id:data[0].id,
                    ibvl_voltage_less_than :data[0].ibvl_voltage_less_than,
                    tt1vl_from_voltage:data[0].tt1vl_from_voltage,
                    tt1vl_to_voltage : data[0].tt1vl_to_voltage,
                    tt1vl_delay : data[0].tt1vl_delay,
                    tt2vl_from_voltage : data[0].tt2vl_from_voltage,
                    tt2vl_to_voltage : data[0].tt2vl_to_voltage,
                    tt2vl_delay : data[0].tt2vl_delay
               })
             

           }
            // this.setState({LoadList: this.state.LoadList.concat(response.data)},()=>this.datatableRerender(data)) 
            
            this.setState({ loader: false,voltageBoolean:x })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel() {
        if(this.VoltageForm.controls.id.value==null){
            this.VoltageForm.reset()
           
        }else{
            this.getVoltage()
           
        }
     
      
        
    }
    VoltageSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addVoltage(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Voltage Added Successfully")
                this.getVoltage();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editVoltage(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Voltage Updated Successfully")
                this.getVoltage();

            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    deleteVoltage(event) {
        var x=this.VoltageForm.controls.id.value
        // console.log(x,"tested")
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                   
                    apiService.deleteVoltage(x).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Record Deleted Successfully")
                        this.getVoltage();

                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    getCurrent() {
      
        this.setState({ loader: true })
        apiService.getCurrent().then(response => {
            //  console.log(response.data)
             var data=response.data
            //  this.setState({LoadList: response.data}) 
             var x=true
           if(data.length==0){
               this.CurrentForm.reset()
           }else{
               x=false
               this.CurrentForm.setValue({
                   id:data[0].id,
                   asl_current_greater_than :data[0].asl_current_greater_than,
                   dbcl_from_current : data[0].dbcl_from_current,
                   dbcl_to_current :data[0].dbcl_to_current,
                   dbcl_delay : data[0].dbcl_delay,
                   ibcl_current_greater_than :data[0].ibcl_current_greater_than

               })
             

           }
            // this.setState({LoadList: this.state.LoadList.concat(response.data)},()=>this.datatableRerender(data)) 
            
            this.setState({ loader: false,currentBoolean:x })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel1() {
        if(this.CurrentForm.controls.id.value==null){
            this.CurrentForm.reset()
           
        }else{
            this.getCurrent()
           
        }
     
      
        
    }
    CurrentSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addCurrent(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Current Added Successfully")
                this.getCurrent();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editCurrent(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Current Updated Successfully")
                this.getCurrent();

            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    deleteCurrent(event) {
        var x=this.CurrentForm.controls.id.value
        // console.log(x,"tested")
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                   
                    apiService.deleteCurrent(x).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Record Deleted Successfully")
                        this.getCurrent();

                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    getPhase() {
      
        this.setState({ loader: true })
        apiService.getPhase().then(response => {
            //  console.log(response.data)
             var data=response.data
            //  this.setState({LoadList: response.data}) 
             var x=true
           if(data.length==0){
               this.PhaseForm.reset()
           }else{
               x=false
               this.PhaseForm.setValue({
                   id:data[0].id,
                   phase :data[0].selection,
                   name:data[0].selection_name
                  

               })
             

           }
            // this.setState({LoadList: this.state.LoadList.concat(response.data)},()=>this.datatableRerender(data)) 
            
            this.setState({ loader: false,phaseBoolean:x })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel2() {
        if(this.PhaseForm.controls.id.value==null){
            this.PhaseForm.reset()
           
        }else{
            this.getPhase()
           
        }
     
      
        
    }
    phaseSubmit(event, value) {
        // console.log(value)
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addPhase(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Phase Added Successfully")
                this.getPhase();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editPhase(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Phase Updated Successfully")
                this.getPhase();

            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    deletePhase(event) {
        var x=this.PhaseForm.controls.id.value
        // console.log(x,"tested")
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                   
                    apiService.deletePhase(x).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Record Deleted Successfully")
                        this.getPhase();

                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }



    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/tapping_settings')
            if (localStorage.getItem('selectedboard')) {
                // this.getdropdowndata()
                // this.boardData()
                // this.cancel()

                // this.setState({
                //     showcontent: true,
                //     LoadList:[],
                //     AlarmList:[]
                // },()=>{
                //      this.getLoad(true)
                // this.getAlarm(false)
                // })
               

                // console.log(this.state.dropDownList)
                // this.boardData()
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
               
            }

        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    toggle(option) {
 
        this.setState({
            activetab: option
        },()=> {
          
        //    this.cancel()
        //     this.datatableRerender(true)
        })

       
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            //  AlarmList:[],
            // LoadList:[]
        })
        // this.LoadForm.reset()
       

    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            activeTab:1,
            // AlarmList:[],
            // LoadList:[]
        },()=>{
            // this.toggle(1)
            localStorage.setItem('selectedboard',board_id)

            // this.cancel()
            this.getVoltage()
            this.getCurrent()
            this.getPhase()
        //    this.getLoad(true)
        //    this.getAlarm(false)
        })
    
        // this.componentDidMount()
    }



    render() {
        var volt=this.VoltageForm.value
        var current=this.CurrentForm.value
        var phase=this.PhaseForm.value

    	var volttapsettingstypes= [{"id":1,"name":"120V Two Wire Neutral"},{"id":2,"name":"120V Three Wire Neutral"},{"id":3,"name":"277V Three Wire Neutral"},{"id":4,"name":"240V Three Wire Neutral"}];


        return (    
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                            <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5">
                                                      <Nav tabs>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('1'); }}
                                                              >
                                                                  Voltage Tapping
                            </NavLink>
                                                          </NavItem>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('2'); }}
                                                              >
                                                                  Current Tapping
                            </NavLink>
                                                          </NavItem>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('3'); }}
                                                              >
                                                                 Phase Supply
                            </NavLink>
                                                          </NavItem>
                                                      </Nav>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody>
                                                  <TabContent activeTab={this.state.activetab}>
                                                      <TabPane tabId="1">
                                                {this.state.voltageBoolean ? 
                                                      <FieldGroup
                                                control={this.VoltageForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                         <CardTitle className="tapHead" tag="h5">Instant Bypass Voltage Limits :</CardTitle>
                                                        <Row>
                                                       
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="ibvl_voltage_less_than"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Voltage Less Than" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                          </Row>
                                                          <hr style={{marginTop:17}}/>
                                                          <CardTitle className="tapHead" tag="h5">Transformer TAP 1 Voltage :</CardTitle>

                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt1vl_from_voltage"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="From Voltage" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt1vl_to_voltage"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="To Voltage" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                       />

                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt1vl_delay"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Limits Delay" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            </Row>
                                                            <hr style={{marginTop:17}}/>

                                                            <CardTitle className="tapHead" tag="h5">Transformer TAP 2 Voltage :</CardTitle>

                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt2vl_from_voltage"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="From Voltage" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt2vl_to_voltage"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="To Voltage" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="tt2vl_delay"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Limits Delay" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                           
                                                             </Row>
                                                             <hr style={{marginTop:17}}/>
                                                        <Row>
                                                          
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.VoltageSubmit(event, this.VoltageForm.getRawValue())}
                                                                    >
                                                                        {this.VoltageForm.controls.id.value==null ? "Add" : "Update"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                )}
                                            />:<>
                                               <CardTitle className="tapHead" tag="h5">Instant Bypass Voltage Limits :</CardTitle>
                                               <Row>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">Voltage Less Than </span> : {volt.ibvl_voltage_less_than}
                                                            </Col>
                                                </Row>
                                                <hr style={{marginTop:17}}/>

                                                <CardTitle className="tapHead" tag="h5">Transformer TAP 1 Voltage :</CardTitle>
                                               <Row>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">From Voltage</span> : {volt.tt1vl_from_voltage}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">To Voltage </span>: {volt.tt1vl_to_voltage}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">Limits Delay </span> : {volt.tt1vl_delay}
                                                            </Col>
                                                </Row>
                                                <hr style={{marginTop:17}}/>

                                                <CardTitle className="tapHead" tag="h5">Transformer TAP 2 Voltage :</CardTitle>
                                               <Row>
                                               <Col className="pr-1" md="3">
                                               <span className="tapsubtitle"> From Voltage</span> : {volt.tt2vl_from_voltage}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle"> To Voltage </span>: {volt.tt2vl_to_voltage}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">  Limits Delay </span>: {volt.tt2vl_delay}
                                                            </Col>
                                                </Row>
                                                <hr style={{marginTop:17}}/>

                                                <Row>
                                                          
                                                          <Col className="pr-1" md="3">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      type="submit"
                                                                      variant="contained"
                                                                      color="primary"
                                                                    //   disabled={invalid}
                                                                      onClick={(event) => this.setState({voltageBoolean:true})}
                                                                  >
                                                                     Edit
                                                                  </Button>
                                                              </div>
                                                          </Col>
                                                          <Col className="pr-1" md="3">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      onClick={(e) => this.deleteVoltage(e)}
                                                                      variant="contained"
                                                                      color="danger"
                                                                  >
                                                                      Delete
                          </Button>
                                                              </div>
                                                          </Col>
                                                          <Col className="pr-1" md="3">
                                                          </Col>
                                                      </Row>
                                                 
                                            </>
                                        }
                                                      </TabPane>
                                                      <TabPane tabId="2">
                                                {this.state.currentBoolean ? 
                                                      <FieldGroup
                                                control={this.CurrentForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                         <CardTitle className="tapHead" tag="h5">Auto Shutdown Limits :</CardTitle>
                                                        <Row>
                                                       
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="asl_current_greater_than"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Current Greater Than" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                          </Row>
                                                          <hr style={{marginTop:17}}/>
                                                          <CardTitle className="tapHead" tag="h5">Instant Bypass Current Limits :</CardTitle>

                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="ibcl_current_greater_than"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Current Greater Than" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            </Row>
                                                            <hr style={{marginTop:17}}/>
                                                            <CardTitle className="tapHead" tag="h5">Delay Bypass Current Limits :</CardTitle>

                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="dbcl_from_current"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="From Current" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="dbcl_to_current"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="To Current" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="dbcl_delay"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Limits Delay" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Enter the value`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                           
                                                             </Row>
                                                             <hr style={{marginTop:17}}/>
                                                        <Row>
                                                          
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.CurrentSubmit(event, this.CurrentForm.getRawValue())}
                                                                    >
                                                                        {this.CurrentForm.controls.id.value==null ? "Add" : "Update"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel1()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                )}
                                            />:<>
                                                         <CardTitle className="tapHead" tag="h5">Auto Shutdown Limits :</CardTitle>
                                               <Row>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle"> Current Greater Than</span> : {current.asl_current_greater_than}
                                                            </Col>
                                                </Row>
                                                <hr style={{marginTop:17}}/>
                                                <CardTitle className="tapHead" tag="h5">Instant Bypass Current Limits :</CardTitle>
                                               <Row>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">Current Greater Than </span>: {current.ibcl_current_greater_than}
                                                            </Col>
                                                         
                                                </Row>
                                                <hr style={{marginTop:17}}/>
                                                <CardTitle className="tapHead" tag="h5">Delay Bypass Current Limits :</CardTitle>
                                               <Row>
                                               <Col className="pr-1" md="3">
                                               <span className="tapsubtitle"> From Current </span> : {current.dbcl_from_current}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">To Current</span> : {current.dbcl_to_current}
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <span className="tapsubtitle">Limits Delay </span>: {current.dbcl_delay}
                                                            </Col>
                                                </Row>
                                                <hr style={{marginTop:17}}/>
                                                <Row>
                                                          
                                                          <Col className="pr-1" md="3">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      type="submit"
                                                                      variant="contained"
                                                                      color="primary"
                                                                    //   disabled={invalid}
                                                                      onClick={(event) => this.setState({currentBoolean:true})}
                                                                  >
                                                                     Edit
                                                                  </Button>
                                                              </div>
                                                          </Col>
                                                          <Col className="pr-1" md="3">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      onClick={(e) => this.deleteCurrent(e)}
                                                                      variant="contained"
                                                                      color="danger"
                                                                  >
                                                                      Delete
                          </Button>
                                                              </div>
                                                          </Col>
                                                          <Col className="pr-1" md="3">
                                                          </Col>
                                                      </Row>
                                                 
                                            </>
                                        }
                                                      </TabPane>
                                                      <TabPane tabId="3">
                                                {this.state.phaseBoolean ? 
                                                      <FieldGroup
                                                control={this.PhaseForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                        <Row>
                                                       
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="phase"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Voltage Settings"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Voltage Settings
                                                  </MenuItem>
                                                                                        {volttapsettingstypes.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                {option.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `*  Voltage Settings is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                               />
                                                            </Col>
                                                            <Col className="pr-1" md="2">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.phaseSubmit(event, this.PhaseForm.getRawValue())}
                                                                    >
                                                                        {this.PhaseForm.controls.id.value==null ? "Add" : "Update"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="2">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel2()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                          </Row>
                                                        
                                                    </form>
                                                )}
                                            />:<>
                                               <Row>
                                                            <Col className="pr-1" md="6" style={{paddingTop:15}}>
                                                            <span className="tapsubtitle"> Selected Voltage Tapping Setting </span> : {phase.name}
                                                            </Col>
                                                            <Col className="pr-1" md="2">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      type="submit"
                                                                      variant="contained"
                                                                      color="primary"
                                                                    //   disabled={invalid}
                                                                      onClick={(event) => this.setState({phaseBoolean:true})}
                                                                  >
                                                                     Edit
                                                                  </Button>
                                                              </div>
                                                          </Col>
                                                          <Col className="pr-1" md="2">
                                                              <div className="login-button changepassword text-center">
                                                                  <Button
                                                                      onClick={(e) => this.deletePhase(e)}
                                                                      variant="contained"
                                                                      color="danger"
                                                                  >
                                                                      Delete
                          </Button>
                                                              </div>
                                                          </Col>
                                                         
                                                </Row>
                                           
                                             
                                                 
                                            </>
                                        }
                                                      </TabPane>
                                                      
                                                       </TabContent>
                                              </CardBody>
                                          </Card>
                                      </Col>
                              
                            </Row>
                 
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(TapSetting);
