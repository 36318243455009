import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import WaveProgressBar from "./WaveProgressBar";

// Custom styles for the components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  progressContainer: {
    width: 40,
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    padding: theme.spacing(2),
  },
  progressBar: {
    borderRadius: 5,
  },
  tabsContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
}));

// Custom styled LinearProgress for vertical progress bar
const VerticalLinearProgress = withStyles((theme) => ({
  root: {
    height: "100%",
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const PageWithProgressBar = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      

      {/* Tabs */}
      <Paper className={classes.tabsContainer}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Tab 1" />
          <Tab label="Tab 2" />
          <Tab label="Tab 3" />
          <Tab label="Tab 4" />
        </Tabs>
      </Paper>

      {/* Vertical Progress Bar */}
      <Paper className={classes.progressContainer}>
        {/* <VerticalLinearProgress variant="determinate" value={value} /> */}
        <WaveProgressBar variant="determinate" value={30}></WaveProgressBar>
      </Paper>
    </div>
  );
};

export default PageWithProgressBar;
