import React, { Component } from "react";
import '../services/loaderCss.scss';
import Logo from '../loading.gif'

export default class Loader extends Component {
  render() {
    return (
      <div className="background">
      <div className="wrappers">
          <img src={Logo} className="App-logo-gif" alt="logo" />
          <div className="loading">Fetching data from Smartmeter. Please wait...</div> 
      </div>
    </div>
    )
  }
}

