import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import * as apiService from '../services/apiService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import swal from 'sweetalert';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import "../assets/css/auth.css"
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Customers extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            editmode: false,
            showtable: true,
            buttontext: 'Add',
            customers: [],
            countrylist: [],
            currencylist: [],
            statelist: [],
            boardslist: [],
            selectedboard: [],
            primaryboard: [],
            boardsinfolist: [], 
            productinfolist: [],           
            selectedCountryValue: '',
            selectedCurrencyValue: '',
            selectedStateValue: '',  
            selectedPrimaryBoardValue: '',   
            activeStep: 0,
            currentcustomer:'',
            selectedPrimaryBoardinfoValue: '' ,
            boardinfoeditmode: false,
            boardinfobuttontext: 'Add',   
            productinfobuttontext: 'Add'            
        }
        this.pri_list = [];
        this.board_info_list = [];
        this.product_info_list = [];
        this.inputRef = React.createRef();
    }

    keyCount = 0;
    customerForm = FormBuilder.group({
        id: [""],
        customername: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        created_by: [localStorage.getItem('user_id')],
        updated_by: [localStorage.getItem('user_id')],
        password: ["",[Validators.required]],
        first_name: ["",[Validators.required]],
        last_name: [""],
        phone: ["",[Validators.minLength(8),Validators.maxLength(15),Validators.pattern("^[0-9]*$")]],
        address_1: [""],
        address_2: [""],
        country: ["",[Validators.required]],
        state: ["",[Validators.required]],
        city: ["",[Validators.required,Validators.pattern('[a-zA-Z ]*')]],
        pincode: ["",[Validators.minLength(6),Validators.maxLength(6),Validators.pattern("^[0-9]*$")]],
        meter_type: ["1",[Validators.required]],
        currency: [""],
        notes: [""],
        is_admin: false,
        active: true,
        primary_board: ["",[Validators.required]],
        boards: [[]],
        time_zone: [""],
        boards_info: [[]],
        product_info: [[]]
    });

    secondpageForm =  FormBuilder.group({
        board_id: ["", [Validators.required]],
        created_by: [localStorage.getItem('user_id')],
        customer_id: ["", [Validators.required]],
        equipments: ["", [Validators.required]],
        id: [""],
        installation_date: [this.today, [Validators.required]],
        kva: ["", [Validators.required]],
        load_type: ["", [Validators.required]],
        machine_hours: [""],
        machine_location: [""],
        operating_condition: [""],
        sim: [""],
        updated_by: [localStorage.getItem('user_id')],
        wifi: [""],
    })

    product_info_form = FormBuilder.group({
        created_by: [""],
        customer_id: [localStorage.getItem('user_id')],
        id: [""],
        name: ["", [Validators.required]],
        notes: [""],
        specification: [""],
        updated_by: [localStorage.getItem('user_id')],
    })

    componentDidMount() {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('is_admin') == 'true') {
            this.props.history.push('/neurobotz/customers')
            this.getdropdowns();
            this.getcustomers();
            this.getCustomersBoards();
            this.convertdate(new Date())
        } else {
            this.props.history.push('/neurobotz/dashboard')
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
      this.inputRef = React.createRef();
    }

    getdropdowns() {
        this.setState({ loader: true })
        apiService.getDropdown().then(response => {
            this.setState({ loader: false, countrylist: response.data.countrylist, currencylist: response.data.currencylist})
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getCustomersBoards() {
        this.setState({ loader: true })
        apiService.getCustomerBoards().then(response => {
            this.setState({ loader: false, boardslist: this.state.boardslist.concat(response.data)})
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getcustomers() {
        if ($.fn.dataTable.isDataTable('#customertable')) {
            $('#customertable').DataTable().clear().destroy();
        }   
        this.setState({ loader: true })
        apiService.getCustomers().then(response => {
            this.setState({ customers: this.state.customers.concat(response.data) })
            $(document).ready(function () {
                $('#customertable').DataTable({
                  "bLengthChange": false,
                  "pageLength": 15,
                  "searching": true,
                  "bInfo": true,
                  "bSort": true,
                  "bPaginate": true,
                  "dom": 'Bfrtip',
                  "buttons": [{
                    'extend': "excel",
                    "title": "Customer Details",
                    "exportOptions": {
                      "columns": ':not(:first-child)',
                    }
                  }]
                });
            });
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    
    cancel() {
        this.customerForm.reset()
        this.setState({
            editmode: false,
            buttontext: 'Add',
            selectedPrimaryBoardValue: '',
            selectedPrimaryBoardinfoValue: '',
            selectedCountryValue: '',
            selectedStateValue: '',
            // boardslist: [],
            boardsinfolist: [],
            productinfolist: [],
            currentcustomer: ''
        });
        this.customerForm.patchValue({
            id: "",
            customername: "",
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            phone:"",
            address_1: "",
            address_2: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            currency: "",
            notes: "",
            is_admin: false,
            active: true,
            primary_board: "",
            boards: [],
            boards_info: [],
            product_info: [],
            time_zone: "",
            meter_type:"1"
        })
        this.secondpageForm.patchValue({
            board_id: "",
            customer_id: "",
            equipments: "",
            id: "",
            installation_date: this.today,
            kva: "",
            load_type: "",
            machine_hours:"",
            machine_location: "",
            operating_condition: "",
            sim: "",
            wifi: "",
        })
        this.product_info_form.patchValue({
            customer_id: "",
            id: "",
            name: "",
            notes: "",
            specification: "",
        })
        this.pri_list = [];
        this.board_info_list = [];
        this.product_info_list = [];
    }

    boardinfocancel() {
        this.setState({
            boardinfoeditmode: false,
            boardinfobuttontext: 'Add',
            selectedPrimaryBoardinfoValue: ''
        })
        this.secondpageForm.patchValue({
            board_id: "",
            equipments: "",
            id: "",
            installation_date: this.today,
            kva: "",
            load_type: "",
            machine_hours:"",
            machine_location: "",
            operating_condition: "",
            sim: "",
            wifi: "",
        })
        this.board_info_list = this.state.boardslist.filter(({ id: id1 }) => !this.state.boardsinfolist.some(({ board_id: id2 }) => id2 == id1));
    }

    productinfocancel() {
        this.setState({
            productinfobuttontext: 'Add',
        })
        this.product_info_form.patchValue({
            id: "",
            name: "",
            notes: "",
            specification: "",
        })
    }

    convertdate(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.secondpageForm.patchValue({
            installation_date:this.today
        })
    }

    customeredit(value) {
        this.setState({
            showtable : false,
            boardsinfolist: [],
            productinfolist: []
        })
        this.getstatedropdown(value.country)
        this.pri_list = value.boards;
        var boards_list = [];
        for (let i =0 ; i<= value.boards.length -1 ;i++){
            boards_list.push(value.boards[i]['id'])
        }
        this.customerForm.patchValue({
            id: value.id,
            customername: value.customername,
            email: value.email,
            password: value.password,
            first_name: value.first_name,
            last_name: value.last_name,
            phone:value.phone,
            address_1: value.address_1,
            address_2: value.address_2,
            country: value.country,
            state: value.state,
            city: value.city,
            pincode: value.pincode,
            currency: value.currency,
            notes:value.notes,
            is_admin: value.is_admin,
            active: value.active,
            primary_board: value.primary_board,
            boards: boards_list,
            boards_info: value.boards_info,
            product_info: value.product_info,
            time_zone: value.time_zone,
        })
        this.secondpageForm.patchValue({
            customer_id: value.id
        })
        this.product_info_form.patchValue({
            customer_id: value.id
        })
        this.setState({
            editmode: true,
            buttontext: 'Update',
            selectedboard: boards_list,
            // boardslist: value.boards,
            selectedPrimaryBoardValue: value.primary_board,
            currentcustomer: value.id,
            selectedStateValue: value.state,
            selectedCountryValue: value.country,
            boardsinfolist: value.boards_info,
            productinfolist: value.product_info
        },()=>{
            this.getunAssignedboards(value.boards)
        })
        if ($.fn.dataTable.isDataTable('#boardinfotable')) {
            $('#boardinfotable').DataTable().clear().destroy();
        }   
        $(document).ready(function () {
            $('#boardinfotable').DataTable({
              "bLengthChange": false,
              "pageLength": 15,
              "searching": true,
              "bInfo": true,
              "bSort": true,
              "bPaginate": true,
              "dom": 'Bfrtip',
              "buttons": [{
                'extend': "excel",
                "title": "Customer Details",
                "exportOptions": {
                  "columns": ':not(:first-child)',
                }
              }]
            });
        });
        this.board_info_list = []
        if (value.boards_info.length == 0) {
            this.board_info_list = value.boards
        } else {
            this.board_info_list = value.boards.filter(({ id: id1 }) => !value.boards_info.some(({ board_id: id2 }) => id2 == id1));
        }
        this.statehandleChange(value.state)
        // this.getCustomersBoards();
    }
    getunAssignedboards(data){
        this.setState({ loader: true })
        apiService.getCustomerBoards().then(response => {
            this.setState({ loader: false, boardslist: data.concat(response.data)})
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    boardinfoedit(value) {
        this.setState({
            boardinfobuttontext : 'Update',
            boardinfoeditmode: true,
            selectedPrimaryBoardinfoValue:  value.board_id
        })
        this.secondpageForm.patchValue({
            board_id: value.board_id,
            customer_id: this.state.currentcustomer,
            equipments: value.equipments,
            id: value.id,
            installation_date: value.installation_date,
            kva: value.kva,
            load_type: value.load_type,
            machine_hours:value.machine_hours,
            machine_location: value.machine_location,
            operating_condition: value.operating_condition,
            sim: value.sim,
            wifi: value.wifi,
        })
        this.board_info_list.push({'board_name': value.boardname, 'id': value.board_id})
        //debugger;
        this.inputRef.current.focus();
    }

    productinfoedit(value) {
        this.setState({
            productinfobuttontext : 'Update',
        })
        this.product_info_form.patchValue({
            customer_id: this.state.currentcustomer,
            id: value.id,
            name:value.name,
            notes: value.notes,
            specification: value.specification,
        })
    }

    currencyhandleChange(e) {
        this.setState({
            selectedCurrencyValue: e
        })
        this.customerForm.patchValue({
            currency: e
        })
    }

    statehandleChange(e) {
        this.setState({
            selectedStateValue: e
        })
        this.customerForm.patchValue({
            state: e
        })
    }

    countryhandleChange(e) {
        if (e == '') {
            this.setState({
                selectedCountryValue: e
            })
            this.customerForm.patchValue({
                country: e
            })
        } else {
            this.setState({
                selectedCountryValue: e
            })
            this.customerForm.patchValue({
                country: e
            })
            this.getstatedropdown(e);
            this.getcurrency(e)
        }
    }

    boardhandleChange(event,option) {
        if (option == '') {
            this.setState({
                selectedboard: [],
                primaryboard: [],
                selectedPrimaryBoardValue: ''
            })
            this.customerForm.patchValue({
                boards: [],
                primary_board:""
            })
            this.pri_list = []
        } else {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            var prime=this.state.selectedPrimaryBoardValue
            var prime1=this.state.selectedPrimaryBoardValue

            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj.id != option['id'])
                if(prime==option['id']){
                    if(this.pri_list.length!=0){
                        prime1=this.pri_list[0]['id']
                        this.setState({selectedPrimaryBoardValue:this.pri_list[0]['id']})
                    }
                }
                if(this.pri_list.length ==0){
                    prime1=""
                    this.setState({selectedPrimaryBoardValue:""}) 
  
                }
            } else {
                var filter=this.pri_list.filter((obj)=>obj.id ==option['id'] )
                if(filter.length==0){
                    this.pri_list.push(option)

                }
                if(prime=="" && this.pri_list.length >0){
                    prime1=this.pri_list[0]['id']

                    this.setState({selectedPrimaryBoardValue:this.pri_list[0]['id']}) 

                }
               

            }
        
            this.setState({
                selectedboard: [option.id],
                primaryboard: this.pri_list,
            })
            this.customerForm.patchValue({
               boards: [option.id],
                primary_board:prime1
            })

        }
    }

    primaryboardhandleChange(option) {
        if (option == '') {
            this.setState({
                selectedPrimaryBoardValue: ''
            })
            this.customerForm.patchValue({
                primary_board: ''
            })
        } else {
            this.setState({
                selectedPrimaryBoardValue: option
            })
            this.customerForm.patchValue({
                primary_board: option
            })
        }
    }

    primaryboardinfohandleChange(option) {
        if (option == '') {
            this.setState({
                selectedPrimaryBoardinfoValue: ''
            })
            this.secondpageForm.patchValue({
                board_id: ''
            })
        } else {
            this.setState({
                selectedPrimaryBoardinfoValue: option
            })
            this.secondpageForm.patchValue({
                board_id: option
            })
        }
    }

    getstatedropdown(country) {
        this.setState({ loader: true })
        apiService.getStates(country).then(response => {
            this.setState({ loader: false, statelist: response.data})
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getcurrency(country) {
        this.setState({ loader: true })
        apiService.getCurrency(country).then(response => {
            this.setState({ loader: false,selectedCurrencyValue: response.data[0]['name']})
            this.customerForm.patchValue({
                currency: response.data[0]['name']
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    can_admin(e) {
        if (e == false) {
            this.customerForm.patchValue({
                is_admin: true
            })
        } else {
            this.customerForm.patchValue({
                is_admin: false
            })
        }
    }
    
      can_active(e) {
        if (e == false) {
            this.customerForm.patchValue({
                active: true
            })
        } else {
            this.customerForm.patchValue({
                active: false
            })
        }
    }
    
    

    handleSubmit(e,value) {
        e.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addCustomer(value).then(response => {
                this.setState({ loader: false , editmode: true, buttontext: 'Update',currentcustomer: response.data.id})
                apiService.Alerts("Customer Added Successfully")     
                this.customerForm.patchValue({
                    id: response.data.id,
                    time_zone: response.data.time_zone
                })
                this.secondpageForm.patchValue({
                    customer_id: response.data.id
                })
                this.board_info_list = []
                this.board_info_list = this.pri_list
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            value.boards_info = this.state.boardsinfolist
            value.product_info = this.state.productinfolist
            apiService.updateCustomer(value).then(response => {
                this.setState({ loader: false, editmode: true, buttontext: 'Update'})
                apiService.Alerts("Customer Updated Successfully")
                this.board_info_list = []
                this.board_info_list = this.pri_list     
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    handleSubmitboardinfo(e,value) {
        e.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addBoardinfo(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Board Info Added Successfully")     
                this.state.boardsinfolist.push(response.data)
                this.board_info_list = this.state.boardslist.filter(({ id: id1 }) => !this.state.boardsinfolist.some(({ board_id: id2 }) => id2 == id1));
                this.boardinfocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateBoardinfo(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Board Info Updated Successfully")     
                this.getboardinfolist(response.data.board_id)
                this.board_info_list = this.state.boardslist.filter(({ id: id1 }) => !this.state.boardsinfolist.some(({ board_id: id2 }) => id2 == id1));
                this.boardinfocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    handleSubmitproductinfo(e,value) {
        e.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addProductinfo(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Product Info Added Successfully")     
                this.state.productinfolist.push(response.data)
                this.productinfocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateProductinfo(value).then(response => {
                this.setState({ loader: false})
                apiService.Alerts("Product Info Updated Successfully")     
                this.getproductinfolist(response.data.customer_id)
                this.productinfocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    getboardinfolist(board_id) {
        this.setState({ loader: true })
            apiService.getBoardinfo(board_id).then(response => {
                this.setState({ loader: false})
                this.setState({
                    boardsinfolist: [],
                    boardsinfolist: response.data
                })   
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
    }

    getproductinfolist(customer_id) {
        this.setState({ loader: true })
            apiService.getProductinfo(customer_id).then(response => {
                this.setState({ loader: false})
                this.setState({
                    productinfolist: [],
                    productinfolist: response.data
                })   
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
    }


    customerdelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Customer!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.deleteCustomer(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Customer Deleted Successfully")      
                    this.getcustomers();
                    this.getCustomersBoards();
                    this.cancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    boardinfodelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Board Info!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.deleteBoardinfo(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Board Info Deleted Successfully")      
                    this.state.boardsinfolist = this.state.boardsinfolist.filter((item) => item.id !== value.id)
                    this.board_info_list = this.state.boardslist.filter(({ id: id1 }) => !this.state.boardsinfolist.some(({ board_id: id2 }) => id2 == id1));
                    this.boardinfocancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    productinfodelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Product Info!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.deleteProductinfo(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Product Info Deleted Successfully")      
                    this.state.productinfolist = this.state.productinfolist.filter((item) => item.id !== value.id)
                    this.productinfocancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }

    convertfalsetrue() {
        this.setState({
            showtable : false,
            boardsinfolist: [],
            productinfolist: [],
            selectedPrimaryBoardinfoValue: ''
        },()=>{
            this.cancel();
            this.boardinfocancel();
            this.productinfocancel();
            this.getCustomersBoards();
            this.getunAssignedboards([])
        })

    }

    backtonormal() {
        this.setState({
            showtable : true,
            customers: []
        })
        this.boardinfocancel();
        this.productinfocancel();
        this.getcustomers();
    }

    render() {
        var meter_list=[{id:1,name:"Meter"},{id:2,name:"Flow meter"}]
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            {
                this.state.showtable == true ?
                <Row>
                <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                        <CardTitle tag="h5" className="flexblock">Customer Details  <span><Button
                            onClick={() => this.convertfalsetrue()}
                            variant="contained"
                            color="primary"
                            className="flexblockbutton"
                            >
                            Add Customer
                        </Button></span></CardTitle>
                        </CardHeader>
                        <CardBody>
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <table id="customertable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    <tr>
                                        <th style={{width : '100px'}}>Actions</th>
                                        <th style={{width : '200px'}}>Customer Name</th>
                                        <th style={{width : '200px'}}>Email-Id</th>
                                        <th style={{width : '200px'}}>Primary Board</th>
                                        <th style={{width : '200px'}}>Boards</th>
                                        <th style={{width : '200px'}}>FirstName</th>
                                        <th style={{width : '200px'}}>LastName</th>
                                        <th style={{width : '200px'}}>Address1</th>
                                        <th style={{width : '200px'}}>Address2</th>
                                        <th style={{width : '200px'}}>Country</th>
                                        <th style={{width : '200px'}}>State</th>
                                        <th style={{width : '100px'}}>City</th>
                                        <th style={{width : '100px'}}>Meter type</th>
                                        <th style={{width : '100px'}}>Pincode</th>
                                        <th style={{width : '200px'}}>Phone No.</th>
                                        <th style={{width : '150px'}}>Currency</th>
                                        <th style={{width : '200px'}}>Notes</th>
                                        <th style={{width : '100px'}}>Active</th>  
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.customers.map((value, index) => {
                                        return <tr key={index}>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={(e) => this.customerdelete(e,value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href="javascript:void(0)" onClick={(e) => this.customeredit(value)}><i className="fa fa-edit icons"></i></a>
                                                    </td>
                                                    <td>{value.customername}</td>
                                                    <td>{value.email}</td>
                                                    <td>
                                                        {value.boards.map((option, index) => (
                                                            <span key={index}>
                                                                {option.id == value.primary_board ? <span>{option.board_name}</span>: ''}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {value.boards.map((value, index) => {
                                                            return <span key={index}>{value.board_name},</span>
                                                        })}
                                                    </td>
                                                    <td>{value.first_name}</td>
                                                    <td>{value.last_name}</td>
                                                    <td>{value.address_1}</td>
                                                    <td>{value.address_2}</td>
                                                    <td>{value.country_name}</td>
                                                    <td>{value.state}</td>
                                                    <td>{value.city}</td>
                                                    <td>{value.meter_type=="2" ? "Flow Meter":"Meter"}</td>
                                                    <td>{value.pincode}</td>
                                                    <td>{value.phone}</td>
                                                    <td>{value.currency}</td>
                                                    <td>{value.notes}</td>
                                                    <td>
                                                        {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                                    </td>
                                                    
                                                </tr>
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                :
                (<>
                <Row>
                <Col md="12">
                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                  <CardHeader>
                    <CardTitle tag="h5">Customer Info :</CardTitle>
                  </CardHeader>
                  <CardBody>
                      <FieldGroup
                      control={this.customerForm}
                      strict= {false}
                      render={({ get, invalid, value }) => (
                      <form>
                          <Row>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="customername"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Customer Name" {...handler("text")}  />
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* Customer Name is required`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="email"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required") || hasError("email")} label="Email Id" {...handler("text")}  />
                                              </Grid>
                                              <span className="error">{touched
                                                  && hasError("required")
                                                  && `* Email is required`}
                                                  {touched
                                                      && hasError("email")
                                                      && `* Enter Valid Email Id`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              {this.state.editmode == false ? 
                                  <Col className="pr-1" md="4">
                                  <FieldControl
                                      name="password"
                                      strict= {false}
                                      render={({ handler, touched, hasError }) => (
                                          <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <Grid className="Formfieldcss" container spacing={1}>                
                                                  <Grid item>
                                                  <TextField type="password" fullWidth variant="outlined" error={touched && hasError("required")} label="Password *" {...handler("text")} />
                                                  </Grid>
                                                  <span className="error">{touched
                                                  && hasError("required")
                                                  && `* Password is required`}
                                                
                                              </span>
                                              </Grid>
                                          </div>
                                      )}
                                      />
                                  </Col>
                                  : ''}
                              </Row>
                              <Row>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="first_name"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField type="text" fullWidth variant="outlined" error={touched && hasError("required")}  label="First Name *" {...handler("text")}  />
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* First Name is required`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="last_name"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" fullWidth variant="outlined"  label="Last Name" {...handler("text")}  />
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="phone"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField type="number"   fullWidth variant="outlined"  error={touched && hasError("minLength") || hasError("maxLength") || hasError("pattern")} label="Phone Number" {...handler("text")}  />
                                              </Grid>
                                              <span className="error">{touched
                                                  && hasError("minLength") && !hasError("pattern")
                                                  && `* Phone number must have atleast 8 digits`}
                                                  {touched
                                                      && hasError("maxLength") && !hasError("pattern")
                                                      && `* Phone number must have only max 15 digits`}
                                                       {touched
                                                      && hasError("pattern")  
                                                      && `* Avoid special charectors`}
                                                      </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="address_1"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" fullWidth variant="outlined"  label="Address 1" {...handler("text")}  />
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="address_2"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" fullWidth variant="outlined"  label="Address 2" {...handler("text")}  />
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="4">
                              <FieldControl
                                  name="pincode"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField type="number" fullWidth variant="outlined"  error={touched && hasError("minLength") || hasError("maxLength") || hasError("pattern")} label="Pincode" {...handler("text")}  />
                                              </Grid>
                                              <span className="error">{touched
                                                  && hasError("minLength") && !hasError("pattern")
                                                  && `* Pincode must have 6 digits`}
                                                  {touched
                                                      && hasError("maxLength") && !hasError("pattern")
                                                      && `* Pincode must have 6 digits`}
                                                       {touched
                                                      && hasError("pattern")  
                                                      && `* Avoid special charectors`}
                                                      </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="country"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField  fullWidth                     
                                                  select
                                                  label="Select Country *"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                  value={this.state.selectedCountryValue}
                                                  error={touched && hasError("required")}
                                              >
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.countryhandleChange('')}>
                                                      Select Country
                                                  </MenuItem>
                                                  {this.state.countrylist.map(option => (
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.code} value={option.code} onClick={(e) => this.countryhandleChange(option.code)}>
                                                      {option.name}
                                                  </MenuItem>
                                                  ))}
                                              </TextField>
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* Country is required`}
                                              </span>
                                          </Grid>
                                          
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="state"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField  fullWidth                     
                                                  select
                                                  label="Select State *"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                  value={this.state.selectedStateValue}
                                                  error={touched && hasError("required")}
                                              >
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.statehandleChange('')}>
                                                      Select State
                                                  </MenuItem>
                                                  {this.state.statelist.map(option => (
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.name} value={option.name} onClick={(e) => this.statehandleChange(option.name)}>
                                                      {option.name}
                                                  </MenuItem>
                                                  ))}
                                              </TextField>
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* State is required`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="city"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField type="text" fullWidth variant="outlined"  label="City *" {...handler("text")} error={touched && hasError("required") ||  hasError("pattern")} />
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* City is required`}
                                                       {touched
                                                      && hasError("pattern")
                                                      && `* City name must be in  Alphabets`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="currency"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField  fullWidth                      
                                                  label="Currency"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                  disabled
                                              >
                                              </TextField>
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              </Row>
                              <hr />
                              <Row>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="boards"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <FormControl variant="outlined" fullWidth>
                                                  <InputLabel id="demo-mutiple-name-label">Boards *</InputLabel>
                                                          <Select
                                                          labelId="demo-mutiple-name-label"
                                                          id="demo-mutiple-name"
                                                          multiple
                                                          value={this.state.selectedboard}
                                                          {...handler("text")}
                                                          label="Boards"
                                                          >
                                                          {this.state.boardslist.map((option) => (
                                                              <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.boardhandleChange(e,option)}>
                                                              {option.board_name}
                                                              </MenuItem>
                                                          ))}
                                                          </Select>
                                                  </FormControl>
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="primary_board"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField  fullWidth                     
                                                  select
                                                  label="Select Primary Board *"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                  error={touched && hasError("required")}
                                                  value={this.state.selectedPrimaryBoardValue}
                                                  >
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.primaryboardhandleChange('')}>
                                                      Select Primary Board
                                                  </MenuItem>
                                                  {this.pri_list.map(option => (
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.primaryboardhandleChange(option.id)}>
                                                      {option.board_name}
                                                  </MenuItem>
                                                  ))}
                                              </TextField>
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* Primary Board is required`}
                                                      
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="time_zone"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField  fullWidth                      
                                                  label="Time Zone"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                  disabled
                                              >
                                              </TextField>
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              <FieldControl
                                  name="meter_type"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                              <TextField  fullWidth                     
                                                  select
                                                  label="Select Meter Type"
                                                  variant="outlined"
                                                  {...handler("text")}
                                                //   value={this.state.selectedStateValue}
                                                  error={touched && hasError("required")}
                                              >
                                                  {/* <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.statehandleChange('')}>
                                                      Select State
                                                  </MenuItem> */}
                                                  {meter_list.map(option => (
                                                  <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.name} value={option.id} >
                                                      {option.name}
                                                  </MenuItem>
                                                  ))}
                                              </TextField>
                                              </Grid>
                                              <span className="error">
                                                  {touched
                                                      && hasError("required")
                                                      && `* Meter Type is required`}
                                              </span>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                             
                             
                              </Row>
                              <hr />
                              <Row>
                              <Col className="pr-1" md="6">
                              <FieldControl
                                      name="is_admin"
                                      strict= {false}
                                      render={({ handler, touched }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                              <Grid item>
                                              <FormControlLabel
                                                  control={<Checkbox checked={this.customerForm.getRawValue().is_admin} value="is_admin" color="primary" onClick={(event) => this.can_admin(this.customerForm.getRawValue().is_admin)} />}
                                                  label="Is Admin"
                                              />
                                              </Grid>
                                          </Grid>
                                      </div>
                                      )}
                                  />
                              </Col>
                              <Col className="pr-1" md="6">
                              <FieldControl
                                      name="active"
                                      strict= {false}
                                      render={({ handler, touched }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1} alignItems="flex-end">
                                              <Grid item>
                                              <FormControlLabel
                                                  control={<Checkbox checked={this.customerForm.getRawValue().active} value="active"  color="primary" onClick={(event) => this.can_active(this.customerForm.getRawValue().active)} />}
                                                  label="Active"
                                              />
                                              </Grid>
                                          </Grid>
                                      </div>
                                      )}
                                  />
                              </Col>
                              </Row>
                              <hr />
                              <Row>
                              <Col className="pr-1" md="12">
                              <FieldControl
                                  name="notes"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                      <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <Grid className="Formfieldcss" container spacing={1}>                
                                              <Grid item>
                                                  <TextField type="text" multiline rows={2} fullWidth variant="outlined"  label="Notes" {...handler("text")} />
                                              </Grid>
                                          </Grid>
                                      </div>
                                  )}
                                  />
                              </Col>
                              <Col className="pr-1" md="3">
                              </Col>
                              <Col className="pr-1" md="3">
                              <div className="login-button changepassword">
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={invalid}
                                      onClick={(event) => this.handleSubmit(event,this.customerForm.getRawValue())}
                                      >
                                      {this.state.buttontext}
                                  </Button>
                              </div>
                              </Col>
                              <Col className="pr-1" md="3">
                              <div className="login-button changepassword">
                              <Button
                                  onClick={() => this.backtonormal()}
                                  variant="contained"
                                  color="warning"
                                  >
                                  Back
                              </Button>
                              </div>
                              </Col>
                              <Col className="pr-1" md="3">
                              </Col>
                          </Row>
                      </form>
                      )} 
                  />
                  </CardBody>
                </Card>
              </Col>
              </Row>
              {
                  this.state.buttontext == 'Update' ? 
                  <>
                  <Row>
                    <Col md="12">
                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader>
                            <CardTitle tag="h5">Board Info : </CardTitle>
                        </CardHeader>
                        <CardBody>
                        <FieldGroup
                            control={this.secondpageForm}
                            strict= {false}
                            render={({ get, invalid, value }) => (
                            <form>
                                <Row>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="board_id"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                    <TextField  fullWidth                     
                                                        select
                                                        label="Select Board"
                                                        variant="outlined"
                                                        {...handler("text")}
                                                        value={this.state.selectedPrimaryBoardinfoValue}
                                                        error={touched && hasError("required")}
                                                        >
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => this.primaryboardinfohandleChange('')}>
                                                            Select Board
                                                        </MenuItem>
                                                        {this.board_info_list.map(option => (
                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.primaryboardinfohandleChange(option.id)}>
                                                            {option.board_name}
                                                        </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Board is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="kva"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="number" inputRef={this.inputRef}   fullWidth variant="outlined"  label="SmartPo Capacity (KVA)" {...handler("number")} error={touched && hasError("required")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* kva is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="equipments"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Connected Equipments" {...handler("text")} error={touched && hasError("required")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Connected Equipments is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="load_type"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Load Type" {...handler("text")} error={touched && hasError("required")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Load Type is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="machine_location"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Machine Location" {...handler("text")}  />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="machine_hours"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="number" fullWidth variant="outlined"  label="Machine Hours" {...handler("number")}  />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="operating_condition"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Operation Condition" {...handler("text")}  />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="installation_date"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="date" required fullWidth variant="outlined" label="Installation Date" error={touched && hasError("required")}  {...handler("text")} />
                                                    </Grid>
                                                    <span className="error">
                                                        {touched
                                                            && hasError("required")
                                                            && `* Installation Date is required`}
                                                    </span>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="sim"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Sim #" {...handler("text")}  />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                    <FieldControl
                                        name="wifi"
                                        strict= {false}
                                        render={({ handler, touched, hasError }) => (
                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                    <Grid item>
                                                        <TextField type="text" fullWidth variant="outlined"  label="Wifi Name" {...handler("text")}  />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    </Col>
                                    <Col className="pr-1" md="3">
                                        <div className="login-button changepassword">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid}
                                                onClick={(event) => this.handleSubmitboardinfo(event,this.secondpageForm.getRawValue())}
                                                >
                                                {this.state.boardinfobuttontext}
                                            </Button>
                                        </div>
                                        </Col>
                                        <Col className="pr-1" md="3">
                                        <div className="login-button changepassword">
                                        <Button
                                            onClick={() => this.boardinfocancel()}
                                            variant="contained"
                                            color="danger"
                                            >
                                            Cancel
                                        </Button>
                                        </div>
                                    </Col>
                                    </Row>
                                </form>
                            )}
                        />
                        <hr />
                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <table id="boardinfotable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <thead>
                                    <tr>
                                        <th style={{width : '100px'}}>Actions</th>
                                        <th style={{width : '200px'}}>Board Name</th>
                                        <th style={{width : '200px'}}>SmartPO Capacity (kVA)</th>
                                        <th style={{width : '200px'}}>Connected Equipments</th>
                                        <th style={{width : '200px'}}>Load Type</th>
                                        <th style={{width : '200px'}}>Machine Location</th>
                                        <th style={{width : '200px'}}>Machine Hours</th>
                                        <th style={{width : '200px'}}>Operating Condition</th>  
                                        <th style={{width : '200px'}}>Installation Date</th>
                                        <th style={{width : '200px'}}>Sim #</th>  
                                        <th style={{width : '200px'}}>Wifi name</th>    
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.boardsinfolist.map((value, index) => {
                                        return <tr key={index}>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={(e) => this.boardinfodelete(e,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href="javascript:void(0)" onClick={(e) => this.boardinfoedit(value)}><i className="fa fa-edit icons"></i></a>
                                                    </td>
                                                    <td>{value.boardname}</td>
                                                    <td>{value.kva}</td>
                                                    <td>{value.equipments}</td>
                                                    <td>{value.load_type}</td>
                                                    <td>{value.machine_location}</td>
                                                    <td>{value.machine_hours}</td>
                                                    <td>{value.operating_condition}</td>
                                                    <td>{value.installation_date}</td>
                                                    <td>{value.sim}</td>
                                                    <td>{value.wifi}</td>
                                                </tr>
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <CardHeader>
                                <CardTitle tag="h5">Product Info : </CardTitle>
                            </CardHeader>
                            <CardBody>
                            <FieldGroup
                                control={this.product_info_form}
                                strict= {false}
                                render={({ get, invalid, value }) => (
                                <form>
                                    <Row>
                                        <Col className="pr-1" md="4">
                                        <FieldControl
                                            name="name"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <TextField  fullWidth                     
                                                            label="Product Name"
                                                            variant="outlined"
                                                            {...handler("text")}
                                                            error={touched && hasError("required")}
                                                            required
                                                            >
                                                        </TextField>
                                                        </Grid>
                                                        <span className="error">
                                                            {touched
                                                                && hasError("required")
                                                                && `* Product Name is required`}
                                                        </span>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md="4">
                                        <FieldControl
                                            name="specification"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <TextField  fullWidth                     
                                                            label="Product Specification"
                                                            variant="outlined"
                                                            {...handler("text")}
                                                            >
                                                        </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md="4">
                                        <FieldControl
                                            name="notes"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        <TextField  fullWidth                     
                                                            label="Notes"
                                                            variant="outlined"
                                                            {...handler("text")}
                                                            multiline rows={2}
                                                            >
                                                        </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            />
                                        </Col>
                                        <Col className="pr-1" md="3">
                                        </Col>
                                        <Col className="pr-1" md="3">
                                            <div className="login-button changepassword">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={invalid}
                                                    onClick={(event) => this.handleSubmitproductinfo(event,this.product_info_form.getRawValue())}
                                                    >
                                                    {this.state.productinfobuttontext}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="pr-1" md="3">
                                            <div className="login-button changepassword">
                                                <Button
                                                    onClick={() => this.productinfocancel()}
                                                    variant="contained"
                                                    color="danger"
                                                    >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="pr-1" md="3">
                                        </Col>
                                    </Row>
                                </form>
                                )}
                            />
                            <hr />
                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <table id="productinfotable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <thead>
                                            <tr>
                                                <th style={{width : '100px'}}>Actions</th>
                                                <th style={{width : '200px'}}>Product Name</th>
                                                <th style={{width : '200px'}}>Product Specification</th>
                                                <th style={{width : '200px'}}>Notes</th>    
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.productinfolist.map((value, index) => {
                                                return <tr key={index}>
                                                            <td>
                                                                <a href="javascript:void(0)" onClick={(e) => this.productinfodelete(e,value)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <a href="javascript:void(0)" onClick={(e) => this.productinfoedit(value)}><i className="fa fa-edit icons"></i></a>
                                                            </td>
                                                            <td>{value.name}</td>
                                                            <td>{value.specification}</td>
                                                            <td>{value.notes}</td>
                                                        </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>   
                </> 
                  :
                  ''
              }
                
                </> 
            )
            }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor 
    }
  }

export default connect(mapStateToProps)(Customers);