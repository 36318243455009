import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

class Changepassword extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false
        }
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/changepassword')
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    changeForm = FormBuilder.group({
        current_password: ["", [Validators.required]],
        new_password: ["", [Validators.required]],
        new_password_again: ["", [Validators.required]]
    });

    handleSubmit(e, value) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.Changepassword(value).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Password has been Changed !!!!")
            localStorage.clear();
            this.props.history.push('/neurobotz/login')
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
      }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
              <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                <FieldGroup
                    control={this.changeForm}
                    render={({ get, invalid, value }) => (
                    <form onSubmit={(e) => this.handleSubmit(e, value)}>
                        <Row>
                            <Col className="pr-1" md="4">
                            <FieldControl
                                name="current_password"
                                render={({ handler, touched, hasError }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <TextField type="password" required fullWidth variant="outlined" error={touched && hasError("required")} label="Current Password" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* Current Password is required`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md="4">
                            <FieldControl
                                name="new_password"
                                render={({ handler, touched, hasError }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <TextField type="password" required fullWidth variant="outlined" error={touched && hasError("required")} label="New Password" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* New Password is required`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md="4">
                            <FieldControl
                                name="new_password_again"
                                render={({ handler, touched, hasError }) => (
                                    <div>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <TextField type="password" required fullWidth variant="outlined" error={touched && hasError("required")} label="New Password Again" {...handler("text")}  />
                                            </Grid>
                                            <span className="error">
                                                {touched
                                                    && hasError("required")
                                                    && `* New Password Again is required`}
                                            </span>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                        </Row>
                      <div className="login-button changepassword">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={invalid}
                            >
                            Change Password
                        </Button>
                      </div>
                    </form>
                    )} 
                />
                </CardBody>
              </Card>
            </Col>
              </Row>
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(Changepassword);