import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(.75),
  },
}));

export default function DragMultiselectSelect(props) {
    const {maintitle, alllist , selectedlist} = props;
    const classes = useStyles();
    const [initialchipData, setinitialChipData] = React.useState(props.selectlist);
    const [selectedchipData, setselectedChipData] = React.useState([]);

    const selectdata = (data) => () => {
        var selectedlist = props.selectlist
        for(let i=0;i<=selectedlist.length-1;i++) {
            if(data['id'] == selectedlist[i]['id']) {
                setselectedChipData([...selectedchipData, selectedlist[i]]);
            }
        }
        setinitialChipData((chips) => chips.filter((chip) => chip.id !== data.id));
    }

    const deselectdata = (data) => () => {
        setselectedChipData((chips) => chips.filter((chip) => chip.id !== data.id));
        var filteredlist = initialchipData.filter(chip => chip.id !== data.id)
        setinitialChipData([...filteredlist, data]);
    }

  return (
    <>
        <div>
            <h5>{maintitle}</h5>
        </div>
        <div>
            <h6>{alllist}</h6>
        </div>
        <div component="ul" className={classes.root}>
        {initialchipData.map((data) => {
            return (
            <li key={data.name} onClick={selectdata(data)}>
                <Chip
                deleteIcon={<ArrowDownwardIcon />}
                clickable
                label={data.name}
                onDelete={selectdata(data)}
                className={classes.chip}
                variant="outlined"
                color="secondary"
                />
            </li>
            );
        })}
        </div>
        <div>
            <h6>{selectedlist}</h6>
        </div>
        <div component="ul" className={classes.root}>
        {selectedchipData.map((data) => {
            return (
            <li key={data.id} onClick={deselectdata(data)}>
                <Chip
                deleteIcon={<ArrowUpwardIcon />}
                clickable
                label={data.name}
                onDelete={deselectdata(data)}
                className={classes.chip}
                color="primary"
                variant="outlined"
                />
            </li>
            );
        })}
        </div>
    </>
  );
}