import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import "../assets/css/auth.css"
import Switch from '@material-ui/core/Switch';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import floor_image from '../assets/images/poorvika_blueprint.jpg';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing2.png';
import swingicon1 from '../assets/images/displayswing.png';
import schedule from '../assets/images/schedule.png';
import remote from '../assets/images/remote.png';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Thermostat extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            modal: false,     
            activetab: "1",
            today:[new Date(new Date().toDateString()),new Date(new Date().toDateString())],
            dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())],

            thermostatList: [],
            showcontent: false,
            ChoosedThermoStat: null,
            thermostatName: "",
            autoStatusSetting: "",
            dataChange: false,
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            },
            editmode: false,
            buttontext: 'Add',
            dropDownList: [],

            scheduleHistoryList: [],
            ScheduleList: [],
            BoardList: [],
            startdate:"",
            enddate:'',

            remoteDataTemp:0,
            remoteDataPower:'AUTO',
            remoteDataPowerid:'',
            remoteDataFan:'LOW',
            remoteDataFanid:3,
            remoteDataSwing:'OFF',
            remoteDataSwingid:6,
            remoteThermo_id:'',
            remoteThermo_name:'',
        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.togglemodal = this.togglemodal.bind(this)
        this.getRemote = this.getRemote.bind(this)
        this.fanControl = this.fanControl.bind(this)
        this.swingControl = this.swingControl.bind(this)
        this.increaseTemp = this.increaseTemp.bind(this)
        this.decreaseTemp = this.decreaseTemp.bind(this)
        this.closeRemote = this.closeRemote.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.getdropdowndata()


    }
    scheduleForm = FormBuilder.group({
        id: [""],
        equipment: ["", [Validators.required]],
        datarange: ["", [Validators.required]],
        startTime: ["", [Validators.required]],

        endTime: ["", [Validators.required]],
        operation: ["", [Validators.required]],
        speed: [""],
        swing: [""],
        temperature: [""],

    });
    // class thermostat {
    //     equipment: Number;
    //     startTime: Time;
    //     endTime: Time;
    //     operation: Number;
    //     speed: Number;
    //     swing: Number;
    //     temperature: Number

    // }
	operationSelection() {
        // console.log(this.scheduleForm.value)
		// var x = this.thermosatatModel.operation
		if (this.scheduleForm.value.operation == 1) {
            this.scheduleForm.patchValue({
                swing:this.state.dropDownList.swing[0].id,
                speed:this.state.dropDownList.speed[0].id,
                temperature:this.state.dropDownList.temperature[0].id
            })

			

		} else {
		 this.scheduleForm.patchValue({
                swing:undefined,
                speed:undefined,
                temperature:undefined
            })

		}
	

	}
    cancel() {
        
        this.scheduleForm.reset()
        this.datehandleChange1(this.state.today)
        this.setState({
            buttontext: 'Add',
            dateselected:this.state.today
        })

    }
    statDetails() {
        // console.log(this.state.ScheduleList,this.state.scheduleHistoryList)
        this.setState({
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            }
        })

        if (this.state.dataChange == true) {
            // debugger
            this.setState({ dataChange: false,
                ChoosedThermoStat: null,
                thermostatName: "",
                autoStatusSetting: "", })
            // this.ChoosedThermoStat = null
            // this.acStatus = ''
            // this.fanStatus = ''
            // this.thermostatName = ''

            this.setState({ loader: true })
            apiService.getThermostat().then(response => {
                this.setState({ thermostatList: response.data, loader: false })

            }, error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)

            })

        } if (this.state.dataChange == false) {
            this.setState({ dataChange: true })
        }
        // this.clearForm()
    }
    thermoStatusCheckbox(data) {
        var checked = data.target.checked
        // console.log(data, checked)

        var x
        if (checked == true) {
            x = 0
        } else if (checked == false) {
            x = 1
        }
        this.setState({ autoStatusSetting: x }, () => {
            this.setState({ loader: true })
            apiService.editStatusHvac(this.state.ChoosedThermoStat.id, this.state.autoStatusSetting).then(response => {
                this.getSchedule()
                this.setState({ loader: false })

            }, error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)

            })
        })


        // this.loadingService.showLoading();
        // this.apiservice.editStatusHvac(this.ChoosedThermoStat.id, this.autoStatusSetting).subscribe((res: any) => {
        // 	this.getSchedule()
        // 	this.clearForm()
        // 	this.loadingService.hideLoading();

        // 	}, err => {
        // 		this.loadingService.hideLoading();
        // 		this.apiservice.errorfunction(err.error[0])

        // 	})
    }
    selectThermoStat(data) {
        var x = {
            temp: data.set_temp,
            power: data.operation,
            fan: "AUTO",
            swing: "AUTO",

        }


        if (data.operation == "ON") {
            x = {
                temp: data.schedule_temp,
                power: data.operation,
                fan: data.fan_speed,
                swing: data.swing_status,

            }
        } else if (data.operation == "OFF") {
            x = {
                temp: data.set_temp,
                power: data.operation,
                fan: "--",
                swing: "--",

            }
        }

        if(data.fan_speed == ""){
            this.setState({
                remoteDataFan:'LOW'
            })
        }
        else{
            this.setState({
                remoteDataFan: data.fan_speed
            })
        }

        if(data.swing_status == ""){
            this.setState({
                remoteDataSwing:'OFF'
            })
        }
        else{
            this.setState({
                remoteDataSwing: data.swing_status
            })
        }

        if(data.operation == 'ON'){
            this.setState({
                remoteDataPower:data.operation,
                remoteDataPowerid: 1,
                remoteDataTemp:data.schedule_temp,
            })
        }
        else{
            this.setState({
                remoteDataPower:data.operation,
                remoteDataPowerid: 2,
                remoteDataTemp:data.set_temp,
            })
        }
        this.setState({
            ChoosedThermoStat: data,
            thermostatName: data.thermo_desc,
            autoStatusSetting: data.status,
            displayData: x,
            remoteThermo_id:data.id,
            remoteThermo_name:data.thermo_name
        }, () => {this.getSchedule()}
        )

        sessionStorage.setItem("selectedThermoStatID", data.id);

    }

//     selectThermoStat(val) {
//         console.log(val)
//         this.setState({ loader: true })
//         apiService.getScheduleCurrentHvac(val.id).then(response => {
//             this.setState({loader: false })
//             console.log(response.data[0])
//             var data = response.data[0]
//             var x = {
//             temp: data.set_temp,
//             power: data.operation,
//             fan: "AUTO",
//             swing: "AUTO",

//             } 

//             if(JSON.stringify(val) === JSON.stringify(data)) {
// `                console.log('true')
// `            }

//             else{
//                 console.log('false')
//             }



//             if (data.operation == "ON") {
//                 x = {
//                     temp: data.schedule_temp,
//                     power: data.operation,
//                     fan: data.fan_speed,
//                     swing: data.swing_status,

//                 }
//             } else if (data.operation == "OFF") {
//                 x = {
//                     temp: data.set_temp,
//                     power: data.operation,
//                     fan: "--",
//                     swing: "--",

//                 }
//             }


//             this.setState({
//                 ChoosedThermoStat: data,
//                 thermostatName: data.thermo_desc,
//                 autoStatusSetting: data.status,
//                 displayData: x
//             }, () => this.getSchedule()
//             )
           
//         }, error => {
//             this.setState({ loader: false })
//             apiService.ErrorMessage(error)

//         })
       
    // }
    getSchedule() {
        // console.log(this.state.ChoosedThermoStat, "test", this.state.displayData)

        this.setState({ loader: true })
        apiService.getScheduleHvac(this.state.ChoosedThermoStat.id).then(response => {
            this.setState({ ScheduleList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
        this.setState({ loader: true })
        apiService.getScheduleHistoryHvac(this.state.ChoosedThermoStat.id).then(response => {
            this.setState({ scheduleHistoryList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    getdropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {
             var x={
                 equipment:response.data[0].Equipment,
                 operation:response.data[1].operation,
                 speed:response.data[2].speed,
                 swing:response.data[3].swing,
                 temperature:response.data[4].temperature,
 
             }
            //  console.log(x)

            this.setState({ dropDownList: x, loader: false })
            // console.log(this.state.dropDownList,response,"worsk")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    getThermostat() {

        this.setState({ loader: true })
        apiService.getThermostat().then(response => {
            // console.log(response.data)
            this.setState({ thermostatList: response.data })
            this.setState({ loader: false })
            var obj = JSON.parse(sessionStorage.getItem('selectedThermoStatID'))            
            if(sessionStorage.getItem('selectedThermoStatID').length == 0){
                this.setState({
                    thermostatList: response.data 
                })                
            }
            else{
                var item = this.state.thermostatList.find(item => item.id === obj);
                this.selectThermoStat(item)
            }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    boardData() {

        this.setState({ loader: true })
        var x = localStorage.getItem('selectedcustomer')
        apiService.topBoardDropdown(x).then(response => {
            this.setState({ BoardList: response.data })

            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleSubmit(event, value) {
       
        event.preventDefault();
        // console.log(value)
        var p=this.state

        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addSheduleHvac(value,p.startdate,p.enddate,p.ChoosedThermoStat).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Schedule Created Successfully")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editScheduleHvac(value,p.startdate,p.enddate,p.ChoosedThermoStat).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Schedule Updated Successfully")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    scheduleEdit(value) {
        // console.log(value)
        var x=[new Date(new Date(value.from_date).toDateString()),new Date(new Date(value.to_date).toDateString())]
        // dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())]
        this.scheduleForm.patchValue({
            id: value.id,
            equipment: value.equipments,
            datarange:x,
            startTime: value.from_time,
    
            endTime: value.to_time,
            operation: value.operation,
            speed: value.fan_speed,
            swing: value.swing_status,
            temperature:value.temperature
            // id: value.id,
            // name: value.thermo_name,
            // desc: value.thermo_desc,
            // board: value.board_id,
            // temperature: value.set_temp,

        })
     
        this.setState({
            buttontext: 'Update',
            dateselected:x
        })
        this.datehandleChange1(x)
    }

    scheduleDelete( value) {
        // event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteScheduleHvac(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Schedule Deleted Successfully")
                        this.getSchedule();
                        this.cancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/hvac')
            if (localStorage.getItem('selectedboard')) {

                this.setState({
                    showcontent: true,
                    thermostatList: []
                })

                this.datehandleChange1(this.state.today)
                // this.scheduleForm.patchValue({datarange:this.state.today})
                // this.setState({
                //     startdate: this.dateconvertion(this.state.today[0]),
                //     enddate: this.dateconvertion(this.state.today[1])
        
                // })
                sessionStorage.setItem("selectedThermoStatID", '');
                this.getThermostat()
                // if (sessionStorage.getItem('selectedThermoStat').length == 0) {
                //     console.log("empty")
                // }
                // console.log(this.state.dropDownList)
                // this.boardData()
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))

            }

        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    datehandleChange1(e) {
        // console.log(e,"comes")
        this.scheduleForm.patchValue({datarange:e})
        this.setState({
            startdate: this.dateconvertion(e[0]),
            enddate: this.dateconvertion(e[1])

        })
        // this.convertdate(e.target.value,localStorage.getItem('selectedcustomer'),'production')
    }

    dateconvertion(e){
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        return x
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    boardhandler(board_id) {
        // console.log(board_id, "board")
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            ChoosedThermoStat: null,
            thermostatName: "",
            autoStatusSetting: "",
            dataChange: false,
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            },
            buttontext: 'Add',

            scheduleHistoryList: [],
            ScheduleList: [],
        })
        localStorage.setItem('selectedboard', board_id)
        this.getThermostat()
        this.cancel()
        // if (this.state.period == 'MONTHLY') {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'QUARTERLY') {
        //     this.fetchdata(this.state.period,this.state.selectedquarter,this.state.checkboxvalue,this.selectedboard)
        // } else if (this.state.period == 'YEARLY') {
        //     this.fetchdata(this.state.period,this.state.selectedyear,this.state.checkboxvalue,this.selectedboard)
        // } else {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,this.selectedboard)
        // }
        this.setState({
            showcontent: true
        })
    }

    //-----------------------------REMOTE CONTROLS START-----------------------------------------------------------------

    togglemodal() {
        this.setState({
          modal: !this.state.modal
        });
        // this.getThermostat()
    }

    closeRemote(){
        this.getThermostat()
    }

    remoteApicall(name,value){
        if(name == ''){
            this.setState({ loader: true })
            apiService.hvacRemoteApi(this.state.remoteThermo_id,this.state.remoteThermo_name,this.state.remoteDataPowerid,this.state.remoteDataFanid,this.state.remoteDataSwingid,this.state.remoteDataTemp).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({ 
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,
        
                    },
                })
                apiService.Alerts("Command sent")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

        else{
            this.setState({ loader: true })
            apiService.hvacRemoteApiSingle(this.state.remoteThermo_id,this.state.remoteThermo_name,name,value).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({ 
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,
        
                    },
                })
                apiService.Alerts("Command sent")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
        
    }

    getRemote(){
        if(this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ){
            this.setState({
                remoteDataPower: 'ON',
                remoteDataPowerid:1
            },()=>{
                this.remoteApicall("","")
            })
        }
        else{
            this.setState({
                remoteDataPower: 'OFF',
                remoteDataPowerid:2
            },() =>{
                this.remoteApicall('operation', this.state.remoteDataPowerid)
            })
        }
    }

    fanControl(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataFan == 'LOW'){
                this.setState({
                    remoteDataFan: 'HIGH',
                    remoteDataFanid:4
                },()=>{
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
            else{
                this.setState({
                    remoteDataFan: 'LOW',
                    remoteDataFanid:3
                },() =>{
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
        }
    }

    swingControl(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataSwing == 'OFF'){
                this.setState({
                    remoteDataSwing: 'ON',
                    remoteDataSwingid:5
                },() =>{
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
            else{
                this.setState({
                    remoteDataSwing: 'OFF',
                    remoteDataSwingid:6
                },() =>{
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
        }
    }

    increaseTemp(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataTemp == 32){
                this.setState({
                    remoteDataTemp: 32
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })    
            }
            else{
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp + 1
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

    decreaseTemp(){
        if(this.state.remoteDataPower == 'ON'){
            if(this.state.remoteDataTemp == 16){
                this.setState({
                    remoteDataTemp: 16
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })    
            }
            else{
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp -1 
                },() =>{
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

//-----------------------------REMOTE CONTROLS END-----------------------------------------------------------------

    toggle(option) {
        this.setState({
            activetab: option
        })
        // if (option == '2') {
        //     this.getinterventionlist(localStorage.getItem('selectedboard'))
        // } 
        // else {
        //     this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            // this.createchart(this.state.chartdata,this.state.belowtableuom,this.props.charttheme)
        }
    }


 render() {
     function firstcolor(data){
        var x='#1092C1' 
        if(data=='ON'){
            x='green' 
        }else if(data=='OFF'){
            x='red' 
        }
        return x
     }
//    var x='green' 


//    [ngStyle]="displayData.power=='ON' ? { color:'green' } : displayData.power=='OFF' ? {color: 'red'} : displayData.power=='AUTO' ? {color: '#1092C1'} : {color: ''}"
  return (
      <>
          <div className="content">
              {
                  this.state.loader && <Loader />
              }
              <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
              {this.state.showcontent == true ?
                  <>
                      <Row>
                          {/* Remote Modal */}
                        <MDBContainer>
                        <MDBModal isOpen={this.state.modal} side position="top-right" data-mdb-backdrop="static">
                            <MDBModalHeader style={{display:"unset"}}>
                                <span className="ml-3">Remote - {this.state.thermostatName}</span>
                                <button type="button" onClick={() => { this.togglemodal(); this.closeRemote();}} className="close text-right mr-4 p-0" aria-label="Close">
                                    <span style={{fontSize:'50px', color:'red'}} aria-hidden="true">×</span>
                                </button>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <Row className="m-2" style={{height:"460px",background:"#f0f0f5"}}>
                                    <Col sm="12 p-0" className="border">
                                        <Row className="m-2 border">
                                            <Col sm="12" className="p-4 d-flex justify-content-center align-items-center"  style={{height:"180px",background:"#1f1f2e"}}>
                                                {this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO' ?
                                                <Row>
                                                    <Col sm="12">
                                                        <i class="fa fa-power-off fa-3x" style={{color:"#ff3333"}} aria-hidden="true"></i> &nbsp;&nbsp; <span className="text-white" style={{fontSize:"30px"}}><b>{this.state.remoteDataPower}</b></span>
                                                    </Col>
                                                </Row>
                                                
                                                :
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="12">
                                                            <i class="fa fa-power-off remote_display" id="remotepower" aria-hidden="true" style={{color:"#0FFF50"}}></i> &nbsp;&nbsp;<span className="text-white remote_display">{this.state.remoteDataPower}</span>
                                                            </Col>
                                                            <Col sm="12" className="pt-3">
                                                                <i class="fas fa-fan remote_display" style={{color:"#c2c2d6"}} aria-hidden="true"></i> &nbsp; <span className="text-white remote_display">{this.state.remoteDataFan}</span>
                                                            </Col>
                                                            <Col sm="12" className="pt-3">
                                                                <img src={swingicon1} height={20} width={20}/> &nbsp; <span className="text-white remote_display">{this.state.remoteDataSwing}</span>
                                                            </Col>
                                                            
                                                        </Row>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center align-items-center">
                                                        <span className="remote_display_temp"><b>{this.state.remoteDataTemp}&#176; C</b></span>
                                                    </Col>
                                                </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="m-2 border">
                                            <Col sm="12" className="p-3"  style={{height:"250px",background:"white"}}>
                                                <Row>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remotepower" onClick={this.getRemote}><i class="fa fa-power-off remote_temp_icon" id="remotepower" aria-hidden="true" style={{color:"#ff4d4d"}}></i></a>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remotefan" onClick={this.fanControl}><i class="fas fa-fan remote_temp_icon" id="remotefan" style={{color:"#47d147"}}></i></a>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <a href='javascript:void(0)' className="p-2 bg-light rounded" id="remoteswing" onClick={this.swingControl}><img src={swingicon} height={45} width={45}/></a>
                                                    </Col>
                                                </Row>
                                                <Row className="bg-light" style={{margin:"50px 10px 0 10px", height:"100px", borderRadius:"30px",alignItems:"center",justifyContent:'center'}}>
                                                    <Col className="d-flex justify-content-center">
                                                        <i class="fa fa-minus-circle remote_temp_icon" aria-hidden="true" onClick={this.decreaseTemp} style={{cursor:"pointer"}}></i>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                    <span style={{fontSize:"20px"}}>Temp</span>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        <i class="fa fa-plus-circle remote_temp_icon" style={{cursor:"pointer"}} onClick={this.increaseTemp} aria-hidden="true"></i>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </MDBModalBody>
                            <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => { this.togglemodal(); this.closeRemote();}}>Close</MDBBtn>
                            {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                            </MDBModalFooter>
                        </MDBModal>
                        </MDBContainer>

                          <Col md="12">

                              {this.state.dataChange == false ?
                                  <Row>
                                      <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5" className="dashboardcardtitle" style={{marginTop:0}}>Thermostat Control Centre</CardTitle>
                                              </CardHeader>
                                          </Card>
                                      </Col>
                                      <Col md="6">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5">
                                                      <Nav tabs>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('1'); }}
                                                              >
                                                                  List
                            </NavLink>
                                                          </NavItem>
                                                          {/* <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('2'); }}
                                                              >
                                                                  Blue print
                            </NavLink>
                                                          </NavItem> */}
                                                      </Nav>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody>
                                                  <TabContent activeTab={this.state.activetab}>
                                                      <TabPane tabId="1">
                                                          <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                              <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                  <tbody>
                                                                      {this.state.thermostatList.map((value, index) => (
                                                                          <tr key={index + 1} onClick={() => this.selectThermoStat(value)}>
                                                                              <th style={{ width: '150px' }}>
                                                                                 {this.state.ChoosedThermoStat==value &&  <i class="fa fa-check icons selectedthermo" aria-hidden="true"></i>}
                                                                                  {value.thermo_desc}</th>
                                                                              <td style={{ width: '150px' }}>{value.operation == 'AUTO' && <span>{value.set_temp + '°'}</span>}
                                                                                  {value.operation == 'OFF' && <span>--</span>}
                                                                                  {value.operation == 'ON' && <span>{value.schedule_temp + '°'}</span>}</td>

                                                                          </tr>

                                                                      ))}
                                                                  </tbody>
                                                                  {/* <tbody> */}

                                                              </table>
                                                          </div>

                                                      </TabPane>
                                                      <TabPane tabId="2">


                                                          <img src={floor_image} className={"img-rounded"} alt="Cinque Terre" width="100%" />
                                                      </TabPane>
                                                  </TabContent>
                                              </CardBody>
                                          </Card>
                                      </Col>
                                      <Col md="6">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                              {/* <CardTitle tag="h5" className="dashboardcardtitle">Thermostat status </CardTitle> */}

                                                  <CardTitle tag="h5" className="dashboardcardtitle">
                                                      <Row>
                                                          <Col className="pr-1" md="6">
                                                              {this.state.thermostatName}
                                                          </Col>
                                                          <Col className="pr-1 d-flex justify-content-center" lg="3">
                                                              {/* <Button
                                                                  type="submit"
                                                                  variant="contained"
                                                                  color="primary"
                                                                  disabled={this.state.ChoosedThermoStat == null}
                                                                  onClick={() => this.statDetails()}
                                                              >
                                                            <img src={schedule} height={45} width={45}/>
                                                            </Button> */}

                                                            <button
                                                                disabled={this.state.ChoosedThermoStat == null}
                                                                onClick={() => this.statDetails()}
                                                                style={{cursor:'pointer',display:'grid',padding:'5px', border:'none', backgroundColor:"Transparent"}}
                                                              >
                                                            <img src={schedule} height={50} width={55} style={{display:'block', margin: '0 auto'}}/>
                                                            <span className={"card-user pl-3 pr-3 " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{fontSize:'18px'}}>Schedule</span>
                                                            </button>
                                                          </Col>
                                                          <Col className="pr-1 d-flex justify-content-center" lg="3">
                                                            <button style={{cursor:'pointer',display:'grid',padding:'5px', border:'none', backgroundColor:"Transparent"}} onClick={this.togglemodal} data-backdrop="static" data-keyboard="false" disabled={this.state.ChoosedThermoStat == null}><img src={remote} height={50} width={50} style={{display:'block', margin: '0 auto'}}/>
                                                                <span className={"card-user pl-3 pr-3 " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{fontSize:'18px'}}>Remote</span>
                                                            </button>
                                                          </Col>
                                                      </Row>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody style={{ textAlign: "center" }}>
                                                  <div className={"dialog"}>
                                                      <img src={dial_image} style={{ position: "center", objectFit: "cover" }} className={"img-rounded"} alt="Cinque Terre" width="100%" />
                                                      <div>
                                                          <label>Set to</label><br />
                                                          {this.state.displayData.power != 'OFF' && <span>{this.state.displayData.temp}°</span>}

                                                      </div>
                                                  </div>
                                                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)+ ' '+ "thermocard"} >
                                                      <CardHeader>
                                                          <table >
                                                              <tr>
                                                                  <td>
                                                                      {/* <i style="font-size: 28px !important;padding-top: 2px;	color: green;"
																			[ngStyle]="displayData.power=='ON' ? { color:'green' } : displayData.power=='OFF' ? {color: 'red'} : displayData.power=='AUTO' ? {color: '#1092C1'} : {color: ''}"
                                                                            className='fas fa-power-off'></i><br> */}
                                                                     <i className="fa fa-power-off"  style={{color:firstcolor(this.state.displayData.power)}}/><br/>
                                                                      <span
                                                                          className="batch">POWER</span><br />
                                                                      <h5
                                                                      >
                                                                          {this.state.displayData.power}
                                                                      </h5>
                                                                  </td>

                                                                  <td>
                                                                      {/* <img src="../../assets/images/IRimage/fan.new.png"
																			width="30px" height="30px"><br> */}
                                                                              <i className="fas fa-fan" style={{color:firstcolor(this.state.displayData.fan)}} /><br/>
                                                                      <span
                                                                          className="batch">FAN</span>
                                                                      <h5 >{this.state.displayData.fan}</h5>
                                                                  </td>
                                                                  <td>
                                                                      {/* <img src="../../assets/images/IRimage/newAC.png"
																			width="30px" height="30px"><br> */}
                                                                             <i class="fas fa-wind" style={{color:firstcolor(this.state.displayData.swing)}}/><br/>
                                                                      <span
                                                                          className="batch">SWING</span>
                                                                      <h5 >{this.state.displayData.swing}
                                                                      </h5>
                                                                  </td>
                                                              </tr>

                                                          </table>
                                                      </CardHeader>
                                                  </Card>

                                              </CardBody>
                                          </Card>
                                      </Col>
                                  </Row>
                                  : <Row>
                                      <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5" className="dashboardcardtitle">{this.state.thermostatName} Status </CardTitle>
                                                  <CardTitle tag="h5" className="dashboardcardtitle">
                                                      <Switch
                                                          checked={this.state.autoStatusSetting == 0}
                                                          color="primary"
                                                          name="checkedA"
                                                          onChange={(e) => this.thermoStatusCheckbox(e)}
                                                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                          className={"switchtoggler " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                      />
                                                      {this.state.autoStatusSetting == 0 ? <span>Auto</span> : <span>Manual</span>}
                                                      <Button
                                                          type="button"
                                                          variant="contained"
                                                          color="danger"
                                                          style={{ float: 'right', marginTop: -41 }}
                                                          // disabled={this.state.ChoosedThermoStat==null}
                                                          onClick={() => this.statDetails()}
                                                          data-toggle="tooltip" data-placement="left" title="Click to go back"

                                                      >
                                                          X
                                                                    </Button></CardTitle>
                                                  {/* <Button></Button> */}

                                              </CardHeader>
                                          </Card>
                                          {this.state.autoStatusSetting == 1 &&
                                          <Row>
                                              <Col md="12">
                                                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                      <CardHeader>
                                                          <CardTitle tag="h5"></CardTitle>
                                                      </CardHeader>
                                                      <CardBody>
                                                          <FieldGroup
                                                              control={this.scheduleForm}
                                                              strict={false}
                                                              render={({ get, invalid, value }) => (
                                                                <form>
                                                                <Row>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="equipment"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField fullWidth
                                                                                                select
                                                                                                label="Select Mode"
                                                                                                variant="outlined"
                                                                                                {...handler("text")}
                                                                                                //   value={this.state.selectedCountryValue}
                                                                                                error={touched && hasError("required")}
                                                                                            >
                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                    Select Mode
                                            </MenuItem>
                                                                                                {this.state.dropDownList.equipment.map(option => (
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Mode name is required`}
                                                                                        </span>
                                                                                    </Grid>

                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                    <FieldControl
                                            name="datarange"
                                            strict= {false}
                                            render={({ handler, touched, hasError }) => (
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                        <Grid item>
                                                        {/* <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>   */}
                                                        <div className='control-pane'>
                                                            <div className='control-section'>
                                                                <div className='datepicker-control-section'>
                                                                    <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange1(e.target.value)} value={this.state.dateselected}>
                                                                        {/* <PresetsDirective>
                                                                            <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                            <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                            <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                            <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                        </PresetsDirective> */}
                                                                    </DateRangePickerComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="startTime"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="Start Time " {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="endTime"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="End Time " {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="operation"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField fullWidth
                                                                                                select
                                                                                                label="Select Operation"
                                                                                                variant="outlined"
                                                                                                {...handler("text")}
                                                                                                onClick={()=>this.operationSelection()}
                                                                                                //   value={this.state.selectedCountryValue}
                                                                                                error={touched && hasError("required")}
                                                                                            >
                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                    Select Operation
                                            </MenuItem>
                                                                                                {this.state.dropDownList.operation.map(option => (
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Operation is required`}
                                                                                        </span>
                                                                                    </Grid>

                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                           {(this.scheduleForm.value.operation==1 && this.scheduleForm.value.equipment == 1) && <>  
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="speed"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField fullWidth
                                                                                                select
                                                                                                label="Select Speed"
                                                                                                variant="outlined"
                                                                                                {...handler("text")}
                                                                                                //   value={this.state.selectedCountryValue}
                                                                                                // error={touched && hasError("required")}
                                                                                            >
                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                    Select Speed
                                            </MenuItem>
                                                                                                {this.state.dropDownList.speed.map(option => (
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </Grid>
                                                                                        {/* <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Speed is required`}
                                                                                        </span> */}
                                                                                    </Grid>

                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="swing"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField fullWidth
                                                                                                select
                                                                                                label="Select Swing"
                                                                                                variant="outlined"
                                                                                                {...handler("text")}
                                                                                                //   value={this.state.selectedCountryValue}
                                                                                                // error={touched && hasError("required")}
                                                                                            >
                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                    Select Swing
                                            </MenuItem>
                                                                                                {this.state.dropDownList.swing.map(option => (
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </Grid>
                                                                                        {/* <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Swing is required`}
                                                                                        </span> */}
                                                                                    </Grid>

                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="3">
                                                                        <FieldControl
                                                                            name="temperature"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField fullWidth
                                                                                                select
                                                                                                label="Select Temperature"
                                                                                                variant="outlined"
                                                                                                {...handler("text")}
                                                                                                //   value={this.state.selectedCountryValue}
                                                                                                // error={touched && hasError("required")}
                                                                                            >
                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                    Select Temperature
                                            </MenuItem>
                                                                                                {this.state.dropDownList.temperature.map(option => (
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </Grid>
                                                                                        {/* <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Board is required`}
                                                                                        </span> */}
                                                                                    </Grid>

                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                                                                                                                                                                                                 
                                                               </>
                                                              }
                                                               <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.handleSubmit(event, this.scheduleForm.getRawValue())}
                                                                    >
                                                                        {this.state.buttontext}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                                </Row>
                                                               </form>
                                                      
                                                              )}
                                                          />
                                                      </CardBody>

                                                  </Card>
                                              </Col>
                                          </Row>
 }
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5">
                                                      <Row>
                                                            <Col className="pr-1" md="12">
                                                                {this.state.thermostatName} Schedules
                                                            </Col>

                                                      </Row>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody style={{ textAlign: "center" }}>

                                                  <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                      <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                          <thead>
                                                              <tr>
                                                                  {this.state.autoStatusSetting == 1 && <th style={{width : '100px'}}>Actions</th>}
                                                                  <th style={{width : '100px'}}>Mode</th>
                                                                  <th style={{width : '100px'}}>From Date</th>
                                                                  <th style={{width : '100px'}}>To Date</th>
                                                                  <th style={{width : '100px'}}>Start Time</th>
                                                                  <th style={{width : '100px'}}>End Time</th>
                                                                  <th style={{width : '100px'}}>Status</th>
                                                                  <th style={{width : '100px'}}>Fan Speed</th>
                                                                  <th style={{width : '100px'}}>Swing</th>
                                                                  <th style={{width : '100px'}}>Temperature(C)</th>
                                                                  <th style={{width : '200px'}}>Created / Updated at</th>



                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              {this.state.ScheduleList.map((value, index) => (
                                                                  <tr key={index + 1}>
                                                                      {/* <td>{index+1}</td> */}
                                                                      {this.state.autoStatusSetting == 1 && <td>
                                                                          <a href="javascript:void(0)" onClick={(e) => this.scheduleEdit(value)}><i className="fa fa-edit icons"></i></a>
                                                                      </td>}
                                                                      <td>{value.equipment_name}</td>
                                                                      <td>{value.from_date}</td>
                                                                      <td>{value.to_date}</td>
                                                                      <td>{value.from_time}</td>
                                                                      <td>{value.to_time}</td>
                                                                      <td>{value.operation_name}</td>
                                                                      <td>{value.speed_name}</td>
                                                                      <td>{value.swing_name}</td>
                                                                      <td>{value.temp_name}{value.operation_name != 'OFF' && <span>°</span>}</td>
                                                                      <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>

                                                                      {/* <td>{Moment(value.updated_at).format('MMM DD yyyy hh:mm')}</td> */}
                                                                      {/* <td>{value.board_name}</td>
                                                            <td>{value.imei}</td>
                                                            <td>{value.expiry_date}</td>
                                                            <td>
                                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                                            </td> */}

                                                                  </tr>

                                                              ))}
                                                          </tbody>
                                                          {/* <tbody> */}

                                                      </table>
                                                  </div>


                                              </CardBody>
                                          </Card>
                                      </Col>
                                      <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardHeader>
                                                      <CardTitle tag="h5" className="dashboardcardtitle">

                                                      {this.state.thermostatName} Schedule History</CardTitle>
                                                  </CardHeader>
                                              </CardHeader>
                                              <CardBody style={{ textAlign: "center" }}>

                                                  <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                      <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                          <thead>
                                                              <tr>
                                                                  <th style={{width : '100px'}}>Actions</th>
                                                                  <th style={{width : '100px'}}>Mode</th>
                                                                  <th style={{width : '100px'}}>From Date</th>
                                                                  <th style={{width : '100px'}}>To Date</th>
                                                                  <th style={{width : '100px'}}>Start Time</th>
                                                                  <th style={{width : '100px'}}>End Time</th>
                                                                  <th style={{width : '100px'}}>Status</th>
                                                                  <th style={{width : '100px'}}>Fan Speed</th>
                                                                  <th style={{width : '100px'}}>Swing</th>
                                                                  <th style={{width : '100px'}}>Temperature(C)</th>

                                                                  <th style={{width : '200px'}}>Created / Updated at</th>

                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              {this.state.scheduleHistoryList.map((value, index) => (
                                                                  <tr key={index + 1}>
                                                                      {/* <td>{index+1}</td> */}
                                                                      <td>
                                                                      <a href="javascript:void(0)" onClick={(event) => this.scheduleDelete(value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;

                                                                      {this.state.autoStatusSetting == 1 && 
                                                                          <a href="javascript:void(0)" onClick={(e) => this.scheduleEdit(value)}><i className="fa fa-check icons"></i></a>
                                                                         }
                                                                          </td>
                                                                      <td>{value.equipment_name}</td>
                                                                      <td>{value.from_date}</td>
                                                                      <td>{value.to_date}</td>
                                                                      <td>{value.from_time}</td>
                                                                      <td>{value.to_time}</td>
                                                                      <td>{value.operation_name}</td>
                                                                      <td>{value.speed_name}</td>
                                                                      <td>{value.swing_name}</td>
                                                                      <td>{value.temp_name}{value.operation_name != 'OFF' && <span>°</span>}</td>
                                                                      <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>

                                                                      {/* <td>{Moment(value.updated_at).format('MMM DD yyyy hh:mm')}</td> */}
                                                                      {/* <td>{value.board_name}</td>
                                                            <td>{value.imei}</td>
                                                            <td>{value.expiry_date}</td>
                                                            <td>
                                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                                            </td> */}

                                                                  </tr>

                                                              ))}
                                                          </tbody>
                                                          {/* <tbody> */}

                                                      </table>
                                                  </div>


                                              </CardBody>
                                          </Card>
                                      </Col>

                                  </Row>}
                          </Col>
                      </Row>
                  </>
                  :
                  <Row>
                      <Col md="12">
                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                              <CardHeader>
                              </CardHeader>
                              <CardBody>
                                  <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(Thermostat);
