import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../services/normalloader';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import * as apiService from '../services/apiService';
import { Checkbox, ListItemIcon, ListItemText } from "@material-ui/core"
import { CardHeader, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Switch from '@material-ui/core/Switch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import swal from 'sweetalert';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import Thermostat from './Thermostat';
import floor_image from '../assets/images/poorvika_blueprint.jpg';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing2.png';
import swingicon1 from '../assets/images/displayswing.png';
import schedule from '../assets/images/schedule.png';
import remote from '../assets/images/remote.png';
import { isUndefined } from '@syncfusion/ej2-base';
const $ = require('jquery');
$.DataTable = require('datatables.net');
class GroupConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            showcontent: true,
            modal: false,
            activetab: "1",
            dataChange: false,
            buttontext: 'Add',
            from_date: "",
            to_date: '',
            groupList: [],
            ProcessgroupList: [],
            SubProcessgroupList: [],
            SubProcessNameList: [],
            GroupsearchTerm: '',
            formatGroupList: [],
            formatProcessgroupList: [],
            formatSubProcessgroupList: [],
            isAllDailyProcessSelected: false,
            daily_selectedprocess: [],
            isAllmainmeterSelected: false,
            mainmeter_selectedprocess: [],
        };
        //this.toggle('1')
    }
    GroupForm = FormBuilder.group({
        id: [""],
        group_name: ["", [Validators.required]],
        main_meter: [""],
        customer_id: [""]
    });
    GroupMappingForm = FormBuilder.group({
        id: [""],
        grouping_id: ["", [Validators.required]],
        process_name: ["", [Validators.required]],
        customer_id: [""]
    });
    SubGroupMappingForm = FormBuilder.group({
        id: [""],
        process_name: [""],
        subprocess_name: [""],
        subprocess_id: ["", [Validators.required]],
        grouping_process_id: ["", [Validators.required]],
        customer_id: [""]
    });
    componentDidMount() {
        this.toggle('1');
    }
    handleEventInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        var Formvalue = this.SubGroupMappingForm.getRawValue();
        this.SubGroupMappingForm.patchValue({
            id: value.id,
            process_name: Formvalue.process_name,
            subprocess_name: Formvalue.subprocess_name,
            subprocess_id: Formvalue.subprocess_id,
            grouping_process_id: event.target.value,
            customer_id: Formvalue.customer_id
        })
        this.getSubProcessGroupingList(event.target.value);
    };
    handleGroupEventInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        var Formvalue = this.GroupMappingForm.getRawValue();
        this.GroupMappingForm.patchValue({
            id: Formvalue.id,
            grouping_id: event.target.value,
            process_name: Formvalue.process_name,
            customer_id: Formvalue.customer_id,
        })
        this.getProcessGroupingList(event.target.value);
    };
    //--------------------Grouping-----------------//
    getGroupingList() {
        apiService.getGroupingList().then(response => {
            this.setState({ groupList: response.data, loader: false })
            this.formatGroupingData();
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    formatGroupingData() {

        var formatData = this.state.groupList.map(item => ({
            ...item,
            //['board_id']: this.BindBoardName(item.lboard_id),
            ['updated_at']: Moment(item.updated_at).format('MMM DD YYYY HH:mm:ss')// Update the specified column
        }));
        this.setState({ formatGroupList: formatData });
    }
    formatProcessGroupingData() {

        var formatData = this.state.ProcessgroupList.map(item => ({
            ...item,
            ['grouping_id']: item.grouping_id,
            ['lgrouping_id']: this.BindGroup(item.grouping_id),
            ['updated_at']: Moment(item.updated_at).format('MMM DD YYYY HH:mm:ss')// Update the specified column
        }));
        this.setState({ formatProcessgroupList: formatData });
    }
    formatPSubProcessgroupData() {

        var formatData = this.state.SubProcessgroupList.map(item => ({
            ...item,
            ['lgrouping_process_id']: this.BindProcessGroup(item.grouping_process_id),
            ['updated_at']: Moment(item.updated_at).format('MMM DD YYYY HH:mm:ss')// Update the specified column
        }));
        this.setState({ formatSubProcessgroupList: formatData });
    }
    Groupingcancel() {
        this.setState({
            buttontext: 'Add',
            mainmeter_selectedprocess:''
        })
        this.GroupForm.reset()

    }
    GroupinghandleSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            value.customer_id = localStorage.getItem('selectedcustomer');
            this.setState({ loader: true })
            apiService.addGrouping(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getGroupingList();
                this.Groupingcancel();

            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateGrouping(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getGroupingList();
                this.Groupingcancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    Groupingedit(value) {
        this.GroupForm.patchValue({
            id: value.id,
            group_name: value.group_name,
            customer_id: value.customer_id,
            main_meter: value.main_meter,
        })
        this.setState({ mainmeter_selectedprocess: value.main_meter });
        this.setState({
            buttontext: 'Update'
        })
    }
    Groupingdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Group!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteGrouping(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts(response.data[0].message)
                        this.getGroupingList();
                        this.Groupingcancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    //--------------------Grouping------------------//

    //--------------------Group Mapping-----------------//
    getProcessGroupingList(value = null) {
        apiService.getProcessGroupingList(value).then(response => {
            this.setState({ ProcessgroupList: response.data, loader: false })
            this.formatProcessGroupingData();
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    triggergroupingCalc(event) {
        event.preventDefault();
        apiService.triggergroupingCalc().then(response => {
            apiService.Alerts(response.data[0].message)
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    ProcessGroupingcancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.GroupMappingForm.reset()
    }
    ProcessGroupinghandleSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            value.customer_id = localStorage.getItem('selectedcustomer');
            this.setState({ loader: true })
            apiService.addProcessGrouping(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getProcessGroupingList();
                this.ProcessGroupingcancel();

            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateProcessGrouping(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getProcessGroupingList();
                this.ProcessGroupingcancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    ProcessGroupingedit(value) {
        this.GroupMappingForm.patchValue({
            id: value.id,
            grouping_id: value.grouping_id,
            customer_id: value.customer_id,
            process_name: value.process_name,
        })
        this.setState({
            buttontext: 'Update'
        })
    }
    ProcessGroupingdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Group!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteProcessGrouping(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts(response.data[0].message)
                        this.getProcessGroupingList();
                        this.ProcessGroupingcancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    //--------------------Group Mapping------------------//
    //--------------------Subprocess Mapping-----------------//
    getSubProcessGroupingList(value = null) {
        apiService.getSubProcessMappingList(value).then(response => {
            this.setState({ SubProcessgroupList: response.data, loader: false })
            this.formatPSubProcessgroupData();
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    SubProcessGroupingcancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.SubGroupMappingForm.reset()
    }
    SubProcessGroupinghandleSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            value.customer_id = localStorage.getItem('selectedcustomer');
            //const subprocess=[];
            const subprocessids = value.subprocess_id;
            const subprocess = subprocessids.map(selectedId => ({
                id: selectedId,
                itemName: this.state.SubProcessNameList.find(option => option.board_id === selectedId).process_name
            }));

            // subprocess = subprocessids.map(
            //     (selectedId) => new{
            //         id:selectedId,
            //         itemName:this.state.SubProcessNameList.find((option) => option.board_id === selectedId).process_name
            //     }
            //   );
            //value.subprocess_name = this.state.SubProcessNameList.filter(s => s.board_id == value.subprocess_id)[0]['process_name'];
            this.setState({ loader: true })
            apiService.addSubProcessMapping(value, subprocess).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getSubProcessGroupingList();
                this.SubProcessGroupingcancel();
                this.setState({ daily_selectedprocess: [], isAllDailyProcessSelected: false });
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateSubProcessMapping(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts(response.data[0].message)
                this.getSubProcessGroupingList();
                this.SubGroupMappingForm();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    SubProcessGroupingedit(value) {
        this.SubGroupMappingForm.patchValue({
            id: value.id,
            process_name: value.process_name,
            subprocess_name: value.subprocess_name,
            subprocess_id: value.subprocess_id,
            grouping_process_id: value.grouping_process_id,
            customer_id: value.customer_id
        })
        this.setState({ daily_selectedprocess: [value.subprocess_id] });
        this.setState({
            buttontext: 'Update'
        })
    }
    SubProcessGroupingdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Group!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteSubProcessMapping(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts(response.data[0].message)
                        this.getSubProcessGroupingList();
                        this.SubGroupMappingForm();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    //--------------------Subprocess Mapping------------------//
    getSubProcessNameList() {
        apiService.getSupprocessNameList().then(response => {
            this.setState({ SubProcessNameList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    BindGroup(id) {
        if (this.state.groupList.filter(s => s.id == id).length > 0)
            return this.state.groupList.filter(s => s.id == id)[0]['group_name']
        else {
            return "";
        }
    }
    handleProcessSelectAll = () => {
        const { isAllDailyProcessSelected } = this.state;
        const allIds = this.state.SubProcessNameList.map((option) => option.board_id);
        this.SubGroupMappingForm.patchValue({
            subprocess_id: isAllDailyProcessSelected ? [] : allIds
        })
        this.daily_pri_list = []
        this.setState({
            daily_selectedprocess: isAllDailyProcessSelected ? [] : allIds,
            isAllDailyProcessSelected: !isAllDailyProcessSelected,
        });
    };
    handlemainmeterSelectAll = () => {
        const { isAllmainmeterSelected } = this.state;
        const allIds = this.state.SubProcessNameList.map((option) => option.board_id);
        this.GroupForm.patchValue({
            main_meter: isAllmainmeterSelected ? [] : allIds
        })
        this.daily_pri_list = []
        this.setState({
            mainmeter_selectedprocess: isAllmainmeterSelected ? [] : allIds,
            isAllmainmeterSelected: !isAllmainmeterSelected,
        });
    };
    handleChange = (event) => {
        const { value } = event.target;
        if (value.includes('all')) {
            this.handleProcessSelectAll();
            return;
        }
        this.setState({ daily_selectedprocess: value, isAllDailyProcessSelected: false });
        if (this.state.SubProcessNameList.length == value.length) {
            this.setState({ isAllDailyProcessSelected: true });
        }
        this.SubGroupMappingForm.patchValue({
            subprocess_id: value
        })
    };
    handlemainmeterChangeNew=(event)=>{
        const { value } = event.target;
        this.GroupForm.patchValue({
            main_meter: value
        })
        this.setState({ mainmeter_selectedprocess: value });
    }
    handlemainmeterChange = (event) => {
        const { value } = event.target;
        if (value.includes('all')) {
            this.handlemainmeterSelectAll();
            return;
        }
        this.setState({ mainmeter_selectedprocess: value, isAllmainmeterSelected: false });
        if (this.state.SubProcessNameList.length == value.length) {
            this.setState({ isAllmainmeterSelected: true });
        }
        this.GroupForm.patchValue({
            main_meter: value
        })
    };
    BindProcessGroup(id) {
        if (this.state.ProcessgroupList.filter(s => s.id == id).length > 0)
            return this.state.ProcessgroupList.filter(s => s.id == id)[0]['process_name']
        else {
            return "";
        }
    }
    toggle(option) {
        this.setState({
            activetab: option,
            GroupsearchTerm: '',
            buttontext: 'Add'
        })
        if (option == "2") {
            this.getProcessGroupingList();
        }
        if (option == "3") {
            this.getProcessGroupingList();
            this.getSubProcessGroupingList();
            this.getSubProcessNameList();
        }
        if (option == "1") {
            this.getGroupingList();
            this.getProcessGroupingList();
            this.getSubProcessNameList();
        }
    }
    handleGroupSearchInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ GroupsearchTerm: value });
    };
    BindmainMeter(id) {
        if (this.state.SubProcessNameList.filter(s => s.board_id == id).length > 0)
            return this.state.SubProcessNameList.filter(s => s.board_id == id)[0]['process_name']
        else {
            return "";
        }
    }

    render() {
        const { daily_selectedprocess, isAllDailyProcessSelected } = this.state;
        const { mainmeter_selectedprocess, isAllmainmeterSelected } = this.state;
        const selectedNames = daily_selectedprocess.map(
            (selectedId) =>
                this.state.SubProcessNameList.find((option) => option.board_id === selectedId).process_name
        );
        // const selectedmainmeterNames = mainmeter_selectedprocess.map(
        //     (selectedId) =>
        //         !isUndefined(this.state.SubProcessNameList.find((option) => option.board_id === selectedId)) ? this.state.SubProcessNameList.find((option) => option.board_id === selectedId).process_name : ''
        // );
        const filteredGroupData = this.state.formatGroupList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.GroupsearchTerm.toLowerCase())
            )
        );
        const filteredProcessgroupData = this.state.formatProcessgroupList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.GroupsearchTerm.toLowerCase())
            )
        );
        const filteredSubProcessgroupData = this.state.formatSubProcessgroupList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.GroupsearchTerm.toLowerCase())
            )
        );
        const classes = ((theme) => ({
            formControl: {
                margin: theme.spacing(1),
                width: 300
            },
            indeterminateColor: {
                color: "#0044f5"
            },
            selectAllText: {
                fontWeight: 500
            },
            selectedAll: {
                backgroundColor: "#0044f5",
                "&:hover": {
                    backgroundColor: "#0044f5"
                }
            }
        }));
        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <>
                        <Row>
                            <Col md="12">
                                <div className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                        <CardTitle tag="h5">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('1'); }}
                                                    >
                                                        Group Creation
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('2'); }}
                                                    >
                                                        Process Mapping
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('3'); }}
                                                    >
                                                        Sub Process Mapping
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent activeTab={this.state.activetab}>
                                            <TabPane tabId="2">
                                                <div>
                                                    {this.state.showcontent == true ?
                                                        <>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <FieldGroup
                                                                                control={this.GroupMappingForm}
                                                                                strict={false}
                                                                                render={({ get, invalid, value }) => (
                                                                                    <form>
                                                                                        <Row>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <FieldControl
                                                                                                    name="grouping_id"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField fullWidth
                                                                                                                        select
                                                                                                                        label="Select Group *"
                                                                                                                        variant="outlined"
                                                                                                                        {...handler("text")}
                                                                                                                        //value={handler.value}
                                                                                                                        name="grouping_id"
                                                                                                                        error={touched && hasError("required")}
                                                                                                                        //onChange={this.handleGroupEventInputChange}
                                                                                                                        onChange={(event) => {
                                                                                                                            //handler.onChange(event); 
                                                                                                                            this.handleGroupEventInputChange(event);
                                                                                                                        }}

                                                                                                                    >
                                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                            Select Group
                                                                                                                        </MenuItem>
                                                                                                                        {this.state.groupList.map(option => (
                                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                                {option.group_name}
                                                                                                                            </MenuItem>
                                                                                                                        ))}
                                                                                                                    </TextField>
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Group is required`}
                                                                                                                </span>
                                                                                                            </Grid>

                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <FieldControl
                                                                                                    name="process_name"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Process Name" {...handler("text")} />
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Process Name is required`}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                            </Col>

                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        type="submit"
                                                                                                        variant="contained"
                                                                                                        color="primary"
                                                                                                        disabled={invalid}
                                                                                                        onClick={(event) => this.ProcessGroupinghandleSubmit(event, this.GroupMappingForm.getRawValue())}
                                                                                                    >
                                                                                                        {this.state.buttontext}
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        onClick={() => this.ProcessGroupingcancel()}
                                                                                                        variant="contained"
                                                                                                        color="danger"
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>

                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </form>
                                                                                )}
                                                                            />
                                                                        </CardBody>

                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col className="pr-1" md="9">
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">

                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.GroupsearchTerm} onChange={this.handleGroupSearchInputChange} />
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </div>
                                                                                    {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                                </Col>
                                                                            </Row>
                                                                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: '100px' }}>Actions</th>
                                                                                            <th style={{ width: '100px' }}>Group</th>
                                                                                            <th style={{ width: '100px' }}>Process Name</th>
                                                                                            <th style={{ width: '200px' }}>Created at</th>
                                                                                            <th style={{ width: '200px' }}>Updated at</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {filteredProcessgroupData.map((value, index) => (
                                                                                            <tr key={index + 1}>
                                                                                                {/* <td>{index+1}</td> */}
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" onClick={(event) => this.ProcessGroupingdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    <a href="javascript:void(0)" onClick={(e) => this.ProcessGroupingedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                </td>
                                                                                                <td>{value.lgrouping_id}</td>
                                                                                                <td>{value.process_name}</td>

                                                                                                <td>{Moment(value.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>

                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <Row>
                                                            <Col md="12">
                                                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <CardHeader>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="1">
                                                <div>
                                                    {this.state.showcontent == true ?
                                                        <>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <FieldGroup
                                                                                control={this.GroupForm}
                                                                                strict={false}
                                                                                render={({ get, invalid, value }) => (
                                                                                    <form>
                                                                                        <Row>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <FieldControl
                                                                                                    name="group_name"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Group Name" {...handler("text")} />
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Group Name is required`}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                                        <Grid item>
                                                                                                            <TextField fullWidth
                                                                                                                select
                                                                                                                label="Main Meter"
                                                                                                                variant="outlined"
                                                                                                                name="main_meter"
                                                                                                                value={this.state.mainmeter_selectedprocess}
                                                                                                            onChange={this.handlemainmeterChangeNew}
                                                                                                            >
                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                    Select Main Meter
                                                                                                                </MenuItem>
                                                                                                                {this.state.SubProcessNameList.map(option => (
                                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.board_id} value={option.board_id} >
                                                                                                                        {option.process_name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </TextField>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        type="submit"
                                                                                                        variant="contained"
                                                                                                        color="primary"
                                                                                                        disabled={invalid}
                                                                                                        onClick={(event) => this.GroupinghandleSubmit(event, this.GroupForm.getRawValue())}
                                                                                                    >
                                                                                                        {this.state.buttontext}
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        onClick={() => this.Groupingcancel()}
                                                                                                        variant="contained"
                                                                                                        color="danger"
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>

                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </form>
                                                                                )}
                                                                            />
                                                                        </CardBody>

                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col className="pr-1" md="9">
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">

                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.GroupsearchTerm} onChange={this.handleGroupSearchInputChange} />
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </div>
                                                                                    {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                                </Col>
                                                                            </Row>
                                                                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: '100px' }}>Actions</th>
                                                                                            <th style={{ width: '100px' }}>Group Name</th>
                                                                                            <th style={{ width: '100px' }}>Main Meter</th>
                                                                                            <th style={{ width: '200px' }}>Created at</th>
                                                                                            <th style={{ width: '200px' }}>Updated at</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {filteredGroupData.map((value, index) => (
                                                                                            <tr key={index + 1}>
                                                                                                {/* <td>{index+1}</td> */}
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" onClick={(event) => this.Groupingdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    <a href="javascript:void(0)" onClick={(e) => this.Groupingedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                </td>
                                                                                                <td>{value.group_name}</td>
                                                                                                <td>{this.BindmainMeter(value.main_meter)}</td>
                                                                                                <td>{Moment(value.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <Row>
                                                            <Col md="12">
                                                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <CardHeader>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div>
                                                    {this.state.showcontent == true ?
                                                        <>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <FieldGroup
                                                                                control={this.SubGroupMappingForm}
                                                                                strict={false}
                                                                                render={({ get, invalid, value }) => (
                                                                                    <form>
                                                                                        <Row>
                                                                                            {/* <Col className="pr-1" md="3">
                                                                                                <FieldControl
                                                                                                    name="process_name"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Process Name" {...handler("text")} />
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Process Name is required`}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                            </Col> */}
                                                                                            <Col className="pr-1" md="3">
                                                                                                <FieldControl
                                                                                                    name="grouping_process_id"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField fullWidth
                                                                                                                        select
                                                                                                                        label="Select Group Process *"
                                                                                                                        variant="outlined"
                                                                                                                        {...handler("text")}
                                                                                                                        name="grouping_process_id"
                                                                                                                        //   value={this.state.selectedCountryValue}
                                                                                                                        onChange={this.handleEventInputChange}
                                                                                                                        error={touched && hasError("required")}
                                                                                                                    >
                                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                            Select Group Process
                                                                                                                        </MenuItem>
                                                                                                                        {this.state.ProcessgroupList.map(option => (
                                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                                {option.process_name}
                                                                                                                            </MenuItem>
                                                                                                                        ))}
                                                                                                                    </TextField>
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Group Process is required`}
                                                                                                                </span>
                                                                                                            </Grid>

                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <FormControl className={classes.formControl} style={{ width: "100%", marginBottom: 32 }}>
                                                                                                    <InputLabel id="mutiple-select-label">Sub Process</InputLabel>
                                                                                                    <Select
                                                                                                        labelId="mutiple-select-label"
                                                                                                        id="demo-mutiple-name-label3"
                                                                                                        fullWidth variant="outlined"
                                                                                                        multiple required
                                                                                                        label="Sub Process"
                                                                                                        onChange={this.handleChange}
                                                                                                        value={this.state.daily_selectedprocess}
                                                                                                        renderValue={() => selectedNames.join(', ')}

                                                                                                    >
                                                                                                        <MenuItem
                                                                                                            value="all"
                                                                                                            classes={{
                                                                                                                root: this.state.isAllDailyProcessSelected ? classes.selectedAll : ""
                                                                                                            }}
                                                                                                        >
                                                                                                            <ListItemIcon>
                                                                                                                <Checkbox
                                                                                                                    classes={{ indeterminate: classes.indeterminateColor }}
                                                                                                                    checked={this.state.isAllDailyProcessSelected}
                                                                                                                    indeterminate={
                                                                                                                        this.state.daily_selectedprocess.length > 0 && this.state.daily_selectedprocess.length < this.state.SubProcessNameList.length
                                                                                                                    }
                                                                                                                />
                                                                                                            </ListItemIcon>
                                                                                                            <ListItemText
                                                                                                                classes={{ primary: classes.selectAllText }}
                                                                                                                primary="Select All"
                                                                                                            />
                                                                                                        </MenuItem>
                                                                                                        {this.state.SubProcessNameList.map((option) => (
                                                                                                            <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.board_id} value={option.board_id}>
                                                                                                                <ListItemIcon>
                                                                                                                    <Checkbox checked={this.state.daily_selectedprocess.includes(option.board_id)} />
                                                                                                                </ListItemIcon>
                                                                                                                <ListItemText primary={option.process_name} />
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                                {/* <FieldControl
                                                                                                    name="subprocess_id"
                                                                                                    strict={false}
                                                                                                    render={({ handler, touched, hasError }) => (
                                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                <Grid item>
                                                                                                                    <TextField fullWidth
                                                                                                                        select
                                                                                                                        label="Select Sub Process *"
                                                                                                                        variant="outlined"
                                                                                                                        {...handler("text")}
                                                                                                                        name="subprocess_id"
                                                                                                                        //   value={this.state.selectedCountryValue}
                                                                                                                        error={touched && hasError("required")}
                                                                                                                    >
                                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                            Select Sub process
                                                                                                                        </MenuItem>
                                                                                                                        {this.state.SubProcessNameList.map(option => (
                                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.board_id} value={option.board_id} >
                                                                                                                                {option.process_name}
                                                                                                                            </MenuItem>
                                                                                                                        ))}
                                                                                                                    </TextField>
                                                                                                                </Grid>
                                                                                                                <span className="error">
                                                                                                                    {touched
                                                                                                                        && hasError("required")
                                                                                                                        && `* Sub Process is required`}
                                                                                                                </span>
                                                                                                            </Grid>

                                                                                                        </div>
                                                                                                    )}
                                                                                                /> */}
                                                                                            </Col>

                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        type="submit"
                                                                                                        variant="contained"
                                                                                                        color="primary"
                                                                                                        disabled={invalid}
                                                                                                        onClick={(event) => this.SubProcessGroupinghandleSubmit(event, this.SubGroupMappingForm.getRawValue())}
                                                                                                    >
                                                                                                        {this.state.buttontext}
                                                                                                    </Button>

                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className="pr-1" md="3">
                                                                                                <div className="login-button changepassword text-center">
                                                                                                    <Button
                                                                                                        onClick={() => this.SubProcessGroupingcancel()}
                                                                                                        variant="contained"
                                                                                                        color="danger"
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                    {
                                                                                                        <Button
                                                                                                            type="button"
                                                                                                            variant="contained"
                                                                                                            color="secondary"
                                                                                                            //disabled={invalid}
                                                                                                            onClick={(event) => this.triggergroupingCalc(event)}
                                                                                                        >
                                                                                                            Update Grouping Calculation
                                                                                                        </Button>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>

                                                                                            <Col className="pr-1" md="3">
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </form>
                                                                                )}
                                                                            />
                                                                        </CardBody>

                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                            <CardTitle tag="h5"></CardTitle>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col className="pr-1" md="9">
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">

                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.GroupsearchTerm} onChange={this.handleGroupSearchInputChange} />
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </div>
                                                                                    {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                                </Col>
                                                                            </Row>
                                                                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: '100px' }}>Actions</th>
                                                                                            <th style={{ width: '100px' }}>Group Process</th>
                                                                                            <th style={{ width: '100px' }}>Sub Process</th>
                                                                                            <th style={{ width: '200px' }}>Created at</th>
                                                                                            <th style={{ width: '200px' }}>Updated at</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {filteredSubProcessgroupData.map((value, index) => (
                                                                                            <tr key={index + 1}>
                                                                                                {/* <td>{index+1}</td> */}
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" onClick={(event) => this.SubProcessGroupingdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    <a href="javascript:void(0)" onClick={(e) => this.SubProcessGroupingedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                </td>
                                                                                                <td>{value.lgrouping_process_id}</td>
                                                                                                <td>{value.subprocess_name}</td>

                                                                                                <td>{Moment(value.created_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>

                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <Row>
                                                            <Col md="12">
                                                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <CardHeader>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </>

                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}
export default connect(mapStateToProps)(GroupConfiguration);

