import * as React from 'react';
import Stack from '@mui/material/Stack';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import tinygradient from "tinygradient";
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';

const MAX = 100;
const MIN = 0;
const ariaLabel = { 'aria-label': 'description' };
// const marks = [
//   {
//     value: MIN,
//     label: '',
//   },
//   {
//     value: MAX,
//     label: '',
//   },
// ];

const useStyles = makeStyles({
  root: {
    width: 200
  }
});

// const CustomSliderStyles = {
//   '& .MuiSlider-root': {
//       width: "100px"
//   },
//   '& .MuiSlider-track': {
//       color: "#749761"
//   },
//   '& .MuiSlider-rail': {
//       color: "#acc4e4"
//   },
//   '& .MuiSlider-active': {
//       color: "#f5e278"
//   }
// };

const gradientYellow = tinygradient("#FFFFED", "#8B8000");
const gradientBlue = tinygradient("#ADD8E6", "#00008B");
const gradient = tinygradient("#ADD8E6", "#00008B");
const gradientGrey = tinygradient("#000000", "#808080");
const CustomSlider = withStyles({
  // color: "#f5e278", //color of the slider between thumbs
  "& .MuiSlider-thumb": {
    // backgroundColor: "#f5e278" //color of thumbs
  },
  "& .MuiSlider-track": {
    // color: "#f5e278" ////color of the slider outside  teh area between thumbs
    border: "1px solid #000000!important",
  },
  root: {
    height: 70,
    width: "40px!important"
  },
  MuiSlider: {
    root: {
    width: 100
  }}
  ,
  rail: {
    // background: "#aac5f4",
    opacity: 0.7,
    height: 7,
    borderRadius: 7,
    overflow: "hidden"
  },
  track: {
    backgroundImage: ({ value, backgroundColor, sliderclr }) =>
      `linear-gradient(.125turn, ${sliderclr} 10%, ${backgroundColor.rgbAt(
        value / 100
      )} 70%)`,
      border: "1px solid #000000!important",
    height: 7,
    borderRadius: 7,
    overflow: "hidden"
  },
  thumb: {
    height: "40px!important",
    width: "40px!important",
    marginTop: -8,
    backgroundColor: ({ value, backgroundColor }) => backgroundColor.rgbAt(value / 100),
    "&:focus, &:hover": {
      boxShadow: "inherit"
    },
    border: "solid 2px #bdd4ff"
  }
})(Slider);


const CustomInput = withStyles({
  // color: "#f5e278", //color of the slider between thumbs
  "& .MuiInput-root": {
    '&::before': {
      borderBottom: 0 
    },
    // backgroundColor: "#f5e278" //color of thumbs
    borderBottom: 0 
  },
  "& .MuiInputBase-root.Mui-disabled": {
    '&:before': {
      borderBottom: 0 
    },
    // backgroundColor: "#f5e278" //color of thumbs
    borderBottom: 0 
  },
  "& .MuiSlider-rail": {
    // color: "#f5e278" ////color of the slider outside  teh area between thumbs
  },
  root: {
    borderBottom: 0 
  },
  
})(Input);


function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '0°C',
  },
  {
    value: 20,
    label: '20°C',
  },
  {
    value: 37,
    label: '37°C',
  },
  {
    value: 100,
    label: '100°C',
  },
];

export default function VerticalSlider({backgroundColor, sliderclr, sliderIndex, handleSet}) {
  // const classes = useStyles({ props.backgroundColor });
  const [val, setVal] = React.useState(MIN);
  const [loader, setLoader] = React.useState(false);
  const handleChange = (_, newValue) => {
    setVal(newValue);
    handleSet(sliderIndex, newValue);
  };
  return (
    <Stack sx={{ height: 300,margin:"40px" }} spacing={1} direction="row">
      <CustomSlider
        aria-label="Temperature"
        orientation="vertical"
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        defaultValue={30}
        value={val}
        sliderIndex={sliderIndex}
        backgroundColor={backgroundColor}
        sliderclr={sliderclr}
        min={MIN}
        max={MAX}
        onChange={handleChange}
      />
      <div style={{marginTop: '130px',
    fontSize: '20px',
    fontWeight: 'bold'}}>{val}</div>
      {/* <CustomInput disabled defaultValue="Brightness" value={val} /> */}
      {/* <Slider
        aria-label="Temperature"
        orientation="vertical"
        defaultValue={30}
        valueLabelDisplay="auto"
        disabled
      />
      <Slider
        getAriaLabel={() => 'Temperature'}
        orientation="vertical"
        getAriaValueText={valuetext}
        defaultValue={[20, 37]}
        valueLabelDisplay="auto"
        marks={marks}
      /> */}
    </Stack>
  );
}