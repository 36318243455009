import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {logoimage} from "../routes"
import '../assets/css/auth.css';
import Loader from '../services/normalloader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import * as apiService from '../services/apiService';

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  sigintext : {
    align: 'center'
  }
});

class ForgetPassword extends Component {
  constructor(props) {
    super();
    this.state = {
        loader: false
    }
  }

  componentDidMount() {

  }

  forgetForm = FormBuilder.group({
    email: ["", [Validators.required, Validators.email]],
    loc: [window.location.origin],
  });

  handleSubmit(e, value) {
    e.preventDefault();
    this.setState({ loader: true })
    apiService.Forgetpassword(value).then(response => {
        this.setState({ loader: false })
        apiService.Alerts("Password Reset Request has been Mailed !!!!")
        this.props.history.push('/neurobotz/login')
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="login-bg" >
        {
            this.state.loader && <Loader />
        }
        <CssBaseline />
        <div className={classes.paper}>
          <Card className="login-box">
            <CardContent className="login-box-text">    
              <img src={logoimage()} alt="logo" height="80" width="300" />
                <Typography component="h1" variant="h5" className="authfontfamily">
                  Forget Password
                </Typography>
                  <FieldGroup
                    control={this.forgetForm}
                    render={({ get, invalid, value }) => (
                    <form onSubmit={(e) => this.handleSubmit(e, value)}>
                      <FieldControl
                        name="email"
                        render={({ handler, touched, hasError }) => (
                        <div>
                            <Grid className="Formfieldcss" container spacing={1}>                
                                <Grid item>
                                    <TextField required fullWidth variant="outlined" error={touched && hasError("required")} label="Email Address" {...handler("text")}  />
                                </Grid>
                                <span className="error">{touched
                                    && hasError("required")
                                    && `* Email is required`}
                                    {touched
                                        && hasError("email")
                                        && `* Enter Valid Email Id`}
                                </span>

                            </Grid>
                        </div>
                        )}
                      />
                      <div className="login-button">
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={invalid}
                            >
                            Submit
                        </Button>
                      </div>
                  </form>
                )} 
              />
              <Grid container>
                  <Grid item xs>
                      <Link to="/neurobotz/login" variant="body2">
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link to="/neurobotz/login" className="atextdecoration authfontfamily2">Login</Link>
                  </Grid>
              </Grid>
              </CardContent>
              </Card>
          </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(ForgetPassword);