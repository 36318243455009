import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
} from "react-reactive-form";
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import {SampleBase } from './Samplebase'
import TopNavDropdown from './TopDropdown';
import AmCharts from '@amcharts/amcharts3-react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {chartPhase} from "../routes"
import {v3_server_check} from "../routes"


class PowerAnalyser extends SampleBase {
    constructor(props) {
        super();
                // var newtimeZone = localStorage.getItem('time_zone')
        // if(newtimeZone){
        //     let options = {
        //         timeZone: newtimeZone,
        //         year: 'numeric',
        //         month: 'numeric',
        //         day: 'numeric',
        //         hour: 'numeric',
        //         minute: 'numeric',
        //         second: 'numeric',
        //       },
        //     formatter = new Intl.DateTimeFormat([], options);
        //     var timezone = (formatter.format(new Date())).split(" ");
        //     var timezoneformat = timezone[0].replace(/,\s*$/, "")
        //     var finaloutput = new Date(timezoneformat)
        //     this.today = finaloutput
        // }
        // else{
        //     this.today = new Date(new Date().toDateString());
        // }  
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
            - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = new Date(this.lastStart.getFullYear(), this.lastStart.getMonth() + 1, 0);
        var year=new Date().getFullYear()-1

        this.yearStart = new Date(new Date(year+"-1-1").toDateString());
        this.yearEnd = new Date(new Date(year+"-12-31").toDateString());
        this.state = {
            loader: false,
            charttype: 'area',
            checkboxvalue:  'Amp',
            minutevalue: 60,
            prefix: '',
            suffix: '',
            high: '',
            low: '',
            average: '',
            high1: '',
            low1: '',
            average1: '',
            total: '',
            final_chart_data: [],    
            final_chart_data1: [],           
       
            defaultrange : [this.today,this.today],
            processed_data: [],
            chart_data: [],
            processed_data1: [],
            chart_data1: [],
            seriesdata: [],
            fillAlphas: 1,
            graphtype: 'area',
            tooltip: {},
            showcontent: false, 
            backgroundcolor: 'white',
            textcolor: 'black',
            v2_full_data:{}
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.newtest = this.newtest.bind(this);
        this.newtest1 = this.newtest1.bind(this);

        this.selectedcustomer = ''
        this.selectedboard = ''
        this.graphtype = 'area'
        this.fillAlphas= 1
        this.seriesdata = []
        this.seriesData = []
        this.chartconfig = {}
        this.chartconfig1 = {}
        this.finalchartdata = []
        this.finalchartdata1 = []

        this.var1 = [];
        this.var2 = [];
        this.var3 = [];
        this.calcData = []
        this.calcData1 = []
        this.var11 = [];
        this.var21 = [];
        this.var31 = [];

    }

    componentDidMount() { 
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/poweranalyser')
        if (localStorage.getItem('selectedboard')) {
            this.setState({
                showcontent: true
            })
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    componentDidUpdate(prevProps) {
        if (this.props.themecolor == 'greycolor' || this.props.themecolor ==  'whitecolor') {
            this.setState({
                backgroundcolor : 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor : 'white',
                textcolor: 'black'
            })
        }
        if (prevProps.theme != this.props.theme) {
            this.refinechartdata(this.finalchartdata,this.state.checkboxvalue)
        }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.convertdate(this.state.defaultrange,board_id)
        this.setState({
            showcontent: true
        })
    }

    realtimeForm = FormBuilder.group({
        daterange: [""],
        uom: ["Amp", ],
        chart_type: ["area"],
        minutes: [60]
    });

    uomhandleChange(e) {
        e.preventDefault();
        this.realtimeForm.patchValue({
            uom: e.target.value
        })
        this.setState({
            checkboxvalue: e.target.value
        },()=>{
          if(v3_server_check()==true){
            this.fetchdata(this.startdate,this.enddate,e.target.value,localStorage.getItem('selectedboard'),this.state.minutevalue);
          }else{
               this.v2_value_mapping()
              }

        })
    }

    MinutehandleChange(e) {
        e.preventDefault();
        this.realtimeForm.patchValue({
            minutes: e.target.value
        })
        this.setState({
            minutevalue: e.target.value
        })
        this.fetchdata(this.startdate,this.enddate,this.state.checkboxvalue,localStorage.getItem('selectedboard'),e.target.value);
    }

    charthandleChange(e) {
        e.preventDefault();
        this.realtimeForm.patchValue({
            chart_type: e.target.value
        })
        this.setState({
            charttype: e.target.value,
        })
        if (e.target.value == 'area' || e.target.value == 'column') {
            this.fillAlphas= 1
            if (e.target.value == 'area') {
                this.graphtype = 'area'
            } else {
                this.graphtype = 'column'
            }
        } else {
            this.graphtype = 'spline'
            this.fillAlphas= 0
        }
        this.refinechartdata(this.finalchartdata,this.state.checkboxvalue)
        if(this.state.checkboxvalue=='V'){
          this.refinechartdata1(this.finalchartdata1,this.state.checkboxvalue)
        }

    }

    datehandleChange(e) {
        this.setState({
            defaultrange: e.target.value
        })
        this.convertdate(e.target.value,localStorage.getItem('selectedboard'))
    }

    convertdate(e,board_id) {
        const sy = e[0].getFullYear();
        const sm = e[0].getMonth() + 1;
        const sd = e[0].getDate();
        const ey = e[1].getFullYear();
        const em = e[1].getMonth() + 1;
        const ed = e[1].getDate();
        this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        this.fetchdata(this.startdate,this.enddate,this.state.checkboxvalue,board_id,this.state.minutevalue);
    }

    refinechartdata(data,uom) {
          var chartdata1 = [];
          var chartdata2 = [];
          var chartdata3 = [];
            for (var i = 0; i <= data.length - 1; i++) {
              var time = new Date(data[i].time)
              var y = time.getUTCFullYear();
              var m = time.getUTCMonth();
              var d = time.getUTCDate();
              var h = time.getUTCHours();
              var min = time.getUTCMinutes();
              var s = time.getUTCSeconds();
              var tZ = new Date().getTimezoneOffset() / 30
                if (tZ === -17.5) {
                    h -= -17;
                    min -= -30;
                } else {
                    h -= tZ;
                }
                var timestamp = new Date(y, m, d, h, min, s)
              var dat = new Date(timestamp);
              var final = dat.getTime();
              chartdata1.push([final, data[i].r_value])
              chartdata2.push([final, data[i].y_value])
              chartdata3.push([final, data[i].b_value])
            }
            var var1 = chartdata1
            var var2 = chartdata2
            var var3 = chartdata3
            this.var1 = var1
            this.var2 = var2
            this.var3 = var3
            if(chartPhase()==true){
            this.seriesData = [{
                name: 'Phase 1 ',
                data: var1,
                type: this.graphtype,
                color: '#CDDC39',
                fillOpacity: 1,
                threshold: null
                }, {
                name: 'Phase 2 ',
                data: var2,
                type: this.graphtype,
                threshold: null,
                color: '#673AB7',
                fillOpacity: 1
        
                }, {
                name: 'Phase 3 ',
                data: var3,
                type: this.graphtype,
                threshold: null,
                color: '#03A2E9',
                fillOpacity: 1,
        
                }]
            }else{
                this.seriesData = [{
                    name: 'Phase 1 ',
                    data: var1,
                    type: this.graphtype,
                    color: '#CDDC39',
                    fillOpacity: 1,
                    threshold: null
            
                    }, {
                    name: 'Phase 2 ',
                    data: var2,
                    type: this.graphtype,
                    threshold: null,
                    color: '#673AB7',
                    fillOpacity: 1
            
                }]
            }  
            this.setState({
              final_chart_data : this.seriesData,
            })
            if (uom == 'Amp') {
              this.setState({
                suffix : 'Amp', prefix: '' 
              })
            } else if (uom == 'V') {
              this.setState({
                suffix : 'V', prefix: '' 
              })
            } else if (uom == 'PF') {
              this.setState({
                suffix : 'PF', prefix: '' 
              })
            } else {
              this.setState({
                suffix : 'Amp', prefix: '' 
              })
            }
            this.bottomTableData();
            setTimeout(() => {
              var x=document.getElementsByClassName("highcharts-button")
              // console.log(x)
              if(x!=undefined && x.length!=0){
                  x[x.length-1].onclick()
  
       }
  }, 100);
    }
    refinechartdata1(data,uom) {
      var chartdata1 = [];
      var chartdata2 = [];
      var chartdata3 = [];
        for (var i = 0; i <= data.length - 1; i++) {
          var time = new Date(data[i].time)
          var y = time.getUTCFullYear();
          var m = time.getUTCMonth();
          var d = time.getUTCDate();
          var h = time.getUTCHours();
          var min = time.getUTCMinutes();
          var s = time.getUTCSeconds();
          var tZ = new Date().getTimezoneOffset() / 30
          if (tZ === -17.5) {
              h -= -17;
              min -= -30;
          } else {
              h -= tZ;
          }
          var timestamp = new Date(y, m, d, h, min, s)
          var dat = new Date(timestamp);
          var final = dat.getTime();
          chartdata1.push([final, data[i].br_value])
          chartdata2.push([final, data[i].ry_value])
          chartdata3.push([final, data[i].yb_value])
        }
        var var1 = chartdata1
        var var2 = chartdata2
        var var3 = chartdata3
        this.var11 = var1
        this.var21 = var2
        this.var31 = var3
        if(chartPhase()==true){
        this.seriesData1 = [{
            name: 'Phase 1 ',
            data: var1,
            type: this.graphtype,
            color: '#3F51B5',
            fillOpacity: 1,
            threshold: null
            }, {
            name: 'Phase 2 ',
            data: var2,
            type: this.graphtype,
            threshold: null,
            color: '#009486',
            fillOpacity: 1
    
            }, {
            name: 'Phase 3 ',
            data: var3,
            type: this.graphtype,
            threshold: null,
            color: '#B4C132',
            fillOpacity: 1,
    
            }]
        }else{
            this.seriesData1 = [{
                name: 'Phase 1 ',
                data: var1,
                type: this.graphtype,
                color: '#3F51B5',
                fillOpacity: 1,
                threshold: null
        
                }, {
                name: 'Phase 2 ',
                data: var2,
                type: this.graphtype,
                threshold: null,
                color: '#009486',
                fillOpacity: 1
        
            }]
        }  
        this.setState({
          final_chart_data1 : this.seriesData1,
        })
        if (uom == 'Amp') {
          this.setState({
            suffix : 'Amp', prefix: '' 
          })
        } else if (uom == 'V') {
          this.setState({
            suffix : 'V', prefix: '' 
          })
        } else if (uom == 'PF') {
          this.setState({
            suffix : 'PF', prefix: '' 
          })
        } else {
          this.setState({
            suffix : 'Amp', prefix: '' 
          })
        }
        this.bottomTableData();
        setTimeout(() => {
          var x=document.getElementsByClassName("highcharts-button")
          // console.log(x)
          if(x!=undefined && x.length!=0){
              x[x.length-1].onclick()

   }
}, 100);
}

    fetchdata(startdate,enddate,uom,board_id,minutevalue) {
      var minutes=this.realtimeForm.value.minutes

     if(v3_server_check()==true){
   this.chart_data_v3(startdate,enddate,uom,board_id,minutes)
     }else{
      this.chart_data_v2(startdate,enddate,uom,board_id,minutes)
     }
    }
    chart_data_v2(startdate,enddate,uom,board_id,minutevalue){
      this.setState({ loader: true })
      apiService.PAchartdata(startdate,enddate,board_id).then(response => {
        this.setState({ loader: false,v2_full_data:response.data },()=>{
          this.v2_value_mapping()
        })
       
       
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }
    v2_value_mapping(){
      var uom =this.state.checkboxvalue
      var p=this.state.v2_full_data
      if (uom == 'Amp') {
        this.setState({
          loader: false,
          high: p.processed_data.current['high'],
          low: p.processed_data.current['low'],
          average: p.processed_data.current['avg'],
          processed_data: p.processed_data.current,
          chart_data: p.chart_data[0].current,
        })
        this.finalchartdata = p.chart_data[0].current
        this.calcData = p.processed_data.current
        this.refinechartdata(this.finalchartdata,uom)  
      } else if (uom == 'V') {
        this.setState({
          loader: false,
          high: p.processed_data.phase_to_neutral['high'],
          low: p.processed_data.phase_to_neutral['low'],
          average: p.processed_data.phase_to_neutral['avg'],
          processed_data: p.processed_data.phase_to_neutral,
          chart_data: p.chart_data[0].phase_to_neutral,
        })
        this.finalchartdata = p.chart_data[0].phase_to_neutral
        this.calcData = p.processed_data.phase_to_neutral
        this.refinechartdata(this.finalchartdata,uom) 


        //phase to phase

        this.setState({
          high1: p.processed_data.phase_to_phase['high'],
          low1: p.processed_data.phase_to_phase['low'],
          average1: p.processed_data.phase_to_phase['avg'],
          processed_data1: p.processed_data.phase_to_phase,
          chart_data1: p.chart_data[0].phase_to_phase,
        })
        this.finalchartdata1 = p.chart_data[0].phase_to_phase
        this.calcData1 = p.processed_data.phase_to_phase
        this.refinechartdata1(this.finalchartdata1,uom) 
      } else if (uom == 'PF') {
        this.setState({
          loader: false,
          high: p.processed_data.power_factor['high'],
          low: p.processed_data.power_factor['low'],
          average: p.processed_data.power_factor['avg'],
          processed_data: p.processed_data.power_factor,
          chart_data: p.chart_data[0].power_factor,
        })
        this.finalchartdata = p.chart_data[0].power_factor
        this.calcData = p.processed_data.power_factor
        this.refinechartdata(this.finalchartdata,uom) 
      } else {
        this.setState({
          loader: false,
          high: p.processed_data.current['high'],
          low: p.processed_data.current['low'],
          average: p.processed_data.current['avg'],
          processed_data: p.processed_data.current,
          chart_data: p.chart_data[0].current,
        })
        this.finalchartdata = p.chart_data[0].current
        this.calcData = p.processed_data.current
        this.refinechartdata(this.finalchartdata,uom)  
      }
    }
    chart_data_v3(startdate,enddate,uom,board_id,minutevalue){
      this.setState({ loader: true })
      apiService.TimePAchartdata(startdate,enddate,uom,board_id,minutevalue).then(response => {
        this.setState({ loader: false })
        if (uom == 'Amp') {
          this.setState({
            loader: false,
            high: response.data.processed_data.current['high'],
            low: response.data.processed_data.current['low'],
            average: response.data.processed_data.current['avg'],
            processed_data: response.data.processed_data.current,
            chart_data: response.data.chart_data.current,
          })
          this.finalchartdata = response.data.chart_data.current
          this.calcData = response.data.processed_data.current
          this.refinechartdata(this.finalchartdata,uom)  
        } else if (uom == 'V') {
          this.setState({
            loader: false,
            high: response.data.processed_data.phase_to_neutral['high'],
            low: response.data.processed_data.phase_to_neutral['low'],
            average: response.data.processed_data.phase_to_neutral['avg'],
            processed_data: response.data.processed_data.phase_to_neutral,
            chart_data: response.data.chart_data.phase_to_neutral,
          })
          this.finalchartdata = response.data.chart_data.phase_to_neutral
          this.calcData = response.data.processed_data.phase_to_neutral
          this.refinechartdata(this.finalchartdata,uom) 
        } else if (uom == 'PF') {
          this.setState({
            loader: false,
            high: response.data.processed_data.power_factor['high'],
            low: response.data.processed_data.power_factor['low'],
            average: response.data.processed_data.power_factor['avg'],
            processed_data: response.data.processed_data.power_factor,
            chart_data: response.data.chart_data.power_factor,
          })
          this.finalchartdata = response.data.chart_data.power_factor
          this.calcData = response.data.processed_data.power_factor
          this.refinechartdata(this.finalchartdata,uom) 
        } else {
          this.setState({
            loader: false,
            high: response.data.processed_data.current['high'],
            low: response.data.processed_data.current['low'],
            average: response.data.processed_data.current['avg'],
            processed_data: response.data.processed_data.current,
            chart_data: response.data.chart_data.current,
          })
          this.finalchartdata = response.data.chart_data.current
          this.calcData = response.data.processed_data.current
          this.refinechartdata(this.finalchartdata,uom)  
        }
       
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }

    bottomTableData(){
      var testObj={
          max:'full',
          min:'full'
      }
      var obj1=JSON.stringify(testObj)
      sessionStorage.setItem('bottomTableMaxMinPA',obj1)  
      var obj={
        max:this.calcData.high,
        min:this.calcData.low,
        average:this.calcData.avg
      }
      sessionStorage.setItem('bottomTableDataPA', JSON.stringify(obj))
    }
    
    newtest(event) {
      var timearray1=[]
		  var timearray2=[]
      var timearray3=[]
      var array=[]
      var max1=null
      var min1=null
      var sum=0
      var x= 0
      var max = event.max;
      var min = event.min;
      var uom = event.target.series[0]['yAxis']['axisTitle']['textStr']
      if (event.target.series.length == 2) {
          for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
              if (event.target.series[0]['xData'].length == 0) {
                timearray1 = [[]]
              } else {
                timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
              } 
          }
          for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
            if (event.target.series[1]['xData'].length == 0) {
              timearray2 = [[]]
            } else {
              timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
            } 
          }
          timearray3 = []
      } else if (event.target.series.length == 3) {
        for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
          if (event.target.series[0]['xData'].length == 0) {
            timearray1 = [[]]
          } else {
            timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
          if (event.target.series[1]['xData'].length == 0) {
            timearray2 = [[]]
          } else {
            timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[2]['xData'].length - 1 ; i++) {
          if (event.target.series[2]['xData'].length == 0) {
            timearray3 = [[]]
          } else {
            timearray3.push([event.target.series[2]['xData'][i],event.target.series[2]['yData'][i]])
          } 
        }
      } else {
        for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
          if (event.target.series[0]['xData'].length == 0) {
            timearray1 = [[]]
          } else {
            timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
          if (event.target.series[1]['xData'].length == 0) {
            timearray2 = [[]]
          } else {
            timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
          } 
        }
          timearray3 = [[]]
        }
      if (uom == 'Amp' || uom == 'V' || uom == 'PF') {
        timearray1.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0  
          }
          if(x==1){
            array.push(row)
          }
        });
        timearray2.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0
          }
          if(x==1){
            array.push(row)
          }
        });
        timearray3.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0
          }
          if(x==1){
            array.push(row)
          }
        });
      } 
      if (array.length == 0 ) {
        max1=0
        min1=0
        sum=0
        var average1= 0
        var obj={
            max:max1,
            min: min1,
            average: average1
        }
      } else {
        max1=Math.max.apply(Math, array.map(function(o) { return o[1]; }))
        min1=Math.min.apply(Math, array.map(function(o) { return o[1]; }))
        sum=0
        array.forEach((key)=>{
            sum=sum+key[1]
        })
        var average1=sum/(array.length)
        var obj={
            max:max1,
            min:min1,
            average:average1
        }
      }
      var obj1=JSON.stringify(obj)
      sessionStorage.setItem('bottomTableDataPA',obj1)
      this.setState({
          high: max1.toFixed(2),
          low: min1.toFixed(2),
          average: average1.toFixed(2)
      })
    }
    newtest1(event) {
      var timearray1=[]
		  var timearray2=[]
      var timearray3=[]
      var array=[]
      var max1=null
      var min1=null
      var sum=0
      var x= 0
      var max = event.max;
      var min = event.min;
      var uom = event.target.series[0]['yAxis']['axisTitle']['textStr']
      if (event.target.series.length == 2) {
          for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
              if (event.target.series[0]['xData'].length == 0) {
                timearray1 = [[]]
              } else {
                timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
              } 
          }
          for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
            if (event.target.series[1]['xData'].length == 0) {
              timearray2 = [[]]
            } else {
              timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
            } 
          }
          timearray3 = []
      } else if (event.target.series.length == 3) {
        for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
          if (event.target.series[0]['xData'].length == 0) {
            timearray1 = [[]]
          } else {
            timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
          if (event.target.series[1]['xData'].length == 0) {
            timearray2 = [[]]
          } else {
            timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[2]['xData'].length - 1 ; i++) {
          if (event.target.series[2]['xData'].length == 0) {
            timearray3 = [[]]
          } else {
            timearray3.push([event.target.series[2]['xData'][i],event.target.series[2]['yData'][i]])
          } 
        }
      } else {
        for (let i = 0 ; i<= event.target.series[0]['xData'].length - 1 ; i++) {
          if (event.target.series[0]['xData'].length == 0) {
            timearray1 = [[]]
          } else {
            timearray1.push([event.target.series[0]['xData'][i],event.target.series[0]['yData'][i]])
          } 
        }
        for (let i = 0 ; i<= event.target.series[1]['xData'].length - 1 ; i++) {
          if (event.target.series[1]['xData'].length == 0) {
            timearray2 = [[]]
          } else {
            timearray2.push([event.target.series[1]['xData'][i],event.target.series[1]['yData'][i]])
          } 
        }
          timearray3 = [[]]
        }
      if (uom == 'Amp' || uom == 'V' || uom == 'PF') {
        timearray1.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0  
          }
          if(x==1){
            array.push(row)
          }
        });
        timearray2.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0
          }
          if(x==1){
            array.push(row)
          }
        });
        timearray3.forEach(row => {
          if(row[0]>min){
              x=1
          }
          if(row[0]>max){
            x=0
          }
          if(x==1){
            array.push(row)
          }
        });
      } 
      if (array.length == 0 ) {
        max1=0
        min1=0
        sum=0
        var average1= 0
        var obj={
            max:max1,
            min: min1,
            average: average1
        }
      } else {
        max1=Math.max.apply(Math, array.map(function(o) { return o[1]; }))
        min1=Math.min.apply(Math, array.map(function(o) { return o[1]; }))
        sum=0
        array.forEach((key)=>{
            sum=sum+key[1]
        })
        var average1=sum/(array.length)
        var obj={
            max:max1,
            min:min1,
            average:average1
        }
      }
      var obj1=JSON.stringify(obj)
      sessionStorage.setItem('bottomTableDataPA1',obj1)
      this.setState({
          high1: max1.toFixed(2),
          low1: min1.toFixed(2),
          average1: average1.toFixed(2)
      })
    }


    render() {
        const PAchartconfig = {
            chart: {
              renderTo: 'PAchartdiv',
              backgroundColor: this.state.backgroundcolor,
              zoomType: 'x',
              style: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                color: this.state.textcolor,
                fontWeight: 'bold',
              },
            },
            exporting: { enabled: true },
            credits: {
              enabled: false
            },
            rangeSelector: {
              selected: 5,
              inputEnabled: false,
              buttons: [{
                type: 'all',
                text: 'Reset',
              }],
              buttonTheme: {
                  width: 80,
                  style:{
                      fontSize:'13px',
                      fontWeight:'400',
                      
                  }
              },
              // labelStyle: {
              //   visibility: 'hidden'
              // }
            },
      
            legend: {
              enabled: true,
              itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
              title: {
                enabled: true,
                text: 'Date / Time',
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  color: this.state.textcolor,
                  fontWeight: 'bold',
                }
              },
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              events: {
                afterSetExtremes: function (event) {
                  let min = event.min;
                  let max = event.max;
                  var testObj={
                    max:max,
                    min:min
                  }
                  var obj1=JSON.stringify(testObj)
                  sessionStorage.setItem('bottomTableMaxMinPA',obj1)
                },
                setExtremes: this.newtest
              }
            },
            yAxis: {
              opposite: false,
              min: 0,
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              title: {
                text: this.state.checkboxvalue,
                style: {
                    fontFamily: 'Montserrat',
                  fontSize: '14px',
                   fontWeight: 'bold',
                   color: this.state.textcolor,
                },
              },
            },
            tooltip: {
              headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
              valuePrefix: this.state.prefix + ' ', 
              valueSuffix: ' ' + this.state.suffix,
              valueDecimals:2,
              split: false,
              shared: true,
              style: {fontSize: '14px'} 
            },
            series:  this.state.final_chart_data
        };
        var x=3
        var y=v3_server_check()
        if(y==true){
            x=2
        }
        const PAchartconfig1= {
            chart: {
              renderTo: 'PAchartdiv1',
              backgroundColor: this.state.backgroundcolor,
              zoomType: 'x',
              style: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                color: this.state.textcolor,
                fontWeight: 'bold',
              },
            },
            exporting: { enabled: true },
            credits: {
              enabled: false
            },
            rangeSelector: {
              selected: 1,
              inputEnabled: false,
              buttonTheme: {
                visibility: 'hidden'
              },
              labelStyle: {
                visibility: 'hidden'
              }
            },
      
            legend: {
              enabled: true,
              itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
              title: {
                enabled: true,
                text: 'Date / Time',
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  color: this.state.textcolor,
                  fontWeight: 'bold',
                }
              },
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              events: {
                afterSetExtremes: function (event) {
                  let min = event.min;
                  let max = event.max;
                  var testObj={
                    max:max,
                    min:min
                  }
                  var obj1=JSON.stringify(testObj)
                  sessionStorage.setItem('bottomTableMaxMinPA1',obj1)
                },
                setExtremes: this.newtest1
              }
            },
            yAxis: {
              opposite: false,
              min: 0,
              labels: {
                style: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                }
            },
              title: {
                text: this.state.checkboxvalue,
                style: {
                    fontFamily: 'Montserrat',
                  fontSize: '14px',
                   fontWeight: 'bold',
                   color: this.state.textcolor,
                },
              },
            },
            tooltip: {
              headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
              valuePrefix: this.state.prefix + ' ', 
              valueSuffix: ' ' + this.state.suffix,
              valueDecimals:2,
              split: false,
              shared: true,
              style: {fontSize: '14px'} 
            },
            series:  this.state.final_chart_data1
        };
        // console.log(PAchartconfig,PAchartconfig1)
        // }
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
            {this.state.showcontent == true ?
            <Row>
              <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5"></CardTitle>
                </CardHeader>
                <CardBody>
                <FieldGroup
                    control={this.realtimeForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="6">
                            <FieldControl
                                name="datarange"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                            <div className='control-pane'>
                                                <div className='control-section'>
                                                    <div className='datepicker-control-section'>
                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e)} value={this.state.defaultrange}>
                                                            <PresetsDirective>
                                                                <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                            </PresetsDirective>
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md={x}>
                            <FieldControl
                                name="uom"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item> 
                                                <InputLabel id="demo-simple-select-outlined-label2">Units</InputLabel>  
                                                <Select
                                                    labelId="demo-simple-select-outlined-label2"
                                                    id="demo-simple-select-outlined2"
                                                    label="Units"
                                                    fullWidth variant="outlined"
                                                    onChange={(e) => this.uomhandleChange(e)}
                                                    value={this.state.checkboxvalue}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'Amp'}>Ampere (Amp)</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'V'}>Voltage (V)</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'PF'}>Power Factor (PF)</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            <Col className="pr-1" md={x}>
                            <FieldControl
                                name="chart_type"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-simple-select-outlined-label3">Charts</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label3"
                                                    id="demo-simple-select-outlined3"
                                                    fullWidth variant="outlined"
                                                    label="Charts"
                                                    onChange={(e) => this.charthandleChange(e)}
                                                    value={this.state.charttype}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'line'}>Line</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'column'}>Column</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={'area'}>Area</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                            
                            {y==true && <Col className="pr-1" md={x}>
                            <FieldControl
                                name="minute"
                                strict= {false}
                                render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                                <InputLabel id="demo-simple-select-outlined-label4">Interval</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label4"
                                                    id="demo-simple-select-outlined4"
                                                    fullWidth variant="outlined"
                                                    label="Interval"
                                                    onChange={(e) => this.MinutehandleChange(e)}
                                                    value={this.state.minutevalue}
                                                    >
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={1}>1 Minute</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={15}>15 Minutes</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={30}>30 Minutes</MenuItem>
                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value={60}>1 Hour</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                />
                            </Col>
                    }
                        </Row>
                    </form>
                    )} 
                />
                <hr />
               {y==false && this.state.checkboxvalue=="V" &&
                <CardTitle tag="h5">Voltage (Phase - neutral)</CardTitle>

               }

                <HighchartsReact 
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={PAchartconfig}
                    containerProps = {{ className: 'chartContainer' }}
                    id="PAchartdiv"
                />
                </CardBody>
              </Card>
            </Col>
            </Row>
            :
                <Row>
                    <Col md="12">
                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardHeader> 
                        </CardHeader>
                        <CardBody>
                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            }
            {this.state.showcontent == true && <>
                <Row>
                <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                            <Col md="12" xs="12">
                            <div className="numbers text-center">
                                    <CardTitle tag="p">
                                        {this.state.prefix} {this.state.high} {this.state.suffix}
                                    </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                            <i className="fas fa-bolt text-danger" /> High   
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                                <CardTitle tag="p">
                                    {this.state.prefix} {this.state.low} {this.state.suffix}
                                </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                          <i className="fas fa-bolt text-warning" /> Low
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                                    <CardTitle tag="p">
                                        {this.state.prefix} {this.state.average} {this.state.suffix}
                                    </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                        <i className="fas fa-bolt text-success" /> Average
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    </Row>
                   
                   {y==false && this.state.checkboxvalue=="V" && <>
                          <Row>
                <Col lg="12" md="12" sm="12">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                <CardTitle tag="h5">Voltage (Phase - Phase)</CardTitle>

                      <HighchartsReact 
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={PAchartconfig1}
                    containerProps = {{ className: 'chartContainer' }}
                    id="PAchartdiv1"
                />
                </CardBody>
                </Card>
                </Col></Row>
                 <Row>
                <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                            <Col md="12" xs="12">
                            <div className="numbers text-center">
                                    <CardTitle tag="p">
                                        {this.state.prefix} {this.state.high1} {this.state.suffix}
                                    </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                            <i className="fas fa-bolt text-danger" /> High   
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                                <CardTitle tag="p">
                                    {this.state.prefix} {this.state.low1} {this.state.suffix}
                                </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                          <i className="fas fa-bolt text-warning" /> Low
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                    <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                        <CardBody>
                        <Row>
                        <Col md="12" xs="12">
                            <div className="numbers text-center">
                                    <CardTitle tag="p">
                                        {this.state.prefix} {this.state.average1} {this.state.suffix}
                                    </CardTitle>
                                <p />
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats text-center">
                        <i className="fas fa-bolt text-success" /> Average
                        </div>
                        </CardFooter>
                    </Card>
                    </Col>
                    </Row>
                 
                     </>
                   } </> }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(PowerAnalyser);