import React, { Component } from 'react';
import OrganizationChart from './OrgTree';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import * as apiService from "../services/apiService";
import meter from "../assets/images/meter.png"
import drups from "../assets/images/drups.png"
import mainbox from "../assets/images/main-box.png" 
import maintrans from "../assets/images/main-trans.png" 
import subtrans from "../assets/images/sub-trans.png"
import grid from "../assets/images/grid.png" 
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Loader from '../services/normalloader';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';

export class TreeChart extends Component {

    constructor(props) {
        super();
        this.state = {
            loader: false,
            showcontent: false,
            parenttree: [],
            mainparenttree: [],
            defaultzoom: "1.0",
            childdefaultzoom: "1.0",      
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.showchildtree = false
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
        //   if (localStorage.getItem('is_admin') == 'true') {
            this.props.history.push('/neurobotz/sld')
            this.getparentree();
            this.getmainparenttree();
        //   } else {
            //   this.props.history.push('/neurobotz/dashboard')
          }
        // } else {
        //   this.props.history.push('/neurobotz/login')
        }
    

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
        })
    }

    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
        })
    }

    async getmainparenttree() {
        this.setState({ loader: true })
        await apiService.mainparenttreechart().then(response => {
            var data = response.data.main
            for (let i = 0 ; i <= data.length - 1 ; i++ ) {
                data[i]['field1'] = data[i]['average_neutral_voltage'] !== null ? "Avg. VL-N - " + data[i]['average_neutral_voltage'] : ""
                data[i]['field2'] = data[i]['average_phase_voltage'] !== null ? "Avg. VL-L - " + data[i]['average_phase_voltage']: ""
                data[i]['field3'] = data[i]['average_current'] !== null ? "Avg. Ampere - " +  data[i]['average_current']: ""
                data[i]['field4'] = data[i]['total_power_factor'] !== null ? "Avg. PF - " + data[i]['total_power_factor']: ""
                data[i]['field5'] = data[i]['total_active_power'] !== null ? "Total kW - " + data[i]['total_active_power']: ""
                data[i]['field6'] = data[i]['total_apparent_power'] !== null ? "Total kVA - " + data[i]['total_apparent_power']: ""
                if (data[i]['image'] === 'meter') {
                    data[i]['img'] = meter
                } else if (data[i]['image'] === 'drups') {
                    data[i]['img'] = drups
                } else if (data[i]['image'] === 'mainbox') {
                    data[i]['img'] = mainbox
                } else if (data[i]['image'] === 'maintrans') {
                    data[i]['img'] = maintrans
                } else if (data[i]['image'] === 'subtrans') {
                    data[i]['img'] = subtrans
                } else if (data[i]['image'] === 'grid') {
                    data[i]['img'] = grid
                } else {
                    data[i]['img'] = meter
                } 
            }
            this.setState({ mainparenttree: data,loader: false}, function() {
                this.showchildtree = true;
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    async getparentree() {
        var finalresponse = {"id": 222}
        this.setState({ loader: true })
        await apiService.treechart(finalresponse).then(response => {
            var flatarray = response.data.result
            var fresharray = flatarray.filter((obj) => obj['id'] !== "552" && obj['id'] !== "553" && obj['id'] !== "565" && obj['id'] !== "554")
            for (let i = 0 ; i <= fresharray.length - 1 ; i++ ) {
                fresharray[i]['field1'] = fresharray[i]['average_neutral_voltage'] !== null ? "Avg. VL-N - " + fresharray[i]['average_neutral_voltage'] : ""
                fresharray[i]['field2'] = fresharray[i]['average_phase_voltage'] !== null ? "Avg. VL-L - " + fresharray[i]['average_phase_voltage']: ""
                fresharray[i]['field3'] = fresharray[i]['average_current'] !== null ? "Avg. Ampere - " +  fresharray[i]['average_current']: ""
                fresharray[i]['field4'] = fresharray[i]['total_power_factor'] !== null ? "Avg. PF - " + fresharray[i]['total_power_factor']: ""
                fresharray[i]['field5'] = fresharray[i]['total_active_power'] !== null ? "Total kW - " + fresharray[i]['total_active_power']: ""
                fresharray[i]['field6'] = fresharray[i]['total_apparent_power'] !== null ? "Total kVA - " + fresharray[i]['total_apparent_power']: ""
                if (fresharray[i]['image'] === 'meter') {
                    fresharray[i]['img'] = meter
                } else if (fresharray[i]['image'] === 'drups') {
                    fresharray[i]['img'] = drups
                } else if (fresharray[i]['image'] === 'mainbox') {
                    fresharray[i]['img'] = mainbox
                } else if (fresharray[i]['image'] === 'maintrans') {
                    fresharray[i]['img'] = maintrans
                } else if (fresharray[i]['image'] === 'subtrans') {
                    fresharray[i]['img'] = subtrans
                } else if (fresharray[i]['image'] === 'grid') {
                    fresharray[i]['img'] = grid
                } else {
                    fresharray[i]['img'] = meter
                } 
            }
            this.setState({ parenttree: fresharray,loader: false}, function() {
                this.showchildtree = true;
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        }) 
        
    }

    slidechange(e) {
        this.setState({
            defaultzoom: e.target.value
        })
        var zoomScale = Number(e.target.value);
        this.setZoom(zoomScale,document.getElementsByClassName('MainOrgChartdiv')[0])
    }

    childslidechange(e) {
        this.setState({
            childdefaultzoom: e.target.value
        })
        var zoomScale = Number(e.target.value);
        this.setchildZoom(zoomScale,document.getElementsByClassName('ChildOrgChartdiv')[0])
    }

    setZoom(zoom,el) {
        var transformOrigin = [0,0];
          el = el;
          var p = ["webkit", "moz", "ms", "o"],
              s = "scale(" + zoom + ")",
              oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";
  
          for (var i = 0; i < p.length; i++) {
              el.style[p[i] + "Transform"] = s;
              el.style[p[i] + "TransformOrigin"] = oString;
          }
  
          el.style["transform"] = s;
          el.style["transformOrigin"] = oString;  
    }

    setchildZoom(zoom,el) {
        var transformOrigin = [0,0];
        el = el;
        var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

        for (var i = 0; i < p.length; i++) {
            el.style[p[i] + "Transform"] = s;
            el.style[p[i] + "TransformOrigin"] = oString;
        }

        el.style["transform"] = s;
        el.style["transformOrigin"] = oString;  
    }

  render() {
        return (
            <>
                <div className="content">
                {
                    this.state.loader && <Loader />
                }
                    {this.showchildtree ? 
                            <Row>
                                <Col md="12">
                                    <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
                                </Col>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                        {localStorage.getItem('selectedcustomer') === '222'
                                            ?
                                            <CardTitle tag="h5" className="flexblock">Titan NeuroSense EMS Dashboard</CardTitle>
                                            :
                                            <CardTitle tag="h5" className="flexblock"></CardTitle>
                                        }
                                        </CardHeader>
                                        <CardBody>
                                            {localStorage.getItem('selectedcustomer') === '222'
                                            ?
                                            <>
                                                <div className='makeitfit'>
                                                <Row>
                                                    <Col md="3">
                                                    <label>Zoom : </label>
                                                    <Select
                                                        className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        labelId="childselectdrop"
                                                        id="childselectdrop"
                                                        fullWidth variant="outlined"
                                                        required
                                                        label="Zoom"
                                                        value={this.state.childdefaultzoom}
                                                        onChange={(e) => this.childslidechange(e)}
                                                        >
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.10">10%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.25">25%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.5">50%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.75">75%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.0">100%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.25">125%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.5">150%</MenuItem>
                                                    </Select>
                                                    </Col>
                                                </Row>
                                                    <div className='ChildOrgChart' style={{height: '800px'}}>
                                                        <OrganizationChart  nodes={this.state.parenttree} customclass={"ChildOrgChartdiv"} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='makeitfit'>
                                                <Row>
                                                    <Col md="3">
                                                    <label>Zoom : </label>
                                                    <Select
                                                        className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        labelId="childselectdrop"
                                                        id="childselectdrop"
                                                        fullWidth variant="outlined"
                                                        required
                                                        label="Zoom"
                                                        value={this.state.defaultzoom}
                                                        onChange={(e) => this.slidechange(e)}
                                                        >
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.10">10%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.25">25%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.5">50%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="0.75">75%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.0">100%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.25">125%</MenuItem>
                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} value="1.5">150%</MenuItem>
                                                    </Select>
                                                    </Col>
                                                </Row>
                                                    <div className='MainOrgChart' style={{height: '400px'}}>
                                                        <OrganizationChart  nodes={this.state.mainparenttree} customclass={"MainOrgChartdiv"}/>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                               <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>SLD is not configured for this customer ...</div> 
                                            </>
                                            }
                                           
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        :
                        <>
                            <Row>
                                <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader> 
                                    </CardHeader>
                                    <CardBody>
                                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(TreeChart);