import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import classnames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';

import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Demand extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            activetab: "1",
            LogList: [],
            DefaultAlertList: [{ units: "kWh", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "kW", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "kva", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "Amp", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "V", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "PF", upper_range: 0, lower_range: 0, delay: 0, status: false }],
            AlertList: [{ units: "kWh", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "kW", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "kva", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "Amp", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "V", upper_range: 0, lower_range: 0, delay: 0, status: false },
            { units: "PF", upper_range: 0, lower_range: 0, delay: 0, status: false }],
            showcontent: false,
            AlertData: [],
            startdate:Moment(new Date()).format('YYYY-MM-DD'),
            enddate:Moment(new Date()).format('YYYY-MM-DD'),
            dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())]


        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.datehandleChange(this.state.dateselected)

    }



    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/alert')
            if (localStorage.getItem('selectedboard')) {
                // this.getdropdowndata()
                // this.boardData()
                // this.cancel()

                this.setState({
                    showcontent: true,

                }, () => {
                    // this.getAlert()
                    // this.getLogs()
                })


                // console.log(this.state.dropDownList)
                // this.boardData()
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))

            }

        } else {
            this.props.history.push('/neurobotz/login')
        }
    }
    cancelAlarm(){
        this.setState({AlertList:this.state.DefaultAlertList},()=>{
            this.getAlert()
        })
  
    }
    getAlert() {

        this.setState({ loader: true })
        apiService.getAlert().then(response => {
            var y = this.state.AlertList.map((key) => {
                var p = response.data.filter((obj) => obj.units == key.units)
                var x = key
                if (p.length != 0) {
                    x = { units: p[0].units, upper_range: p[0].upper_range, lower_range: p[0].lower_range, delay: p[0].delay, status: p[0].status }

                }
                return x
            })
            this.setState({ AlertData: y, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getLogs() {
        if ($.fn.dataTable.isDataTable('#alertTable')) {
            $('#alertTable').DataTable().destroy();
        }
        this.setState({ loader: true })
        var r=this.state
        apiService.getAlertLogs(r.startdate,r.enddate).then(response => {
            // this.setState({ LoadList: response.data })
            // this.setState({ LoadList: [] })
            this.setState({ LogList: response.data })

            // this.setState({AlarmList: this.state.AlarmList.concat(response.data)}) 
            this.datatableRerender()
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    datatableRerender() {


        $(document).ready(function () {
            $('#alertTable').DataTable({
                "bLengthChange": false,
                "pageLength": 5,
                "searching": true,
                "bInfo": false,
                "bSort": true,
                "bPaginate": true,
                "dom": 'Bfrtip',
                "buttons": [{
                    'extend': "excel",
                    "title": "Log Details",
                  
                }]
            });
        });

    }

    Submit() {

        this.setState({ loader: true })
        apiService.addAlert(this.state.AlertData).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Added Successfully")
            this.getAlert(true);
            // this.cancel();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })

    }
    toggle(option) {


        this.setState({
            activetab: option
        }, () => {

            //    this.cancel()
            // this.datatableRerender(true)
        })


    }




    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
        // this.LoadForm.reset()


    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            activeTab: 1,
            dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())]

            // AlarmList:[],
            // LoadList:[]
        }, () => {
            // this.toggle(1)
            localStorage.setItem('selectedboard', board_id)
            this.datehandleChange(this.state.dateselected)
            this.getAlert()
            this.getLogs()
        })

        // this.componentDidMount()
    }
    datehandleChange(e) {
        // console.log(e,"comes")
        // this.scheduleForm.patchValue({datarange:e})
        this.setState({
            startdate: Moment(e[0]).format('YYYY-MM-DD'),
            enddate: Moment(e[1]).format('YYYY-MM-DD')

        })
        // this.convertdate(e.target.value,localStorage.getItem('selectedcustomer'),'production')
    }

 
    onchangeinput(event, i, p) {
        var x = event.target.value
        // console.log(this.state.AlertData, x)
        var y = this.state.AlertData
        //  if(x==""){
        //      x=0
        //  }
        if (p == 1) {
            y[i].upper_range = x
        } else if (p == 2) {
            y[i].lower_range = x
        } else if (p == 3) {
            y[i].delay = x
        } else if (p == 4) {
            var z = event.target.checked
            // console.log(z)
            y[i].status = z
        }
        this.setState({ AlertData: y })

    }

    render() {


        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Alert
                            </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Log
                            </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">
                                                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '100px' }}>Unit</th>
                                                                    <th style={{ width: '100px' }}>Upper Range</th>
                                                                    <th style={{ width: '100px' }}>Lower Range</th>
                                                                    <th style={{ width: '100px' }}>Delay</th>
                                                                    <th style={{ width: '100px' }}>Status</th>




                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.AlertData.map((value, index) => (
                                                                    <tr key={index + 1}>

                                                                        <td>{value.units}</td>
                                                                        <td><TextField type="number" required fullWidth variant="outlined" disabled={value.status == false} onChange={(event) => this.onchangeinput(event, index, 1)} label="Upper Range" value={value.upper_range} /></td>
                                                                        <td><TextField type="number" required fullWidth variant="outlined" disabled={value.status == false} onChange={(event) => this.onchangeinput(event, index, 2)} label="Lower Range" value={value.lower_range} /></td>
                                                                        <td><TextField type="number" required fullWidth variant="outlined" disabled={value.status == false} onChange={(event) => this.onchangeinput(event, index, 3)} label="Delay" value={value.delay} /></td>
                                                                        <td><Checkbox
                                                                            checked={value.status}
                                                                            // onChange={handleChange}
                                                                            onChange={(event) => this.onchangeinput(event, index, 4)}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            color="primary"
                                                                        /></td>


                                                                    </tr>

                                                                ))}
                                                            </tbody>
                                                            {/* <tbody> */}

                                                        </table>
                                                        <Row >

                                                            <Col className="pr-1" md="2">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        //   disabled={invalid}
                                                                        onClick={() => this.Submit()}
                                                                    >
                                                                        Submit
                                                                  </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="2">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancelAlarm()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                          </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>

                                                    </div>

                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                  
                                              <Col md="4">
                                             
                                                    
                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <Grid className="Formfieldcss" container spacing={1}>                
                                            <Grid item>
                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                            <div className='control-pane'>
                                                <div className='control-section'>
                                                    <div className='datepicker-control-section'>
                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e.target.value)}  value={this.state.dateselected}>
                                                          
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>
                                        </div>
                                        </Col>
                                        <Col className="pr-1" md="2" style={{paddingTop:13}}>
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        //   disabled={invalid}
                                                                        onClick={() => this.getLogs()}
                                                                    >
                                                                        Submit
                                                                  </Button>
                                                                </div>
                                                            </Col>
                                        </Row>
                                                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>

                                                        <table id="alertTable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '100px' }}>S.No</th>
                                                                    <th style={{ width: '100px' }}>Alert Type</th>
                                                                    <th style={{ width: '100px' }}>Phase</th>
                                                                    <th style={{ width: '100px' }}>Process</th>
                                                                    <th style={{ width: '100px' }}>Value</th>
                                                                    <th style={{ width: '100px' }}>Created at</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.LogList.map((value, index) => (
                                                                    <tr key={index + 1}>



                                                                        <td>{value.alert_type}</td>
                                                                        <td>{value.phase}</td>
                                                                        <td>{value.process}</td>
                                                                        <td>{value.value}</td>
                                                                        <td>{value.created_at}</td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div> 

                                                    {/* <img src={floor_image} className={"img-rounded"} alt="Cinque Terre" width="100%" /> */}
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>


                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(Demand);
