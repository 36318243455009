import React, { Component } from 'react'
import TopNavDropdown from './TopDropdown';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import * as apiService from '../services/apiService';
import Moment from 'moment';
import Loader from '../services/normalloader';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export class Heater extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showcontent: false,
            dropDownList: [{ id: '0', value: "OFF" }, { id: 1, value: "ON" }],
            loader: false,
            startdate: "",
            enddate: '',
            ScheduleList: [],
            upperLimit: 1,
            upperLimitBtn: false,
            lowerLimitBtn: false,
            lowerLimit: 1,
            bypassStatusList: [{ id: 'active', value: "Active" }, { id: 'inactive', value: "Inactive" }],
            selectedBypassValue: 'active',
            today: [new Date(new Date().toDateString()), new Date(new Date().toDateString())],
            Modalopen: false,
        }

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
    }

    scheduleForm = FormBuilder.group({
        id: [""],
        datarange: ["", [Validators.required]],
        startTime: ["", [Validators.required]],
        endTime: [""],
        operation: ["", [Validators.required]],
    });

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/heater')
            this.dateconvertion(new Date())
        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        })
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            buttontext: 'Add',
        })
        localStorage.setItem('selectedboard', board_id)
        this.setState({
            showcontent: true
        })
        this.getSchedule()
    }

    dateconvertion(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.scheduleForm.patchValue({
            datarange: x
        })
        return x
    }

    handleSubmit(event, value) {
        event.preventDefault();
        var p = this.state
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addSheduleHeater(value, value.datarange, value.datarange).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Schedule Created Successfully")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editScheduleHeater(value, value.datarange, value.datarange).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Schedule Updated Successfully")
                this.getSchedule();
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    handleSubmitControl(event, val) {
        event.preventDefault();
        this.setState({ loader: true })
        apiService.heaterController(val).then(response => {
            this.setState({ loader: false })
            let msg = response.data[0].message
            apiService.Alerts(msg)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    getSchedule() {
        this.setState({ loader: true })
        apiService.getScheduleHeater().then(response => {
            this.setState({
                ScheduleList: response.data,
                loader: false
            })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }

    scheduleEdit(value) {
        var x = [new Date(new Date(value.from_date).toDateString()), new Date(new Date(value.to_date).toDateString())]
        // dateselected:[new Date(new Date().toDateString()),new Date(new Date().toDateString())]
        this.scheduleForm.patchValue({
            id: value.id,
            datarange: value.from_date,
            startTime: value.from_time,
            endTime: value.from_time,
            operation: value.operation,
        })

        this.setState({
            buttontext: 'Update',
            dateselected: x
        })
    }

    cancel() {
        this.scheduleForm.reset()
        this.datehandleChange1(this.state.today)
        this.setState({
            buttontext: 'Add',
            dateselected: this.state.today
        })
    }

    scheduleDelete(value) {
        // event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteScheduleHeater(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Schedule Deleted Successfully")
                        this.getSchedule();
                        this.cancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }

    handleUpperLimitSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterUpperlimit(this.state.upperLimit).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Upper Limit set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleLowerLimitSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterLowerlimit(this.state.lowerLimit).then(response => {
            this.setState({ loader: false })
            apiService.Alerts("Lower Limit set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleBypassSubmit(e) {
        e.preventDefault();
        this.setState({ loader: true })
        apiService.heaterBypassStatus(this.state.selectedBypassValue).then(response => {
            this.setState({ loader: false })
            apiService.Alerts(response?.data[0]?.message || "Bypass Status set Successfully")
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    upperlimitregex(e) {
        e.preventDefault();
        if (e.target.value >= -100 === false || e.target.value <= 100 == false) {
            this.setState({ upperLimitBtn: true })
        } else if (e.target.value == '') {
            this.setState({ upperLimitBtn: true })
        } else {
            this.setState({ upperLimitBtn: false })
        }
        this.setState({ upperLimit: e.target.value })
    }

    lowerlimitregex(e) {
        e.preventDefault();
        if (e.target.value >= -100 === false || e.target.value <= 100 == false) {
            this.setState({ lowerLimitBtn: true })
        } else if (e.target.value == '') {
            this.setState({ lowerLimitBtn: true })
        } else {
            this.setState({ lowerLimitBtn: false })
        }
        this.setState({ lowerLimit: e.target.value })
    }

    handleClose(e) {
        e.preventDefault();
        this.setState({ Modalopen: false })
    }

    openSwalModel(e) {
        e.preventDefault();
        this.setState({ Modalopen: true })
        apiService.coldstoragecurrentdisplay().then(response => {
            this.setState({
                upperLimit: response.data[0].upper_limit,
                lowerLimit: response.data[1].lower_limit,
                selectedBypassValue: response.data[2].bypass
            })
        }).catch(error => {

        })
    }

    render() {
        return (
            <div className="content">
                {
                    this.state.loader && <Loader />
                }
                <TopNavDropdown
                    customerhandler={this.customerhandler}
                    selectedcustomer={this.selectedcustomer}
                    boardhandler={this.boardhandler}
                    selectedboard={this.selectedboard}
                />
                {this.state.showcontent == true ?
                    <>
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                        <CardTitle tag="h5"></CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FieldGroup
                                            control={this.scheduleForm}
                                            strict={false}
                                            render={({ get, invalid, value }) => (
                                                <form>
                                                    <Row>
                                                        <Col className="pr-1" md="3">
                                                            <FieldControl
                                                                name="datarange"
                                                                strict={false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField type="date" className="customlabelplaceholder" fullWidth variant="outlined" label="Date *" error={touched && hasError("required")} {...handler("text")} />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Date is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            />
                                                        </Col>
                                                        <Col className="pr-1" md="3">
                                                            <FieldControl
                                                                name="startTime"
                                                                strict={false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    className="customlabelplaceholder"
                                                                                    views={['hours', 'minutes', 'seconds']}
                                                                                    type="time"
                                                                                    required
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    error={touched && hasError("required")}
                                                                                    label="Time " {...handler("text")}
                                                                                />
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Time is required`}
                                                                            </span>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            />
                                                        </Col>
                                                        {/* <Col className="pr-1" md="3">
                                                <FieldControl
                                                    name="endTime"
                                                    strict={false}
                                                    render={({ handler, touched, hasError }) => (
                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                <Grid item>
                                                                    <TextField 
                                                                        type="time" 
                                                                        required 
                                                                        fullWidth 
                                                                        variant="outlined" 
                                                                        error={touched && hasError("required")} 
                                                                        label="End Time " {...handler("text")} 
                                                                    />
                                                                </Grid>
                                                                <span className="error">
                                                                    {touched
                                                                        && hasError("required")
                                                                        && `* End Time is required`}
                                                                </span>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                />
                                            </Col> */}
                                                        <Col className="pr-1" md="3">
                                                            <FieldControl
                                                                name="operation"
                                                                strict={false}
                                                                render={({ handler, touched, hasError }) => (
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Operation"
                                                                                    variant="outlined"
                                                                                    {...handler("text")}
                                                                                    // onClick={()=>this.operationSelection()}
                                                                                    //   value={this.state.selectedCountryValue}
                                                                                    error={touched && hasError("required")}
                                                                                >
                                                                                    <MenuItem
                                                                                        className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                                        value=''
                                                                                    >
                                                                                        Select Operation
                                                                                    </MenuItem>
                                                                                    {this.state.dropDownList.map(option => (
                                                                                        <MenuItem
                                                                                            className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                                            key={option.id}
                                                                                            value={option.id}
                                                                                        >
                                                                                            {option.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                            <span className="error">
                                                                                {touched
                                                                                    && hasError("required")
                                                                                    && `* Operation is required`}
                                                                            </span>
                                                                        </Grid>

                                                                    </div>
                                                                )}
                                                            />
                                                        </Col>
                                                        {/* <Col className="pr-1" md="3">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(event) => this.openSwalModel(event)}
                                                            >
                                                                Operations
                                                            </Button>
                                                        </Col> */}
                                                        <Col className="pr-1" md="1.5">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="success"
                                                                onClick={(event) => this.handleSubmitControl(event, 1)}
                                                            >
                                                                ON
                                                            </Button>
                                                        </Col>
                                                        <Col className="pr-1" md="1.5">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="danger"
                                                                onClick={(event) => this.handleSubmitControl(event, 0)}
                                                            >
                                                                OFF
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="pr-1" md="3"></Col>
                                                        <Col className="pr-1" md="3">
                                                            <div className="login-button changepassword text-center">
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={invalid}
                                                                    onClick={(event) => this.handleSubmit(event, this.scheduleForm.getRawValue())}
                                                                >
                                                                    {this.state.buttontext}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="3">
                                                            <div className="login-button changepassword text-center">
                                                                <Button
                                                                    onClick={() => this.cancel()}
                                                                    variant="contained"
                                                                    color="danger"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="3"></Col>
                                                    </Row>
                                                </form>
                                            )}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                            <CardHeader>
                                <CardTitle tag="h5">
                                    <Row>
                                        <Col className="pr-1" md="12">
                                            {/* {this.state.thermostatName} Schedules */}
                                        </Col>

                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            <CardBody style={{ textAlign: "center" }}>
                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100px' }}>Actions</th>
                                                <th style={{ width: '100px' }}>Date</th>
                                                <th style={{ width: '100px' }}>Time</th>
                                                <th style={{ width: '100px' }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ScheduleList.map((value, index) => (
                                                <tr key={index + 1}>
                                                    <td>
                                                        <a
                                                            href="javascript:void(0)"
                                                            onClick={(event) => this.scheduleDelete(value.id)}
                                                        >
                                                            <i className="fa fa-trash icons"></i>
                                                        </a>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href="javascript:void(0)" onClick={(e) => this.scheduleEdit(value)}>
                                                            <i className="fa fa-edit icons"></i>
                                                        </a>
                                                    </td>
                                                    <td>{value.from_date}</td>
                                                    <td>{value.from_time}</td>
                                                    <td>{value.operation_name}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                                <Dialog fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.Modalopen}>
                                    <DialogTitle id="customized-dialog-title">
                                        Operations
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <div style={{ textAlign: "center" }}>
                                            <Row className="mb-3">
                                                <Col className="pr-1" md="6">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="success"
                                                        onClick={(event) => this.handleSubmitControl(event, 1)}
                                                    >
                                                        ON
                                                    </Button>
                                                </Col>
                                                <Col className="pr-1" md="6">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="danger"
                                                        onClick={(event) => this.handleSubmitControl(event, 0)}
                                                    >
                                                        OFF
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                    <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                            <Grid item>
                                                                <TextField type="number" helperText="Enter between -100 to +100" required fullWidth variant="outlined" label="Upper Limit" value={this.state.upperLimit} onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()} onChange={(e) => { this.upperlimitregex(e) }} />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Col>
                                                <Col className="pr-1" md="6">
                                                    <div className="login-button changepassword text-center">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={this.state.upperLimitBtn}
                                                            onClick={(event) => this.handleUpperLimitSubmit(event)}
                                                        >
                                                            Set upper limit
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                    <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                            <Grid item>
                                                                <TextField type="number" helperText="Enter between -100 to +100" required fullWidth variant="outlined" label="Lower Limit" value={this.state.lowerLimit} onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()} onChange={(e) => { this.lowerlimitregex(e) }} />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Col>
                                                <Col className="pr-1" md="6">
                                                    <div className="login-button changepassword text-center">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={this.state.lowerLimitBtn}
                                                            onClick={(event) => this.handleLowerLimitSubmit(event)}
                                                        >
                                                            Set lower limit
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6" style={{ textAlign: "-webkit-center" }}>
                                                    <div style={{ width: "75%" }} className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                            <Grid item>
                                                                <TextField fullWidth
                                                                    select
                                                                    label="Select Bypass Status"
                                                                    variant="outlined"
                                                                    onChange={(e) => this.setState({ selectedBypassValue: e.target.value })}
                                                                    value={this.state.selectedBypassValue}
                                                                >
                                                                    {this.state.bypassStatusList.map(option => (
                                                                        <MenuItem
                                                                            className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                            key={option.id}
                                                                            value={option.id}
                                                                        >
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Col>
                                                <Col className="pr-1" md="6">
                                                    <div className="login-button changepassword text-center">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(event) => this.handleBypassSubmit(event)}
                                                        >
                                                            Set bypass status
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant="contained" color="danger" onClick={(e) => this.handleClose(e)}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </CardBody>
                        </Card>
                    </>
                    :
                    <Row>
                        <Col md="12">
                            <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default Heater