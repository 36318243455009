/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import DemoNavbar from "../components/Navbars/DemoNavbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";
import {routes,routeselect} from "../routes.js";

var ps;

class Dashboard extends React.Component {
  constructor(props) { 
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "warning",
      routing:[]
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    this.routefunction()
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
  }
  routefunction(){
    var x=routeselect()
    this.setState({routing:x})
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
  }
 
  componentDidUpdate(prevProps,prevState){
    if(prevProps.timestamp!=this.props.timestamp){
      this.routefunction()

      // console.log("it comes here")

    }

  }
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
    if (color == 'black' || color == 'blue') {
      this.props.changeTheme('lighttheme',color+'color','none')
    } else if (color == 'grey') {
      this.props.changeTheme('greytheme',color+'color','dark')
    } else {
      this.props.changeTheme('darktheme',color+'color','dark')
    }
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={this.state.routing}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className={"main-panel " + (this.state.backgroundColor === "black" ? "lighttheme" : this.state.backgroundColor === "blue" ? "bluelighttheme" : this.state.backgroundColor === "grey" ? "greytheme" : "darktheme")} ref={this.mainPanel}>
          <DemoNavbar {...this.props} bgColor={this.state.backgroundColor} handleBgClick={this.handleBgClick} />
          <Switch>
            {this.state.routing.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          <Footer fluid bgColor={this.state.backgroundColor} handleBgClick={this.handleBgClick} />
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    timestamp:state.timestamp
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTheme: (theme,themecolor,charttheme) => {dispatch({type: 'CHANGE_THEME', theme: theme, themecolor: themecolor, charttheme: charttheme})}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
