import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import Switch from '@material-ui/core/Switch';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.useTheme(am4themes_animated);

class Landingpage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            checkedA: true,
            checkedAuom: 'kWh',
            checkedAtime: 'Yesterday',
            checkedAtotaltext: 'Total kWh',
            checkedAtotal: '',
            checkedB: true,
            checkedBuom: 'kWh',
            checkedBtime: 'Last 7 Days',
            checkedBtotaltext: 'Total kWh',
            checkedBtotal: '',
            checkedE: true,
            checkedEuom: 'kWh',
            checkedEtime: 'Yesterday',
            checkedF: true,
            checkedFuom: 'kWh',
            checkedFtime: 'Last 7 Days',
            checkedC: true,
            checkedCuom: 'Maximum Demand (kVA)',
            checkedCtime: 'Yesterday',
            checkedCtotaltext: 'Total kVA',
            checkedCtotal: '',
            checkedD: true,
            checkedDuom: 'Peak Ampere (amp)',
            checkedDtime: 'Yesterday',
            checkedDtotaltext: 'Total Amp',
            checkedDtotal: '',
            kwhyesterday: [],
            kwh7days: [],
            costyesterday: [],
            cost7days: [],
            kwyesterday: [],
            kw7days: [],
            kvayesterday: [],
            kva7days: [],
            ampyesterday: [],
            amp7days: [],
            showcontent: false,
            prevkwhtotal: '',
            last7dayskwhtotal: '',
            prevkvatotal: '',
            last7dayskvatotal:'',
            prevampstotal: '',
            last7daysampstotal: '',
            prevkwtotal: '',
            last7dayskwtotal: '',
            prevcosttotal: '',
            last7dayscosttotal: '',
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
    }

    componentDidMount() {
      if (localStorage.getItem('token')) {
        this.props.history.push('/neurobotz/dashboard')
        this.createchart2();
        if (localStorage.getItem('selectedboard')) {
          // this.initresponse(parseInt(localStorage.getItem('selectedboard')))
          this.setState({
            showcontent: true
          })
        }
      } else {
        this.props.history.push('/neurobotz/login')
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.theme != this.props.theme) {
        this.createchart1(this.state.checkedA,this.state.kwhyesterday,this.state.costyesterday,this.props.theme);
        this.createchart2(this.state.checkedB,this.state.kwh7days,this.state.cost7days,this.props.theme);
        this.createchart34('topchartdiv3',this.state.kwyesterday,this.props.theme); 
        this.createchart34('topchartdiv4',this.state.kw7days,this.props.theme);
        this.createchart7(this.state.checkedE,this.state.kwhyesterday,this.state.costyesterday,this.props.theme);
        this.createchart8(this.state.checkedF,this.state.kwh7days,this.state.cost7days,this.props.theme);
        this.createchart5(this.state.checkedC,this.state.kvayesterday,this.state.kva7days,this.props.theme);
        this.createchart6(this.state.checkedD,this.state.ampyesterday,this.state.amp7days,this.props.theme);
      }
    }

    customerhandler(customerId) {
      this.selectedcustomer = customerId
      this.setState({
        showcontent: false
      })
    }
  
    boardhandler(board_id) {
      this.selectedboard = board_id
      this.setState({
        kwhyesterday: [],
        kwh7days: [],
        costyesterday: [],
        cost7days: [],
        kwyesterday: [],
        kw7days: [],
        kvayesterday: [],
        kva7days: [],
        ampyesterday: [],
        amp7days: [],
      })
      this.initresponse(board_id)
      this.setState({
        showcontent: true
      })
    }

    initresponse(board_id) {
      this.setState({ loader: true })
      apiService.Landingpageboxes(board_id).then(response => {
          this.setState({ loader: false })
          if (response.data.length != 0) {
            this.setState({
              kwh7days: this.state.kwh7days.concat(response.data[0].last7days_kwh_data),
              kwhyesterday: this.state.kwhyesterday.concat(response.data[0].previous_kwh_data),
              cost7days: this.state.cost7days.concat(response.data[0].last7days_cost_data),
              costyesterday: this.state.costyesterday.concat(response.data[0].previous_cost_data),
              kwyesterday: this.state.kwyesterday.concat(response.data[0].previous_kw_data),
              kw7days: this.state.kw7days.concat(response.data[0].last7days_kw_data),
              kva7days: this.state.kva7days.concat(response.data[0].last7days_kva_data),
              kvayesterday: this.state.kvayesterday.concat(response.data[0].previous_kva_data),
              amp7days: this.state.amp7days.concat(response.data[0].last7days_amps_data),
              ampyesterday: this.state.ampyesterday.concat(response.data[0].previous_amps_data),
            })
            this.setState({
              checkedAtotal : response.data[0].previous_kwh_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              checkedBtotal : response.data[0].last7days_kwh_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              checkedCtotal : response.data[0].previous_kva_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              checkedDtotal : response.data[0].previous_amps_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              prevkwhtotal: response.data[0].previous_kwh_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last7dayskwhtotal: response.data[0].last7days_kwh_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              prevkvatotal: response.data[0].previous_kva_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last7dayskvatotal:response.data[0].last7days_kva_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              prevampstotal: response.data[0].previous_amps_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last7daysampstotal: response.data[0].last7days_amps_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              prevkwtotal: response.data[0].previous_kw_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last7dayskwtotal: response.data[0].last7days_kw_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              prevcosttotal: response.data[0].previous_cost_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
              last7dayscosttotal: response.data[0].last7days_cost_data.map(item => parseInt(item.value)).reduce((prev, curr) => prev + curr, 0),
            })
            this.createchart1(this.state.checkedA,response.data[0].previous_kwh_data,response.data[0].previous_cost_data,this.props.theme);
            this.createchart2(this.state.checkedB,response.data[0].last7days_kwh_data,response.data[0].last7days_cost_data,this.props.theme);
            this.createchart34('topchartdiv3',response.data[0].previous_kw_data,this.props.theme); 
            this.createchart34('topchartdiv4',response.data[0].last7days_kw_data,this.props.theme);
            this.createchart5(this.state.checkedC,response.data[0].previous_kva_data,response.data[0].last7days_kva_data,this.props.theme);
            this.createchart6(this.state.checkedD,response.data[0].previous_amps_data,response.data[0].last7days_amps_data,this.props.theme);                                 
            this.createchart7(this.state.checkedE,response.data[0].previous_kwh_data,response.data[0].previous_cost_data,this.props.theme);
            this.createchart8(this.state.checkedF,response.data[0].last7days_kwh_data,response.data[0].last7days_cost_data,this.props.theme);                               
          } 
      }).catch(error => {
          this.setState({ loader: false })
          apiService.ErrorMessage(error)
      })
    }

    handleChange(event) {
      var key = event.target.name
      var val = event.target.checked
      var obj  = {}
      obj[key] = val
      if (event.target.name == 'checkedA') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key2 = event.target.name + 'uom'
          var val2 = 'kWh'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total kWh'
          var key4 = event.target.name + 'total'
          var val4 = this.state.prevkwhtotal
          obj[key1] = val1
          obj[key2] = val2
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key2 = event.target.name + 'uom'
          var val2 = 'Cost'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total Cost'
          var key4 = event.target.name + 'total'
          var val4 = this.state.prevcosttotal
          obj[key1] = val1
          obj[key2] = val2
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        }
        this.createchart1(obj['checkedA'],this.state.kwhyesterday,this.state.costyesterday,this.props.theme);
      } 
      if (event.target.name == 'checkedB') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key2 = event.target.name + 'uom'
          var val2 = 'kWh'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total kWh'
          var key4 = event.target.name + 'total'
          var val4 = this.state.last7dayskwhtotal
          obj[key1] = val1
          obj[key2] = val2
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key2 = event.target.name + 'uom'
          var val2 = 'Cost'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total Cost'
          var key4 = event.target.name + 'total'
          var val4 = this.state.last7dayscosttotal
          obj[key1] = val1
          obj[key2] = val2
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        }
        this.createchart2(obj['checkedB'],this.state.kwh7days,this.state.cost7days,this.props.theme);
      } 
      if (event.target.name == 'checkedE') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key2 = event.target.name + 'uom'
          var val2 = 'kWh'
          obj[key1] = val1
          obj[key2] = val2
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key2 = event.target.name + 'uom'
          var val2 = 'Cost'
          obj[key1] = val1
          obj[key2] = val2
          this.setState(obj)
        }
        this.createchart7(obj['checkedE'],this.state.kwhyesterday,this.state.costyesterday,this.props.theme);
      } 
      if (event.target.name == 'checkedF') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key2 = event.target.name + 'uom'
          var val2 = 'kWh'
          obj[key1] = val1
          obj[key2] = val2
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key2 = event.target.name + 'uom'
          var val2 = 'Cost'
          obj[key1] = val1
          obj[key2] = val2
          this.setState(obj)
        }
        this.createchart8(obj['checkedF'],this.state.kwh7days,this.state.cost7days,this.props.theme);
      } 
      if (event.target.name == 'checkedC') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total kVA'
          var key4 = event.target.name + 'total'
          var val4 = this.state.prevkvatotal
          obj[key1] = val1
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total kVA'
          var key4 = event.target.name + 'total'
          var val4 = this.state.last7dayskvatotal
          obj[key1] = val1
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        }  
        this.createchart5(obj['checkedC'],this.state.kvayesterday,this.state.kva7days,this.props.theme);
      } 
      if (event.target.name == 'checkedD') {
        if (event.target.checked == true) {
          var key1 = event.target.name + 'time'
          var val1 = 'Yesterday'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total Amp'
          var key4 = event.target.name + 'total'
          var val4 = this.state.prevampstotal
          obj[key1] = val1
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        } else {
          var key1 = event.target.name + 'time'
          var val1 = 'Last 7 Days'
          var key3 = event.target.name + 'totaltext'
          var val3 = 'Total Amp'
          var key4 = event.target.name + 'total'
          var val4 = this.state.last7daysampstotal
          obj[key1] = val1
          obj[key3] = val3
          obj[key4] = val4
          this.setState(obj)
        }  
        this.createchart6(obj['checkedD'],this.state.ampyesterday,this.state.amp7days,this.props.theme);
      } 
    }

    createchart1(checkedA,data1,data2,theme) { 
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      let chart = am4core.create("topchartdiv1", am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0; 
      chart.innerRadius = am4core.percent(30);
      chart.logo.disabled = true;
      if (checkedA == true) {
        chart.data = data1
      } else {
        chart.data = data2
      }
      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.ticks.template.disabled = true;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.maxWidth = 300;

      chart.legend.valign = "middle"
      chart.legend.scrollable = true;
      series.alignLabels = false;
      series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      series.labels.template.radius = am4core.percent(-45);
      series.labels.template.fill = am4core.color("white");
      series.labels.template.relativeRotation = 90;
      series.hiddenState.properties.endAngle = -90;
      series.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
          return 0;
        }
        return radius;
      });
      series.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      series.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
          return 0
        }
        return 1;
      });
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;
            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart1 = chart;
    }

    createchart2(checkedB,data1,data2,theme) { 
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      let chart = am4core.create("topchartdiv2", am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0; 
      chart.innerRadius = am4core.percent(30);
      chart.logo.disabled = true;
      if (checkedB == true) {
        chart.data = data1
      } else {
        chart.data = data2
      }
      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.ticks.template.disabled = true;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";

      chart.legend.valign = "middle"
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      series.alignLabels = false;
      series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      series.labels.template.radius = am4core.percent(-45);
      series.labels.template.fill = am4core.color("white");
      series.labels.template.relativeRotation = 90;
      series.hiddenState.properties.endAngle = -90;
      series.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0;
        }
        return radius;
      });
      series.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      series.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0
        }
        return 1;
      });
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;

            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart2 = chart;
    }

    createchart7(checkedE,data1,data2,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      var iconPath
      var chart = am4core.create("topchartdiv7", am4charts.SlicedChart);
      chart.hiddenState.properties.opacity = 0;
      chart.logo.disabled = true;
      if (checkedE == true) {
        chart.data = data1
        iconPath = "m411.992,208.486c-5.641-10.172-16.359-16.485-27.992-16.485h-77.219l13.109-157.343c1.211-14.547-7.57-28.062-21.352-32.875-13.766-4.797-29.07,0.328-37.164,12.469l-160,240c-6.547,9.82-7.156,22.445-1.586,32.851 5.563,10.406 16.406,16.898 28.211,16.898h77.445l-13.352,173.547c-1.133,14.688 7.914,28.25 21.914,32.852 3.289,1.086 6.664,1.602 9.992,1.602 10.797,0 21.164-5.492 27.133-15.039l160-256c6.174-9.868 6.494-22.298 0.861-32.477z"
      } else {
        chart.data = data2
        iconPath = "M234.753,92.469c32.329-27.158,53.931-88.341,40.637-91.017c-17.664-3.557-56.022,12.04-74.562,14.788c-26.296,3.175-54.936-28.515-71.012-10.851c-13.071,14.362,9.371,66.592,44.482,89.346C69.546,146.219-77.69,404.673,179.171,423.426C534.582,449.375,356.615,142.639,234.753,92.469z M265.276,296.298c-1.093,10.076-6.433,19.188-14.415,25.374c-8.428,6.532-18.999,9.57-29.502,10.421v11.133c0,2.979-1.301,5.86-3.531,7.832c-3.065,2.712-7.569,3.381-11.289,1.667c-3.673-1.69-6.086-5.457-6.086-9.499v-12.168c-1.801-0.342-3.589-0.749-5.356-1.234c-9.816-2.697-18.921-7.954-25.572-15.732c-3.313-3.877-6.014-8.276-7.882-13.025c-0.488-1.241-0.923-2.505-1.304-3.783c-0.345-1.157-0.701-2.333-0.824-3.539c-0.207-2.023,0.194-4.087,1.137-5.889c1.938-3.707,6.022-5.946,10.192-5.574c4.104,0.364,7.701,3.212,8.993,7.124c0.398,1.205,0.668,2.44,1.115,3.632c0.443,1.184,0.978,2.335,1.607,3.431c1.242,2.158,2.798,4.148,4.59,5.875c3.694,3.559,8.399,5.872,13.304,7.248v-41.362c-9.591-2.483-19.491-5.69-27.411-11.848c-3.849-2.994-7.115-6.714-9.254-11.117c-2.257-4.647-3.192-9.824-3.23-14.966c-0.039-5.221,0.953-10.396,3.131-15.153c2.04-4.454,4.977-8.453,8.578-11.768c7.7-7.087,17.928-11.04,28.187-12.492v-0.91v-10.647c0-2.978,1.301-5.86,3.531-7.832c3.066-2.711,7.568-3.381,11.289-1.667c3.672,1.691,6.086,5.457,6.086,9.499v10.647v0.847c1.367,0.172,2.73,0.378,4.086,0.624c10.074,1.823,19.927,5.983,27.294,13.246c3.49,3.44,6.347,7.539,8.356,12.009c0.561,1.247,1.052,2.523,1.477,3.824c0.396,1.213,0.794,2.462,0.983,3.728c0.302,2.021-0.006,4.109-0.871,5.958c-1.772,3.788-5.746,6.2-9.927,6.021c-4.108-0.179-7.83-2.854-9.301-6.694c-0.438-1.142-0.657-2.351-1.104-3.49c-0.451-1.153-1.035-2.253-1.708-3.292c-1.308-2.02-3.003-3.752-4.938-5.179c-4.19-3.094-9.272-4.706-14.35-5.607v39.582c6.035,1.445,12.075,3.021,17.857,5.301c8.739,3.446,17.02,8.73,21.79,17.062c-0.74-1.298-1.46-2.563,0.025,0.043c1.458,2.56,0.762,1.34,0.03,0.057C264.854,280.704,266.101,288.701,265.276,296.298z"
      }
      var series = chart.series.push(new am4charts.PictorialStackedSeries());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.alignLabels = true;
      series.maskSprite.path = iconPath;
      series.ticks.template.locationX = 1;
      series.ticks.template.locationY = 0.5;
      series.labelsContainer.width = 200;
      series.ticks.template.disabled = true;
      series.labels.template.disabled = true;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;
            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;
            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart7 = chart;
    }

    createchart8(checkedF,data1,data2,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      var iconPath
      var chart = am4core.create("topchartdiv8", am4charts.SlicedChart);
      chart.hiddenState.properties.opacity = 0;
      chart.logo.disabled = true;
      if (checkedF == true) {
        chart.data = data1
        iconPath = "m411.992,208.486c-5.641-10.172-16.359-16.485-27.992-16.485h-77.219l13.109-157.343c1.211-14.547-7.57-28.062-21.352-32.875-13.766-4.797-29.07,0.328-37.164,12.469l-160,240c-6.547,9.82-7.156,22.445-1.586,32.851 5.563,10.406 16.406,16.898 28.211,16.898h77.445l-13.352,173.547c-1.133,14.688 7.914,28.25 21.914,32.852 3.289,1.086 6.664,1.602 9.992,1.602 10.797,0 21.164-5.492 27.133-15.039l160-256c6.174-9.868 6.494-22.298 0.861-32.477z"
      } else {
        chart.data = data2
        iconPath = "M234.753,92.469c32.329-27.158,53.931-88.341,40.637-91.017c-17.664-3.557-56.022,12.04-74.562,14.788c-26.296,3.175-54.936-28.515-71.012-10.851c-13.071,14.362,9.371,66.592,44.482,89.346C69.546,146.219-77.69,404.673,179.171,423.426C534.582,449.375,356.615,142.639,234.753,92.469z M265.276,296.298c-1.093,10.076-6.433,19.188-14.415,25.374c-8.428,6.532-18.999,9.57-29.502,10.421v11.133c0,2.979-1.301,5.86-3.531,7.832c-3.065,2.712-7.569,3.381-11.289,1.667c-3.673-1.69-6.086-5.457-6.086-9.499v-12.168c-1.801-0.342-3.589-0.749-5.356-1.234c-9.816-2.697-18.921-7.954-25.572-15.732c-3.313-3.877-6.014-8.276-7.882-13.025c-0.488-1.241-0.923-2.505-1.304-3.783c-0.345-1.157-0.701-2.333-0.824-3.539c-0.207-2.023,0.194-4.087,1.137-5.889c1.938-3.707,6.022-5.946,10.192-5.574c4.104,0.364,7.701,3.212,8.993,7.124c0.398,1.205,0.668,2.44,1.115,3.632c0.443,1.184,0.978,2.335,1.607,3.431c1.242,2.158,2.798,4.148,4.59,5.875c3.694,3.559,8.399,5.872,13.304,7.248v-41.362c-9.591-2.483-19.491-5.69-27.411-11.848c-3.849-2.994-7.115-6.714-9.254-11.117c-2.257-4.647-3.192-9.824-3.23-14.966c-0.039-5.221,0.953-10.396,3.131-15.153c2.04-4.454,4.977-8.453,8.578-11.768c7.7-7.087,17.928-11.04,28.187-12.492v-0.91v-10.647c0-2.978,1.301-5.86,3.531-7.832c3.066-2.711,7.568-3.381,11.289-1.667c3.672,1.691,6.086,5.457,6.086,9.499v10.647v0.847c1.367,0.172,2.73,0.378,4.086,0.624c10.074,1.823,19.927,5.983,27.294,13.246c3.49,3.44,6.347,7.539,8.356,12.009c0.561,1.247,1.052,2.523,1.477,3.824c0.396,1.213,0.794,2.462,0.983,3.728c0.302,2.021-0.006,4.109-0.871,5.958c-1.772,3.788-5.746,6.2-9.927,6.021c-4.108-0.179-7.83-2.854-9.301-6.694c-0.438-1.142-0.657-2.351-1.104-3.49c-0.451-1.153-1.035-2.253-1.708-3.292c-1.308-2.02-3.003-3.752-4.938-5.179c-4.19-3.094-9.272-4.706-14.35-5.607v39.582c6.035,1.445,12.075,3.021,17.857,5.301c8.739,3.446,17.02,8.73,21.79,17.062c-0.74-1.298-1.46-2.563,0.025,0.043c1.458,2.56,0.762,1.34,0.03,0.057C264.854,280.704,266.101,288.701,265.276,296.298z"
      }
      var series = chart.series.push(new am4charts.PictorialStackedSeries());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.alignLabels = true;
      series.maskSprite.path = iconPath;
      series.ticks.template.locationX = 1;
      series.ticks.template.locationY = 0.5;
      series.labelsContainer.width = 200;
      series.ticks.template.disabled = true;
      series.labels.template.disabled = true;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;

            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart8 = chart;
    }

    createchart34(divdata,data,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_material);
      } 
      let chart = am4core.create(divdata, am4charts.PieChart3D);
      chart.logo.disabled = true;
      chart.hiddenState.properties.opacity = 0;
      chart.data =data
      chart.innerRadius = 50;
      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      series.labels.template.radius = am4core.percent(-45);
      series.labels.template.fill = am4core.color("white");
      series.labels.template.relativeRotation = 90;
      series.hiddenState.properties.endAngle = -90;
      series.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0;
        }
        return radius;
      });
      series.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      series.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0
        }
        return 1;
      });
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      if (theme == 'darktheme') {
        series.colors.list = [
          am4core.color("#e91e63"),
          am4core.color("#00bcd4"),
          am4core.color("#f7e53b"),
          am4core.color("#ffc107"),
          am4core.color("#2196f3"),
          am4core.color("#93c360"),
          am4core.color("#ab26c2"),
          am4core.color("#009688"),
          am4core.color("#d73e71"),
          am4core.color("#58e2c2"),
        ];
      }
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;

            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart3 = chart;
    }

    createchart5(checkedC,data1,data2,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dataviz);
      } 
      let chart = am4core.create("topchartdiv5", am4charts.PieChart3D);
      chart.logo.disabled = true;
      chart.hiddenState.properties.opacity = 0;
      chart.innerRadius = am4core.percent(30);
      chart.depth = 30;
      var selected;
      var types;
      if (checkedC == true) {
        types = data1
      } else {
        types = data2
      }
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      chart.data = generateChartData();
      var pieSeries = chart.series.push(new am4charts.PieSeries3D());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.slices.template.propertyFields.stroke = "color";
      pieSeries.slices.template.propertyFields.isActive = "pulled";
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.ticks.template.disabled = true;
      pieSeries.alignLabels = false;
      pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      pieSeries.labels.template.radius = am4core.percent(-45);
      pieSeries.labels.template.fill = am4core.color("white");
      pieSeries.labels.template.relativeRotation = 90;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0;
        }
        return radius;
      });
      pieSeries.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      pieSeries.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0
        }
        return 1;
      });
      function generateChartData() {
        var chartData = [];
        if (theme == 'darktheme') {
          var colorslist = [
            am4core.color("#e91e63"),
            am4core.color("#00bcd4"),
            am4core.color("#f7e53b"),
            am4core.color("#ffc107"),
            am4core.color("#2196f3"),
            am4core.color("#93c360"),
            am4core.color("#ab26c2"),
            am4core.color("#009688"),
            am4core.color("#d73e71"),
            am4core.color("#58e2c2"),
          ];
        } else {
          var colorslist = []
        }
        for (var i = 0; i < types.length; i++) {
          if (theme == 'darktheme' || theme == 'greytheme') {
            var col=colorslist[i]
          } else {
            var col=chart.colors.getIndex(i)
          }
          if (i == selected) {
            for (var x = 0; x < types[i].subs.length; x++) {
              chartData.push({
                name: types[i].subs[x].name +"("+types[i].name+")",
                value: types[i].subs[x].value,
                color: col,
                pulled: true
              });
            }
          } else {
              chartData.push({
                name: types[i].name,
                value: types[i].value,
                color:  col,
                id: i
              });
          }
        }
        return chartData;
      }
      pieSeries.slices.template.events.on("hit", function(event) {
        if (event.target.dataItem.dataContext.id != undefined) {
          selected = event.target.dataItem.dataContext.id;
        } else {
          selected = undefined;
        }
        chart.data = generateChartData();
      });
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;

            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart5 = chart;
    }

    createchart6(checkedD,data1,data2,theme) {
      if (theme == 'darktheme' || theme == 'greytheme') {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.unuseTheme(am4themes_kelly);
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_material);
        am4core.unuseTheme(am4themes_dark);
        am4core.unuseTheme(am4themes_dataviz);
        am4core.useTheme(am4themes_kelly);
      } 
      let chart = am4core.create("topchartdiv6", am4charts.PieChart3D);
      chart.logo.disabled = true;
      chart.innerRadius = am4core.percent(30);
      chart.depth = 30;
      var selected;
      var types;
      if (checkedD == true) {
        types = data1
      } else {
        types = data2
      }
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.valign = "middle";
      chart.legend.maxWidth = 300;
      chart.legend.scrollable = true;
      chart.data = generateChartData1();
      var pieSeries = chart.series.push(new am4charts.PieSeries3D());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.slices.template.propertyFields.stroke = "color"; 
      pieSeries.slices.template.propertyFields.isActive = "pulled";
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.ticks.template.disabled = true;
      pieSeries.alignLabels = false;
      pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      pieSeries.labels.template.radius = am4core.percent(-45);
      pieSeries.labels.template.fill = am4core.color("white");
      pieSeries.labels.template.relativeRotation = 90;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.labels.template.adapter.add("radius", function(radius, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0;
        }
        return radius;
      });
      pieSeries.labels.template.adapter.add("fill", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return am4core.color("#ffffff");
        }
        return color;
      });
      pieSeries.labels.template.adapter.add("opacity", function(color, target) {
        if (target.dataItem && (target.dataItem.values.value.percent < 1)) {
          return 0
        }
        return 1;
      });
      function generateChartData1() {
        var chartData = [];
        if (theme == 'darktheme') {
          var colorslist = [
            am4core.color("#e91e63"),
            am4core.color("#00bcd4"),
            am4core.color("#f7e53b"),
            am4core.color("#ffc107"),
            am4core.color("#2196f3"),
            am4core.color("#93c360"),
            am4core.color("#ab26c2"),
            am4core.color("#009688"),
            am4core.color("#d73e71"),
            am4core.color("#58e2c2"),
          ];
        } else {
          var colorslist = []
        }
        for (var i = 0; i < types.length; i++) {
          if (theme == 'darktheme' || theme == 'greytheme') {
            var col=colorslist[i]
          } else {
            var col=chart.colors.getIndex(i)
          }
          if (i == selected) {
            for (var x = 0; x < types[i].subs.length; x++) {
              chartData.push({
                name: types[i].subs[x].name +"("+types[i].name+")",
                value: types[i].subs[x].value,
                color: col,
                pulled: true
              });
            }
          } else {
              chartData.push({
                name: types[i].name,
                value: types[i].value,
                color: col,
                id: i
              });
          }
        }
        return chartData;
      }
      pieSeries.slices.template.events.on("hit", function(event) {
        if (event.target.dataItem.dataContext.id != undefined) {
          selected = event.target.dataItem.dataContext.id;
        } else {
          selected = undefined;
        }
        chart.data = generateChartData1();
      });
      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 400) {
            chart.legend.maxHeight = 150;

            chart.legend.disabled = true;
            return true;
          } 
          if (target.pixelWidth > 400) {
            chart.legend.maxHeight = 300;

            chart.legend.disabled = false;
             return false;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);
            return state;
          }
          return null;
        }
      });
      this.chart6 = chart;
    }

    componentWillUnmount() {
      if (this.chart1) {
        this.chart1.dispose();
      }
      if (this.chart2) {
        this.chart2.dispose();
      }
      if (this.chart3) {
        this.chart3.dispose();
      }
      if (this.chart5) {
        this.chart5.dispose();
      }
      if (this.chart6) {
        this.chart6.dispose();
      }
      if (this.chart7) {
        this.chart7.dispose();
      }
      if (this.chart8) {
        this.chart8.dispose();
      }
    }

    render() {
        return (
          <>
            <div className="content">
            {
                this.state.loader && <Loader />
            }
            <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} showProcessDropdown={true}/>
              {this.state.showcontent == true ? 
               <Row>
                <Col md="6">
                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle"> 
                        <Switch
                          checked={this.state.checkedA}
                          color="primary"
                          name="checkedA"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          className={"switchtoggler " + (this.props.theme) + ' ' + (this.props.themecolor)}
                        />
                        {this.state.checkedAuom} - {this.state.checkedAtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                     <div id="topchartdiv1" style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                     <hr />
                     <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> {this.state.checkedAtotaltext} : {this.state.checkedAtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle">
                      <Switch
                          checked={this.state.checkedB}
                          color="primary"
                          name="checkedB"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          className={"switchtoggler " + (this.props.theme) + ' ' + (this.props.themecolor)}
                        />
                        {this.state.checkedBuom} - {this.state.checkedBtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv2" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                      <hr />
                     <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> {this.state.checkedBtotaltext} : {this.state.checkedBtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5"> 
                        <Switch
                          checked={this.state.checkedE}
                          color="primary"
                          name="checkedE"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        {this.state.checkedEuom} - {this.state.checkedEtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv7" style={{ width: "100%", height: "350px" }}></div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5">
                      <Switch
                          checked={this.state.checkedF}
                          color="primary"
                          name="checkedF"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        {this.state.checkedFuom} - {this.state.checkedFtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <div id="topchartdiv8" style={{ width: "100%", height: "350px" }}></div>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle"> 
                        Peak Demand (kW) - Yesterday</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv3" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                      <hr />
                      <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total kW : {this.state.prevkwtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle">
                      Peak Demand (kW) - Last 7 Days</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <div id="topchartdiv4" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                    <hr />
                    <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> Total kW : {this.state.last7dayskwtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle"> 
                        <Switch
                          checked={this.state.checkedC}
                          color="primary"
                          name="checkedC"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        {this.state.checkedCuom} - {this.state.checkedCtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div id="topchartdiv5" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                      <hr />
                      <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> {this.state.checkedCtotaltext} : {this.state.checkedCtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader>
                      <CardTitle tag="h5" className="dashboardcardtitle">
                      <Switch
                          checked={this.state.checkedD}
                          color="primary"
                          name="checkedD"
                          onChange={(e) =>this.handleChange(e)}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        {this.state.checkedDuom} - {this.state.checkedDtime}</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <div id="topchartdiv6" style={{ width: "100%", height: "350px", fontFamily: 'Inter', fontWeight: '500' }}></div>
                    <hr />
                    <CardTitle tag="h5" className="dashboardcardtitle" style={{textAlign:'center'}}> {this.state.checkedDtotaltext} : {this.state.checkedDtotal}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :
              <Row>
                <Col md="12">
                  <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                    <CardHeader> 
                    </CardHeader>
                    <CardBody>
                      <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }
            </div>
          </>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    themecolor: state.themecolor 
  }
}

export default connect(mapStateToProps)(Landingpage);