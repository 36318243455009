import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col ,TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';

import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Demand extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loader: false,
            activetab: "1",

            LoadList: [],
            AlarmList:[],
            showcontent: false,
            editmode: false,
            buttontext: 'Add',
            dropDownList: [],
            BoardList: []
        }

        // console.log(this.state,props)

        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''

    }
    LoadForm = FormBuilder.group({
        id:[""],
        load_seq: ["", [Validators.required]],
        load_id: ["", [Validators.required]],
        load_status: ["ON", [Validators.required]],
        fdate: ["", [Validators.required]],
        time: ["", [Validators.required]],
        description: ["", [Validators.required]]
    });

    AlarmForm = FormBuilder.group({
        id:[""],
        desc: ["", [Validators.required]],
        type: ["", [Validators.required]],
        value: ["ON", [Validators.required]],
        time: ["", [Validators.required]],
        load: ["", [Validators.required]],
    });

    cancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.LoadForm.reset()
        this.LoadForm.patchValue({
            fdate:this.dateconvertion(new Date()),
            load_status:"ON",
            time:this.Timeconvertion(new Date())
        })
        this.AlarmForm.reset()
        this.AlarmForm.patchValue({
           load:"L1",
           type:"KVA",
            time:this.Timeconvertion(new Date())
        })
        
    }
    dateconvertion(e){
        var x=Moment(e).format('YYYY-MM-DD')
        // const sy = e.getFullYear();
        // const sm = ("0" + (e.getMonth())).slice(-2);
        // const sd = ("0" + e.getDate() ).slice(-2);
        // var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        // console.log(x)
        return x
    }
    Timeconvertion(e){
        var x=Moment(e).format('HH:mm:ss')
        // const hh = ("0" + (e.getHours())).slice(-2);
        // const mm = ("0" + (e.getMinutes())).slice(-2);
        // const ss = ("0" + e.getSeconds()).slice(-2);
        // var x = hh.toString() + ':' + mm.toString() + ':' + ss.toString();
        return x
    }
    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.push('/neurobotz/demand')
            if (localStorage.getItem('selectedboard')) {
                // this.getdropdowndata()
                // this.boardData()
                // this.cancel()

                // this.setState({
                //     showcontent: true,
                //     LoadList:[],
                //     AlarmList:[]
                // },()=>{
                //      this.getLoad(true)
                // this.getAlarm(false)
                // })
               

                // console.log(this.state.dropDownList)
                // this.boardData()
                // this.fetchdata(this.state.period,this.pri_list,this.state.checkboxvalue,localStorage.getItem('selectedboard'))
               
            }

        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    getLoad(data) {
        if ($.fn.dataTable.isDataTable('#loadtable')) {
            $('#loadtable').DataTable().destroy();
        }
        this.setState({ loader: true })
        apiService.getLoad().then(response => {
            //  console.log(response.data)
             this.setState({LoadList: response.data},()=>this.datatableRerender(data)) 

            // this.setState({LoadList: this.state.LoadList.concat(response.data)},()=>this.datatableRerender(data)) 
            
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
  
    getAlarm(data) {
         if ($.fn.dataTable.isDataTable('#alarmtable')) {
                    $('#alarmtable').DataTable().destroy();
                }
        this.setState({ loader: true })
        apiService.getDemand().then(response => {
            //  console.log(response.data)
            // this.setState({ LoadList: response.data })
            // this.setState({ LoadList: [] })
            this.setState({ AlarmList: response.data })
       
            // this.setState({AlarmList: this.state.AlarmList.concat(response.data)}) 
            this.datatableRerender(data)
            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    datatableRerender(data){
          if(data==true){
           
            var x='#loadtable'
            if(this.state.activetab==2){
                 x='#alarmtable'
                 if ($.fn.dataTable.isDataTable('#alarmtable')) {
                    $('#alarmtable').DataTable().destroy();
                }
            }else{
                if ($.fn.dataTable.isDataTable('#loadtable')) {
                    $('#loadtable').DataTable().destroy();
                }
            }
           
            $(document).ready(function () {
                $(x).DataTable({
                    "bLengthChange": false,
                    "pageLength": 5,
                    "searching": true,
                    "bInfo": true,
                    "bSort": true,
                    "bPaginate": true,
                    "dom": 'Bfrtip',
                    "buttons": []
                });
            });
           
          }
        }

    handleSubmit(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addLoad(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Load Added Successfully")
                this.getLoad(true);
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editLoad(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Load Updated Successfully")
                this.getLoad(true);
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    toggle(option) {
        var x='#loadtable'
        // if(option==2){
        //     if ($.fn.dataTable.isDataTable('#alarmtable')) {
        //         console.log(89)
        //         $('#alarmtable').DataTable().clear().destroy();
        //     }
        //      x='#alarmtable'
            
        // }else{
        //     if ($.fn.dataTable.isDataTable('#loadtable')) {
        //         console.log(989)

        //         $('#loadtable').DataTable().clear().destroy();
        //     }
        // }
         if(option==2){
                if ($.fn.dataTable.isDataTable('#loadtable')) {
                    $('#loadtable').DataTable().destroy();
                }
               
                 x='#alarmtable'
                
            }else{
                if ($.fn.dataTable.isDataTable('#alarmtable')) {
                    $('#alarmtable').DataTable().destroy();
                }
            }
        this.setState({
            activetab: option
        },()=> {
          
           this.cancel()
            this.datatableRerender(true)
        })

       
    }
    LoadEdit(value) {
        // console.log(value)
        var x="OFF"
        if(value.load_state==true){
            x="ON"
        }
        this.LoadForm.patchValue({
            id:value.id,
            load_seq: value.sequence,
            load_id: value.load_id,
            load_status: x,
            fdate: value.date,
            time: value.time,
            description:value.description
           
        })
     

        this.setState({
            buttontext: 'Update'
        })
    }

    loadDelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Load!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLoad(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Load Deleted Successfully")
                        this.getLoad(true);
                        this.cancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    handleSubmit1(event, value) {
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addAlarm(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Alarm Added Successfully")
                this.getAlarm(true);
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.editAlarm(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Alarm Updated Successfully")
                this.getAlarm(true);
                this.cancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
  
    AlarmEdit(data) {
        // console.log(value)

        this.AlarmForm.patchValue({
            id:data.id,
            desc: data.description,
            type: data.type,
             value: data.value,
            time: data.delay_time,
             load: data.load
           
        })
     

        this.setState({
            buttontext: 'Update'
        })
    }

    alarmDelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Alarm!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteAlarm(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Alarm Deleted Successfully")
                        this.getAlarm(true);
                        this.cancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }

  

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            //  AlarmList:[],
            // LoadList:[]
        })
        // this.LoadForm.reset()
       

    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            activeTab:1,
            // AlarmList:[],
            // LoadList:[]
        },()=>{
            // this.toggle(1)
            localStorage.setItem('selectedboard',board_id)

            this.cancel()
           this.getLoad(true)
           this.getAlarm(false)
        })
    
        // this.componentDidMount()
    }



    render() {
        var status=["ON","OFF"]
       var type = ["KVA", "KW", "Amp"]
       var load = ["L1", "L2", "L3", "L4", "L5", "L6", "L7"]

        return (    
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                            <Col md="12">
                                          <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                              <CardHeader>
                                                  <CardTitle tag="h5">
                                                      <Nav tabs>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('1'); }}
                                                              >
                                                                  Load Schedule
                            </NavLink>
                                                          </NavItem>
                                                          <NavItem>
                                                              <NavLink
                                                                  className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                  onClick={() => { this.toggle('2'); }}
                                                              >
                                                                  Alarm
                            </NavLink>
                                                          </NavItem>
                                                      </Nav>
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardBody>
                                                  <TabContent activeTab={this.state.activetab}>
                                                      <TabPane tabId="1">
                                                      <FieldGroup
                                                control={this.LoadForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="load_seq"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Load Sequence" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Load Sequence is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            <FieldControl
                                                                    name="load_id"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Load *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Load
                                                  </MenuItem>
                                                                                        {load.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option} value={option} >
                                                                                                {option}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Load ID is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="load_status"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Load Status *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Load Status
                                                  </MenuItem>
                                                                                        {status.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option} value={option} >
                                                                                                {option}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Load Status is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />

                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="description"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Description" {...handler("text")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Description is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                     
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="fdate"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="date" required fullWidth variant="outlined" error={touched && hasError("required")} label="Date" {...handler("date")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Date is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="time"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="Time" {...handler("time")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Time is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                             </Row>
                                                        <Row>
                                                          
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.handleSubmit(event, this.LoadForm.getRawValue())}
                                                                    >
                                                                        {this.state.buttontext}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                )}
                                            />
                                                      </TabPane>
                                                      <TabPane tabId="2">
                                                      <FieldGroup
                                                control={this.AlarmForm}
                                                strict={false}
                                                render={({ get, invalid, value }) => (
                                                    <form>
                                                        <Row>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="desc"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Description" {...handler("text")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Load Sequence is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                             <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="type"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Type *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Type
                                                  </MenuItem>
                                                                                        {type.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option} value={option} >
                                                                                                {option}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Type is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />

                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="value"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="number" required fullWidth variant="outlined" error={touched && hasError("required")} label="Value" {...handler("number")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Value is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                     
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="time"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField type="time" required fullWidth variant="outlined" error={touched && hasError("required")} label="Delay Time(sec) " {...handler("time")} />
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Time is required`}
                                                                                </span>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <FieldControl
                                                                    name="load"
                                                                    strict={false}
                                                                    render={({ handler, touched, hasError }) => (
                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                <Grid item>
                                                                                    <TextField fullWidth
                                                                                        select
                                                                                        label="Select Load *"
                                                                                        variant="outlined"
                                                                                        {...handler("text")}
                                                                                        //   value={this.state.selectedCountryValue}
                                                                                        error={touched && hasError("required")}
                                                                                    >
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                            Select Load
                                                  </MenuItem>
                                                                                        {load.map(option => (
                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option} value={option} >
                                                                                                {option}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                </Grid>
                                                                                <span className="error">
                                                                                    {touched
                                                                                        && hasError("required")
                                                                                        && `* Load is required`}
                                                                                </span>
                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                />

                                                            </Col>
                                                              </Row>
                                                        <Row>
                                                          
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={invalid}
                                                                        onClick={(event) => this.handleSubmit1(event, this.AlarmForm.getRawValue())}
                                                                    >
                                                                        {this.state.buttontext}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        onClick={() => this.cancel()}
                                                                        variant="contained"
                                                                        color="danger"
                                                                    >
                                                                        Cancel
                            </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                )}
                                            />
                                                          {/* <img src={floor_image} className={"img-rounded"} alt="Cinque Terre" width="100%" /> */}
                                                      </TabPane>
                                                  </TabContent>
                                              </CardBody>
                                          </Card>
                                      </Col>
                              
                            </Row>
                            {this.state.activetab=="1" ?

                            <Row>   
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5"></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                               
                                                <table id="loadtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                    <thead>
                                                        <tr>
                                                        <th style={{width : '100px'}}>Actions</th>
                                                        <th style={{width : '100px'}}>S.No</th>
                                        <th style={{width : '140px'}}>Load Sequence</th>
                                        <th style={{width : '100px'}}>Load Id</th>
                                        <th style={{width : '100px'}}>Date</th>
                                        <th style={{width : '100px'}}>Time</th>
                                        <th style={{width : '130px'}}>Load State</th>
                                        <th style={{width : '130px'}}>Description</th>
                                        <th style={{width : '200px'}}>Created at</th>
                                        
                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.LoadList.map((value, index) => (
                                                            <tr key={index + 1}>
                                                                
                                                                <td>
                                                                    <a href="javascript:void(0)" onClick={(event) => this.loadDelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)" onClick={(e) => this.LoadEdit(value)}><i className="fa fa-edit icons"></i></a>
                                                                </td>

                                                                <td>{index+1}</td>
                                                                <td>{value.sequence}</td>
                                                                <td>{value.load_id}</td>
                                                                <td>{value.date}</td>
                                                                <td>{value.time}</td>
                                                                <td>{value.load_state.toString()}</td>
                                                                <td>{value.description}</td>
                                                                <td>{value.created_at}</td>


                                                             

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                      
                                           </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>:
                              <Row>   
                              <Col md="12">
                                  <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                      <CardHeader>
                                          <CardTitle tag="h5"></CardTitle>
                                      </CardHeader>
                                      <CardBody>
                                          <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    
                                         <table id="alarmtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                         <thead>
                                             <tr>
                                             <th style={{width : '100px'}}>Actions</th>
                                             <th style={{width : '100px'}}>Load</th>
                                          <th style={{width : '100px'}}>Type</th>
                                          <th style={{width : '110px'}}>Delay Time</th>
                                          <th style={{width : '140px'}}>Description</th>
                                          <th style={{width : '100px'}}>Value</th>  
                                          <th style={{width : '200px'}}>Created / Updated at</th> 
                             
                             </tr>
                                         </thead>
                                         <tbody>
                                             {this.state.AlarmList.map((value, index) => (
                                                 <tr key={index + 1}>
                                                     
                                                     <td>
                                                         <a href="javascript:void(0)" onClick={(event) => this.alarmDelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                     <a href="javascript:void(0)" onClick={(e) => this.AlarmEdit(value)}><i className="fa fa-edit icons"></i></a>
                                                     </td>

                                                     <td>{value.load}</td>
                                                     <td>{value.type}</td>
                                                     <td>{value.delay_time}</td>
                                                     <td>{value.description}</td>
                                                     <td>{value.value}</td>
                                                     
                                                     <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                      

                                                 </tr>
                                             ))}
                                         </tbody>
                                     </table>
  
                                         </div>
                                      </CardBody>
                                  </Card>
                              </Col>
                          </Row>
    }
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(Demand);
