import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col ,TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {FormControl} from "@material-ui/core"
import TextField from '@material-ui/core/TextField';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import DragMultiselectSelect from './DragMultiselectSelect';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);
const $ = require('jquery');
$.DataTable = require('datatables.net');

export class ADRComponent extends React.Component {
    constructor(props) {
        super();
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd =this.today;
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        this.state = {
            loader: false,
            showcontent: true,
            activetab: "1",
            radiooption: 'time',
            defaultrange : [this.today,this.today],
            date: '',
            selectedoperation: 'on',
            time: '00:00',
            viewADR: true,
            selectedUnit: 'kwh',
            beforeadr: {
                high: 8640,
                low: 5574.24,
                total: 6678,
                avg: 6455.232
            },
            afteradr: {
                high: 6048,
                low: 3901.968,
                total: 4674.6,
                avg: 4518.6624
            }
        }
        this.beforeadrchartconfig = {}
        this.afteradrchartconfig = {}
        this.customerlist = [
            {'id': '1', 'name': 'TITAN NEUROSENSE EMS'},
            {'id': '2', 'name': 'AG PLASTICS'},
            {'id': '3', 'name': 'BP PROTEA GLEN'},
            {'id': '4', 'name': 'Riverside Manor'},
            {'id': '5', 'name': 'Zydex Industries Gavasad'},
            {'id': '6', 'name': 'MTO Forestry George Sawmill'},
            {'id': '7', 'name': 'REYNOLDS PENS INDIA PVT LTD'}
        ]

        this.boardlist = [
            {'id': '1', 'name': 'MCC 1-24-4 (CASE POLISHING BLOWER)'},
            {'id': '2', 'name': 'SLP 1-7-12 (TREATMENT - LIGHTING)'},
            {'id': '3', 'name': 'MVP 1-4 (CANTEEN)'},
            {'id': '4', 'name': 'MVP 1-6 (COMPRESSOR MAIN)'},
            {'id': '5', 'name': 'SCREW CHILLER -1 (200 TR)'},
            {'id': '6', 'name': 'MCC 1-17-17 (PRINTING)'},
            {'id': '7', 'name': 'MCC 1-19 (JIG BORING AC)'},
            {'id': '8', 'name': 'MCC 1-23-4 (CASE D.M PLANT)'},
            {'id': '9', 'name': 'MCC 1-24-3 (DET)'},
            {'id': '10', 'name': 'MCC 1-24-4 (CASE POLISHING BLOWER)'},
            {'id': '11', 'name': 'HAG 1'},
            {'id': '12', 'name': 'ICE PLANT 1'},
            {'id': '13', 'name': 'ICEPLANT 2'},
            {'id': '14', 'name': 'SD 1'},
            {'id': '15', 'name': '400TR Chiller'},
            {'id': '16', 'name': 'ETP PANEL'},
            {'id': '17', 'name': 'ETP(SMART PO)'},
            {'id': '18', 'name': 'HVAC'},
            {'id': '19', 'name': 'Coal Burn'},
            {'id': '20', 'name': 'Compressor'},
        ]
        this.loadlist = [
            {'id': '1', 'name': 'CHILLER MOTOR2SF'},
            {'id': '2', 'name': 'STEPPER MC 4YTF'},
            {'id': '3', 'name': 'WOVEN MOTOR 21R'},
            {'id': '4', 'name': 'COOLING TOWER 16Q'},
            {'id': '5', 'name': 'STEPPER MC 45RFC'},
            {'id': '6', 'name': 'CHILLER MOTOR43'},
            {'id': '7', 'name': 'COOLING TOWER 34U'},
            {'id': '8', 'name': 'WOVEN MOTRO57J'},
            {'id': '9', 'name': 'COMP MOTOR114C'},
            {'id': '10', 'name': 'CHILLER MOTOR66A'},
        ]

        this.beforeadrdata = [
            {
                "MINUTE": "12:01 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:02 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:03 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:04 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:05 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:06 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:07 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:08 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:09 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:10 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:11 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:12 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:13 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:14 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:15 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:16 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:17 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:18 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:19 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:20 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:21 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:22 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:23 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:24 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:25 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:26 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:27 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:28 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:29 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:30 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:31 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:32 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:33 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:34 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:35 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:36 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:37 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:38 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:39 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:40 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:41 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:42 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:43 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:44 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:45 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:46 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:47 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:48 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:49 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:50 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:51 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:52 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:53 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:54 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:55 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "12:56 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:57 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "5574.24"
            },
            {
                "MINUTE": "12:58 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "5760"
            },
            {
                "MINUTE": "12:59 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            },
            {
                "MINUTE": "01:00 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "8640"
            }
        ]

        this.afteradrdata = [
            {
                "MINUTE": "01:01 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:02 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:03 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:04 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:05 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:06 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:07 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:08 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:09 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:10 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:11 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:12 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:13 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:14 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:15 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:16 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:17 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:18 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:19 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:20 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:21 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:22 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:23 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:24 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:25 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:26 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:27 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:28 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:29 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:30 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:31 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:32 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:33 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:34 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:35 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:36 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:37 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:38 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:39 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:40 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:41 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:42 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:43 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:44 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:45 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:46 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:47 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:48 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:49 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:50 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:51 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:52 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:53 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:54 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:55 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "01:56 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:57 p.m",
                "VALUE1": "464.52",
                "VALUE2": "12",
                "VALUE3": "3901.968"
            },
            {
                "MINUTE": "01:58 p.m",
                "VALUE1": "480",
                "VALUE2": "12",
                "VALUE3": "4032"
            },
            {
                "MINUTE": "01:59 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            },
            {
                "MINUTE": "02:00 p.m",
                "VALUE1": "720",
                "VALUE2": "12",
                "VALUE3": "6048"
            }
        ]
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('is_admin') == 'true' || localStorage.getItem('is_admin') == true) {
                this.props.history.push('/neurobotz/autodemandresponse')
                this.convertdate1(new Date())
                this.createbeforeadrchart(this.beforeadrdata,this.state.selectedUnit,this.props.charttheme);
                this.createafteradrchart(this.afteradrdata,this.state.selectedUnit,this.props.charttheme);  
            } else {
                this.props.history.push('/neurobotz/dashboard')
            }
        } else {
            this.props.history.push('/neurobotz/login')
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
          this.createbeforeadrchart(this.beforeadrdata,this.state.selectedUnit,this.props.charttheme);
          this.createafteradrchart(this.afteradrdata,this.state.selectedUnit,this.props.charttheme);  
        }
    }

    datehandleChange(e) {
        this.setState({
            defaultrange: e.target.value
        })
    }

    createbeforeadrchart(data,unit,theme) {
        this.beforeadrchartconfig = AmCharts.makeChart("beforeadrchartdiv", {
            "type": "serial",
            "theme": theme,
            "dataProvider": data,
            "valueAxes": [ {
              "gridColor": "#FFFFFF",
              "gridAlpha": 0.2,
              "dashLength": 0,
              "title": unit
            } ],
            "gridAboveGraphs": true,
            "startDuration": 1,
            "graphs": [ {
              "balloonText": "<b>[[value]] </b>" + unit,
              "fillAlphas": 0,
              "lineAlpha": 1,
              "type": "line",
              "valueField": "VALUE3",
            } ],
            "chartCursor": {
              "categoryBalloonEnabled": false,
              "cursorAlpha": 0,
              "zoomable": false
            },
            "categoryField": "MINUTE",
            "categoryAxis": {
              "gridPosition": "start",
              "gridAlpha": 0,
              "tickPosition": "start",
              "tickLength": 20
            },
            "export": {
              "enabled": true
            }
          
          } )
    }

    createafteradrchart(data,unit,theme) {
        this.afteradrchartconfig = AmCharts.makeChart("afteradrchartdiv", {
            "type": "serial",
            "theme": theme,
            "dataProvider": data,
            "valueAxes": [ {
              "gridColor": "#FFFFFF",
              "gridAlpha": 0.2,
              "dashLength": 0,
              "title": unit
            } ],
            "gridAboveGraphs": true,
            "startDuration": 1,
            "graphs": [ {
              "balloonText": "<b>[[value]] </b>" + unit,
              "fillAlphas": 0,
              "lineAlpha": 1,
              "type": "line",
              "valueField": "VALUE3",
            } ],
            "chartCursor": {
              "categoryBalloonEnabled": false,
              "cursorAlpha": 0,
              "zoomable": false
            },
            "categoryField": "MINUTE",
            "categoryAxis": {
              "gridPosition": "start",
              "gridAlpha": 0,
              "tickPosition": "start",
              "tickLength": 20
            },
            "export": {
              "enabled": true
            }
          
          } )
    }

    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.setState({
            date: this.dailytoday 
        })
    }

    toggle(option) {
        this.setState({
            activetab: option
        })  
    }

    toggleradio(option) {
        this.setState({
            radiooption: option.target.value
        })  
    }

    handleunitchange(e) {
        e.preventDefault()
        this.setState({
            selectedUnit: e.target.value
        }, () => {
            this.createbeforeadrchart(this.beforeadrdata,this.state.selectedUnit,this.props.charttheme);
            this.createafteradrchart(this.afteradrdata,this.state.selectedUnit,this.props.charttheme);  
        })
        
    }

    handleadrSubmit(e) {
        e.preventDefault()
        console.log('submit')
    }

    generateviewADR(e) {
        e.preventDefault()
        console.log('submit')
        this.setState({
            viewADR: true
        })
    }

    canceladr(e) {
        e.preventDefault()
        console.log('cancel')
    }

    handleoperationchange(e) {
        e.preventDefault()
        this.setState({
            selectedoperation: e.target.value
        })
    }

    render() {
        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Create ADR
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            View ADR
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">
                                                    <Row>
                                                        <Col>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    value={this.state.radiooption}
                                                                    onChange={(e) => this.toggleradio(e)}
                                                                >
                                                                    <FormControlLabel label={<div><span className="makeitupper">T</span>ime Based</div>}  value="time" control={<Radio />} checked={this.state.radiooption === 'time'}  />
                                                                    <FormControlLabel label={<div><span className="makeitupper">E</span>vent Based</div>} className="makeitupper" value="event" control={<Radio />}  checked={this.state.radiooption === 'event'} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md="4">
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="date" required fullWidth variant="outlined" label="Date" value={this.state.date} onChange={(e) => this.setState({date:e.target.value})}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                    <FormControl variant="outlined"
        margin={"1"}
        style={{ width: "100%", marginBottom: 32 }}>
                                                                    <InputLabel id="demo-mutiple-name-label3000">Operation</InputLabel>
                                                                    <Select
                                                                        labelId="demo-mutiple-name-label3000"
                                                                        id="demo-mutiple-name-label3"
                                                                        fullWidth variant="outlined"
                                                                        required
                                                                        label="Operation"
                                                                        value={this.state.selectedoperation}
                                                                        onChange={(e) => this.handleoperationchange(e)}
                                                                        >
                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='on' value='on'>
                                                                            ON
                                                                        </MenuItem>
                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='off' value='off'>
                                                                            OFF
                                                                        </MenuItem>
                                                                    </Select>
                                                                    </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <Grid className="Formfieldcss" container spacing={1}>                
                                                                    <Grid item>
                                                                        <TextField type="time" required fullWidth variant="outlined" label="Time" value={this.state.time} onChange={(e) => this.setState({time:e.target.value})}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.customerlist} maintitle='Customer' alllist='All Customers'  selectedlist='Selected Customers' />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.boardlist} maintitle='Process' alllist='All Process'  selectedlist='Selected Process' />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.loadlist} maintitle='Load' alllist='All Loads'  selectedlist='Selected Loads' />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={(event) => this.handleadrSubmit(event)}
                                                                        >
                                                                        Create
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                                <div className="login-button changepassword text-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="danger"
                                                                        onClick={(event) => this.canceladr(event)}
                                                                        >
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="pr-1" md="3">
                                                            </Col>
                                                        </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.customerlist} maintitle='Customer' alllist='All Customers'  selectedlist='Selected Customers' />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.boardlist} maintitle='Process' alllist='All Process'  selectedlist='Selected Process' />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col>
                                                            <DragMultiselectSelect selectlist={this.loadlist} maintitle='Load' alllist='All Loads'  selectedlist='Selected Loads' />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md="4">
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                            <div className="login-button changepassword text-center">
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(event) => this.generateviewADR(event)}
                                                                    >
                                                                    Generate
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                        </Col>
                                                    </Row>
                                                    {this.state.viewADR == true ? 
                                                        <div>
                                                            <hr />
                                                            <Row>
                                                            <Col md="6">
                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                                        <Grid item>
                                                                            <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>  
                                                                            <div className='control-pane'>
                                                                                <div className='control-section'>
                                                                                    <div className='datepicker-control-section'>
                                                                                        <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e)} value={this.state.defaultrange}>
                                                                                            <PresetsDirective>
                                                                                                <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                                                <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                                                <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                                                <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                                                <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                                            </PresetsDirective>
                                                                                        </DateRangePickerComponent>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Col>
                                                            <Col md="4">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                                        <Grid item>
                                                                        <InputLabel id="demo-mutiple-name-label30000">Units</InputLabel>
                                                                        <Select
                                                                            labelId="demo-mutiple-name-label30000"
                                                                            id="demo-mutiple-name-label3"
                                                                            fullWidth variant="outlined"
                                                                            required
                                                                            label="Units"
                                                                            value={this.state.selectedUnit}
                                                                            onChange={(e) => this.handleunitchange(e)}
                                                                            >
                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='kwh' value='kwh'>
                                                                                kWh
                                                                            </MenuItem>
                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='kw' value='kw'>
                                                                                kW
                                                                            </MenuItem>
                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='kva' value='kva'>
                                                                                kVA
                                                                            </MenuItem>
                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key='kvah' value='kvah'>
                                                                                kVAh
                                                                            </MenuItem>
                                                                        </Select>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <h6>Before ADR</h6>
                                                                </Col>
                                                                <Col md="12">
                                                                <div id="beforeadrchartdiv" style={{ width: "100%", height: '500px'}} options={this.beforeadrchartconfig}></div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.beforeadr.high} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-danger" /> High   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.beforeadr.low} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-warning" /> Low   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.beforeadr.total} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-warning" /> Total   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.beforeadr.avg} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-success" /> Average   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            </Row>
                                                            <hr />
                                                            <Row>
                                                                <Col md="12">
                                                                    <h6>After ADR</h6>
                                                                </Col>
                                                                <Col md="12">
                                                                <div id="afteradrchartdiv" style={{ width: "100%", height: '500px'}} options={this.afteradrchartconfig}></div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.afteradr.high} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-danger" /> High   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.afteradr.low} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-warning" /> Low   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.afteradr.total} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-warning" /> Total   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="3" md="6" sm="6" style={{padding: '10px'}}>
                                                                <div className="adrcardborder">
                                                                    <Row>
                                                                        <Col md="12" xs="12">
                                                                        <div className="numbers text-center">
                                                                            <CardTitle tag="p">
                                                                                {this.state.afteradr.avg} {this.state.selectedUnit}
                                                                            </CardTitle>
                                                                        </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className="stats text-center">
                                                                        <i className="fas fa-bolt text-success" /> Average   
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            </Row>
                                                        </div>
                                                    :
                                                    <></>
                                                    }
                                                    
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>   
                    }
                </div>
            </>           
        )
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(ADRComponent);