import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col ,TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {FormControl} from "@material-ui/core"
import TextField from '@material-ui/core/TextField';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import swal from 'sweetalert';
import onIcon from "../assets/images/status_on.png" 
import offIcon from "../assets/images/status_off.png"

export class BoardStatus extends React.Component {

    constructor(props) {
        super();
        this.state = {
            loader: false,
            showcontent: false,
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            list_board_status: [],
            shiftlist: [
                {'name':'15 Minutes','value':'15 minutes'},
                {'name':'30 Minutes','value':'30 minutes'},
                {'name':'1 Hour','value':'1 hour'},
                {'name':'6 Hours','value':'6 hours'},
                {'name':'12 Hours','value':'12 hours'},
                {'name':'1 Day','value':'1 day'},
                {'name':'3 Days','value':'3 days'},
                {'name':'7 Days','value':'7 days'}
            ],
            selectedshift: ''
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = [];
        this.daily_pri_list = [];
        this.shift_list = [
            {'name':'15 Minutes','value':'15 minutes'},
            {'name':'30 Minutes','value':'30 minutes'},
            {'name':'1 Hour','value':'1 hour'},
            {'name':'6 Hours','value':'6 hours'},
            {'name':'12 Hours','value':'12 hours'},
            {'name':'1 Day','value':'1 day'},
            {'name':'3 Days','value':'3 days'},
            {'name':'7 Days','value':'7 days'}
        ];
    }

    dailyreportForm = FormBuilder.group({
        start_date: [''],
        duration: [''],
        board_id: [[]],

    });

    componentDidMount() {
        if (localStorage.getItem('token')) {
            // this.convertdate1(new Date())
            this.props.history.push('/neurobotz/board_status')
        } else {
            this.props.history.push('/neurobotz/login')
          }
    }

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false,
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            list_board_status: [],
            selectedshift: ''
        })
        this.pri_list = [];
        this.daily_pri_list = [];
        this.dailyreportForm.patchValue({
            board_id: []
        })
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.getboardsfordropdown(localStorage.getItem('selectedcustomer'))
        this.setState({
            showcontent: true,
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            list_board_status: [],
            selectedshift: ''
        })
        this.pri_list = [];
        this.daily_pri_list = [];
        this.dailyreportForm.patchValue({
            board_id: []
        })
    }

    getboardsfordropdown(selectedcustomer) {
        this.setState({ loader: true })
        apiService.Boarddropdowndata(selectedcustomer).then(response => {
            this.setState({ loader: false, production_process_list: response.data, daily_production_process_list: response.data})
            this.pri_list = response.data
            this.daily_pri_list = response.data;
            var finalarray = []
            for(let i=0; i<= response.data.length - 1; i++) {
                finalarray.push(response.data[i]['id'])
            }
            this.dailyreportForm.patchValue({
                board_id: finalarray
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    processhandleChange(option) {
        if (option == '') {
            this.setState({
                selectedprocess: [],
            })
            this.pri_list = []
        } else {
            var test = false;
            for (let i = 0 ; i<= this.pri_list.length -1 ;i++) {
                if (this.pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj.id != option['id'])
            } else {
                this.pri_list.push(option)
            }
            this.setState({
                selectedprocess: [option.id],
            })
        }
    }

    daily_processhandleChange(option) {
        if (option == '') {
            this.setState({
                daily_selectedprocess: [],
            })
            this.dailyreportForm.patchValue({
                board_id: []
            })
            this.daily_pri_list = []
        } else {
            var test = false;
            for (let i = 0 ; i<= this.daily_pri_list.length -1 ;i++) {
                if (this.daily_pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }  
            }
            if (test == true) {
                this.daily_pri_list = this.daily_pri_list.filter((obj) => obj.id != option['id'])
            } else {
                this.daily_pri_list.push(option)
            }
            this.setState({
                daily_selectedprocess: [option.id],
            })
            this.dailyreportForm.patchValue({
                board_id: [option.id]
            })
        }
    }

    shifthandleChange(option) {
        if (option == '') {
            this.setState({
                selectedshift: ''
            })
        } else {
            this.setState({
                selectedshift: option.value
            })
        }
    }

    formatDateforlist(date) {
       return Moment(date).format('MMMM Do YYYY [at] hh:mm a')
    }

    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.dailyreportForm.patchValue({
            start_date:this.dailytoday
        })
    }

    GetboardStatus(e,formdata) {
        e.preventDefault();
        if (formdata['board_id'].length == 0) {
            apiService.Alerts_error('Meter Field Required')
        } else if (formdata['start_date'] === '' && formdata['duration'] === '') {
            apiService.Alerts_error('Either Date or Interval is Required')
        } else if (formdata['start_date']  && formdata['duration'] ) {
            apiService.Alerts_error('Interval and Date both are selected. Please select either Interval or Date.')
            this.setState({ list_board_status: [] })
        } else {
            this.setState({ loader: true})
            apiService.BoardStatus(formdata).then(response => {
                this.setState({ loader: false,list_board_status: response.data})
                console.log(response)
            }).catch(error => {
                this.setState({ loader: false,list_board_status: [] })
                apiService.ErrorMessage(error)
            })
    }
    }

    render() {
        return (
            <>
                <div className="content">
                {
                    this.state.loader && <Loader />
                }
                <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} showProcessDropdown={true}/>
                {this.state.showcontent == true ?
                    <>
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                        <CardTitle tag="h5">
                                            Board Status
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                            <FieldGroup
                                                control={this.dailyreportForm}
                                                strict= {false}
                                                render={({ get, invalid, value }) => (
                                                <form>
                                                    <Row>
                                                        <Col className="pr-1" md="4">
                                                        <FieldControl
                                                            name="duration"
                                                            strict= {false}
                                                            render={({ handler, touched, hasError }) => (
                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                                        <Grid item>
                                                                        <FormControl variant="outlined"
          margin={"1"}
          style={{ width: "100%", marginBottom: 32 }}>
                                                                        <InputLabel id="demo-mutiple-name-label300">Interval *</InputLabel>
                                                                        <Select
                                                                            labelId="demo-mutiple-name-label300"
                                                                            id="demo-mutiple-name-label300"
                                                                            fullWidth variant="outlined"
                                                                            required
                                                                            label="Interval"
                                                                            value={this.state.selectedshift}
                                                                            {...handler("text")}
                                                                            >
                                                                            <MenuItem className={"formfields shiftoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={''} value={''}>
                                                                                Select Interval
                                                                            </MenuItem>
                                                                            {this.state.shiftlist.map(option => (
                                                                                <MenuItem className={"formfields shiftoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.value} value={option.value} onClick={(e) => this.shifthandleChange(option)}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        </FormControl>
                                                                        </Grid>
                                                                        <span className="error">
                                                                            {touched
                                                                                && hasError("required")
                                                                                && `* Interval is required`}
                                                                        </span>
                                                                    </Grid>
                                                                </div>
                                                            )}
                                                            />
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                        <FieldControl
                                                            name="start_date"
                                                            strict= {false}
                                                            render={({ handler, touched, hasError }) => (
                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                                        <Grid item>
                                                                            <TextField type="date" className="customlabelplaceholder"  fullWidth variant="outlined" label="Date *" error={touched && hasError("required")} {...handler("text")}/>
                                                                        </Grid>
                                                                        <span className="error">
                                                                            {touched
                                                                                && hasError("required")
                                                                                && `* Date is required`}
                                                                        </span>
                                                                    </Grid>
                                                                </div>
                                                            )}
                                                            />
                                                        
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                        <FieldControl
                                                            name="board_id"
                                                            strict= {false}
                                                            render={({ handler, touched, hasError }) => (
                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>                
                                                                        <Grid item>
                                                                        <FormControl variant="outlined"
          margin={"1"}
          style={{ width: "100%", marginBottom: 32 }}>
                                                                        <InputLabel id="demo-mutiple-name-label30">Meter *</InputLabel>
                                                                        <Select
                                                                            labelId="demo-mutiple-name-label3"
                                                                            id="demo-mutiple-name-label3"
                                                                            fullWidth variant="outlined"
                                                                            multiple required
                                                                            label="Meter"
                                                                            value={this.state.daily_selectedprocess}
                                                                            {...handler("text")}
                                                                            >
                                                                            {this.state.daily_production_process_list.map(option => (
                                                                                <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.daily_processhandleChange(option)}>
                                                                                    {option.itemName}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        </FormControl>
                                                                        </Grid>
                                                                        <span className="error">
                                                                            {touched
                                                                                && hasError("required")
                                                                                && `* Process is required`}
                                                                        </span>
                                                                    </Grid>
                                                                </div>
                                                            )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md="4">
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                            <div className="login-button changepassword text-center">
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={invalid}
                                                                    onClick={(event) => this.GetboardStatus(event,this.dailyreportForm.getRawValue())}
                                                                    >
                                                                    Get Status
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col className="pr-1" md="4">
                                                        </Col>
                                                    </Row>
                                                </form>
                                                )}
                                            /> 
                                            <hr />
                                            <Row>
                                                {this.state.list_board_status.length !== 0
                                                ?
                                                <Col md="12" style={{display: 'contents'}}>
                                                    {this.state.list_board_status.map(option => (
                                                        <Col md="4">
                                                            <div style={{border: '2px solid black', margin: '10px 0px'}}>
                                                                <Row>
                                                                    <Col md="12" style={{textAlign: 'center'}}>
                                                                        <span className="boardstatus_boardname" style={{fontSize: '22px'}}>{option.board_id}  <span className="boardstatus_boardicon">{option.status === 1 ? <img src={onIcon} height="45px" width="45px"></img> : <img src={offIcon} height="45px" width="45px"></img>}</span></span>
                                                                    </Col>
                                                                </Row>
                                                                <hr style={{backgroundColor:'black', margin: '0px'}}/>
                                                                <Row>
                                                                    <Col md="12" style={{textAlign: 'left',paddingLeft:'25px'}}>
                                                                        <span className="" style={{fontSize: '15px'}}>Last Data recd. - <b>{this.formatDateforlist(option.created_at)}</b></span>
                                                                    </Col>
                                                                </Row>
                                                            </div>    
                                                        </Col>
                                                    ))}
                                                </Col>
                                                :
                                                <Col md="12">
                                                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Select the interval</div>
                                                </Col>
                                                }
                                                
                                            </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    :
                    <Row>
                        <Col md="12">
                            <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      theme: state.theme,
      themecolor: state.themecolor,
      charttheme: state.charttheme 
    }
}
  
export default connect(mapStateToProps)(BoardStatus);