import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Button
} from "reactstrap";
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import {initialRoute} from "../routes"
import {v3_server_check} from "../routes"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);


class Dashboard extends React.Component {
  constructor(props) {
    super();
    this.state = {
        uom: 'kWh',
        currency: '',
        currentyear: '',
        currentmonth: '',
        trackusage: [],
        trackusagecost: [],
        prefix: '',
        suffix: '',
        loader: false,
        radiovalue: 'kWh',
        energy_so_far_today : '',
        energy_currently_consumping : '',
        energy_7_day_average : '',
        cost_so_far_today : '',
        cost_currently_consumping : '',
        cost_7_day_average : '',
        showcontent: false, 
        dial_data: [],  
        from_date: '',
        to_date: '',
        total_consumption : '',
        total_cost : ''
    }
    this.customerhandler = this.customerhandler.bind(this)
    this.boardhandler = this.boardhandler.bind(this)
    this.selectedcustomer = ''
    this.selectedboard = '' 
    this.selecteduom = 'kWh'
    this.trackusageconfig = {}
    this.trackusagecostconfig = {}
    this.today = new Date(new Date().toDateString());
    this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
    this.monthEnd = this.today;
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      // this.props.history.push("dashboard")
      const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
      ];
      this.setState({currency: localStorage.getItem('currency'), currentyear: 1900 + new Date().getYear(), currentmonth: monthNames[new Date().getMonth()]});
      if (localStorage.getItem('selectedboard')) {
        this.setState({
          showcontent: true
        })
      }
      this.currency_selected()
      this.convertdate(this.monthStart,this.monthEnd)
     
    } else {
      this.props.history.push('/neurobotz/login')
    }
  }
  currency_selected(){
    var x=localStorage.getItem('currency')
    this.setState({suffix : x,currency:x})

  }

  dialForm = FormBuilder.group({
    from_date: ["", [Validators.required]],
    to_date: ["", [Validators.required]]
  });

  unithandleChange(event){
    event.preventDefault();
    this.selecteduom = event.target.value
    this.trackusageconfig = {}
    this.setState({
      radiovalue: event.target.value,
      uom: event.target.value
    })
    this.getdialresponse(this.selectedboard,this.state.from_date,this.state.to_date);
    this.getcardboxesresponse(this.selectedboard,this.today);
    this.getrealchartresponse(this.selectedboard,this.today)
    // console.log(this.state.radiovalue,this.selectedboard,this.state.from_date,this.state.to_date)
  }

  convertdate(monthStart,monthEnd) {
    const sy = monthStart.getFullYear();
    const sm = ("0" + (monthStart.getMonth() + 1)).slice(-2);
    const sd = ("0" + monthStart.getDate()).slice(-2);
    const ey = monthEnd.getFullYear();
    const em = ("0" + (monthEnd.getMonth() + 1)).slice(-2);
    const ed = ("0" + monthEnd.getDate()).slice(-2);
    var from_date = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    var to_date = ey.toString() + '-' + em.toString() + '-' + ed.toString();
    this.dialForm.patchValue({
      from_date: from_date,
      to_date: to_date
    })
    this.setState({
      from_date: from_date,
      to_date: to_date
    })
  }

  getdialresponse(board_id,from_date,to_date) {
    if(v3_server_check()==true){
      this.dial_v3(board_id,from_date,to_date)

    }else{
     this.dial_v2(board_id,from_date,to_date)
    }

   
  }
  dial_v2(board_id,from_date,to_date){
    this.setState({ loader: true })
    apiService.NewDashboarddialv2(board_id,from_date,to_date,this.selecteduom).then(response => {
      var data = this.selecteduom === 'kWh' ? response.data.dial_data : response.data.dial_data_kvah
      for (let i = 0 ; i <= data.length-1 ; i++ ) {
        var lastStr = data[i]['date'].split("-").splice(-1)[0];
        var lastButStr= data[i]['date'].split("-").splice(-2)[0];
        data[i]['new_category'] = lastButStr + '-' + lastStr
      }
      // console.log(data)
      this.createchart1(data,this.props.theme);
        this.setState({ loader: false, dial_data: data,
        total_consumption: this.selecteduom === 'kWh' ? response.data.sum[0].total_value : response.data.sum_kvah[0].total_value_kvah, total_cost : this.selecteduom === 'kWh' ?  response.data.sum[0].total_cost_value : response.data.sum_kvah[0].total_cost_value_kvah }) 
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
  }
  dial_v3(board_id,from_date,to_date){
    this.setState({ loader: true })
    apiService.NewDashboarddial(board_id,from_date,to_date).then(response => {
        this.setState({ loader: false, dial_data: response.data.dial_data.dial_data,
        total_consumption: response.data.dial_data.total_data.consumption, total_cost : response.data.dial_data.total_data.cost })
        for (let i = 0 ; i <= response.data.dial_data.dial_data.length-1 ; i++ ) {
          var lastStr = response.data.dial_data.dial_data[i]['date'].split("-").splice(-1)[0];
          var lastButStr= response.data.dial_data.dial_data[i]['date'].split("-").splice(-2)[0];
          response.data.dial_data.dial_data[i]['new_category'] = lastButStr + '-' + lastStr
        }
        this.createchart1(response.data.dial_data.dial_data,this.props.theme);
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
  }

  getrealchartresponse(board_id,today) {
    if(v3_server_check()==true){
      this.line_charts_v3(board_id,today)

    }else{
     this.line_charts_v2(board_id,today)
    }

  }
  line_charts_v2(board_id,today){
    const sy = today.getFullYear();
    const sm = today.getMonth() + 1;
    const sd = today.getDate();
    var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    this.setState({ loader: true })
    apiService.NewDashboardrealtimev2(dates,dates,board_id,this.selecteduom).then(response => {
      this.setState({ loader: false, trackusage:  this.selecteduom === ' kWh' ? response.data.chart_data[0].kwh : response.data.chart_data[0].kVAh, trackusagecost: response.data.chart_data[0].cost}) 
      this.createchart3(this.selecteduom === 'kWh' ? response.data.chart_data[0].kwh : response.data.chart_data[0].kVAh,this.selecteduom,this.props.charttheme);
        this.createchart4(response.data.chart_data[0].cost,this.state.uom,this.props.charttheme); 
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
  }

  line_charts_v3(board_id,today) {
    const sy = today.getFullYear();
    const sm = today.getMonth() + 1;
    const sd = today.getDate();
    var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    this.setState({ loader: true })
    apiService.NewDashboardrealtime(dates,dates,'kWh',board_id,1).then(response => {
        this.setState({ loader: false, trackusage:  response.data.chart_data[0].kwh_cost, trackusagecost: response.data.chart_data[0].kwh_cost})
        this.createchart3(response.data.chart_data[0].kwh_cost,this.state.uom,this.props.charttheme);
        this.createchart4(response.data.chart_data[0].kwh_cost,this.state.uom,this.props.charttheme); 
    }).catch(error => {
        this.setState({ loader: false })
        apiService.ErrorMessage(error)
    })
  }

  getcardboxesresponse(board_id,today) {
    if(v3_server_check()==true){
      this.card_data_v3(board_id,today)

    }else{
     this.card_data_v2(board_id,today)
    }
  }
card_data_v2(board_id,today){
  const sy = today.getFullYear();
  const sm = today.getMonth() + 1;
  const sd = today.getDate();
  var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
  this.setState({ loader: true })
  apiService.NewDashboardcardboxesv2(board_id,this.selecteduom).then(response => {
      this.setState({ loader: false })
      this.setState({
        energy_7_day_average: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['kwh']['kwh_30days_avg'] : response.data.dashboard_data_kvah[0]['kVAh']['kVAh_30days_avg'],
        energy_so_far_today: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['kwh']['kwh_so_far'] : response.data.dashboard_data_kvah[0]['kVAh']['kVAh_so_far'],
        energy_currently_consumping: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['currently_consuming'] : response.data.dashboard_data_kvah[0]['currently_consuming'],
        cost_7_day_average: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['cost']['cost_30days_avg'] : response.data.dashboard_data_kvah[0]['cost']['cost_30days_avg'],
        cost_currently_consumping: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['monthly_cost'] : response.data.dashboard_data_kvah[0]['monthly_cost'],
        cost_so_far_today: this.selecteduom === 'kWh' ? response.data.dashboard_data[0]['cost']['cost_so_far'] : response.data.dashboard_data_kvah[0]['cost']['cost_so_far'],
      })
  }).catch(error => {
      this.setState({ loader: false })
      apiService.ErrorMessage(error)
  })
}
card_data_v3(board_id,today) {
  const sy = today.getFullYear();
  const sm = today.getMonth() + 1;
  const sd = today.getDate();
  var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
  this.setState({ loader: true })
  apiService.NewDashboardcardboxes(dates,dates,board_id).then(response => {
      this.setState({ loader: false })
      this.setState({
        energy_7_day_average: response.data.dashboard_data[0]['kwh']['kwh_30days_avg'],
        energy_so_far_today: response.data.dashboard_data[0]['kwh']['kwh_so_far'],
        energy_currently_consumping: response.data.dashboard_data[0]['currently_consuming'],
        cost_7_day_average: response.data.dashboard_data[0]['cost']['cost_30days_avg'],
        cost_currently_consumping: response.data.dashboard_data[0]['monthly_cost'],
        cost_so_far_today: response.data.dashboard_data[0]['cost']['cost_so_far'],
      })
  }).catch(error => {
      this.setState({ loader: false })
      apiService.ErrorMessage(error)
  })
}
  componentDidUpdate(prevProps) {
    if (prevProps.theme != this.props.theme) {
      this.createchart1(this.state.dial_data,this.props.theme);  
      this.createchart3(this.state.trackusage,this.selecteduom,this.props.charttheme);
      this.createchart4(this.state.trackusagecost,this.state.uom,this.props.charttheme);
    }
  }

  createchart1(dial_data,theme) {
    // console.log(dial_data)
    if (theme == 'darktheme' || theme == 'greytheme') {
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.unuseTheme(am4themes_dark);
    } 
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.logo.disabled = true;
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
chart.exporting.menu.verticalAlign = "top";
    // will use this to store colors of the same items
    var colors = {};
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "new_category";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataItems.template.text = "{new_category}";
    categoryAxis.adapter.add("tooltipText", function(tooltipText, target){
      return categoryAxis.tooltipDataItem.dataContext.new_category;
    })
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = this.state.radiovalue;
    valueAxis.tooltip.disabled = true;
    valueAxis.min = 0;
    // single column series for all data
    var columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.columns.template.width = am4core.percent(80);
    columnSeries.tooltipText = "{new_category}: {valueY} " + this.state.radiovalue;
    columnSeries.dataFields.categoryX = "new_category";
    columnSeries.dataFields.valueY = this.selecteduom === 'kWh' ? "total" : 'total_kvah';
    // second value axis for quantity
    var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = this.state.prefix + " Cost " + this.state.suffix;
    valueAxis2.renderer.opposite = true;
    valueAxis2.syncWithAxis = valueAxis;
    valueAxis2.tooltip.disabled = true;
    // quantity line series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.tooltipText = "{new_category}:" + this.state.prefix + " {valueY} " + this.state.suffix;
    lineSeries.dataFields.categoryX = "new_category";
    lineSeries.dataFields.valueY = this.selecteduom === 'kWh' ? "totalcost" : 'total_cost_kvah';
    lineSeries.yAxis = valueAxis2;
    var bullets = lineSeries.bullets.push(new am4charts.CircleBullet());
    lineSeries.stroke = am4core.color("black");
    lineSeries.fill = lineSeries.stroke;
    lineSeries.strokeWidth = 2;
    bullets.circle.radius = 2;
    lineSeries.snapTooltip = true;
    lineSeries.events.on("datavalidated", function(){
     lineSeries.dataItems.each(function(dataItem){
       if(dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)){
       dataItem.setLocation("categoryX", 0);
       }
       else{
        dataItem.setLocation("categoryX", 0.5);
       }
     })
    })
    columnSeries.columns.template.adapter.add("fill", function(fill, target) {
     var name = target.dataItem.dataContext.date;
     if (!colors[name]) {
       colors[name] = chart.colors.next();
     }
     target.stroke = colors[name];
     return colors[name];
    })
    var rangeTemplate = categoryAxis.axisRanges.template;
    rangeTemplate.tick.disabled = false;
    rangeTemplate.tick.location = 0;
    rangeTemplate.tick.strokeOpacity = 0.6;
    rangeTemplate.tick.length = 60;
    rangeTemplate.grid.strokeOpacity = 0.5;
    rangeTemplate.label.tooltip = new am4core.Tooltip();
    rangeTemplate.label.tooltip.dy = -10;
    rangeTemplate.label.cloneTooltip = false;
    chart.data = dial_data;
    this.chart = chart;
  }

  createchart3(data,uom,theme) {
    this.trackusageconfig = AmCharts.makeChart("chartdiv1",{
      'type': 'serial',
      "theme": theme,
      'hideCredits': true,
      'marginRight': 80,
      'dataProvider': data,
      'legend': {
        'useGraphSettings': true,
        'labelText': 'Total Active Energy',
        'align' : 'right',
        'position' : 'bottom'
      },
      'valueAxes': [{
          'position': 'left',
          'title': uom
      }],
      'graphs': [{
        'id': 'g1',
        'valueField': 'value',
        'balloonText': '[[value]] '+ uom ,
        'bullet': 'round',
        'bulletBorderAlpha': 1,
        'bulletColor': '#FFFFFF',
        'hideBulletsCount': 50,
        'title': 'purple line',
        'useLineColorForBulletBorder': true,
        'lineColor': '#9c27b0',
        'balloon': {
            'drop': true
        },
      }],
      'chartScrollbar': {
        'graph': 'g1',
        'scrollbarHeight': 40,
        'backgroundAlpha': 0,
        'selectedBackgroundAlpha': 0.1,
        'selectedBackgroundColor': '#888888',
        'graphFillAlpha': 0,
        'graphLineAlpha': 0.5,
        'selectedGraphFillAlpha': 0,
        'selectedGraphLineAlpha': 1,
        'autoGridCount': true,
        'color': '#AAAAAA'
      },
      'chartCursor': {
          'categoryBalloonDateFormat': 'JJ:NN, DD MMMM',
          'cursorPosition': 'mouse'
      },
      'categoryField': 'time',
      'categoryAxis': {
          'minPeriod': 'mm',
          'parseDates': true
      },
      "responsive": {
        "enabled": true
      },
      'export': {
          'enabled': true,
          'dateFormat': 'YYYY-MM-DD HH:NN:SS'
      }
    });
  }

  createchart4(data,uom,theme) {
    this.trackusagecostconfig = AmCharts.makeChart("chartdiv2",{
      'type': 'serial',
      "theme": theme,
			    'hideCredits': true,
			    'marginRight': 80,
			    'dataProvider': data,
			    'legend': {
				    'useGraphSettings': true,
				    'labelText': 'Total Active Energy Cost',
				    'align' : 'right',
				    'position' : 'bottom'
				  },
			    'valueAxes': [{
			        'position': 'left',
			        'title': 'Cost'
			    }],
			    'graphs': [{
			        'id': 'g2',
			        'valueField': 'value',
			        'balloonText': this.state.prefix +' [[value]] '+ this.state.suffix,
			        'bullet': 'round',
			        'bulletBorderAlpha': 1,
			        'bulletColor': '#FFFFFF',
			        'hideBulletsCount': 50,
			        'title': 'red line',
					'useLineColorForBulletBorder': true,
					'lineColor': '#357a38',
			        'balloon': {
			            'drop': true
			        },
			    }],
			    'chartScrollbar': {
			        'graph': 'g2',
			        'scrollbarHeight': 40,
			        'backgroundAlpha': 0,
			        'selectedBackgroundAlpha': 0.1,
			        'selectedBackgroundColor': '#888888',
			        'graphFillAlpha': 0,
			        'graphLineAlpha': 0.5,
			        'selectedGraphFillAlpha': 0,
			        'selectedGraphLineAlpha': 1,
			        'autoGridCount': true,
			        'color': '#AAAAAA'
			    },
			    'chartCursor': {
			        'categoryBalloonDateFormat': 'JJ:NN, DD MMMM',
			        'cursorPosition': 'mouse'
			    },
			    'categoryField': 'time',
			    'categoryAxis': {
			        'minPeriod': 'mm',
			        'parseDates': true
          },
          "responsive": {
            "enabled": true
          },
			    'export': {
			        'enabled': true,
              'dateFormat': 'YYYY-MM-DD HH:NN:SS'
			    }
        });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  customerhandler(customerId) {
    this.selectedcustomer = customerId
    this.setState({
      showcontent: false
    })

  }

  boardhandler(board_id) {
    this.selectedboard = board_id
    this.currency_selected()

    this.getdialresponse(board_id,this.state.from_date,this.state.to_date);
    this.getrealchartresponse(board_id,this.today);
    this.getcardboxesresponse(board_id,this.today);
    // this.getnewrealchartresponse(board_id,this.today)
    this.setState({
      showcontent: true
    })
  }

  filterdial(event,value) {
    event.preventDefault();
    var testdate = new Date(new Date().toDateString())
    const sy = testdate.getFullYear();
    const sm = ("0" + (testdate.getMonth() + 1)).slice(-2);
    const sd = ("0" + testdate.getDate()).slice(-2);
    var today = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
    if (value.from_date > value.to_date) {
      apiService.Alerts_info('From date must be less than To date')
    } else if (value.from_date > today) {
      apiService.Alerts_info('Future date cannot be accepted')
    } else if (value.to_date > today) {
      apiService.Alerts_info('Future date cannot be accepted')
    } else {
      var date1 = new Date(value.from_date); 
      var date2 = new Date(value.to_date); 
      var Difference_In_Time = date2.getTime() - date1.getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      // console.log(Difference_In_Days)
      if (Difference_In_Days > 45) {
        apiService.Alerts_info('Date range should not exceed 45 Days')
      } else {
        this.setState({
          from_date: value.from_date,
          to_date: value.to_date
        })
        this.getdialresponse(this.selectedboard,value.from_date,value.to_date)
      }
    }
    
  }

  render() {
    return (
      <>
        <div className="content">
        {
                this.state.loader && <Loader />
            }
        <TopNavDropdown  customerhandler={this.customerhandler} selectedcustomer= {this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard}/>
        {this.state.showcontent == true ?
        <><Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Monthly Consumption / Cost - {this.state.uom} / {this.state.currency} <span className="cardtitle-timetitle">({this.state.from_date} - {this.state.to_date})</span> </CardTitle>
                  <div className="card-category">
                  <FieldGroup
                    control={this.dialForm}
                    strict= {false}
                    render={({ get, invalid, value }) => (
                    <form>
                        <Row>
                            <Col className="pr-1" md="3">
                              <FieldControl
                                  name="from_date"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                      <Grid className="Formfieldcss" container spacing={1}>                
                                          <Grid item>
                                              <TextField type="date" required fullWidth variant="outlined" label="From Date" error={touched && hasError("required")}  {...handler("text")} />
                                          </Grid>
                                          <span className="error">
                                              {touched
                                                  && hasError("required")
                                                  && `* From Date is required`}
                                          </span>
                                      </Grid>
                                  </div>
                                  )}
                                />
                            </Col>
                            <Col className="pr-1" md="3">
                              <FieldControl
                                  name="to_date"
                                  strict= {false}
                                  render={({ handler, touched, hasError }) => (
                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                      <Grid className="Formfieldcss" container spacing={1}>                
                                          <Grid item>
                                              <TextField type="date" required fullWidth variant="outlined" label="To Date" error={touched && hasError("required")}  {...handler("text")} />
                                          </Grid>
                                          <span className="error">
                                              {touched
                                                  && hasError("required")
                                                  && `* To Date is required`}
                                          </span>
                                      </Grid>
                                  </div>
                                  )}
                                />
                            </Col>
                            <Col className="pr-1" md="4">
                                <FormControl>
                                  <FormLabel id="demo-row-radio-buttons-group-label">Unit</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={this.state.radiovalue}
                                    onChange={(e) => this.unithandleChange(e)}
                                  >
                                    <FormControlLabel value="kWh" control={<Radio />} label="kWh" checked={this.state.radiovalue === 'kWh'} />
                                    <FormControlLabel value="kVAh" control={<Radio />} label="kVAh" checked={this.state.radiovalue === 'kVAh'} />
                                  </RadioGroup>
                                </FormControl>
                            </Col>
                            <Col className="pr-1" md="2">
                              <div className="login-button changepassword text-center">
                                  <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      disabled={invalid}
                                      className="flexblockbutton"
                                      onClick={(event) => this.filterdial(event,this.dialForm.getRawValue())}
                                      >
                                      Filter
                                  </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      )}
                    />
                  </div>
                </CardHeader>
                <CardBody>
                <div id="chartdiv" style={{ width: "100%", height: "350px" }} className="sorafontclass"></div>
                <Row>
                  <Col className="pr-1 text-center" md="6">
                    <span className="cardtitle-timetitle dialtotal"><i className={"fas fa-bolt newcolorblue " + (this.props.theme) + ' ' + (this.props.themecolor)} /> {this.state.total_consumption} {this.state.radiovalue}</span>
                    </Col>
                    <Col className="pr-1 text-center" md="6">
                    <span className="cardtitle-timetitle dialtotal"><i className="fas fa-money-bill newcolorgreen" /> {this.state.prefix} {this.state.total_cost} {this.state.suffix}</span>
                  </Col>
                </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="fa fa-calendar" /> This Month
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Track Your Usage</CardTitle>
                  <p className="card-category">{this.state.currentyear} - {this.state.currentmonth}</p>
                </CardHeader>
                <CardBody>
                <div id="chartdiv1" style={{ width: "100%", height: "500px" }} options={this.trackusageconfig} className="sorafontclass"/>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="far fa-clock" /> So Far Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Consumption</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_so_far_today} {this.selecteduom}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers ">
                        <p className="card-category">Energy Consumption</p>
                        <CardTitle tag="p" >{this.state.energy_so_far_today} kWh</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row> */}
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> So Far Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-success" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Consumption</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_currently_consumping} {this.selecteduom}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-success" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">Energy Consumption</p>
                        <CardTitle tag="p">{this.state.energy_currently_consumping} kWh</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row> */}
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> Currently Consuming
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-danger" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Consumption</p>
                        <CardTitle tag="p" className="newsizetext">{this.state.energy_7_day_average} {this.selecteduom}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-bolt text-danger" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">Energy Consumption</p>
                        <CardTitle tag="p">{this.state.energy_7_day_average} kWh</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row> */}
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> 07 Days Average
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader>
                  <CardTitle tag="h5">Track Your Bill</CardTitle>
                  <p className="card-category">{this.state.currentyear} - {this.state.currentmonth}</p>
                </CardHeader>
                <CardBody>
                <div id="chartdiv2" style={{ width: "100%", height: "500px" }} options={this.trackusagecostconfig} className="sorafontclass"/>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="far fa-clock" /> So Far Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-money-bill text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Cost</p>
                        <CardTitle tag="p"  className="newsizetext">{this.state.prefix} {this.state.cost_so_far_today} {this.state.suffix}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> So Far Today
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-money-bill text-success" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Cost</p>
                        <CardTitle tag="p"  className="newsizetext">{this.state.prefix} {this.state.cost_currently_consumping} {this.state.suffix}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> Currently Consuming
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardBody>
                  <Row>
                    <Col md="3" xs="4">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-money-bill text-danger" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers text-center">
                        <p className="card-category newsize">Energy Cost</p>
                        <CardTitle tag="p"  className="newsizetext">{this.state.prefix} {this.state.cost_7_day_average} {this.state.suffix}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> 07 Days Average
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row></>
          :
          <Row>
            <Col md="12">
              <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                <CardHeader> 
                </CardHeader>
                <CardBody>
                <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    themecolor: state.themecolor,
    charttheme: state.charttheme 
  }
}

export default connect(mapStateToProps)(Dashboard);
